import { Permission } from '@campxdev/shared'

import {
  AssessmentRules,
  AssessmentTemplates,
  AssessmentTypes,
} from '../pages/Academics'

export const assessmentRoutes = [
  {
    path: 'assessment-types',
    element: <AssessmentTypes />,
    permissionKey: Permission.ASSESSMENT_TYPE_VIEW,
  },
  {
    path: 'assessment-templates',
    element: <AssessmentTemplates />,
    permissionKey: Permission.CAN_ASSESSMENT_TEMPLATES_VIEW,
  },
  {
    path: 'assessment-templates/:assessmentTemplateId/assessment-rules',
    element: <AssessmentRules />,
    permissionKey: Permission.CAN_ASSESSMENT_RULES_VIEW,
  },
]
