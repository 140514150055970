const dashboardImage = require('./png/dashboard.png')
const CampusEventsIllustartion = require('./png/CampusEvents.png')
const CommingSoonImage = require('./png/img.png')
const UpiLogo = require('./png/UPILogo3x.png')
const creditCardIcon = require('./png/credit-card3x.png')
const debitCardIcon = require('./png/debit-card3x.png')
const mobileBankingIcon = require('./png/mobile-banking3x.png')
const emptyPage = require('./png/empty-page.png')
const formImage = require('./png/FormImage.png')
const girlWriting = require('./png/girl-writing.png')
const wait = require('./png/wait.png')
const sessionIcon = require('./png/session_icon.png')
const auLogo = require('./png/AULogo.png')
const auisclogo = require('./png/AUISCLogo.png')
const iuceelogo = require('./png/IUCEELogo.png')
const eventiternary = require('./png/itinerary .png')

export {
  CampusEventsIllustartion,
  CommingSoonImage,
  UpiLogo,
  auLogo,
  auisclogo,
  creditCardIcon,
  dashboardImage,
  debitCardIcon,
  emptyPage,
  eventiternary,
  formImage,
  girlWriting,
  iuceelogo,
  mobileBankingIcon,
  sessionIcon,
  wait,
}
