import {
  CourseSelector,
  ProgramSelector,
  SingleSelect,
  batchOptions,
} from '@campxdev/shared'
import { Box, Stack } from '@mui/material'

export const ClassroomCardsFilters = ({ filters, setFilters }) => {
  return (
    <Stack direction={'row'} gap={2} mb={3}>
      <Box sx={{ width: '250px' }}>
        <SingleSelect
          name="batch"
          options={[
            { value: 'all', label: 'All' },
            ...batchOptions.map((item, index) => ({
              label: item,
              value: item,
            })),
          ]}
          value={filters.batch ? filters.batch : 'all'}
          onChange={(e) => {
            setFilters((prev) => ({
              ...prev,
              batch: e.target.value === 'all' ? null : e.target.value,
            }))
          }}
          label="Filter By Batch"
        />
      </Box>
      <Box sx={{ width: '300px' }}>
        <CourseSelector
          name="courseId"
          onChange={(e) => {
            setFilters((prev) => ({
              ...prev,
              courseId: e.target.value === 'all' ? null : e.target.value,
              programId: null,
            }))
          }}
          value={filters.courseId ? filters.courseId : 'all'}
          label="Filter By Course"
        />
      </Box>
      <Box sx={{ width: '300px' }}>
        <ProgramSelector
          name="programId"
          onChange={(e) => {
            setFilters((prev) => ({
              ...prev,
              programId: e.target.value === 'all' ? null : e.target.value,
            }))
          }}
          value={filters.programId ? filters.programId : 'all'}
          label="Filter By Program"
          filters={{ courseId: filters.courseId }}
        />
      </Box>
    </Stack>
  )
}
