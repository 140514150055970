import { ErrorBoundary, Helmet, Spinner, useAuth } from '@campxdev/shared'
import { Outlet } from 'react-router-dom'

function PrintLayout() {
  const { data, loading } = useAuth({
    permissionsEndpoint: '/auth-server/auth/my-permissions',
  })

  if (loading) {
    return <Spinner />
  }
  return (
    <>
      <Helmet
        user={data?.user}
        appTitle="Digital Campus | Campx"
        favicon={data?.assets?.logo_square}
        description={'University management system'}
      />
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </>
  )
}

export default PrintLayout
