import { queryClient } from '@campxdev/shared/src/contexts/QueryClientProvider'
import moment from 'moment'

import { Store } from 'pullstate'
import { toast } from 'react-toastify'

import { axios } from '@campxdev/shared'
const upiIcon = require('../../assets/images/png/UPILogo3x.png')

export const verfiyOrder = async (orderId: any) => {
  const res = await axios.get(`/paymentx/online-transactions/verify-order`, {
    params: {
      orderId: orderId,
    },
  })
  return res.data
}

export const billDeskModes = {
  netbanking: 'nb',
  credit_card: 'card',
  debit_card: 'card',
  upi: 'upi',
}

export const TimelineStepStatus = {
  INITIATED: 0,
  IN_PROGRESS: 1,
  PAYMENT_DONE: 2,
  PAYMENT_FAILED: 2,
  SUCCESS: 3,
  FAILED: 3,
}

export const acknowledgePayment = async (
  razorPayRes: any,
  id: string,
  type: string,
  options: {
    admissionId: any
    amount: any
    feeTypes: any
    year: string
    paymentMethod?: string
  },
  formState: any,
  updateStatus: any,
) => {
  const paytmParams = {
    type: type,
    orderId: razorPayRes?.ORDERID ?? razorPayRes?.razorpayOrderId,
  }

  const razorPayParams = {
    type: type,
    orderId: razorPayRes?.razorpay_order_id ?? razorPayRes?.orderId,
    paymentId:
      razorPayRes?.razorpay_payment_id ?? razorPayRes?.razorpayPaymentId,
    signature:
      razorPayRes?.razorpay_signature ?? razorPayRes?.razorpaySignature,
  }

  try {
    const data = await axios
      .post(
        `/paymentx/online-transactions/make-payment`,
        {
          admissionId: options?.admissionId,
          paymentMethod: options?.paymentMethod,
          paidBy: null,
          paidDate: moment(new Date()).format('YYYY-MM-DD'),
          feeSessionId: 1,
          referenceNo:
            type == 'PAYTM'
              ? !!razorPayRes.ORDERID
                ? razorPayRes.ORDERID
                : razorPayRes?.orderId
              : !!razorPayRes?.orderId
              ? razorPayRes?.orderId
              : razorPayRes?.razorpay_order_id,
          year: options?.year,
          amount: options?.amount,
          feeTypes: options?.feeTypes,
          orderId:
            type == 'PAYTM'
              ? !!razorPayRes.ORDERID
                ? razorPayRes.ORDERID
                : razorPayRes?.orderId
              : !!razorPayRes?.orderId
              ? razorPayRes?.orderId
              : razorPayRes?.razorpay_order_id,
        },
        {
          params: type == 'PAYTM' ? paytmParams : razorPayParams,
          headers: { 'x-api-version': 1 },
        },
      )
      .then(async (result) => {
        await axios
          .get('/paymentx/online-transactions/verify-order', {
            params: {
              orderId:
                type == 'PAYTM'
                  ? !!razorPayRes.ORDERID
                    ? razorPayRes.ORDERID
                    : razorPayRes?.orderId
                  : !!razorPayRes?.orderId
                  ? razorPayRes?.orderId
                  : razorPayRes?.razorpay_order_id,
            },
          })
          .then((res) => {
            updateStatus({
              formResponseId: res.data.order.notes.formResponseId,
              razorPayDetails: {
                paymentId: res.data.paymentId,
                razorpayOrderId: res.data.orderId,
                // razorpaySignature: rzPayRes.razorpay_signature,
              },
            })
          })
      })
  } catch (error) {
    const status = await axios
      .get('/paymentx/online-transactions/verify-order', {
        params: {
          orderId:
            type == 'PAYTM'
              ? !!razorPayRes.ORDERID
                ? razorPayRes.ORDERID
                : razorPayRes?.orderId
              : !!razorPayRes?.orderId
              ? razorPayRes?.orderId
              : razorPayRes?.razorpay_order_id,
        },
      })
      .then((res) => res.data)
    studentPaymentStore.update((s) => {
      s.timelineStep = 3
      s.timelineStatus = status.status
    })
    toast.error(
      error?.response?.data?.message ??
        'Unable to complete the payment process',
    )
  }
}
export const billDeskConfig = ({
  payload,
  onSuccess,
  studentStore,
  setLoading,
}) => {
  const responseHandler = function (txn) {
    onSuccess(txn)
    queryClient.invalidateQueries('verify-order')
    studentStore.update((s) => {
      s.timelineStep = 0
      s.timelineStatus = 'INITIATE'
    })
    // eslint-disable-next-line no-console
    console.log('callback received status:: ', txn.status)
    if (txn.status === 111) {
      setLoading(false)
    }
    // eslint-disable-next-line no-console
    console.log('callback received response:: ', txn.response)
  }

  const billDeskWindow = window as any
  const theme_config = {
    sdkPrimaryColor: '#1E19F5',
    sdkAccentColor: '#1E19F5',
    sdkBackgroundColor: '#ffff',
    sdkBannerColor: '#1E19F5',
  }

  // urlToBase64Image(campxLogo, (base64Image, error) => {
  //   if (error) {
  //     axiosErrorToast(error)
  //   } else {
  //     const config = {
  //       responseHandler: responseHandler,
  //       merchantLogo: base64Image,
  //       flowConfig: payload,
  //       flowType: 'payments',
  //       themeConfig: theme_config,
  //     }

  //     billDeskWindow.loadBillDeskSdk(config)
  //   }
  // })

  const config = {
    responseHandler: responseHandler,
    // merchantLogo: base64Image,
    flowConfig: payload,
    flowType: 'payments',
    themeConfig: theme_config,
  }

  billDeskWindow.loadBillDeskSdk(config)
}

export const studentPaymentStore = new Store({
  year: null,
  feeDetails: null,
  feeGroups: null,
  admission: null,
  fees: null,
  previousDue: null,
  currentDue: null,
  totalAmountPayable: 0,
  paymentMethodOptions: null,
  paymentCharges: null,
  paymentMethod: null,
  paymentMethodIcon: null,
  bankPayment: 'other',
  bankPaymentMethodIcon: upiIcon,
  rzrPayRes: null,
  rzyPayKey: null,
  clientLogo: null,
  type: null,
  airpayData: null,
  airpayCredentials: null,
  billDeskData: null,
  billDeskCredentials: null,
  billDeskV2Data: null,
  payable: 0,
  totalPayable: 0,
  timelineStep: 0,
  timelineStatus: 'INITIATED',
  paymentData: null,
  paymentStatus: false,
  paymentError: null,
  totalFee: 0,
  oldData: null,
})

export function getAuthToken(orderResponse) {
  const link = orderResponse?.links?.find((links) => links.rel == 'redirect')
  return link?.headers?.authorization
}

export const initiateBillDeskConfig = async (
  options: any,
  onSuccess: (response) => void,
  setLoading?: any,
) => {
  billDeskConfig({
    payload: options,
    onSuccess,
    studentStore: studentPaymentStore,
    setLoading,
  })
}
