import {
  ActionButton,
  Card,
  CardsGrid,
  DrawerButton,
  FilterButton,
  LabelValue,
  NoDataIllustration,
  PageContent,
  PageHeader,
  SearchBar,
  Spinner,
} from '@campxdev/shared'
import { Stack, Typography } from '@mui/material'
import { formImage } from 'assets/images'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'
import CreateProjectForm from './Forms/CreateProjectForm'
import ProjectFilter from './Forms/ProjectFilter'
import { FundingSources, ProjectStatus, fetchProjects } from './service'
import { StyledFundingStatus, StyledLogoContainer } from './styles'

export const defaultStateObj = {
  filter: {
    title: null,
    startDate: null,
    endDate: null,
    projectStatus: null,
    patentStatus: null,
    fundingSource: null,
    programName: null,
    globalUserId: null,
    limit: 10,
    skip: 0,
  },
  filterApplied: false,
}
export default function Projects() {
  const [state, setState] = useImmer(defaultStateObj)
  const { isLoading, data } = useQuery(
    ['projects', Object.keys(state?.filter)?.map((key) => state.filter[key])],
    () => fetchProjects({ ...state.filter }),
  )

  return (
    <div>
      <PageHeader
        title={'Research Projects'}
        actions={[
          <DrawerButton
            key="1"
            anchor={({ open }) => (
              <ActionButton onClick={open}>Add New Project</ActionButton>
            )}
            content={({ close }) => (
              <CreateProjectForm data={null} close={close} />
            )}
            title="New Research Project"
          />,
        ]}
      />
      <PageContent>
        <Stack gap={2}>
          <Stack direction={'row'} gap={3} sx={{}}>
            <SearchBar
              onSearch={(value) => {
                setState((s) => {
                  s.filter.title = value
                })
              }}
              textFieldProps={{
                placeholder: 'Search by title',
                containerProps: {
                  width: '500px',
                },
              }}
            />
            <DrawerButton
              anchor={({ open }) => (
                <FilterButton
                  onClick={open}
                  filtersApplied={state?.filterApplied}
                />
              )}
              content={({ close }) => (
                <ProjectFilter
                  state={state}
                  setState={setState}
                  close={close}
                />
              )}
              title="Apply Filters"
            />
          </Stack>
          {isLoading ? (
            <>
              <StyledLogoContainer sx={{ width: '100%', height: '80vh' }}>
                <Spinner />
              </StyledLogoContainer>
            </>
          ) : (
            <>
              {data?.length == 0 ? (
                <StyledLogoContainer sx={{ width: '100%', height: '80vh' }}>
                  <NoDataIllustration imageSrc={formImage} />
                </StyledLogoContainer>
              ) : (
                <>
                  <CardsGrid cardWidth={395} gap={20}>
                    {data?.map((item, index) => (
                      <ProjectCard data={item} key={index} />
                    ))}
                  </CardsGrid>
                </>
              )}
            </>
          )}
        </Stack>
      </PageContent>
    </div>
  )
}

export const ProjectCard = ({ data }) => {
  const navigate = useNavigate()

  const projectLeads = data?.projectMembers
    ?.filter((member) => member?.isLead === true)
    ?.map((item) => item?.userDetails?.fullName)

  const projectMembers = data?.projectMembers
    ?.filter((member) => member?.isLead === false)
    ?.map((item) => item?.userDetails?.fullName)

  const memberCapacity = projectMembers?.length

  return (
    <Card
      image={{
        url: data?.coverImage?.url ?? '/images/ProjectDefaultBanner.png',
        height: 120,
      }}
      title={data?.title}
      footer={{
        buttonText: 'view',
        onClick: () => navigate(`/research-projects/${data?._id}/details`),
      }}
    >
      <Stack gap={1.5}>
        <LabelValue
          label={'Project Leads'}
          value={<Typography>{`${projectLeads?.join(', ')}`}</Typography>}
        />
        <LabelValue
          label={'Project Members'}
          value={
            <Typography sx={{ display: 'flex' }}>
              {projectMembers?.slice(0, 3).join(', ')}
              <span style={{ color: 'blue' }}>
                {memberCapacity > 3 && (
                  <>{`  ... +${memberCapacity - 3} more`}</>
                )}
              </span>
            </Typography>
          }
        />

        <Typography variant="subtitle2">{data.programName}</Typography>
        <Stack direction={'row'} alignItems="center" gap={15}>
          <LabelValue
            label={'Funding Status'}
            value={
              <StatusComponent label={FundingSources[data?.fundingSource]} />
            }
          />
          <LabelValue
            label={'Project Status'}
            value={
              <StatusComponent label={ProjectStatus[data?.projectStatus]} />
            }
          />
        </Stack>
      </Stack>
    </Card>
  )
}

export const StatusComponent = ({ label }) => {
  return (
    <>
      <StyledFundingStatus status={label}>{label}</StyledFundingStatus>
    </>
  )
}
