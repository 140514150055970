import { Box, Slider, styled } from '@mui/material'

export const StyledFilterContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(6, minmax(150px, 1fr))',
  gap: '20px',
  alignItems: 'end',
}))

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '30px',
  marginTop: '20px',
})

export const StyledHeadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
})

export const StyledLine = styled(Box)({
  width: '100px',
  border: '1px solid #121212',
  height: '1px',
})

export const StyledSliderContainer = styled(Box)(({ theme }) => ({
  height: '75px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}))

export const StyledSliderCt = styled(Box)(({ theme }) => ({
  border: theme.borders.grayLight,
  borderRadius: '10px',
  padding: '17px 15px 0px 15px',
  display: 'flex',
  alignItems: 'flex-end',
}))

export const StyledSlider = styled(Slider)(({ theme }) => ({
  color: '#3880ff',
  height: 1,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    boxShadow:
      '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow:
          '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: 0,
    backgroundColor: 'unset',
    color: theme.palette.secondary.dark,
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.secondary.main,
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}))

export const StyledTable = styled(Box)({
  maxHeight: '400px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.5em',
    height: '0.5em',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.3)',
    },
  },
  width: '100%',
})

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
  margin: '10px 0px',

  display: 'flex',
  justifyContent: 'center',
  padding: '10px',
}))

export const StyledMediaPrint = styled(Box)({
  textAlign: 'right',
  marginBottom: '16px',
  '@media print': {
    display: 'none',
  },
})

export const StyledTableContainer = styled(Box)({
  background: 'white',
  borderRadius: '16px',
  marginTop: '10px',
  '& table thead tr': {
    backgroundColor: '#efefef',
  },
  '& table tr th': {
    border: '1px solid #bebebe',
    padding: '2px 8px',
    textAlign: 'center',
  },

  '& table td': {
    border: '1px solid #bebebe',
    padding: '5px 10px',
    textAlign: 'center',
    fontWeight: 900,
  },

  '& table': {
    width: ' 100%',
    borderCollapse: 'collapse',
  },
})

export const StyledSignatureContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '20px',
  padding: '20px',
  visibility: 'hidden',
  '@media print': {
    visibility: 'visible',
  },
})

export const StyledLogoContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
})
