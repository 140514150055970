import { DetailedQuestionWiseDistributionReport } from 'pages/Feedback/FacultyFeedbacks/FacultyFeedback/ClassroomWiseAnalysis/ClassroomAnalysis/QuestionWiseDistributionReports/DetailedQuestionWiseDistributionReport'
import { QuestionWiseDistributionReport } from 'pages/Feedback/FacultyFeedbacks/FacultyFeedback/ClassroomWiseAnalysis/ClassroomAnalysis/QuestionWiseDistributionReports/QuestionWiseDistributionReport'

export const feedbackRoutes = [
  {
    path: ':id/classroom-analysis/:classroomId/detailed-question-wise-analysis-report',
    children: [
      {
        index: true,
        element: <DetailedQuestionWiseDistributionReport />,
      },
      {
        path: ':facultyUserId',
        element: <DetailedQuestionWiseDistributionReport />,
      },
    ],
  },
  {
    path: ':id/classroom-analysis/:classroomId/question-wise-analysis-report',
    children: [
      {
        index: true,
        element: <QuestionWiseDistributionReport />,
      },
      {
        path: ':facultyUserId',
        element: <QuestionWiseDistributionReport />,
      },
    ],
  },
]
