import {
  ActionButton,
  FormMultiSelect,
  FormSingleSelect,
  FormTextField,
  Spinner,
  useErrorModal,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import { batchOptions } from 'constants/constants'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { createClassroom, fetchFormOptions, fetchSemesters } from '../services'

const schema = yup.object().shape({
  section: yup.string().required('Section is required'),
  batch: yup.string().required('Batch is required'),
  programId: yup.string().required('Program is required'),
  courseId: yup.string().required('Course is required'),
  noOfStudents: yup.string().required('Number of students is required'),
  timetableSlotTemplateId: yup
    .object()
    .required('Timetable Template is required')
    .nullable(),
})

export default function ClassroomsForm({ close, masterData }) {
  const queryClient = useQueryClient()
  const errorModal = useErrorModal()
  const { data, isLoading: loadingOptions } = useQuery('form-options', () =>
    fetchFormOptions(),
  )

  const { mutate, isLoading: createClassLoading } = useMutation(
    createClassroom,
    {
      onSuccess: (res) => {
        close()
        queryClient.invalidateQueries('classrooms')
        toast.success('Classroom Successfully Created')
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error(error)
        errorModal({ error: error })
      },
    },
  )
  const onSubmit = async (formData) => {
    mutate({
      ...formData,
      roomId: formData?.roomId?.value,
      timetableSlotTemplateId: formData?.timetableSlotTemplateId?.value,
    })
  }

  const onError = (err) => {
    // eslint-disable-next-line no-console
    console.log(err)
  }

  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
  })
  const courseId = watch('courseId')

  const { data: semesters, isLoading: loadingSemesters } = useQuery(
    'semesters',
    () =>
      fetchSemesters({
        courseId,
      }),
    { enabled: !!courseId },
  )

  if (loadingOptions) {
    return <Spinner />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Stack gap={4}>
        <FormSingleSelect
          name="courseId"
          label={'Degree'}
          control={control}
          options={
            masterData?.courses?.map((item) => ({
              label: item?.courseName,
              value: item?.id,
            })) || []
          }
          required
        />

        <FormSingleSelect
          name="programId"
          label={'Program'}
          control={control}
          options={
            masterData?.branches
              ?.filter((item) => item?.courseId == courseId)
              ?.map((item, index) => ({
                label: item?.branchCode,
                value: item?.id,
              })) || []
          }
          required
        />

        <FormSingleSelect
          name="batch"
          label={'Batch'}
          control={control}
          options={
            batchOptions?.map((item, index) => ({
              label: item,
              value: item,
            })) || []
          }
          required
        />

        <FormSingleSelect
          name="section"
          label={'Section'}
          control={control}
          options={
            data?.sections?.map((item, index) => ({
              label: item?.name,
              value: item?.name,
            })) || []
          }
          required
        />

        <FormTextField
          label={'Number of students'}
          name="noOfStudents"
          control={control}
          required
        />

        <FormMultiSelect
          label={'Room Number'}
          name={'roomId'}
          multiple={false}
          control={control}
          options={
            data?.rooms?.rooms?.map((item) => ({
              label: item.roomNo,
              value: item.id,
            })) ?? []
          }
        />

        <FormMultiSelect
          label={'Timetable template'}
          name={'timetableSlotTemplateId'}
          multiple={false}
          control={control}
          options={
            data?.timetableTemplates?.templates?.map((item) => ({
              label: item.name,
              value: item.id,
            })) ?? []
          }
          required
        />

        {courseId && (
          <>
            <FormSingleSelect
              label="Current Semester"
              name="currentSemester"
              control={control}
              options={semesters?.map((item) => ({
                label: `${item.name}`,
                value: item?.semNo,
              }))}
              required
            />
          </>
        )}

        <Stack direction="row" gap={2} mt={2}>
          <ActionButton variant="outlined" fullWidth onClick={close}>
            Cancel
          </ActionButton>
          <ActionButton type="submit" fullWidth loading={createClassLoading}>
            Create Classroom
          </ActionButton>
        </Stack>
      </Stack>
    </form>
  )
}
