import {
  ActionButton,
  AsyncSearchSelect,
  axios,
  FormDatePicker,
  FormSingleSelect,
  FormTextField,
  FormTimePicker,
  ImageUpload,
  isNumber,
  Media,
  MediaRow,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { Box, Stack, Typography } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { AxiosRequestConfig } from 'axios'

import moment from 'moment'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required('Event name is required'),
  eventType: yup.string().required('Event type is required'),
  time: yup.string().required('Event  time is required'),
  date: yup.string().required('Event date is required'),
  venue: yup.string().required('Event location is required'),
  description: yup.string().required('Description is required'),
  capacity: yup.string().required('Capacity is required'),
  isHavingEntryFee: yup.string().required('Having Entry Fees is required'),
  entryFee: yup
    .string()
    .ensure()
    .when('isHavingEntryFee', {
      is: 'yes',
      then: yup.string().required('Entry Fees is required'),
    }),
  image: yup.array().min(1).required('Image is required').nullable(),
})

export default function FormContent({
  data,
  hideDialog,
}: {
  data?: any
  hideDialog: () => void
}) {
  const queryClient = useQueryClient()
  const [media, setMedia] = useState<Media[]>([])
  const [state, setState] = useState([])

  const { control, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      isHavingEntryFee: data?.isHavingEntryFee == true ? 'yes' : 'no',
      image: [{ key: data?.image, url: data?.imageUrl }] ?? [],
      participants: data?.participants.map((item) => item.id) ?? [],
      date: data ? moment(data?.time).toISOString() : '',
    },
  })

  useEffect(() => {
    setState(
      data?.participants
        ?.filter((item) => item.userType == 'admin')
        ?.map((item) => {
          return {
            value: item?.user?.id,
            label: item?.user.fullName,
          }
        }) || [],
    )
  }, [])

  const fetchFacultyUsers = async ({ ...rest }) => {
    const res = await axios.get(`/square/users`, {
      params: { ...rest },
    })
    return res.data?.result?.map((item) => ({
      label: item?.fullName,
      value: item?.id,
    }))
  }

  const onSubmit = async (formData) => {
    const submiteddate = moment(formData?.date).format('YYYY-MM-DD')
    const submitedtime = moment(formData?.time).format('HH:mm:ss')
    const SubmitedDate = moment(submiteddate + ' ' + submitedtime).toISOString(
      true,
    )

    const postBody = {
      ...formData,
      time: SubmitedDate,
      image: media[0]?.key,
      isHavingEntryFee: formData.isHavingEntryFee == 'yes' ? true : false,
      entryFee: formData.isHavingEntryFee == 'no' ? null : formData.entryFee,
      participants: state.map((item) => item.value),
    }
    const config: AxiosRequestConfig = {
      method: data ? 'PUT' : 'POST',
      url: data ? `/square/events/${data.id}` : '/square/events',
      data: postBody,
    }

    try {
      await axios(config)
      queryClient.invalidateQueries(data ? 'event-details' : 'events')
      hideDialog()
      toast.success(
        data ? 'Event Updated successfully' : 'Event Created successfully',
      )
    } catch (error) {
      toast.error(error.response.data.message ?? 'Error saving event')
    }
  }

  const onError = (err) => {
    // eslint-disable-next-line no-console
    console.log(err)
  }
  const onFileUploaded = (res) => {
    setValue('image', [
      { key: res?.key, type: 'image', url: res?.mediaObject?.url },
    ])
    setMedia([{ key: res?.key, type: 'image', url: res?.mediaObject?.url }])
  }

  const handleDelete = (id) => {
    setMedia([])
    setValue('image', [])
  }

  useEffect(() => {
    if (!data) return
    setMedia([{ key: data?.image, type: 'image', url: data?.imageUrl }])
  }, [data])

  useEffect(() => {
    if (watch('isHavingEntryFee') == 'no') {
      setValue('entryFee', '')
    }
  }, [watch('isHavingEntryFee')])

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Stack gap={3.5}>
        <FormTextField
          name="name"
          label={'Event name'}
          control={control}
          required
        />
        <FormSingleSelect
          name="eventType"
          label={'Event Type'}
          control={control}
          options={['Academic', 'Co-Curricular', 'Extra Curricular'].map(
            (item) => ({
              label: item,
              value: item,
            }),
          )}
          required
        />

        <FormDatePicker
          name="date"
          label="Event date "
          control={control}
          required
        />

        <FormTimePicker name="time" label="Event  time" control={control} />

        <FormTextField
          name="venue"
          label={'Event location'}
          control={control}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <LocationOnOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormTextField name="organizer" label={'Organizer'} control={control} />

        <AsyncSearchSelect
          label="Event Admin"
          selectedLabel="Selected EventAdmin"
          fetchFn={fetchFacultyUsers}
          onChange={(value) => {
            setState(value)
          }}
          initialOptions={state}
        />
        <FormTextField
          name="description"
          label={'Event description'}
          control={control}
          multiline
          minRows={5}
          required
        />
        <FormTextField
          name="eventReport"
          label={'Event Report'}
          control={control}
          multiline
          minRows={5}
          required
        />
        <Box>
          <Stack direction={'row'} gap={0.4}>
            <Typography sx={{ opacity: '0.58' }}>Cover Image</Typography>
            <Typography sx={{ color: '#f5222d' }}>*</Typography>
          </Stack>
          {media?.length ? (
            <MediaRow list={media} onDelete={handleDelete} />
          ) : (
            <Controller
              name={'image'}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <ImageUpload
                    onFileUploaded={(res) => {
                      onFileUploaded(res)
                    }}
                    postUrl="/square/events/upload-image"
                  />
                  {error && (
                    <Typography
                      variant="caption"
                      sx={{ pl: '2px', pt: '6px' }}
                      color="rgb(211, 47, 47)"
                    >
                      {error?.message}
                    </Typography>
                  )}
                </>
              )}
            />
          )}
        </Box>
        <FormTextField
          name={'capacity'}
          label={'Capacity'}
          control={control}
          required
        />
        <FormSingleSelect
          label={'Has Entry Fees'}
          name={'isHavingEntryFee'}
          control={control}
          options={[
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
          ]}
          required
        />
        {watch('isHavingEntryFee') === 'yes' && (
          <FormTextField
            label={'Entry Fee'}
            name={'entryFee'}
            control={control}
            onChange={(e) => {
              if (!isNumber(e.target.value)) return
              if (+e.target.value < 0) return
              setValue('entryFee', e.target.value)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="subtitle1">
                    <em>INR</em>
                  </Typography>
                </InputAdornment>
              ),
            }}
            required
          />
        )}
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <ActionButton type="submit" fullWidth>
          {data ? 'Update Event' : 'Add Event'}
        </ActionButton>
        <ActionButton variant="outlined" fullWidth onClick={() => hideDialog()}>
          Cancel
        </ActionButton>
      </Stack>
    </form>
  )
}
