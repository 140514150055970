import { axios } from '@campxdev/shared'
import { createSlice } from '@reduxjs/toolkit'
import { examGroupBatches } from 'constants/constants'
import { ofType } from 'redux-observable'
import { map, switchMap } from 'rxjs/operators'
import { SERVICES } from 'services'

const initialState = {
  loading: true,
  data: {},
  courses: [],
  semesters: [],
  sessions: [],
  subjectTypes: [],
  regulations: [],
  sessionsByTime: {},
  batches: examGroupBatches,
  newBatches: examGroupBatches,
  selected: {
    sessionsPerDay: null,
    sessionTimings: [],
    examStartDate: null,
    groupName: '',
    courseId: null,
    semesterId: null,
    regulation: '',
    examType: [],
    subjectTypeId: null,
    examYear: null,
    examMonth: null,
    batches: [],
  },
}

const slice = createSlice({
  name: 'createExamTimeTable',
  initialState: initialState,
  reducers: {
    fetchData(state) {
      state.loading = true
    },
    fetchedData(state, action) {
      state.loading = false
      state.courses = action.payload.courses
      state.semesters = action.payload.semesters
      state.regulations = action.payload.regulations
      state.subjectTypes = action.payload.subjectTypes
    },
    selectSessionsPerDay(state, action) {
      state.selected.sessionsPerDay = action.payload
      state.selected.sessionTimings = []
      for (let i = 0; i < state.selected.sessionsPerDay; i++) {
        state.selected.sessionTimings.push({
          fromTime: null,
          toTime: null,
        })
      }
    },
    select(state, action) {
      const key = action.payload.key
      state.selected[key] = action.payload.value
      if (key == 'courseId') {
        state.selected['semesterId'] = null
      }
    },
    selectRegulation(state, action) {
      const key = action.payload.key
      state.selected[key] = action.payload.value
      const r = action.payload.value.split('R')[1]
      const index = state.batches.findIndex((item) => {
        return item.split('-')[0].trim() == `20${r}`
      })
      state.newBatches = state.batches.slice(index, state.batches.length)
      if (key == 'courseId') {
        state.selected['semesterId'] = null
      }
    },
    updateGroupName(state, action) {
      state.selected.groupName = action.payload
    },
    selectExamsStartDate(state, action) {
      state.selected.examStartDate = action.payload
    },
    selectRange(state, action) {
      state.selected.sessionTimings[action.payload.index].fromTime =
        action.payload.fromTime
      state.selected.sessionTimings[action.payload.index].toTime =
        action.payload.toTime
    },
    clearState(state, action) {
      state.newBatches = state.batches
      state.selected = {
        sessionsPerDay: null,
        sessionTimings: [],
        examStartDate: null,
        groupName: '',
        courseId: null,
        semesterId: null,
        regulation: '',
        examType: [],
        examMonth: null,
        examYear: null,
        batches: [],
        subjectTypeId: null,
      }
    },
  },
})
export function fetchDataEpic(action$) {
  return action$.pipe(
    ofType(fetchData),
    switchMap(async (action: any) => {
      const courses = await SERVICES.courses.fetchCourses()
      const semesters = await axios.get('/square/semesters')
      const regulations = await axios.get('/square/exams/regulations')
      const subjectTypes = await axios.get('/square/exams/subject-types')
      return {
        courses: courses,
        semesters: semesters.data,
        regulations: regulations.data,
        subjectTypes: subjectTypes.data,
      }
    }),
    map(fetchedData),
  )
}

const { fetchedData } = slice.actions

export const {
  fetchData,
  selectSessionsPerDay,
  select,
  selectRegulation,
  clearState,
  selectRange,
  selectExamsStartDate,
  updateGroupName,
} = slice.actions

export default slice.reducer
