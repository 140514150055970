import { Permission } from '@campxdev/shared'
import { SquarePermissions } from '@campxdev/shared/src/shared-state/PermissionsStore'
import {
  BirdsEyeViewDashboard,
  DepartmentDashboard,
  PaymentsDashboard,
  PrincipalDashboard,
} from 'pages/BirdsEyeView'

import { FacultyFeedbacks } from 'pages/Feedback/FacultyFeedbacks'
import { FacultyFeedback } from 'pages/Feedback/FacultyFeedbacks/FacultyFeedback'

import { FacultyTimetable } from 'pages/ClassRooms/MyClassroom/FacultyTimetable'

import FacilityFeedback from 'pages/Feedback/FacilityFeedbacks/FacilityFeedback/FacilityFeedback'
import FacilityFeedbacks from 'pages/Feedback/FacilityFeedbacks/FacilityFeedbacks'
import { ClassroomAnalysis } from 'pages/Feedback/FacultyFeedbacks/FacultyFeedback/ClassroomWiseAnalysis/ClassroomAnalysis'
import { FeedbackQuestions } from 'pages/Feedback/FeedbackQuestions'
import { UpsertQuestion } from 'pages/Feedback/FeedbackQuestions/UpsertQuestion'
import { ConfigureFeedbackForm } from 'pages/Feedback/components/ConfigureFeedbackForm'
import {
  ArchivedSurveys,
  FormSurveys,
  SurveyView,
  SuurveyFormBuilder,
} from 'pages/FormSurveys'
import FormResponses from 'pages/FormSurveys/FormResponses'
import Projects from 'pages/ResearchProjects/Projects'
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { activitesRoutes } from './activites'
import { classroomRoutes } from './classroom'
import { digitalNoticeBoardRoutes } from './digitalNoticeBoardRoutes'
import { formsRoutes } from './formsRoutes'
import { infrastructureRoutes } from './infrastructureRoutes'
import { mentorsRoutes } from './mentors'
import { myMenteesRoutes } from './myMentees'
import { taskRoutes } from './task'

const Dashboard = lazy(() => import('pages/Dashboard'))
const Students = lazy(() => import('pages/Students/AllStudents'))

const CourseRegistrations = lazy(() => import('pages/CourseRegistrations'))

const StudentDetails = lazy(
  () => import('pages/Students/AllStudents/StudentDetails'),
)
const UpdateStudent = lazy(
  () => import('pages/Students/AllStudents/UpdateStudent'),
)

const StudentPromotions = lazy(() => import('pages/Students/StudentPromotions'))

const StudentPromotionList = lazy(
  () => import('pages/Students/StudentPromotions/PromotionHistory'),
)
const AuditLogs = lazy(() => import('pages/AuditLogs'))

const Attendance = lazy(() => import('pages/Attendance'))

const Careers = lazy(() => import('pages/Careers'))
const ProjectDetails = lazy(
  () => import('pages/ResearchProjects/ProjectDetails'),
)
const ProjectRequests = lazy(
  () => import('pages/ResearchProjects/ProjectRequests'),
)

const ProjectResearchResults = lazy(
  () => import('pages/ResearchProjects/ResearchResults'),
)

const StudentAssessment = lazy(
  () =>
    import('pages/ClassRooms/ClassRoomSubject/Assessments/StudentAssessments'),
)

const MyClassroom = lazy(() => import('pages/ClassRooms/MyClassroom'))

export const baseRoutes = [
  {
    index: true,
    element: <Navigate to={'feed/digitalNoticeBoard'} />,
    permissionKey: Permission.CAN_NOTICE_BOARD_VIEW,
  },
  {
    path: '/analytics-dashboard',
    element: <BirdsEyeViewDashboard />,
    permissionKey: Permission.CAN_BIRDS_EYE_VIEW,
  },
  {
    path: '/payment-dashboard',
    element: <PaymentsDashboard />,
    permissionKey: Permission.CAN_BIRDS_EYE_VIEW,
  },
  {
    path: '/principal-dashboard',
    element: <PrincipalDashboard />,
    permissionKey: Permission.CAN_BIRDS_EYE_VIEW,
  },

  {
    path: '/department-dashboard',
    element: <DepartmentDashboard />,
    permissionKey: Permission.CAN_BIRDS_EYE_VIEW,
  },
  {
    children: digitalNoticeBoardRoutes,
    path: 'feed',
    permissionKey: Permission.CAN_NOTICE_BOARD_VIEW,
  },

  {
    element: <StudentAssessment />,
    path: 'assessment/award-marks/',
    permissionKey: SquarePermissions.CAN_VIEW_MY_SUBJECTS,
  },
  {
    element: <Dashboard />,
    path: 'dashboard',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    element: <Projects />,
    path: 'research-projects',
    permissionKey: Permission.CAN_RESEARCH_PROJECT_VIEW,
  },
  {
    element: <ProjectDetails />,
    path: '/research-projects/:projectId/details',
    permissionKey: Permission.CAN_RESEARCH_PROJECT_VIEW,
  },
  {
    element: <ProjectRequests />,
    path: '/research-projects/:projectId/new-requests',
    permissionKey: Permission.CAN_RESEARCH_PROJECT_VIEW,
  },
  {
    element: <ProjectRequests />,
    path: '/research-projects/:projectId/rejected-requests',
    permissionKey: Permission.CAN_RESEARCH_PROJECT_VIEW,
  },
  {
    element: <ProjectResearchResults />,
    path: '/research-projects/:projectId/research-results',
    permissionKey: Permission.CAN_RESEARCH_PROJECT_VIEW,
  },

  {
    name: 'Tasks',
    path: '/tasks',
    permissionKey: Permission.CAN_TASKS_VIEW,
    children: taskRoutes,
  },

  {
    element: <Attendance />,
    path: '/attendance',
    permissionKey: Permission.CAN_ADMIN_VIEW,
  },
  {
    element: <Students />,
    path: '/students/all-students',
    permissionKey: Permission.STUDENTS_VIEW,
  },

  {
    element: <StudentDetails />,
    path: '/students/all-students/:studentId/details',
    permissionKey: Permission.STUDENT_INFO_VIEW,
  },
  {
    element: <UpdateStudent />,
    path: '/students/all-students/:studentId/edit',
    permissionKey: Permission.STUDENTS_VIEW,
  },
  // {
  //   element: <StudentPromotions />,
  //   path: '/students/student-promotion',
  //   permissionKey: isDevelopment && Permission.STUDENTS_VIEW,
  // },
  // {
  //   element: <StudentPromotionList />,
  //   path: '/students/student-promotion/:programId',
  //   permissionKey: isDevelopment && Permission.STUDENTS_VIEW,
  // },
  {
    name: 'Class Rooms',
    path: '/classrooms',
    children: classroomRoutes,
  },
  {
    element: <MyClassroom />,
    path: 'my-courses',
    permissionKey: SquarePermissions.CAN_VIEW_MY_SUBJECTS,
  },
  {
    element: <FacultyTimetable />,
    path: 'my-courses/faculty-timetable',
    permissionKey: SquarePermissions.CAN_VIEW_MY_SUBJECTS,
  },
  {
    path: '/activities',
    name: 'Activities',
    children: activitesRoutes,
  },
  {
    path: '/mentors',
    name: 'Mentors',
    children: mentorsRoutes,
    permissionKey: SquarePermissions.CAN_VIEW_MENTORS,
  },
  {
    path: '/my-mentees',
    name: 'My Mentees',
    children: myMenteesRoutes,
    permissionKey: SquarePermissions.CAN_VIEW_MENTEES,
  },
  {
    element: <Careers />,
    path: '/careers',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    path: '/surveys',
    permissionKey: Permission.CAN_SURVEYS_VIEW,
    children: [
      {
        index: true,
        permissionKey: Permission.CAN_SURVEYS_VIEW,
        element: <FormSurveys />,
      },
      {
        path: ':surveyId',
        permissionKey: Permission.CAN_SURVEYS_VIEW,
        children: [
          {
            index: true,
            permissionKey: Permission.CAN_SURVEYS_VIEW,
            element: <SurveyView />,
          },
          {
            path: 'form/:formId',
            permissionKey: Permission.CAN_SURVEYS_VIEW,
            element: <SuurveyFormBuilder />,
          },
          {
            path: 'responses',
            permissionKey: Permission.CAN_SURVEYS_VIEW,
            element: <FormResponses />,
          },
        ],
      },
      {
        path: 'archived',
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
        element: <ArchivedSurveys />,
      },
    ],
  },
  {
    path: '/feedback',
    permissionKey: Permission.CAN_VIEW_FEEDBACKS,
    children: [
      {
        path: 'facility-feedbacks',
        permissionKey: Permission.VIEW_FACILITY_FEEDBACKS,
        children: [
          {
            index: true,
            permissionKey: Permission.VIEW_FACILITY_FEEDBACKS,
            element: <FacilityFeedbacks />,
          },
          {
            path: ':id',
            permissionKey: Permission.VIEW_FACILITY_FEEDBACKS,
            children: [
              {
                index: true,
                permissionKey: Permission.VIEW_FACILITY_FEEDBACKS,
                element: <FacilityFeedback />,
              },
              {
                path: 'configure-form/:type',
                permissionKey: Permission.CONFIGURE_FEEDBACK_FORM_FACILITY,
                element: <ConfigureFeedbackForm />,
              },
            ],
          },
        ],
      },
      {
        path: 'faculty-feedbacks',
        permissionKey: Permission.CAN_VIEW_FEEDBACKS,
        children: [
          {
            index: true,
            permissionKey: Permission.CAN_VIEW_FEEDBACKS,
            element: <FacultyFeedbacks />,
          },
          {
            path: ':id',
            permissionKey: Permission.CAN_VIEW_FEEDBACKS,
            children: [
              {
                index: true,
                permissionKey: Permission.CAN_VIEW_FEEDBACKS,
                element: <FacultyFeedback />,
              },
              {
                path: 'configure-form/:type',
                permissionKey: Permission.CAN_CONFIGURE_FEEDBACK_FORM,
                element: <ConfigureFeedbackForm />,
              },
              {
                path: 'classroom-analysis/:classroomId',
                permissionKey: Permission.CAN_VIEW_FEEDBACKS,
                element: <ClassroomAnalysis />,
              },
            ],
          },
        ],
      },
      {
        path: 'feedback-questions',
        permissionKey: Permission.CAN_VIEW_FEEDBACK_QUESTIONS,
        children: [
          {
            index: true,
            permissionKey: Permission.CAN_VIEW_FEEDBACK_QUESTIONS,
            element: <FeedbackQuestions />,
          },
          {
            path: 'new-question',
            permissionKey: Permission.CAN_CREATE_FEEDBACK_QUESTION,
            element: <UpsertQuestion />,
          },
          {
            path: ':id/edit-question',
            permissionKey: Permission.CAN_EDIT_FEEDBACK_QUESTION,
            element: <UpsertQuestion />,
          },
        ],
      },
      // {
      //   path: 'analytics',
      //   permissionKey: Permission.CAN_ADMIN_VIEW,
      //   element: <FeedbackQuestions />,
      // },
    ],
  },

  {
    element: <CourseRegistrations />,
    path: '/course-registrations',
    permissionKey: Permission.COURSE_REGISTRATION_VIEW,
  },
  {
    name: 'Form Builder',
    path: '/forms',
    children: formsRoutes,
  },
  {
    name: 'Infrastructure',
    path: '/infrastructure',
    children: infrastructureRoutes,
  },
  {
    element: <AuditLogs />,
    path: '/audit-logs',
    permissionKey: Permission.VIEW_AUDIT_LOGS,
  },
]
