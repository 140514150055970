import { Box, styled } from '@mui/material'

export const StyledPreviewRibbon = styled(Box)(({ theme }) => ({
  padding: '10px',
  width: '200px',
  textAlign: 'center',
  position: 'absolute',
  right: -70,
  top: 20,
  transform: 'rotate(45deg)',
  background: theme.palette.error.main,
}))

export const StyledAccessFormContainer = styled(Box)(() => ({
  paddingBottom: '50px',
  maxWidth: 1000,
  margin: 'auto',
  position: 'relative',
  overflow: 'hidden',
  background: 'white',
  border: '1px solid #E0E0E0',
  borderRadius: 10,
}))

export const StyledAccessFormAppbar = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'white',
  borderBottom: '1px solid #E0E0E0',
  position: 'sticky',
  top: 0,
  width: '100%',
  padding: '15px 10px',
  zIndex: 100,
  marginBottom: 20,
}))

export const StyledContainer = styled(Box)(({ theme }) => ({
  border: '4px solid #1212121A',
  borderRadius: '10px',
  width: '340px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))
