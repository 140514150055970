import { ActionButton, DeleteButton } from '@campxdev/shared'
import { Box, FormLabel, Stack, Typography, alpha, styled } from '@mui/material'

export const StyledProjectDetails = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  padding: '20px',
  border: theme?.borders?.grayLight,
  gap: '20px',
}))

export const StyledResearchResultCard = styled(Box)(({ theme }) => ({
  minHeight: '120px',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '20px',
  backgroundImage: `linear-gradient(to right, #1E28F5 0%, #00ADC1 95%)`,
  gap: '20px',
  cursor: 'pointer',
}))

export const StyledPage = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: '20px',
}))

export const StyledTimeLineContainer = styled(Box)(({ theme }) => ({
  border: theme.borders.grayLight,
  borderRadius: '10px',
  width: '360px',
  flexShrink: 0,
  paddingBottom: '10px',
}))

export const StyledTimeLineHeader = styled(Box)(({ theme }) => ({
  borderRadius: '10px 10px 0 0',
  background: theme.palette.secondary.lighter,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  height: '60px',
}))

export const StyledTimeLineDot = styled(Box)(({ theme }) => ({
  height: '15px',
  width: '15px',
  borderRadius: '50%',
  border: theme.borders.grayLight,
}))

export const StyledTimeLineBody = styled(Box)(({ theme }) => ({
  height: '70vh',
  overflowY: 'auto',
  '& > *': {
    margin: '0 auto',
    maxWidth: '360px',
  },

  '&::-webkit-scrollbar': {
    width: '0.5em',
    height: '0.5em',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.3)',
    },
  },
}))

export const StyledFundingStatus = styled(Typography)<{
  status: 'Funded' | 'Not Funded' | 'Ongoing' | 'Completed' | 'On Hold'
}>(({ status, theme }) => ({
  fontSize: '15px',
  fontWeight: 'bold',
  color:
    status == 'Funded'
      ? '#573DAB'
      : status == 'Not Funded'
      ? '#121212'
      : status == 'Ongoing'
      ? '#ED9035'
      : status == 'Completed'
      ? '#88B053'
      : status == 'On Hold'
      ? '#149ECD'
      : '#573DAB',
}))

export const StyledTypography = styled(Typography)({
  color: 'grey',
  fontSize: '13px',
})

export const StyledStudentProfileContainer = styled(Box)({
  minHeight: '60px',
  display: 'flex',
  gap: '10px',

  justifyContent: 'flex-start',
  alignItems: 'center',
})

export const StyledActionButton = styled(ActionButton)<{
  actionType: 'accept' | 'reject'
}>(({ theme, actionType }) => ({
  width: '170px',
  height: '40px',
  padding: '10px',
  fontSize: '14px',
  fontWeight: 600,
  background:
    actionType === 'accept'
      ? alpha(theme.palette.common.green, 0.2)
      : alpha(theme.palette.error.main, 0.2),
  color:
    actionType === 'accept'
      ? theme.palette.common.green
      : theme.palette.error.main,
  borderColor:
    actionType === 'accept'
      ? theme.palette.common.green
      : theme.palette.error.main,

  '&:hover': {
    ...(actionType === 'accept'
      ? {
          background: alpha(theme.palette.common.green, 0.25),
          color: theme.palette.common.green,
          borderColor: theme.palette.common.green,
        }
      : {
          background: alpha(theme.palette.error.main, 0.25),
          color: theme.palette.error.main,
          borderColor: theme.palette.error.main,
        }),
  },
}))

export const StyledHideText = styled(Box)(() => ({
  position: 'absolute',
  backgroundColor: 'white',
  padding: '20px',
  top: -30,
  left: 0,
  '&>*': {
    whiteSpace: 'noWrap',
    marginBottom: '10px',
  },
  transform: 'translateY(10%)',
  transition: 'ease-out',
  zIndex: '1',
  boxShadow: '0px 0px 10px 0px #0000001A',
  borderRadius: '10px',
}))

export const StyledTextBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  cursor: 'pointer',

  '& .hide': {
    display: 'none',
  },
  ':hover': {
    '& .hide': {
      display: 'block',
    },
  },
}))

export const StyledStatusContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: '1fr 1fr',
  rowGap: '20px',
  columnGap: '10px',
}))

export const StyledStatus = styled(Box)<{
  status: 'Completed' | 'Ongoing' | 'On Hold'
}>(({ status, theme }) => ({
  width: '170px',
  height: '40px',
  padding: '10px',
  fontSize: '14px',
  fontWeight: 600,
  textAlign: 'center',
  border: '1px solid black',
  borderRadius: '5px',
  background:
    status === 'Completed'
      ? alpha(theme.palette.common.green, 0.2)
      : status === 'Ongoing'
      ? '#ED903510'
      : alpha(theme.palette.error.main, 0.2),
  color:
    status === 'Completed'
      ? theme.palette.common.green
      : status === 'Ongoing'
      ? '#ED9035'
      : theme.palette.error.main,
  borderColor:
    status === 'Completed'
      ? theme.palette.common.green
      : status === 'Ongoing'
      ? '#ED9035'
      : theme.palette.error.main,
}))

export const StyledDateContainer = styled(Box)(({ theme }) => ({
  minWidth: '338px',
  backgroundColor: '#F7F7F7',
  padding: '10px',
  borderRadius: '10px',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const StyledLogoContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const StyledUploadContainer = styled(FormLabel)({
  border: '1px dashed #0000001A',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '10px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#EFEFEF',
  },
})

export const StyledUploadedFileContainer = styled(Box)({
  border: '1px solid #1212121A',
  borderRadius: '10px',
  padding: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '10px 0px',
})

export const KeyWordContainer = styled(Box)(({ theme }) => ({
  height: '30px',
  borderRadius: '5px',
  border: '1px solid #FCD62D80',
  minWidth: '80px',
  padding: '10px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#FCD62D1C',
}))

export const StyledStack = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const StyledDeleteButton = styled(DeleteButton)({
  border: '1px solid #0000001A',
  borderRadius: '10px',
  width: '50px',
  height: '50px',
})
