import { axios } from '@campxdev/shared'
const ENDPOINT = '/square/comments'

export const comments = {
  async addHodComment(postBody: any) {
    return axios.post(`${ENDPOINT}/create-hod-comments`, postBody)
  },

  async getHODComments(params: any) {
    const res = await axios.get(ENDPOINT, {
      params: {
        ...params,
      },
    })
    return res.data
  },
}
