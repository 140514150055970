import { axios } from '@campxdev/shared'
import _ from 'lodash'
import moment from 'moment'
import { toast } from 'react-toastify'
import { SERVICES } from 'services'
import * as XLSX from 'xlsx'

export const reportUrl = {
  subjectWiseAttendance: '/square/classroom-timetable-reports/subject-wise',
  classroomProgress: '/square/classroom-timetable-reports/progress',
  sectionWiseAverageAttendance:
    '/square/classroom-timetable-reports/section-wise-average-attendance',
  studentAttendance: '/square/classroom-timetable-reports/student-attendance',
  syllabusCoverage: '/square/classroom-timetable-reports/syllabus-coverage',
  classroomTimetableStatus:
    '/square/classroom-timetable-reports/timetable-status',
  campusResultAnalysis: '/exams/result-analysis/campus-analysis-report',
  departmentWiseSectionAnalysis:
    '/exams/result-analysis/campus-analysis-section-wise-report',
  departmentWiseCourseAnalysis:
    '/exams/result-analysis/campus-analysis-subject-wise-report',
  facultyAnalysisReport:
    '/exams/result-analysis/campus-faculty-analysis-report',
  questionWiseMarksReport:
    '/exams/internal-exam-question-paper/question-wise-marks-report',
  consolidatedAttendance:
    '/square/classroom-timetable-reports/consolidated-attendance',
  monthlyAttendance: '/square/classroom-timetable-reports/monthly-attendance',
  assessmentStatusReport: `/square/assessment-report/assessment-status-report`,
  assessmentResultSummaryReport: `/square/assessment-report/assessment-result-summary-report`,
  subjectBatchConfiguration: `/square/subject-batch-config/subject-batch-configuration`,
}

export const fetchCoursesAndBranches = async () => {
  const branches = await axios.get('/square/branches')
  const courses = await SERVICES.courses.fetchCourses()
  const timeSlots = await axios.get('/square/timetable-slots/filter')
  return {
    branches: branches.data,
    courses: courses,
    timeSlots: timeSlots.data,
  }
}

export const fetchSubjects = (id: number) => {
  return axios.get(`/square/classrooms/${id}/subjects`).then((res) => res.data)
}

export const fetchCoursesAndBranchesAssessmentTypes = async () => {
  const branches = await axios.get('/square/branches')
  const courses = await SERVICES.courses.fetchCourses()
  const assessmentTypes = await axios.get(`/square/assessment-type`)
  return {
    branches: branches.data,
    courses: courses,
    assessmentTypes: assessmentTypes.data,
  }
}

export const fetchSubjectTypes = () => {
  return axios.get(`/square/subject-type`).then((res) => res.data)
}

export const getSections = async (params: {
  programId?: number
  programIds?: number[]
  courseId: number
  batch: string
}) => {
  let sections = await axios.get('/square/classrooms/filters', {
    params: {
      ...params,
    },
  })
  return sections.data
}

export const getSemesters = (params) => {
  return axios
    .get(`/square/semesters`, {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
}

export const getFaculties = async (params) => {
  const faculties = await axios.get(`/hrms/employees/faculties`, {
    params: {
      ...params,
    },
  })
  return faculties.data
}

export const getFacultyTimeTable = async (params) => {
  const faculties = await axios.get(`/square/timetable/faculty-timetable`, {
    params: {
      ...params,
    },
  })
  return faculties.data
}

interface GetAssessmentList {
  courseId: number
  batch: string
  branchCode?: string
  branches?: string[]
  assessmentTypeIds?: number[]
  assessmentTypeId?: number
}

export const getAssessments = (params: GetAssessmentList) => {
  return axios
    .get(`/square/assessments/list`, {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
}

export const fetchDepartments = (params = {}) => {
  return axios
    .get(`/hrms/departments`, {
      params,
    })
    .then((res) => res.data?.data)
}

export const fetchDepartmentById = (id: string) => {
  return axios.get(`/hrms/departments/${id}`).then((res) => res.data)
}

export const fetchFaculties = (params) => {
  return axios
    .get(`/square/users/drop-down`, {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
}

export const fetchFacultySemesterSubjectReport = (filters) => {
  return axios
    .get(`/square/classrooms/classroom-semester-subjects`, {
      params: {
        ...filters,
      },
    })
    .then((res) => res.data)
}

export async function exportToExcel(filters) {
  const { limit, offset, ...filteredFilters } = filters
  const data = await fetchFacultySemesterSubjectReport(filteredFilters)
  let excelData = data?.result.map((item, index) => ({
    'Sl No.': index + 1,
    Classroom: item?.classroom?.name,
    'Course Name': item?.subject?.name,
    'Course Type': item?.subject.subjectType?.type ?? '',
    Semester: item?.subject?.semNo,
    'Faculty Name': item?.faculties
      .map((faculty) => faculty?.fullName)
      .join(',  '),
  }))

  const fileName = `FacultySubjectReport.xlsx`

  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData, {
    header: Object.keys(excelData[0] || {}),
  })
  ws['!cols'] = [
    { wch: 5 },
    { wch: 20 },
    { wch: 30 },
    { wch: 10 },
    { wch: 15 },
    { wch: 30 },
  ]
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, `${moment().format('DD-MM-YYYY')}`)
  XLSX.writeFile(wb, fileName)
}

export const fetchRegulations = () => {
  return axios.get(`/square/reg-batch/regulations`).then((res) => res.data)
}

export const fetchTimeSlots = (params) => {
  return axios
    .get('/square/timetable-slots/filter', {
      params: {
        ...params,
      },
    })
    .then((res) => res?.data)
}

export const getReportData = async ({ reportType, ...filters }) => {
  try {
    const data = await axios.get(reportUrl[reportType], {
      params: {
        ...filters,
      },
    })
    return data?.data
  } catch (error) {
    toast.error(error?.response?.data?.message ?? 'Unable to get the report')
  }
}

export const fetchCourseSubjects = (params) => {
  return axios
    .get(`square/classrooms/${params.classroomId}/subjects`, {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
}

export const exportReports = async (reportType, columns, filters) => {
  try {
    const data = await axios.get(reportUrl[reportType], {
      params: {
        ...filters,
      },
    })

    let Heading = columns.map((col) => col.key)

    let excelData = data?.data?.result?.map((item, index) => {
      let data = {}
      columns?.forEach((col) => {
        data[col.key] = col?.render
          ? col.render(item[col.dataIndex], item, index)
          : _.get(item, col.dataIndex)
      })
      return data
    })

    const fileName = `${reportType}.xlsx`
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)

    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      `${moment().format('DD-MM-YYYY')} - Users`,
    )
    XLSX.writeFile(wb, fileName)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    toast.error(
      error?.response?.data?.message ?? 'Unable to export the reports',
    )
  }
}

// Assessment Report

export const fetchAssessmentReport = (params) => {
  return axios
    .get(`/square/assessment-report`, {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
}

export const fetchClassroomData = (classroomId: number) => {
  return axios.get(`/square/classrooms/${classroomId}`).then((res) => res.data)
}

export const exportAssessmentReport = (branchCode, batch, data, headers) => {
  const fileName = `Internals_${branchCode}_${batch}.xlsx`
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  const percentageWiseCountHeader = Object.keys(
    data?.percentageWiseCount[Object.keys(data?.percentageWiseCount)[0]],
  )
  const percentageWiseCountData = Object.values(data?.percentageWiseCount).map(
    (countData) => percentageWiseCountHeader.map((key) => countData[key]),
  )

  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
    data?.response?.map((student) => {
      let obj = {}
      headers
        ?.filter((column) => column.status == true)
        .forEach((header) => {
          obj[header?.label] = student[header?.label] ?? '--'
        })
      return obj
    }),
  )
  const wd = XLSX.utils.aoa_to_sheet([
    percentageWiseCountHeader,
    ...percentageWiseCountData,
  ])

  XLSX.utils.book_append_sheet(
    wb,
    ws,
    `${branchCode.slice(0, 4)}_${batch}_Batch`,
  )
  XLSX.utils.book_append_sheet(wb, wd, 'Percentage Wise Count')
  XLSX.writeFile(wb, fileName)
}

export const exportQuestionWiseMarksReport = (data) => {
  const fileName = `Question_Wise_Marks_Report.xlsx`

  const excelData = data?.result?.map((item, index) => {
    const questionData = {}
    questionData['SL No'] = index + 1
    questionData['Student Name'] = item?.studentName
    questionData['Roll Number'] = item?.rollNo
    item?.marks?.forEach((mark, mIndex) => {
      questionData[` ${data?.questions[mIndex]?.slNo}`] =
        mark?.studentMarks ?? '--'
    })
    questionData['Total Marks'] = item?.totalMarks
    return questionData
  })

  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)

  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, `Question_Wise_Marks_Report`)
  XLSX.writeFile(wb, fileName)
}

export const exportForniteAttendanceReport = async ({
  data,
  collegeCode,
  classroom,
  fromDate,
  toDate,
  semNo,
  year,
  regulation,
}) => {
  const fileName = `Fornite_Attendance_Report.xlsx`

  const excelData = data?.map((item, index) => ({
    Degree: classroom?.course?.courseName ?? '',
    'College Code': collegeCode ?? '',
    'HT No': item?.rollNo,
    'Branch Code': classroom?.branchCode ?? '',
    Section: classroom?.section ?? '',
    Regulation: regulation ?? '',
    Year: year ?? '',
    Semester: semNo ?? '',
    'Subject Code': item?.subject?.subjectCode,
    'Subject Name': item?.subject?.subjectName,
    'Subject Type': item?.subject?.subjectType,
    Fortnight: item?.fortnight,
    'Classes Conducted': item?.subject?.numberOfClasses,
    'Classes Attended': item?.subject?.numberOfPresent,
    'Fortnight Start Date': fromDate,
    'Fortnight End Date': toDate,
  }))

  const ws = XLSX.utils.json_to_sheet(excelData)

  ws['!cols'] = [
    { wch: 10 },
    { wch: 15 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
    { wch: 30 },
    { wch: 10 },
    { wch: 10 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
  ]

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, `Fornite_Attendance_Report`)
  XLSX.writeFile(wb, fileName)
}

export const getFortnightReportData = async (filters) => {
  const { fromDate, toDate, ...params } = filters

  const queryParams = {
    ...params,
    fromDate: fromDate ?? '',
    toDate: toDate ?? '',
  }

  try {
    const response = await axios.get(
      '/square/classroom-timetable-reports/student-date-wise-attendance',
      {
        params: queryParams,
      },
    )

    if (response.status !== 200) {
      throw new Error('Failed to fetch data')
    }

    return response.data
  } catch (error) {
    console.error('Error fetching fortnight report data:', error)
    throw error
  }
}
