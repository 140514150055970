import { axios } from '@campxdev/shared'
import { AxiosRequestConfig } from 'axios'
// Projects
export const FundingSources = {
  self_funding: 'Self Funding',
  government_grants: 'Government Grants',
  private_sponsors: 'Private Sponsors',
  no_funding: 'Not Funded',
}

export const ProjectStatus = {
  ongoing: 'Ongoing',
  on_hold: 'On Hold',
  completed: 'Completed',
}

export const PatentStatus = {
  patented: 'Patented',
  no_patent: 'No Patent',
}

export const ResearchResultTypes = {
  narratives: 'Narratives',
  data_tables: 'Data Tables',
  charts: 'Charts',
  images: 'Images',
  videos: 'Videos',
  reports: 'Reports',
  references: 'References',
}

export const fetchPrograms = () => {
  return axios.get('/square/branches').then((res) => res.data)
}
export const convertToNormalText = (text: string) => {
  const textArr = text?.split('_')
  return textArr?.map((item) => item.charAt(0) + item?.slice(1))?.join(' ')
}

export const fetchProjects = (params) => {
  return axios
    .get('/square/research-projects', {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
}

// Project details
export const fetchProjectDetails = (projectId) => {
  return axios
    .get(`/square/research-projects/${projectId}`)
    .then((res) => res.data)
}

export const fetchProjectRequests = (projectId, requestStatus) => {
  return axios
    .get(`/square/research-projects/get-requests`, {
      params: {
        projectId,
        requestStatus,
      },
    })
    .then((res) => res.data)
}

export const acceptRequest = (postBody) => {
  return axios
    .post(`/square/research-projects/request/accept`, postBody)
    .then((res) => res.data)
}

export const uploadFiles = (postBody) => {
  return axios
    .post(`/square/research-projects/upload`, postBody)
    .then((res) => res.data)
}

export const fetchUsers = () => {
  return axios.get(`/square/users`).then((res) => res.data)
}

export const createProject = (postBody) => {
  return axios
    .post(`/square/research-projects`, postBody)
    .then((res) => res.data)
}

export const createTimeLine = (postBody) => {
  return axios
    .post(`/square/research-projects/add-timeline`, postBody)
    .then((res) => res.data)
}

export const fetchResearchResults = (params) => {
  return axios
    .get(`/square/research-projects/research/results`, {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
}

export const fetchTypes = () => {
  return axios
    .get(`/square/research-projects/results/types`)
    .then((res) => res.data)
}

export const addResearchResult = ({ id, ...postBody }) => {
  const config: AxiosRequestConfig = {
    method: id ? 'PUT' : 'POST',
    url: id
      ? '/square/research-projects/update-result'
      : '/square/research-projects/add-result',
    data: { id: id ? id : null, ...postBody },
  }

  return axios(config).then((res) => res.data)
}

export const fetchEmployees = () => {
  return axios
    .get(`/hrms/employees`, {
      params: {
        isActive: true,
      },
    })
    .then((res) => res.data)
}
