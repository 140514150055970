import { axios } from '@campxdev/shared'
import { format } from 'date-fns'
import * as XLSX from 'xlsx'

type GetEmployeesDto = {
  search?: string
  id?: string
  departmentId?: string
  designationId?: string
  roleId?: string
  status?: string
  skip: number
  limit: number
}

type CreateUserDto = {
  institutionIds: number[]
  departmentIds: string[]
  designationId: string
  email: string
  employeeId: string
  username: string
  password: string
  firstName: string
  lastName: string
  gender: string
  roleId: string
  workPhoneNumber: string
  type: string
  _id?: string
}

interface GetEmployeeCountReportDto {
  fromDate: string
  toDate: string
}

const prefix = '/hrms/employees'
export const employees = {
  async fetchAll(params: GetEmployeesDto) {
    const res = await axios.get(prefix, {
      params,
    })
    return res.data
  },

  async fetchEmployeeCountReport(params: GetEmployeeCountReportDto) {
    const res = await axios.get(prefix + '/count-report', {
      params,
    })
    return res.data
  },

  consolidatedTimeSheet: async () => {
    const res = await axios.get(prefix + '/timesheet-report')
    return res.data
  },

  bulkUpdateEmployeeGroups: async (body: { ids: any[]; groupId: string }) => {
    if (!body.groupId) return
    if (!body.ids.length) return
    const res = await axios.post(prefix + '/bulk-assign-groups', body)
    return res.data
  },

  create: async (body: CreateUserDto) => {
    const config = {
      url: body?._id ? `${prefix}/${body._id}` : prefix,
      method: body?._id ? 'PUT' : 'POST',
      data: body,
    }
    const res = await axios(config)
    return res.data
  },

  async exportToExcel() {
    const res = await axios.get(prefix, {
      params: {
        type: 'staff_user',
      },
    })

    const excelData = res?.data?.data?.map((item, index) => ({
      USERID: item?.userId || '',
      EMPLOYEEID: item?.employeeId || '',
      USERNAME: item?.username || '',
      EMAIL: item?.email || '',
      FIRST_NAME: item?.firstName || '',
      LAST_NAME: item?.lastName || '',
      FULL_NAME: item?.fullName || '',
      DEPARTMENT: item?.department?.length ? item?.department[0]?.name : '',
      DESIGNATION: item?.designation?.length ? item?.designation[0]?.name : '',
      AADHAR_NUMBER: item?.aadharNumber || '',
      PAN_NUMBER: item?.panNumber || '',
      WORKPHONENUMBER: item?.workPhoneNumber || '',
      DOB: item?.dateOfBirth
        ? format(new Date(item?.dateOfBirth), 'dd-MM-yyyy')
        : '',
      DOJ: item?.dateOfBirth
        ? format(new Date(item?.dateOfBirth), 'dd-MM-yyyy')
        : '',
      EMPLOYMENT_TYPE: item?.employmentType || '',
      GENDER: item?.gender || '',
      LOCATION: item?.location || '',
      MARITAL_STATUS: item?.maritalStatus || '',
      NICK_NAME: item?.nickName || '',
      PERSONALEMAIL: item?.personalEmail || '',
      PERSONALPHONENUMBER: item?.personalPhoneNumber || '',
    }))
    const fileName = `employees.xlsx`
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'employees')
    XLSX.writeFile(wb, fileName)
  },
}
