import { axios } from '@campxdev/shared'
import * as XLSX from 'xlsx'

export const fetchSectionWiseAttendanceReport = (params) =>
  axios
    .get('/square/classroom-timetable-reports/student-date-wise-attendance', {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)

export const fetchData = (params) =>
  axios
    .get('square/classroom-timetables', {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)

export const exportData = async (params) => {
  const data = await fetchSectionWiseAttendanceReport(params)

  const filename = `${data?.classroom.name} - ${data?.classroom.batchName}.xlsx`
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  let Heading = [
    [
      'SL No',
      'HT No.',
      'Name',
      ...data?.subjects?.map((sub) => `${sub?.name} - ( ${sub?.refCode} )`),
      ...(params.reportType !== 'percentage' ? ['Total'] : []),
      'Percentage %',
    ],
    [
      '',
      '',
      'Total classes taken',
      ...data?.subjects?.map(
        (sub) => `${sub?.numberOfClasses} / ${sub?.totalClasses}`,
      ),
      ...(params?.reportType !== 'percentage'
        ? [
            data?.subjects?.reduce(
              (acc, item) => acc + item?.numberOfClasses,
              0,
            ),
          ]
        : []),
      '100%',
    ],
  ]

  const studentData = data?.data?.map((student, index) => {
    let studentSubject = {}
    data?.subjects?.forEach((subject) => {
      const subjectData = student?.subjects?.find(
        (sub) => sub.subjectId == subject?.id,
      )
      studentSubject[`${subject?.name} - ( ${subject?.refCode} )`] =
        params.reportType == 'percentage'
          ? subjectData
            ? isNaN(
                (subjectData?.numberOfPresent / subjectData?.numberOfClasses) *
                  100,
              )
              ? '--'
              : (
                  (subjectData?.numberOfPresent /
                    subjectData?.numberOfClasses) *
                  100
                ).toFixed(2)
            : '--'
          : params.showApplicableClasses
          ? `${subjectData?.numberOfPresent ?? '0'}  / ${
              subjectData?.numberOfClasses ?? '0'
            } `
          : subjectData?.numberOfPresent ?? 0
    })

    return {
      'SL No.': index + 1,
      'HT No': student?.rollNo,
      Name: student?.fullName,
      ...studentSubject,

      ...(params.reportType !== 'percentage'
        ? {
            total: params?.showApplicableClasses
              ? `${student?.totalPresent ?? '0'} / ${
                  student?.subjects?.reduce(
                    (total, item) => total + item.numberOfClasses,
                    0,
                  ) ?? '0'
                }`
              : student?.totalPresent ?? '0',
          }
        : {}),
      percentage: student?.percentage ? student?.percentage : '0',
    }
  })

  const PercentageWiseCountData = [data?.percWiseCount]
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([{}, ...studentData])

  const wd: XLSX.WorkSheet = XLSX.utils.json_to_sheet(PercentageWiseCountData)

  XLSX.utils.book_append_sheet(wb, wd, `Percentage Wise Count`)
  XLSX.utils.sheet_add_aoa(ws, Heading)

  ws['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
    { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
  ]

  XLSX.utils.sheet_add_json(ws, [], {
    origin: 'A3',
    skipHeader: false,
  })

  XLSX.utils.book_append_sheet(wb, ws, 'Student Attendance')
  XLSX.writeFile(wb, filename)
}

export const attendanceExport = async (params) => {
  const data = await fetchSectionWiseAttendanceReport(params)
  const wb = XLSX.utils.book_new()
  const ws_data = []

  const headerRow1 = ['SL No.', 'HT No.', 'Name']
  const subjects = data.subjects.filter((item) => item.hasAttendance)

  subjects.forEach((subject) => {
    headerRow1.push(`${subject.name} (${subject.refCode})`)
    headerRow1.push('')
    headerRow1.push('')
    if (params.reportType == 'both') {
      headerRow1.push('')
    }
  })
  headerRow1.push('Total')
  ws_data.push(headerRow1)

  const headerRow2 = []
  headerRow2.push('')
  headerRow2.push('')
  headerRow2.push('')
  subjects.forEach(() => {
    headerRow2.push('Present', 'Classes')
    if (params.reportType == 'both') {
      headerRow2.push('Total', 'Percentage')
    } else if (params.reportType == 'percentage') {
      headerRow2.push('Percentage')
    } else if (params.reportType == 'total') {
      headerRow2.push('Total')
    }
  })
  headerRow2.push('Present', 'Classes')
  if (params.reportType == 'both') {
    headerRow2.push('Total', 'Percentage')
  } else if (params.reportType == 'percentage') {
    headerRow2.push('Percentage')
  } else if (params.reportType == 'total') {
    headerRow2.push('Total')
  }
  ws_data.push(headerRow2)

  data.data.forEach((item, index) => {
    const row = [index + 1, item.rollNo, item.fullName]
    subjects.forEach((subject) => {
      const subjectRecord = item.subjects.find(
        (sub) => sub.subjectId == subject.id,
      )
      if (params.reportType == 'both') {
        row.push(
          subjectRecord?.numberOfPresent ?? '--',
          subjectRecord?.numberOfClasses ?? '--',
          subject.totalClasses,
          subjectRecord
            ? (
                (subjectRecord.numberOfPresent /
                  subjectRecord.numberOfClasses) *
                100
              ).toFixed(2)
            : '--',
        )
      } else if (params.reportType == 'percentage') {
        row.push(
          subjectRecord?.numberOfPresent ?? '--',
          subjectRecord?.numberOfClasses ?? '--',
          subjectRecord
            ? (
                (subjectRecord.numberOfPresent /
                  subjectRecord.numberOfClasses) *
                100
              ).toFixed(2)
            : '--',
        )
      } else if (params.reportType == 'total') {
        row.push(
          subjectRecord?.numberOfPresent ?? '--',
          subjectRecord?.numberOfClasses ?? '--',
          subject.totalClasses,
        )
      }
    })
    row.push(item.totalPresent ?? '0', item.totalClasses ?? '0')
    if (params.reportType == 'both') {
      row.push(
        subjects.reduce((total, subject) => total + subject.totalClasses, 0) ??
          '0',
        isNaN(item.percentage) ? '0%' : `${item.percentage}%`,
      )
    } else if (params.reportType == 'percentage') {
      row.push(isNaN(item.percentage) ? '0%' : `${item.percentage}%`)
    } else if (params.reportType == 'total') {
      row.push(
        subjects.reduce((total, subject) => total + subject.totalClasses, 0) ??
          '0',
      )
    }
    ws_data.push(row)
  })

  const ws = XLSX.utils.aoa_to_sheet(ws_data)

  ws['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
    { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
    { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },
  ]

  let currentCol = 3
  subjects.forEach(() => {
    if (params.reportType == 'both') {
      ws['!merges'].push({
        s: { r: 0, c: currentCol },
        e: { r: 0, c: currentCol + 3 },
      })
      currentCol += 4
    } else {
      ws['!merges'].push({
        s: { r: 0, c: currentCol },
        e: { r: 0, c: currentCol + 2 },
      })
      currentCol += 3
    }
  })

  if (params.reportType == 'both') {
    ws['!merges'].push({
      s: { r: 0, c: currentCol },
      e: { r: 0, c: currentCol + 3 },
    })
  } else {
    ws['!merges'].push({
      s: { r: 0, c: currentCol },
      e: { r: 0, c: currentCol + 2 },
    })
  }

  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
  XLSX.writeFile(
    wb,
    `${params.branchCode}-${params.section}-semNo(${params.semNo}).xlsx`,
  )
}

export function suffixName(i) {
  var j = i % 10,
    k = i % 100
  if (j == 1 && k != 11) {
    return 'st'
  }
  if (j == 2 && k != 12) {
    return 'nd'
  }
  if (j == 3 && k != 13) {
    return 'rd'
  }
  return 'th'
}

// Assessment Report

export const fetchAssessmentReport = (params) => {
  return axios
    .get(`/square/assessment-report`, {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
}

export const fetchClassroomData = (classroomId: number) => {
  return axios.get(`/square/classrooms/${classroomId}`).then((res) => res.data)
}

export const exportAssessmentReport = (branchCode, batch, data, headers) => {
  const fileName = `Internals_${branchCode}_${batch}.xlsx`
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  const percentageWiseCountHeader = Object.keys(
    data?.percentageWiseCount[Object.keys(data?.percentageWiseCount)[0]],
  )
  const percentageWiseCountData = Object.values(data?.percentageWiseCount).map(
    (countData) => percentageWiseCountHeader.map((key) => countData[key]),
  )

  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
    data?.response?.map((student) => {
      let obj = {}
      headers
        ?.filter((column) => column.status == true)
        .forEach((header) => {
          obj[header?.label] = student[header?.label] ?? '--'
        })
      return obj
    }),
  )
  const wd = XLSX.utils.aoa_to_sheet([
    percentageWiseCountHeader,
    ...percentageWiseCountData,
  ])

  XLSX.utils.book_append_sheet(
    wb,
    ws,
    `${branchCode.slice(0, 4)}_${batch}_Batch`,
  )
  XLSX.utils.book_append_sheet(wb, wd, 'Percentage Wise Count')
  XLSX.writeFile(wb, fileName)
}
