import { CardsGrid, NoDataIllustration, Spinner } from '@campxdev/shared'
import { FeedbackStore } from 'pages/Feedback/store'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { SERVICES } from 'services'
import { ClassroomCard } from './components/ClassroomCard'
import { ClassroomCardsFilters } from './components/ClassroomCardsFilters'

export const ClassroomWiseAnalysis = () => {
  const facultyFeedbacksStore = FeedbackStore.useState((s) => s)
  const feedback = facultyFeedbacksStore.feedback

  const params = useParams()

  const [filters, setFilters] = useState({
    batch: null,
    courseId: null,
    programId: null,
  })

  const { data, isLoading, isRefetching } = useQuery(
    ['classroom-wise-feedback-analysis-facility', filters],
    () =>
      SERVICES.facilityFeedbacks.getFacilityFeedbackDistributionByClassroom(
        filters,
        params.id,
      ),
  )

  if (isLoading || isRefetching) return <Spinner />
  return (
    <>
      <ClassroomCardsFilters filters={filters} setFilters={setFilters} />
      {data.length ? (
        <>
          <CardsGrid cardWidth={450} gap={20}>
            {data?.map((classroomCardData, index) => (
              <ClassroomCard
                classroomCardData={classroomCardData}
                index={index}
                feedbackStatus={feedback?.status}
              />
            ))}
          </CardsGrid>
        </>
      ) : (
        <NoDataIllustration
          imageSrc="/illustrations/whiteboard.svg"
          message={'No Classrooms Added'}
        />
      )}
    </>
  )
}
