import { PageNotFound } from '@campxdev/shared'
import { SetwinAdmissionForm } from 'pages/PublicForms/Setwin'
const tenantKey = window.location.pathname.split('/')[1]

const pageMap = {
  setwin: <SetwinAdmissionForm />,
  campx_dev: <SetwinAdmissionForm />,
}

const page = tenantKey ? (
  pageMap[tenantKey] ?? <PageNotFound />
) : (
  <PageNotFound />
)

export const publicFormsRoutes = [
  {
    element: page,
    path: 'admission-form',
  },
]
