export const ProfileIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g id="mode-portrait_1_" data-name="mode-portrait (1)" opacity="0.5">
          <path
            id="Path_17999"
            data-name="Path 17999"
            d="M23,16a1,1,0,0,0-1,1v2a3,3,0,0,1-3,3H17a1,1,0,0,0,0,2h2a5.006,5.006,0,0,0,5-5V17A1,1,0,0,0,23,16Z"
          />
          <path
            id="Path_18000"
            data-name="Path 18000"
            d="M1,8A1,1,0,0,0,2,7V5A3,3,0,0,1,5,2H7A1,1,0,1,0,7,0H5A5.006,5.006,0,0,0,0,5V7A1,1,0,0,0,1,8Z"
          />
          <path
            id="Path_18001"
            data-name="Path 18001"
            d="M7,22H5a3,3,0,0,1-3-3V17a1,1,0,1,0-2,0v2a5.006,5.006,0,0,0,5,5H7a1,1,0,0,0,0-2Z"
          />
          <path
            id="Path_18002"
            data-name="Path 18002"
            d="M19,0H17a1,1,0,0,0,0,2h2a3,3,0,0,1,3,3V7a1,1,0,0,0,2,0V5A5.006,5.006,0,0,0,19,0Z"
          />
          <path
            id="Path_18003"
            data-name="Path 18003"
            d="M12,11A4,4,0,1,0,8,7a4,4,0,0,0,4,4Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,12,5Z"
          />
          <path
            id="Path_18004"
            data-name="Path 18004"
            d="M18,20a1,1,0,0,0,1-1,6.006,6.006,0,0,0-6-6H11a6.006,6.006,0,0,0-6,6,1,1,0,0,0,2,0,4,4,0,0,1,4-4h2a4,4,0,0,1,4,4A1,1,0,0,0,18,20Z"
          />
        </g>
      </svg>
    </>
  )
}

export const ClipIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23.137"
        height="24.007"
        viewBox="0 0 23.137 24.007"
      >
        <path
          id="clip_3_"
          data-name="clip (3)"
          d="M22.95,9.6a1,1,0,0,0-1.414,0L10.644,20.539a5,5,0,1,1-7.072-7.071L14.121,2.876a3,3,0,1,1,4.243,4.242L7.815,17.71a1.022,1.022,0,0,1-1.414,0,1,1,0,0,1,0-1.414l9.392-9.435a1,1,0,1,0-1.414-1.414L4.987,14.882a3,3,0,0,0,0,4.243,3.073,3.073,0,0,0,4.243,0L19.778,8.532a5,5,0,1,0-7.071-7.07L2.158,12.054a7,7,0,0,0,9.9,9.9L22.95,11.018a1,1,0,0,0,0-1.418Z"
          transform="translate(-0.108 0.003)"
          fill="#121212"
          opacity="0.5"
        />
      </svg>
    </>
  )
}

export const UploadIcon = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g id="download" opacity="0.5">
        <path
          id="Path_9411"
          data-name="Path 9411"
          d="M9.294.733a2.5,2.5,0,0,1,3.537,0l2.676,2.676a.833.833,0,0,1-1.178,1.176L11.89,2.145,11.9,15a.833.833,0,0,1-.833.833h0A.833.833,0,0,1,10.229,15l-.008-12.84L7.8,4.584A.833.833,0,0,1,6.618,3.405Z"
          transform="translate(-1.062 0)"
          fill="#121212"
        />
        <path
          id="Path_9412"
          data-name="Path 9412"
          d="M19.167,16h0a.833.833,0,0,0-.833.833v3.333A.833.833,0,0,1,17.5,21H2.5a.833.833,0,0,1-.833-.833V16.833A.833.833,0,0,0,.833,16h0A.833.833,0,0,0,0,16.833v3.333a2.5,2.5,0,0,0,2.5,2.5h15a2.5,2.5,0,0,0,2.5-2.5V16.833A.833.833,0,0,0,19.167,16Z"
          transform="translate(0 -2.667)"
          fill="#121212"
        />
      </g>
    </svg>
  </>
)

export const LinkIcon = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.999"
      height="16"
      viewBox="0 0 15.999 16"
    >
      <path
        id="link-alt"
        d="M5.225,10.781a4.319,4.319,0,0,1-.427-.5A.667.667,0,0,1,5.875,9.5a2.777,2.777,0,0,0,.291.341,2.833,2.833,0,0,0,4.007,0l3.667-3.667A2.834,2.834,0,1,0,9.833,2.163l-.705.705a.667.667,0,1,1-.943-.943l.705-.705a4.167,4.167,0,1,1,5.893,5.893l-3.667,3.667a4.166,4.166,0,0,1-5.892,0ZM4.17,16a4.137,4.137,0,0,0,2.946-1.221l.705-.705a.667.667,0,0,0-.943-.943l-.706.705A2.833,2.833,0,1,1,2.166,9.831L5.833,6.164a2.833,2.833,0,0,1,4.007,0,2.96,2.96,0,0,1,.291.34.667.667,0,0,0,1.079-.784,4.167,4.167,0,0,0-6.319-.5L1.224,8.888A4.167,4.167,0,0,0,4.17,16Z"
        transform="translate(-0.003 -0.002)"
      />
    </svg>
  </>
)

export const FileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.333"
    height="16"
    viewBox="0 0 13.333 16"
  >
    <path
      id="document"
      d="M12,9.333a.667.667,0,0,1-.667.667H6A.667.667,0,0,1,6,8.667h5.333A.667.667,0,0,1,12,9.333Zm-2.667,2H6a.667.667,0,0,0,0,1.333H9.333a.667.667,0,1,0,0-1.333Zm6-4.343v5.677A3.337,3.337,0,0,1,12,16H5.333A3.337,3.337,0,0,1,2,12.667V3.333A3.337,3.337,0,0,1,5.333,0h3.01a4.639,4.639,0,0,1,3.3,1.367l2.323,2.324a4.634,4.634,0,0,1,1.367,3.3ZM10.7,2.309a3.34,3.34,0,0,0-.7-.52V4.667a.667.667,0,0,0,.667.667h2.877a3.322,3.322,0,0,0-.521-.7ZM14,6.99c0-.11-.021-.215-.031-.323h-3.3a2,2,0,0,1-2-2v-3.3c-.108-.01-.214-.031-.323-.031H5.333a2,2,0,0,0-2,2v9.333a2,2,0,0,0,2,2H12a2,2,0,0,0,2-2Z"
      transform="translate(-2)"
    />
  </svg>
)

export const MediaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="picture"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      id="Path_6749"
      data-name="Path 6749"
      d="M12.667,0H3.333A3.337,3.337,0,0,0,0,3.333v9.333A3.337,3.337,0,0,0,3.333,16h9.333A3.337,3.337,0,0,0,16,12.667V3.333A3.337,3.337,0,0,0,12.667,0ZM3.333,1.333h9.333a2,2,0,0,1,2,2v9.333a1.967,1.967,0,0,1-.2.857L8.358,7.415a3.333,3.333,0,0,0-4.715,0L1.333,9.724V3.333a2,2,0,0,1,2-2Zm0,13.333a2,2,0,0,1-2-2V11.609L4.585,8.357a2,2,0,0,1,2.829,0l6.109,6.109a1.967,1.967,0,0,1-.857.2Z"
    />
    <path
      id="Path_6750"
      data-name="Path 6750"
      d="M14.833,8.167A2.333,2.333,0,1,0,12.5,5.833,2.333,2.333,0,0,0,14.833,8.167Zm0-3.333a1,1,0,1,1-1,1,1,1,0,0,1,1-1Z"
      transform="translate(-4.167 -1.167)"
    />
  </svg>
)

export const ExamResultIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.3"
        height="20.3"
        viewBox="0 0 15.3 20.3"
      >
        <path
          id="mobile"
          d="M13.833,0H7.167A4.172,4.172,0,0,0,3,4.167V15.833A4.172,4.172,0,0,0,7.167,20h6.667A4.172,4.172,0,0,0,18,15.833V4.167A4.172,4.172,0,0,0,13.833,0Zm2.5,15.833a2.5,2.5,0,0,1-2.5,2.5h-2.5V17.5a.833.833,0,1,0-1.667,0v.833h-2.5a2.5,2.5,0,0,1-2.5-2.5V4.167a2.5,2.5,0,0,1,2.5-2.5h6.667a2.5,2.5,0,0,1,2.5,2.5Zm-2.5-2.5a.833.833,0,0,1-.833.833H8A.833.833,0,1,1,8,12.5h5A.833.833,0,0,1,13.833,13.333Zm-5-2.5A.833.833,0,0,0,9.667,10V9.167h1.667V10A.833.833,0,0,0,13,10V6.667a2.5,2.5,0,1,0-5,0V10A.833.833,0,0,0,8.833,10.833Zm1.667-5a.833.833,0,0,1,.833.833V7.5H9.667V6.667A.833.833,0,0,1,10.5,5.833Z"
          transform="translate(-2.85 0.15)"
          stroke="#fff"
          stroke-width="0.3"
        />
      </svg>
    </>
  )
}

export const CareerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.3"
      height="20.3"
      viewBox="0 0 20.3 20.3"
    >
      <path
        id="briefcase"
        d="M15.833,3.333h-.917A4.174,4.174,0,0,0,10.833,0H9.167A4.174,4.174,0,0,0,5.083,3.333H4.167A4.172,4.172,0,0,0,0,7.5v8.333A4.172,4.172,0,0,0,4.167,20H15.833A4.172,4.172,0,0,0,20,15.833V7.5a4.172,4.172,0,0,0-4.167-4.167ZM9.167,1.667h1.667A2.5,2.5,0,0,1,13.18,3.333H6.82A2.5,2.5,0,0,1,9.167,1.667ZM4.167,5H15.833a2.5,2.5,0,0,1,2.5,2.5V10H1.667V7.5A2.5,2.5,0,0,1,4.167,5ZM15.833,18.333H4.167a2.5,2.5,0,0,1-2.5-2.5V11.667h7.5V12.5a.833.833,0,0,0,1.667,0v-.833h7.5v4.167A2.5,2.5,0,0,1,15.833,18.333Z"
        transform="translate(0.15 0.15)"
        stroke="#fff"
        stroke-width="0.3"
      />
    </svg>
  )
}
