import { Box, styled, Typography } from '@mui/material'
import { ReactNode } from 'react'

const StyledStack = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '& .MuiSvgIcon-root': {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
  },
}))

export default function IconLabel({
  label,
  icon,
  onClick,
}: {
  label: string
  icon: ReactNode
  onClick?: () => void
}) {
  return (
    <StyledStack
      onClick={onClick ?? onClick}
      sx={{
        ...(onClick && {
          cursor: 'pointer',
          '&:hover': { borderBottom: '1px solid gray' },
        }),
      }}
    >
      {icon}
      <Typography variant="subtitle2">{label}</Typography>
    </StyledStack>
  )
}
