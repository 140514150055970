import { Permission } from '@campxdev/shared'
import { lazy } from 'react'

const Buildings = lazy(() => import('pages/Infrastructure/Buildings/Buildings'))
const BuildingDetails = lazy(
  () =>
    import('pages/Infrastructure/Buildings/BuildingDetails/BuildingDetails'),
)
const Labs = lazy(() => import('pages/Infrastructure/Labs/Labs'))
const LabDetails = lazy(
  () => import('pages/Infrastructure/Labs/LabDetails/LabDetails'),
)
const Classrooms = lazy(
  () => import('pages/Infrastructure/Classrooms/Classrooms'),
)
const ClassroomDetails = lazy(
  () =>
    import('pages/Infrastructure/Classrooms/ClassroomDetails/ClassroomDetails'),
)
const Equipment = lazy(
  () => import('pages/Infrastructure/Equipments/Equipments'),
)
const EquipmentDetails = lazy(
  () =>
    import('pages/Infrastructure/Equipments/EquipmentDetails/EquipmentDetails'),
)

export const infrastructureRoutes = [
  {
    element: <Buildings />,
    path: 'buildings',
    permissionKey: Permission.CAN_INFRASTRUCTURE_BUILDINGS_VIEW,
  },
  {
    element: <BuildingDetails />,
    path: `buildings/:id`,
    permissionKey: Permission.CAN_INFRASTRUCTURE_BUILDINGS_VIEW,
  },
  {
    element: <Labs />,
    path: 'labs',
    permissionKey: Permission.CAN_INFRASTRUCTURE_LABS_VIEW,
  },
  {
    element: <LabDetails />,
    path: `labs/:id`,
    permissionKey: Permission.CAN_INFRASTRUCTURE_LABS_VIEW,
  },
  {
    element: <Classrooms />,
    path: 'classrooms',
    permissionKey: Permission.CAN_INFRASTRUCTURE_CLASSROOMS_VIEW,
  },
  {
    element: <ClassroomDetails />,
    path: `classrooms/:id`,
    permissionKey: Permission.CAN_INFRASTRUCTURE_CLASSROOMS_VIEW,
  },
  {
    element: <Equipment />,
    path: 'equipments',
    permissionKey: Permission.CAN_INFRASTRUCTURE_EQUIPMENTS_VIEW,
  },
  {
    element: <EquipmentDetails />,
    path: `equipments/:id`,
    permissionKey: Permission.CAN_INFRASTRUCTURE_EQUIPMENTS_VIEW,
  },
]
