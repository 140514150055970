import {
  ActionButton,
  FormMultiCheckbox,
  FormMultiSelect,
  FormRadioGroup,
  FormSingleSelect,
  Permission,
  Spinner,
  useConfirm,
  useErrorModal,
  ValidateAccess,
} from '@campxdev/shared'
import { Box, Stack, Typography } from '@mui/material'
import * as _ from 'lodash'
import moment from 'moment'
import { Store } from 'pullstate'
import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { classRoomStore } from '../ClassRooms/ClassRooms'
import { postMultipleTimeSlots, postTimeTable } from '../services'
import { timeTableStore } from './ClassRoomTimeTable'
import {
  deleteTimeTable,
  fetchSubjectGroups,
  fetchTimeTableFormOptions,
} from './service'

interface ITimeSlot {
  weekDay: { label: string; value: string }
  time: {}[]
}

const weekArray = moment.weekdays()?.map((item) => item.toUpperCase())

export const timetableStore = new Store({
  subjects: [],
  selectedSubject: null,
})
const TimeConvert = (timeString) => {
  const timeString12hr = new Date(
    '1970-01-01T' + timeString + 'Z',
  ).toLocaleTimeString('en-US', {
    timeZone: 'UTC',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  })
  return timeString12hr
}

export default function TimeTableForm({
  prevData,
  close,
}: {
  prevData: any
  close?: () => void
}) {
  const { isConfirmed } = useConfirm()
  const queryClient = useQueryClient()
  const errorModal = useErrorModal()
  const { classRoom } = classRoomStore.useState((s) => s)
  const subjects = timeTableStore.useState((s) => s.subjects)
  const subjectsMap = _.keyBy(subjects, (s) => s.subjectId)
  const [subjectIsValid, setSubjectIsValid] = useState(true)
  const [facultyIsValid, setFacultyIsValid] = useState(true)
  const { data: Options, isLoading } = useQuery('timetable-form-options', () =>
    fetchTimeTableFormOptions(classRoom.timetableSlotTemplateId),
  )

  const sorter = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
  }
  interface TimeSlot {
    id: number
    orderNumber: number
    fromTime: string
    toTime: string
  }
  const dayWiseTimeSlots = _.groupBy(Options?.timeSlots, 'day')
  const uniqueSlots: TimeSlot[] = _.uniq(
    Options?.timeSlots?.map((item) => item),
  )

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      subjectId: prevData ? prevData?.subjectId : null,
      day: prevData ? prevData?.day : null,
      days: [],
      period: prevData ? prevData?.orderNumber : [],
      roomId: prevData
        ? {
            label: Options?.rooms?.rooms?.find(
              (item) => item?.id == prevData?.roomId,
            )?.roomNo,
            value: Options?.rooms?.rooms?.find(
              (item) => item?.id == prevData?.roomId,
            )?.id,
          }
        : null,
      periods: [],
      groupIds: prevData?.groups
        ? prevData?.groups?.map((group) => ({
            label: group?.name,
            value: group?.id,
          }))
        : [],
      facultyIds: prevData?.faculties?.map((item) => ({
        label: item.fullName,
        value: item.id,
      })),
    },
  })

  const { data: subjectGroups, isLoading: subjectGroupsLoading } = useQuery(
    ['subject-groups', watch('subjectId')],
    () =>
      fetchSubjectGroups({
        classroomId: classRoom.id,
        subjectId: watch('subjectId'),
      }),
    {
      enabled: !!watch('subjectId'),
    },
  )

  const { fields, replace } = useFieldArray({
    name: 'periods',
    control,
  })

  useEffect(() => {
    if (prevData?.id) {
      if (prevData?.subjectId == watch('subjectId')) {
        setValue(
          'facultyIds',
          prevData.faculties?.map((item) => ({
            label: item.fullName,
            value: item.id,
          })),
        )
      } else {
        setValue(
          'facultyIds',
          subjectsMap[watch('subjectId')]?.faculties?.map((item) => ({
            label: item.fullName,
            value: item.id,
          })),
        )
      }
      return
    }
    const sortedDays = watch('days')?.sort(
      (a, b) => sorter[a?.label] - sorter[b?.label],
    )

    replace(
      sortedDays?.map((item, index) => ({
        time: watch('periods')[index]?.time ?? [],
        roomId: watch('periods')[index]?.roomId ?? null,
      })),
    )
  }, [watch('days'), watch('subjectId')])

  const { mutate, isLoading: posting } = useMutation(postTimeTable, {
    onSuccess: () => {
      toast.success('Time table slot Created successfully')
      queryClient.invalidateQueries('classroom-timetable')
      close()
    },
    onError(error) {
      errorModal({ error: error })
    },
  })

  const { mutate: submitMultipleTimeSlots, isLoading: submitting } =
    useMutation(postMultipleTimeSlots, {
      onSuccess: () => {
        toast.success('Time table slot created successfully')
        queryClient.invalidateQueries('classroom-timetable')
        close()
      },
      onError(error) {
        errorModal({ error: error })
      },
    })

  const onSubmit = async (formData) => {
    const selectedSubject = subjects?.find(
      (item) => item.subjectId == formData?.subjectId,
    )

    if (!watch('subjectId')) {
      setSubjectIsValid(false)
      return
    }

    if (!watch('facultyIds')?.length) {
      setFacultyIsValid(false)
      return
    }

    if (!selectedSubject?.faculties?.length) {
      toast.warn('Faculty not assigned to selected Subject')
      return
    }

    if (prevData && formData?.facultyIds?.length == 0) {
      toast.error('Please select at least 1 faculty')
      return
    }

    if (prevData?.id) {
      mutate({
        facultyIds: formData?.facultyIds?.map((item) => item?.value),
        day: formData?.day,
        orderNumber: formData?.period,
        section: classRoom?.section,
        courseId: classRoom?.courseId,
        branchCode: classRoom?.branchCode,
        subjectId: selectedSubject?.subjectId,
        batch: classRoom?.batchName,
        regulationId: classRoom?.regulationId,
        semNo: classRoom?.currentSemester,
        timeTableId: prevData?.id,
        roomId: +formData?.roomId?.value,
        groupIds: formData?.groupIds?.map((group) => group?.value) ?? [],
      })
      return
    }

    const flatTimeSlots = []

    formData?.days?.forEach((item, index) => {
      formData?.periods[index]?.time?.forEach((p, i) => {
        flatTimeSlots.push({
          day: item?.value,
          order: p.value,
          roomId: formData?.periods[index]?.roomId?.value ?? null,
        })
      })
    })

    const postDataMap = flatTimeSlots.map((item) => ({
      facultyIds: formData?.facultyIds?.map((item) => item?.value),
      day: item?.day,
      orderNumber: item?.order,
      section: classRoom?.section,
      courseId: classRoom?.courseId,
      branchCode: classRoom?.branchCode,
      subjectId: selectedSubject?.subjectId,
      groupIds: formData?.groupIds?.map((group) => group?.value) ?? [],
      batch: classRoom?.batchName,
      regulationId: classRoom?.regulationId,
      semNo: classRoom?.currentSemester,
      roomId: item?.roomId,
    }))

    submitMultipleTimeSlots(postDataMap)
  }

  const onError = (err) => {
    // eslint-disable-next-line no-console
    console.log(err)
  }

  const { mutate: deleteTimetableRec, isLoading: deletingTimetableRec } =
    useMutation(deleteTimeTable, {
      onSuccess: (res) => {
        toast.success('Time Table Deleted successfully')
        queryClient.invalidateQueries('classroom-timetable')
        close()
      },
      onError: (err) => {
        // eslint-disable-next-line no-console
        console.log(err)
        errorModal({ error: err })
      },
    })

  const handleDeleteSlot = async () => {
    const confirmed = await isConfirmed(
      'Are you sure you want to delete this slot?',
    )
    if (!confirmed) return

    deleteTimetableRec(prevData?.id)
  }

  if (isLoading) return <Spinner />

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Stack gap={2}>
        <FormSingleSelect
          multiple={false}
          label="Course"
          name="subjectId"
          options={
            subjects?.map((item) => ({
              label: item?.subjectName,
              value: item?.subjectId,
            })) || []
          }
          control={control}
          required
        />
        {!subjectIsValid && (
          <Typography sx={{ color: 'red' }}>Subject is required</Typography>
        )}

        {subjectGroupsLoading ? (
          <Spinner />
        ) : (
          <>
            {subjectGroups?.groups?.length > 0 && (
              <>
                <FormMultiSelect
                  label={'Select Groups'}
                  name={'groupIds'}
                  control={control}
                  options={
                    subjectGroups?.groups?.map((group) => ({
                      label: group?.name,
                      value: group?.id,
                    })) ?? []
                  }
                  required
                />
              </>
            )}

            {prevData ? (
              <>
                <FormRadioGroup
                  label="Select Days"
                  row
                  control={control}
                  name="day"
                  options={weekArray.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />

                <FormRadioGroup
                  control={control}
                  label="Select Period"
                  name="period"
                  options={dayWiseTimeSlots[watch('day')]?.map((item) => ({
                    label: `${TimeConvert(item.fromTime)}   -   ${TimeConvert(
                      item.toTime,
                    )}`,
                    value: item.orderNumber,
                  }))}
                />
                <FormMultiSelect
                  label={'Select Faculties'}
                  name={'facultyIds'}
                  control={control}
                  options={
                    subjectsMap[watch('subjectId')]?.faculties?.map((item) => ({
                      label: item.fullName,
                      value: item.id,
                    })) ?? []
                  }
                />
                <FormMultiSelect
                  multiple={false}
                  label={'Select Room Number'}
                  name={'roomId'}
                  control={control}
                  options={
                    Options?.rooms?.rooms?.map((item) => ({
                      label: item.roomNo,
                      value: item.id,
                    })) ?? []
                  }
                />
              </>
            ) : (
              <>
                <FormMultiCheckbox
                  label="Select Days"
                  row
                  control={control}
                  name="days"
                  options={weekArray.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
                <Box>
                  {fields?.map((item, index) => (
                    <Box key={item?.id} sx={{ margin: '20px 0' }}>
                      <Stack gap={2}>
                        <Typography>{watch('days')?.[index]?.value}</Typography>
                        <FormMultiSelect
                          control={control}
                          label={` Select Periods`}
                          name={`periods.${index}.time`}
                          options={dayWiseTimeSlots[
                            watch('days')?.[index]?.value
                          ]?.map((item) => ({
                            label: `${TimeConvert(
                              item.fromTime,
                            )}   -   ${TimeConvert(item.toTime)}`,
                            value: item.orderNumber,
                          }))}
                        />
                        <FormMultiSelect
                          label={'Select Faculties'}
                          name={'facultyIds'}
                          control={control}
                          options={
                            subjectsMap[watch('subjectId')]?.faculties?.map(
                              (item) => ({
                                label: item.fullName,
                                value: item.id,
                              }),
                            ) ?? []
                          }
                        />
                        {!facultyIsValid && (
                          <Typography sx={{ color: 'red' }}>
                            Faculty is required
                          </Typography>
                        )}

                        <FormMultiSelect
                          multiple={false}
                          label={'Select Room Number'}
                          name={`periods.${index}.roomId`}
                          control={control}
                          options={
                            Options?.rooms?.rooms?.map((item) => ({
                              label: item.roomNo,
                              value: item.id,
                            })) ?? []
                          }
                        />
                      </Stack>
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </>
        )}

        <Stack direction={'row'} gap="20px">
          <ActionButton variant="outlined" fullWidth onClick={close}>
            Cancel
          </ActionButton>
          {prevData && (
            <ActionButton
              variant="outlined"
              color="error"
              fullWidth
              onClick={handleDeleteSlot}
              loading={deletingTimetableRec}
            >
              Delete Class
            </ActionButton>
          )}
          <ValidateAccess accessKey={Permission.CAN_CLASSROOM_SESSION_ADD}>
            <ActionButton loading={posting} fullWidth type="submit">
              {prevData ? 'Edit Class' : 'Add Class'}
            </ActionButton>
          </ValidateAccess>
        </Stack>
      </Stack>
    </form>
  )
}
