import { axios } from '@campxdev/shared'
import { AxiosRequestConfig } from 'axios'
import _ from 'lodash'

export const TIMETABLE_SLOTS = '/square/timetable-slots'

interface PostTimetableSlotDto {
  id?: number
  fromTime: string
  toTime: string
}

export const timetableSlots = {
  async fetchAll(params) {
    const res = await axios.get(TIMETABLE_SLOTS, {
      params: {
        ...params,
      },
    })
    return res.data
  },

  async fetchOne(id: number) {
    const res = await axios.get(`${TIMETABLE_SLOTS}/${id}`)
    return res.data
  },

  async postTimetable(body: PostTimetableSlotDto) {
    const config: AxiosRequestConfig = {
      url: body?.id ? `${TIMETABLE_SLOTS}/${body.id}` : TIMETABLE_SLOTS,
      method: body?.id ? 'PUT' : 'POST',
      data: body,
    }

    const res = await axios(config)
    return res.data
  },

  async deleteTimetable(id: number) {
    const res = await axios.delete(`${TIMETABLE_SLOTS}/${id}`)
    return res.data
  },
}
