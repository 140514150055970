import { Box, styled, Typography } from '@mui/material'
export const StyledCardContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, minmax(0px, 1fr));',
  gap: '20px',
  margin: '20px 0px',
})
export const StyledCard = styled(Box)({
  height: '100px',
  borderRadius: '10px',
  border: '1px solid #1212121A',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
})

export const StyledStudentProfileContainer = styled(Box)({
  minHeight: '80px',
  border: '1px solid #1212121A',
  borderRadius: '10px',
  margin: '20px 0px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '10px',
})

export const StyledAssessmentContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '40px',
})

export const StyledAssessmentCardContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, minmax(0px, 1fr));',
  gap: '20px',
  margin: '40px 0px',
})

export const StyledAssessmentType = styled(Typography)({
  textAlign: 'left',
  borderBottom: '1px solid #1212121A',
  lineHeight: '0.1em',
  margin: '10px 0px',
})

export const StyledSpanAssessmentTypeText = styled('span')({
  backgroundColor: 'white',
  padding: '10px 10px 10px 0px ',
})
