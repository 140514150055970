import { axios } from '@campxdev/shared'
import _ from 'lodash'
import moment from 'moment'
import * as XLSX from 'xlsx'

export let successUrl = `${process.env.REACT_APP_API_URL}/forms/esign/success`
export let failureUrl = `${process.env.REACT_APP_API_URL}/forms/esign/failure`
export let cancelUrl = `${process.env.REACT_APP_API_URL}/forms/esign/cancel`

const createForm = (data: any) => {
  return axios.post('/square/forms', data)
}

const updateForm = ({ data, id }: any) => {
  return axios.put(`/square/forms/${id}`, data)
}

const deleteForm = ({ id }: any) => {
  return axios.delete(`/square/forms/${id}`)
}

const cloneForm = ({ id, data }: any) => {
  return axios.post(`/square/forms/${id}/clone`, data)
}

const getForms = () => {
  return axios.get('/square/forms').then((res) => res.data)
}

const getDefaultForms = () => {
  return axios.get('/square/forms/default')
}

const importForms = (data: any) => {
  return axios.post('/square/forms/import', data)
}

const getForm = (id: any) => {
  return axios.get(`/square/forms/${id}`).then((res) => res.data)
}

const addPage = ({ formId, name }: any) => {
  return axios.post(`/square/forms/${formId}/pages`, { name })
}

const duplicatePage = ({ formId, pageId }: any) => {
  return axios.post(`/square/forms/${formId}/pages/${pageId}/clone`)
}

const deletePage = ({ formId, pageId }: any) => {
  return axios.delete(`/square/forms/${formId}/pages/${pageId}`)
}

const updatePage = async (props: {
  formId: string
  pageId: string
  data: any
  onSuccess: () => void
  onError: (error: any) => void
}) => {
  try {
    await axios.patch(
      `/square/forms/${props.formId}/pages/${props.pageId}`,
      props.data,
    )

    props.onSuccess()
  } catch (error) {
    props.onError(error)
  }
}

const deleteField = ({ formId, pageId, fieldId }: any) => {
  return axios.delete(
    `/square/forms/${formId}/pages/${pageId}/fields/${fieldId}`,
  )
}

const addField = ({ formId, pageId, data }: any) => {
  return axios.post(`/square/forms/${formId}/pages/${pageId}/fields`, data)
}

const updateField = ({ formId, pageId, fieldId, data }: any) => {
  return axios.patch(
    `/square/forms/${formId}/pages/${pageId}/fields/${fieldId}`,
    data,
  )
}

const getFormValidations = () => {
  return axios.get('/square/form-validations')
}

const getDefaultFormValidations = () => {
  return axios.get('/square/form-validations/default')
}

const importFormValidations = (data: any) => {
  return axios.post('/square/form-validations/import', data)
}

const createFormValidation = ({ data }) => {
  return axios.post('/square/form-validations', data)
}

const updateFormValidation = ({ id, data }) => {
  return axios.put(`/square/form-validations/${id}`, data)
}

const deleteFormValidation = ({ id }) => {
  return axios.delete(`/square/form-validations/${id}`)
}

const getFormActivity = ({ queryKey }: any) => {
  return axios.get(`/square/forms/${queryKey[1]}/activity`)
}

const signField = ({ formId, fieldId, data }: any) => {
  return axios.post(`/square/forms/${formId}/fields/${fieldId}/esign`, data)
}

const getTask = ({ queryKey }: any) => {
  return axios.get(`/square/tasks/task-details/${queryKey[1]}`)
}

const submitResponse = ({
  formId,
  responseData,
  razorPayDetails,
}: {
  formId: string
  responseData: any
  razorPayDetails?: any
}) => {
  return axios.post(`/square/forms/${formId}/responses`, {
    responseData: responseData,
    razorPayDetails: razorPayDetails,
  })
}

const updateFormResponseStatus = ({
  formResponseId,
  razorPayDetails,
}: {
  formResponseId: string
  razorPayDetails?: any
}) => {
  return axios.post(`/square/forms/${formResponseId}/update-status`, {
    razorPayDetails: razorPayDetails,
  })
}
const getResponses = async (formId) => {
  const res = await axios.get(`/square/forms/${formId}/responses`)
  return res.data?.map((item) => ({
    ...item,
    data: {
      Date: item?.createdAt,
      ...item?.data?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr?.fieldId]: curr?.value,
        }),
        {},
      ),
    },
  }))
}

const exportResponses = async (columns, responses, formName) => {
  const excelData = responses?.map((item, index) => {
    let data = {}
    columns
      .filter((title) => title.key !== 'paymentStatus')
      .forEach((col) => {
        data[col.title] = col?.render
          ? col.render(_.get(item, col.dataIndex), item, index)
          : _.get(item, col.dataIndex)
      })
    data['PaymentStatus'] = item.paymentStatus
    data['orderId'] = item.orderId

    return data
  })
  const fileName = `${formName}-responses.xlsx`
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)

  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, `${moment().format('DD-MM-YYYY')}`)
  XLSX.writeFile(wb, fileName)
}

const fetchResponseFieldArray = (pageResponse, form) => {
  let responseArray = Object.keys(pageResponse)?.map((item) => {
    let field = form.pages[form.active]?.fields?.find(
      (field) => field?._id == item,
    )
    if (
      field?.fieldType == 'DROPDOWN_MULTIPLE' ||
      field?.fieldType == 'CHECKBOX'
    ) {
      return {
        pageId: form.pages[form.active]._id,
        fieldId: item,
        value: Array.isArray(pageResponse[item])
          ? pageResponse[item]?.map((option) => option?.value)?.join(',')
          : '-',
        label: field?.label,
      }
    } else {
      return {
        pageId: form.pages[form.active]._id,
        fieldId: item,
        value: pageResponse[item],
        label: field?.label,
      }
    }
  })
  return [...responseArray]
}

export {
  addField,
  addPage,
  cloneForm,
  createForm,
  createFormValidation,
  deleteField,
  deleteForm,
  deleteFormValidation,
  deletePage,
  duplicatePage,
  exportResponses,
  fetchResponseFieldArray,
  getDefaultFormValidations,
  getDefaultForms,
  getForm,
  getFormActivity,
  getFormValidations,
  getForms,
  getResponses,
  getTask,
  importFormValidations,
  importForms,
  signField,
  submitResponse,
  updateField,
  updateForm,
  updateFormResponseStatus,
  updateFormValidation,
  updatePage,
}
