import { axios } from '@campxdev/shared'
import * as XLSX from 'xlsx'
interface Uploadbody {
  eventId: number
  imageKeys: Array<string>
}
export const excelExport = async ({ eventId }) => {
  const EventData = await axios.get(`/square/events/details/${eventId}`)

  const excelData = EventData?.data?.participants.map((item, index) => ({
    NAME: item?.user?.fullName || '',
    USERID: item?.user.id || '',
    TYPE:
      item?.userType == 'admin'
        ? 'Admin'
        : item?.userType == 'participant'
        ? 'Student'
        : 'Faculty' || '',
    EMAIL: item?.user?.email || '',
    BATCH: item?.student?.batch || '',
    BRANCH: item?.student?.branchCode || '',

    MOBILE: item?.user?.mobile || '-',
    ATTENDANCE: item?.isAttended ? 'PRESENT' : 'ABSENT',
  }))
  const fileName = `events.xlsx`
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'events')
  XLSX.writeFile(wb, fileName)
}

export const PostEventGalleryImage = (postBody: Uploadbody) => {
  return axios.post('square/events/add-event-gallery ', postBody)
}

export const EventImageDelete = (params) => {
  return axios.delete('square/events/delete-image', {
    params: {
      ...params,
    },
  })
}

export const removeEventParticipant = ({
  participantId,
}: {
  participantId: number
}) => {
  return axios.delete(`square/events/${participantId}`)
}
