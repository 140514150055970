import { Permission } from '@campxdev/shared'

import { SubjectTypes } from '../pages/Academics'

export const courseRoutes = [
  {
    path: 'subject-types',
    element: <SubjectTypes />,
    permissionKey: Permission.SUBJECT_TYPE_VIEW,
  },
]
