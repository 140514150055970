import { Permission } from '@campxdev/shared'

import {
  AcademicCalendar,
  AcademicCalendarEventList,
  AcademicCalendarTimeline,
  AssessmentRules,
  AssessmentTemplates,
  AssessmentTypes,
  Curriculums,
  Holidays,
  Sections,
  Semesters,
  SubjectTypes,
  TimeSlotTemplate,
  TimeTableSlots,
} from '../pages/Academics'

export const academicRoutes = [
  {
    path: 'assessment-types',
    element: <AssessmentTypes />,
    permissionKey: Permission.ASSESSMENT_TYPE_VIEW,
  },
  {
    path: 'assessment-templates',
    element: <AssessmentTemplates />,
    permissionKey: Permission.CAN_ASSESSMENT_TEMPLATES_VIEW,
  },
  {
    path: 'assessment-templates/:assessmentTemplateId/assessment-rules',
    element: <AssessmentRules />,
    permissionKey: Permission.CAN_ASSESSMENT_RULES_VIEW,
  },
  {
    path: 'subject-types',
    element: <SubjectTypes />,
    permissionKey: Permission.SUBJECT_TYPE_VIEW,
  },
  {
    element: <Holidays />,
    path: 'holidays',
    permissionKey: Permission.ACADEMIC_CALENDAR_VIEW,
  },
  {
    element: <Semesters />,
    path: 'semesters',
    permissionKey: Permission.CAN_SEMESTERS_VIEW,
  },

  {
    element: <Curriculums />,
    path: 'curriculums',
    permissionKey: Permission.CAN_CURRICULUMS_VIEW,
  },
  {
    element: <TimeSlotTemplate />,
    path: 'time-slot-templates',
    permissionKey: Permission.CAN_TIME_SLOTS_VIEW,
  },
  {
    path: 'time-slot-templates/:timeTableTemplateId/time-slots',
    element: <TimeTableSlots />,
    permissionKey: Permission.CAN_TIME_SLOTS_VIEW,
  },
  {
    element: <AcademicCalendar />,
    path: 'academic-calendar',
    permissionKey: Permission.ACADEMIC_CALENDAR_VIEW,
  },
  {
    element: <AcademicCalendarEventList />,
    path: 'academic-calendar/semester/:semesterId',
    permissionKey: Permission.ACADEMIC_CALENDAR_VIEW,
  },
  {
    element: <AcademicCalendarTimeline />,
    path: 'academic-calendar-timeline',
    permissionKey: Permission.ACADEMIC_CALENDAR_VIEW,
  },
  {
    element: <Sections />,
    path: 'sections',
    permissionKey: Permission.CAN_INSTITUTION_VIEW,
  },
]
