const POST = {
  announcement: 'SQUARE/posted-announcement',
  event: 'SQUARE/posted-event',
  blog: 'SQUARE/posted-blog',
}

const LOGIN = {
  login: 'AUTH/logged-in',
}

const ATTENDANCE = {
  add: 'SQUARE/marked-attendance',
}

const TASK = {
  created: 'SQUARE/created-task',
  completed: 'SQUARE/changed-task-status-completed',
  ongoing: 'SQUARE/changed-task-status-ongoing',
  'on-hold': 'SQUARE/changed-task-status-onhold',
  cancelled: 'SQUARE/changed-task-status-cancelled',
}

const LMS = {
  'create-assignment': 'LMS/created-assignment',
  'create-quiz': 'LMS/created-quiz',
  'create-handout': 'LMS/created-handout',
}

export const activityLogTypes = {
  POST,
  ATTENDANCE,
  LOGIN,
  TASK,
  LMS,
}
