import { Store } from 'pullstate'
import { QuestionTypes } from './utils'

import produce from 'immer'

export const FeedbackStore = new Store({
  feedback: null,
  type: null,
  enableAPI: true,
})

export const FeedbackQuestionStore = new Store({
  enableAPI: true,
  question: null,
  questionType: QuestionTypes.TEXT_FIELD,
  maximumScore: null,
  optional: false,
  hasScore: true,
  textField: {
    placeholderText: null,
    fieldSize: 'small',
    showCharacterCount: false,
    minimumLength: 1,
    maximumLength: 1,
    lengthMetric: 'characters',
  },
  singleSelect: {
    fieldSize: 'small',
    options: [
      { option: null, score: null },
      { option: null, score: null },
    ],
  },
  multiSelect: {
    fieldSize: 'small',
    options: [
      { option: null, score: null },
      { option: null, score: null },
    ],
    minimumSelectedOptions: 1,
    maximumSelectedOptions: 1,
  },
  multiCheckBox: {
    options: [
      { option: null, score: null },
      { option: null, score: null },
    ],
    minimumSelectedOptions: 1,
    maximumSelectedOptions: 1,
    columns: 1,
  },
  radio: {
    options: [
      { option: null, score: null },
      { option: null, score: null },
    ],
    columns: 1,
  },
  rating: {
    type: 'stars',
    allowPartialRating: false,
  },
})

export const setActivePage = (page) => {
  FeedbackFormStore.update((s) => ({
    ...s,
    currentPage: page + 1,
  }))
}

export const handleAddPage = () => {
  FeedbackFormStore.update((s) => {
    const tempPages = produce(s.pages, (draftPages) => {
      draftPages.push([])
      return draftPages
    })
    return {
      ...s,
      totalPages: tempPages.length,
      currentPage: tempPages.length,
      pages: tempPages,
    }
  })
}

export const handleDeletePage = () => {
  FeedbackFormStore.update((s) => {
    const tempPages = produce(s.pages, (draftPages) => {
      draftPages.splice(s.currentPage - 1, 1)
      for (let i = s.currentPage - 1; i < draftPages.length; i++) {
        for (let j = 0; j < draftPages[i].length; j++) {
          draftPages[i][j] = {
            ...draftPages[i][j],
            page: draftPages[i][j].page - 1,
          }
        }
      }
      return draftPages
    })
    return {
      ...s,
      totalPages: tempPages.length,
      currentPage:
        s.currentPage < tempPages.length ? s.currentPage : tempPages.length,
      pages: tempPages,
    }
  })
}

export const FeedbackFormStore = new Store({
  totalPages: 0,
  currentPage: 1,
  pages: [],
})
