import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

const initialState = {
  loading: true,
  steps: ['Rooms', 'Students Arrangement', 'Seating Arrangement', 'Sessions'],
  allRoomsCapacity: 0,
  totalStudents: 0,
  arrangedStudents: 0,
  roomsMeta: [],
  rooms: [],
  availableRooms: [],
  currentStep: 0,
  subjectStudents: {},
  subjects: {},
  draggableSubjects: [],
  disableNext: true,
  disablePrev: false,
}

const slice = createSlice({
  name: 'seatingArrangement',
  initialState: initialState,
  reducers: {
    fetchStudents(state) {
      state.loading = true
    },
    fetchedStudents(state, action) {
      state.loading = false
      if (action.payload.students.length != 0) {
        state.subjects[action.payload.subjectCode] = action.payload.subjectName
        let exist = state.draggableSubjects.find(
          (item) => item.subjectCode == action.payload.subjectCode,
        )
        if (!exist) {
          state.draggableSubjects.push({
            id: uuidv4(),
            name: action.payload.subjectName,
            subjectCode: action.payload.subjectCode,
          })
        }
        state.subjectStudents[action.payload.subjectCode] = {
          ...state.subjectStudents[action.payload.subjectCode],
          [action.payload.branchCode]: action.payload.students,
        }

        let totalStudentsCount = 0

        for (let subCode of Object.keys(state.subjects)) {
          for (let branchCode of Object.keys(state.subjectStudents[subCode])) {
            totalStudentsCount +=
              state.subjectStudents[subCode][branchCode].length
          }
        }

        state.totalStudents = totalStudentsCount
      }
    },
    clearSubjects(state) {
      state.subjects = {}
      state.totalStudents = 0
      state.draggableSubjects = []
      state.subjectStudents = {}
    },
    deleteBranch(state, action) {
      const { subjectCode, branchCode } = action.payload
      delete state.subjectStudents[subjectCode][branchCode]
      if (Object.keys(state.subjectStudents[subjectCode]).length == 0) {
        delete state.subjectStudents[subjectCode]
        delete state.subjects[subjectCode]
      }

      let totalStudentsCount = 0

      for (let subCode of Object.keys(state.subjects)) {
        for (let branchCode of Object.keys(state.subjectStudents[subCode])) {
          totalStudentsCount +=
            state.subjectStudents[subCode][branchCode].length
        }
      }

      state.totalStudents = totalStudentsCount
      state.draggableSubjects = state.draggableSubjects?.filter(
        (item) => item.subjectCode !== subjectCode,
      )
    },
    nextStep(state) {
      if (state.currentStep < state.steps.length - 1) {
        state.currentStep = state.currentStep + 1
      }
    },
    disableNext(state, action) {
      state.disableNext = action.payload
    },
    prevStep(state) {
      if (state.currentStep > 0) {
        state.currentStep = state.currentStep - 1
      }
    },
    updateRoomValue(state, action) {
      state.availableRooms[action.payload.index][action.payload.key] =
        action.payload.value
    },
    restoreSelectedRooms(state) {
      let data = JSON.parse(localStorage.getItem('seating_plan'))
      state.availableRooms = data.availableRooms
    },
    updateSelectedRooms(state) {
      state.roomsMeta = state.roomsMeta.filter((f) => f)
      state.roomsMeta = state.availableRooms
        .filter((item) => item.selected)
        .map((item) => ({
          roomNo: `${item.block}-${item.roomNo}`,
          rows: item.rows,
          cols: item.cols,
        }))

      state.allRoomsCapacity = state.roomsMeta.reduce((sum, item) => {
        return sum + item.rows * item.cols
      }, 0)

      // localStorage.setItem('seating_plan', JSON.stringify({
      //   // selectedRooms: state.roomsMeta,
      //   allRoomsCapacity: state.allRoomsCapacity,
      //   availableRooms: state.availableRooms
      // }));
    },
    addRoom(state) {
      state.availableRooms = state.availableRooms.filter((f) => f)
      let roomNo = state.availableRooms.length + 1
      state.availableRooms.push({
        roomNo: 'R' + roomNo,
        rows: 6,
        cols: 6,
      })
    },
    deleteRoom(state, action) {
      delete state.availableRooms[action.payload.index]
      state.availableRooms = state.availableRooms.filter((f) => f)
    },
    setAvailableRooms(state, action) {
      state.availableRooms = action.payload.map((item) => ({
        ...item,
        selected: false,
      }))
    },
    arrangeStudents(state) {},
    arrangedStudents(state, action) {
      state.rooms = action.payload.rooms
      state.totalStudents = action.payload.totalStudents
      state.arrangedStudents = action.payload.arrangedStudents
    },
    shuffleSubjects(state, action) {
      state.draggableSubjects = action.payload.subjects
    },
  },
})

export const {
  nextStep,
  prevStep,
  updateRoomValue,
  setAvailableRooms,
  addRoom,
  deleteRoom,
  fetchStudents,
  fetchedStudents,
  arrangedStudents,
  arrangeStudents,
  disableNext,
  updateSelectedRooms,
  deleteBranch,
  shuffleSubjects,
  clearSubjects,
  restoreSelectedRooms,
} = slice.actions

export default slice.reducer
