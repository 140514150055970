import { SquarePermissions } from '@campxdev/shared/src/shared-state/PermissionsStore'
import { AllMentors } from 'pages/Mentors/AllMentors'
import { MentorDetails } from 'pages/Mentors/AllMentors/MentorDetails'
import { AddSession } from 'pages/Mentors/components/AddSession'
import { MenteeDetails } from 'pages/Mentors/components/MenteeDetails'
import { SessionsDetails } from 'pages/Mentors/components/SessionDetails'

export const mentorsRoutes = [
  {
    index: true,
    element: <AllMentors />,
    permissionKey: SquarePermissions.CAN_VIEW_MENTORS,
  },
  {
    index: true,
    element: <MentorDetails />,
    path: ':mentorUserId',
    permissionKey: SquarePermissions.CAN_VIEW_MENTORS,
  },
  {
    element: <MenteeDetails />,
    path: ':mentorUserId/mentees/:studentId',
    permissionKey: SquarePermissions.CAN_VIEW_MENTEES,
  },
  {
    element: <AddSession />,
    path: ':mentorUserId/mentees/:studentId/add-session',
    permissionKey: SquarePermissions.CAN_CREATE_SESSION,
  },
  {
    element: <SessionsDetails />,
    path: ':mentorUserId/sessions/:sessionOrder',
    permissionKey: SquarePermissions.CAN_VIEW_MENTOR_SESSIONS,
  },
]
