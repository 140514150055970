import {
  ActionButton,
  DateRangePicker,
  MultiSelect,
  RadioGroup,
  SingleSelect,
  Spinner,
} from '@campxdev/shared'
import { Stack } from '@mui/material'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useImmer } from 'use-immer'
import {
  FundingSources,
  PatentStatus,
  ProjectStatus,
  fetchEmployees,
  fetchPrograms,
} from '../service'

const defaultStateObj = {
  startDate: null,
  endDate: null,
  projectStatus: null,
  patentStatus: null,
  fundingSource: null,
  programName: null,
  globalUserId: null,
}

export const fetchOptions = async () => {
  const programs = await fetchPrograms()
  const users = await fetchEmployees()
  return {
    programs,
    users,
  }
}
function ProjectFilter({ state, setState, close }) {
  const [filter, setFilter] = useImmer(defaultStateObj)
  const { isLoading: loading, data: options } = useQuery('field-options', () =>
    fetchOptions(),
  )

  const applyFilter = () => {
    setState((s) => {
      s.filter.startDate = filter.startDate
      s.filter.endDate = filter.endDate
      s.filter.projectStatus = filter.projectStatus
      s.filter.patentStatus = filter.patentStatus
      s.filter.fundingSource = filter.fundingSource
      s.filter.programName = filter.programName
      s.filter.globalUserId = filter.globalUserId?.value
    })

    Object.keys(filter).some((key) => {
      if (!!filter[key]) {
        setState((s) => {
          s.filterApplied = true
        })
      }
    })
    close()
  }
  const clearFilters = () => {
    setState((s) => {
      s.filter.startDate = null
      s.filter.endDate = null
      s.filter.projectStatus = null
      s.filter.patentStatus = null
      s.filter.fundingSource = null
      s.filter.programName = null
      s.filter.globalUserId = null
      s.filterApplied = false
    })
    close()
  }

  useEffect(() => {
    const selectedUser = options?.users?.data?.find(
      (item) => item._id == state.filter.globalUserId,
    )
    setFilter({
      ...state.filter,
      globalUserId: {
        label: selectedUser?.fullName ?? '',
        value: selectedUser?._id ?? '',
      },
    })
  }, [])

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      <Stack gap={2}>
        <SingleSelect
          label={'Filter by Research Area/Field'}
          name={'programName'}
          value={filter.programName}
          onChange={(e) => {
            setFilter((s) => {
              s.programName = e.target.value
            })
          }}
          options={
            options?.programs?.map((item) => ({
              value: item.branchName,
              label: item.branchName,
            })) ?? []
          }
        />

        <MultiSelect
          multiple={false}
          label="Search by Researcher/Faculty Name"
          name={'facultyName'}
          value={filter.globalUserId}
          onChange={(value) => {
            setFilter((s) => {
              s.globalUserId = value
            })
          }}
          options={
            options?.users?.data?.map((item) => ({
              label: item?.fullName,
              value: item?._id,
            })) ?? []
          }
          required
        />

        <RadioGroup
          label={'Filter by Project Status'}
          name={'projectStatus'}
          row={true}
          value={filter?.projectStatus}
          onChange={(e) => {
            setFilter((s) => {
              s.projectStatus = e.target.value
            })
          }}
          options={
            Object.keys(ProjectStatus)?.map((key) => ({
              label: ProjectStatus[key],
              value: key,
            })) ?? []
          }
        />

        <DateRangePicker
          label={'Filter by date'}
          name={'date'}
          value={{ start: filter?.startDate, end: filter?.endDate }}
          onChange={(value) => {
            setFilter((s) => {
              s.endDate = value.end
              s.startDate = value.start
            })
          }}
        />

        <SingleSelect
          label={'Funding Source'}
          name={'fundingSource'}
          value={filter?.fundingSource}
          onChange={(e) => {
            setFilter((s) => {
              s.fundingSource = e.target.value
            })
          }}
          options={
            Object.keys(FundingSources)?.map((key) => ({
              label: FundingSources[key],
              value: key,
            })) ?? []
          }
        />

        <RadioGroup
          label={'Filter by Patent Status'}
          name={'patentStatus'}
          row={true}
          value={filter?.patentStatus}
          onChange={(e) => {
            setFilter((s) => {
              s.patentStatus = e.target.value
            })
          }}
          options={
            Object.keys(PatentStatus)?.map((key) => ({
              label: PatentStatus[key],
              value: key,
            })) ?? []
          }
        />

        <Stack direction="row" gap={2} mt={3}>
          <ActionButton variant="outlined" fullWidth onClick={clearFilters}>
            Clear Filter
          </ActionButton>
          <ActionButton type="submit" fullWidth onClick={applyFilter}>
            Apply Filter
          </ActionButton>
        </Stack>
      </Stack>
    </>
  )
}

export default ProjectFilter
