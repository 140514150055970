import { ActionButton, Helmet } from '@campxdev/shared'
import styled from '@emotion/styled'
import { Box, Stack, Typography } from '@mui/material'
import { auLogo, auisclogo, eventiternary, iuceelogo } from 'assets/images'
import { Link } from 'react-router-dom'

const StyledMainContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  background: 'rgba(248, 248, 248, 1)',
  padding: '50px',
  ['@media(max-width: 680px)']: {
    minHeight: '0vh',
    background: 'rgba(248, 248, 248, 1)',
    padding: '20px',
  },
}))

export const StyledAccessFormContainer = styled(Box)(() => ({
  padding: '50px',
  maxWidth: 1000,
  margin: 'auto',
  position: 'relative',
  overflow: 'hidden',
  background: 'white',
  border: '1px solid #E0E0E0',
  borderRadius: 10,
  display: 'grid',
  gap: '30px',
  ['@media(max-width: 680px)']: {
    padding: '20px',
    maxWidth: 1000,
    position: 'relative',
    overflow: 'scroll',
    background: 'white',
    border: '1px solid #E0E0E0',
    borderRadius: 10,
    display: 'grid',
    gap: '30px',
  },
}))

export const StyledFormHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'white',
  borderBottom: '1px solid #E0E0E0',
  width: '100%',
  padding: '15px 10px',

  ['@media(max-width: 680px)']: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    background: 'white',
    borderBottom: '1px solid #E0E0E0',
    width: '100%',
    padding: '15px 10px',
  },
}))

const AUEvent = () => {
  return (
    <>
      <Helmet
        appTitle="Anurag University"
        description={'Event'}
        user={undefined}
        favicon={''}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        padding="20px"
      >
        <Stack width="500px" gap={4}>
          <Box>
            <Box display="flex" justifyContent="space-between">
              <img src={auLogo} width={'30%'} />
              <img src={auisclogo} width={'30%'} />
              <img src={iuceelogo} width={'30%'} />
            </Box>
            <Box display="flex" flexDirection="column">
              <img src={eventiternary} width="100%" height={'70%'} />
            </Box>
          </Box>

          <Typography variant="body1">Select an Option to Continue</Typography>
          <Box>
            <Link
              to={
                'https://ums.campx.in/aupulse/aupulse/public/form/663c92be911321aabf815b3c'
              }
              target="__blank"
            >
              <ActionButton
                variant="outlined"
                fullWidth
                sx={{ margin: '10px' }}
              >
                Proceed without accommodation
              </ActionButton>
            </Link>
            <Link
              to={`https://ums.campx.in/aupulse/aupulse/public/form/663c9300911321aabf816077`}
              target="__blank"
            >
              <ActionButton
                variant="outlined"
                fullWidth
                sx={{ margin: '10px' }}
              >
                Proceed with accommodation
              </ActionButton>
            </Link>
          </Box>
        </Stack>
      </Box>
    </>
  )
}
export default AUEvent
