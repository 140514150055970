import {
  ActionButton,
  axios,
  DatePicker,
  NoDataIllustration,
  PageContent,
  ReportHeader,
  romanize,
  SingleSelect,
  Spinner,
} from '@campxdev/shared'
import {
  Alert,
  Box,
  Button,
  styled as MuiStyled,
  Stack,
  Typography,
} from '@mui/material'
import { formImage } from 'assets/images'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { exportData, fetchSectionWiseAttendanceReport } from './service'

function useUrlParams() {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  for (const i in params) {
    params[i] = decodeURIComponent(params[i])
  }
  return params
}

function AnuragSectionWiseAttendanceReport() {
  const search = useUrlParams()
  const [state, setState] = useState({
    fromDate: null,
    toDate: null,
    reportType: 'count',
    showApplicableClasses: true,
    showActivitySubjects: false,
  })
  const { data, isLoading, error, refetch } = useQuery(
    ['attendance-report'],
    () =>
      fetchSectionWiseAttendanceReport({
        courseId: search.courseId,
        batch: search.batch,
        semNo: search.semNo,
        branchCode: search.branchCode,
        section: search.section,
        fromDate: state.fromDate
          ? moment(state?.fromDate).format('YYYY-MM-DD')
          : '',
        toDate: state?.toDate ? moment(state?.toDate).format('YYYY-MM-DD') : '',
      }),
  )

  const { data: classroom, isLoading: loadingClassroom } = useQuery(
    'classroom',
    () =>
      axios
        .get(`/square/classrooms/${search?.classroomId}`)
        .then((res) => res.data),
  )

  const getDateDetails = () => {
    let fromDate = state.fromDate
    let toDate = state.toDate

    if (fromDate && toDate) {
      return `${moment(state.fromDate).format('DD-MM-YYYY')} to ${moment(
        state?.toDate,
      ).format('DD-MM-YYYY')} Report`
    }
    if (fromDate && !toDate) {
      return `${moment(state?.fromDate).format(
        'DD-MM-YYYY',
      )} to Current Date Report`
    }
    if (!fromDate && toDate) {
      return `Till Date ${moment(state?.toDate).format('DD-MM-YYYY')} Report`
    } else {
      return `Overall Report`
    }
  }

  if (isLoading || loadingClassroom) return <Spinner />
  return (
    <>
      <PageContent>
        <Stack gap={2} style={{ alignItems: 'center' }}>
          <ReportHeader />
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h1">
              {`${classroom?.course?.courseName},  ${romanize(
                search?.semNo,
              )} - Sem, ${classroom?.name},  Attendance Report`}
            </Typography>
          </div>
          <div>
            <Typography variant="h1">{getDateDetails()}</Typography>
          </div>
        </Stack>
        <Stack
          direction={'row'}
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          {state.showApplicableClasses ? (
            <>
              <Alert
                color="warning"
                sx={{ margin: '20px 0px', width: '700px' }}
              >
                <Typography>
                  The count shown in the parenthesis is the number of classes
                  applicable for a student for the respective subjects and the
                  same is considered for calculation of the percentage
                </Typography>
              </Alert>
            </>
          ) : (
            <Box></Box>
          )}
          <StyledHeader>
            <Box sx={{ width: '200px' }}>
              <SingleSelect
                label={'Report Type'}
                name={'reportType'}
                options={[
                  { label: 'Session count', value: 'count' },
                  { label: 'Percentage', value: 'percentage' },
                ]}
                value={state?.reportType}
                onChange={(e) => {
                  setState((prev) => {
                    return {
                      ...prev,
                      reportType: e.target.value,
                    }
                  })
                }}
              />
            </Box>
            <Box sx={{ width: '200px' }}>
              <SingleSelect
                label={'Show Activity Subjects'}
                name={'reportType'}
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                value={state?.showActivitySubjects}
                onChange={(e) => {
                  setState((prev) => {
                    return {
                      ...prev,
                      showActivitySubjects: e.target.value,
                    }
                  })
                }}
              />
            </Box>
            {state.reportType == 'count' && (
              <Box sx={{ width: '200px' }}>
                <SingleSelect
                  label={'Show Applicable Classes'}
                  name={'showApplicableClasses'}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  value={state?.showApplicableClasses}
                  onChange={(e) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        showApplicableClasses: e.target.value,
                      }
                    })
                  }}
                />
              </Box>
            )}
          </StyledHeader>
        </Stack>

        <StyledHeader style={{ textAlign: 'right', marginBottom: '16px' }}>
          <Box
            sx={{
              width: '600px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <DatePicker
              label={'From Date'}
              name={'fromDate'}
              value={state.fromDate}
              maxDate={state.toDate}
              onChange={(value) => {
                setState((prev) => {
                  return {
                    ...prev,
                    fromDate: value,
                  }
                })
              }}
            />
            <DatePicker
              label={'To Date'}
              name={'toDate'}
              value={state.toDate}
              minDate={state.fromDate}
              onChange={(value) => {
                setState((prev) => {
                  return {
                    ...prev,
                    toDate: value,
                  }
                })
              }}
            />

            <Button sx={{ height: '50px' }} onClick={() => refetch()}>
              Submit
            </Button>
          </Box>
          <ActionButton
            style={{ height: '40px' }}
            onClick={(e) => {
              exportData({
                courseId: search?.courseId,
                batch: search?.batch,
                semNo: search?.semNo,
                branchCode: search?.branchCode,
                section: search?.section,
                fromDate: state?.fromDate
                  ? moment(state?.fromDate).format('YYYY-MM-DD')
                  : '',
                toDate: state?.toDate
                  ? moment(state?.toDate).format('YYYY-MM-DD')
                  : '',
                reportType: state?.reportType,
                showApplicableClasses: state?.showApplicableClasses,
              })
            }}
          >
            Download XLSX
          </ActionButton>
        </StyledHeader>
        {data?.data?.length ? (
          <>
            <StyledTableContainer>
              <table>
                <thead>
                  <tr>
                    <th rowSpan={2}>SL No.</th>
                    <th rowSpan={2}>HT No.</th>
                    <th>Name</th>
                    {data?.subjects
                      ?.filter(
                        (item) =>
                          item?.hasAttendance || state.showActivitySubjects,
                      )
                      ?.map((item, index) => (
                        <th key={index}>
                          <Box>
                            <Typography>{item?.name}</Typography>
                            <Typography>{`( ${item?.refCode} )`}</Typography>
                          </Box>
                        </th>
                      ))}
                    {state.reportType !== 'percentage' && (
                      <>
                        <th>Total</th>
                      </>
                    )}
                    <th>%</th>
                  </tr>
                  <tr>
                    <th>Total classes taken</th>
                    {data?.subjects
                      ?.filter(
                        (item) =>
                          item?.hasAttendance || state.showActivitySubjects,
                      )
                      ?.map((item, index) => (
                        <th key={index}>{`${item?.numberOfClasses ?? '--'} / ${
                          item?.totalClasses ?? '--'
                        }`}</th>
                      ))}
                    {state.reportType !== 'percentage' && (
                      <>
                        <th>
                          {data?.subjects
                            ?.filter(
                              (item) =>
                                item?.hasAttendance ||
                                state.showActivitySubjects,
                            )
                            ?.reduce(
                              (acc, item) => acc + item?.numberOfClasses,
                              0,
                            )}
                        </th>
                      </>
                    )}
                    <th>100%</th>
                  </tr>
                </thead>
                <tbody>
                  {data.data.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.rollNo}</td>
                      <td>{item?.fullName}</td>
                      {data?.subjects
                        ?.filter(
                          (item) =>
                            item?.hasAttendance || state.showActivitySubjects,
                        )
                        ?.map((subject, index) => {
                          const subjectRecord = item?.subjects?.find(
                            (sub) => sub?.subjectId == subject?.id,
                          )

                          return (
                            <td key={index}>
                              {state.reportType == 'percentage' ? (
                                <>
                                  {subjectRecord
                                    ? isNaN(
                                        (subjectRecord?.numberOfPresent /
                                          subjectRecord?.numberOfClasses) *
                                          100,
                                      )
                                      ? '--'
                                      : (
                                          (subjectRecord?.numberOfPresent /
                                            subjectRecord?.numberOfClasses) *
                                          100
                                        ).toFixed(2)
                                    : '--'}
                                </>
                              ) : (
                                <>
                                  {state.showApplicableClasses
                                    ? `${
                                        subjectRecord?.numberOfPresent ?? '--'
                                      } / ${
                                        subjectRecord?.numberOfClasses ?? '--'
                                      } ` ?? '0'
                                    : subjectRecord?.numberOfPresent ?? '--'}
                                </>
                              )}
                            </td>
                          )
                        })}

                      {state.reportType !== 'percentage' && (
                        <>
                          <td>
                            {state.showApplicableClasses
                              ? `${item?.totalPresent ?? '0'} / ${
                                  item?.subjects?.reduce(
                                    (total, item) =>
                                      total + item?.numberOfClasses,
                                    0,
                                  ) ?? '0'
                                } `
                              : item?.totalPresent ?? '0'}
                          </td>
                        </>
                      )}
                      <td>
                        {isNaN(item?.percentage) ? '0' : item?.percentage}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </StyledTableContainer>
            {data?.percWiseCount && (
              <>
                <Box sx={{ width: '60%' }}>
                  <StyledTableContainer>
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          {Object.keys(data?.percWiseCount)?.map(
                            (key, index) => (
                              <th key={index}>{key}</th>
                            ),
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>No of Students</td>
                          {Object.keys(data?.percWiseCount)?.map(
                            (item, index) => (
                              <td key={index}>{data?.percWiseCount[item]}</td>
                            ),
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </StyledTableContainer>
                </Box>
              </>
            )}
            <StyledSignatureContainer>
              <Typography variant="h2">Class Teacher</Typography>
              <Typography variant="h2">HOD</Typography>
            </StyledSignatureContainer>
          </>
        ) : (
          <>
            <StyledLogoContainer sx={{ width: '100%', height: '60vh' }}>
              <NoDataIllustration
                imageSrc={formImage}
                message={<Typography variant="h6">No Data Found</Typography>}
              />
            </StyledLogoContainer>
          </>
        )}
      </PageContent>
    </>
  )
}

export default AnuragSectionWiseAttendanceReport

const StyledSignatureContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  padding: 20px;
  visibility: hidden;
  @media print {
    visibility: visible;
  }
`
const StyledHeader = styled.div`
  padding: 5px;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: flex-end;
  @media print {
    display: none;
  }
`

export const StyledTableContainer = styled.div`
  background: white;
  border-radius: 16px;

  table thead tr {
    background-color: #efefef;
  }
  margin-top: 10px;
  table,
  td,
  th {
    border: 1px solid #bebebe;
    padding: 8px;
    text-align: center;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
`

export const StyledLogoContainer = MuiStyled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
