import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { switchMap, map } from 'rxjs/operators'
import { axios } from '@campxdev/shared'
import { Modal } from 'antd'
import _ from 'lodash'

interface AssessmentMarksState {
  data: any
  error: any
  loading: boolean
  assessment: any
  rule: any
  classroomSubject: any
  studentAssessments: Array<any>
  compareStudentAssessments: Array<any>
  compareError: Array<any>
  originalMarks: any[]
}

const initialState: AssessmentMarksState = {
  data: null,
  error: null,
  loading: false,
  assessment: null,
  rule: null,
  classroomSubject: null,
  studentAssessments: [],
  compareStudentAssessments: [],
  compareError: [],
  originalMarks: [],
}

const assessmentMarksSlice = createSlice({
  name: 'assessmentMarks',
  initialState: initialState,
  reducers: {
    fetchAssessmentMarks(state, action) {
      state.loading = true
    },
    fetchedAssessmentMarks(state, action) {
      if (!action.payload) {
        state.loading = false
        return
      }

      state.loading = false
      state.error = null
      state.data = action.payload.data
      state.assessment = action.payload.data.assessment
      state.rule = action.payload.data.rule
      state.classroomSubject = action.payload.data.classroomSubject
      state.studentAssessments = action.payload.data.studentAssessments
      state.originalMarks = action.payload.data.studentAssessments
    },
    updateMarks(state, action) {
      state.studentAssessments[action.payload.index].marks =
        action.payload.marks
    },
    updateCompareMarks(state, action) {
      state.compareStudentAssessments[action.payload.index].marks =
        action.payload.marks
    },
    updateCompareStudentAssessments(state, action) {
      if (action.payload.clear) {
        state.compareStudentAssessments = []
      } else {
        const marksExisting = state.originalMarks?.every(
          (item) => item.marks != null,
        )

        // if (marksExisting) {
        //   state.compareStudentAssessments = action.payload?.data
        // } else {
        state.compareStudentAssessments = state.studentAssessments.map(
          (item) => ({ ...item, marks: null, isAbsent: false }),
        )
        // }
      }
    },
    isAbsent(state, action) {
      state.studentAssessments[action.payload.index].isAbsent =
        action.payload.isAbsent
      if (action.payload.isAbsent == true) {
        state.studentAssessments[action.payload.index].marks = 0
      }
    },

    isAbsentCompare(state, action) {
      state.compareStudentAssessments[action.payload.index].isAbsent =
        action.payload.isAbsent
      if (action.payload.isAbsent == true) {
        state.compareStudentAssessments[action.payload.index].marks = 0
      }
    },

    updateError(state, action) {
      let errorArray = []
      const studentsDiff = current(state.compareStudentAssessments)
      const studentAssessments = current(state.studentAssessments)

      for (const diff of studentsDiff) {
        const prevValueOfDiff = studentAssessments.find(
          (item) => item.id == diff.id,
        )
        if (
          diff.marks != prevValueOfDiff.marks ||
          diff.isAbsent !== prevValueOfDiff.isAbsent
        ) {
          errorArray.push(diff)
        }
      }

      state.compareError = errorArray
    },
  },
})

export function fetchAssessmentMarksEpic(action$) {
  return action$.pipe(
    ofType(fetchAssessmentMarks.type),
    switchMap(async (action: any) => {
      try {
        const res = await axios.get(
          `/square/student-assessments?internalAssessmentId=${action.payload.internalAssessmentId}`,
        )
        return {
          data: res.data,
          assessment: res.data.assessment,
          rule: res.data.rule,
          classroomSubject: res.data.classroomSubject,
          studentAssessments: res.data.studentAssessments,
        }
      } catch (err) {
        if (err.response.status == 422) {
          Modal.confirm({
            title: 'Error',
            content: err.response.data.message,
            okText: 'Create course registrations',
            cancelText: 'Cancel',
            onOk: () => {
              axios
                .get(
                  `/square/student-assessments/sync-with-course-registrations?internalAssessmentId=${action.payload.internalAssessmentId}`,
                )
                .then((res) => {
                  alert('registered successfully')
                  window.location.reload()
                })
                .catch((err) => {
                  alert('error')
                })
            },
          })
        }
      }
    }),
    map(fetchedAssessmentMarks),
  )
}

const { fetchedAssessmentMarks } = assessmentMarksSlice.actions
export const {
  fetchAssessmentMarks,
  updateMarks,
  isAbsent,
  updateCompareMarks,
  isAbsentCompare,
  updateError,
  updateCompareStudentAssessments,
} = assessmentMarksSlice.actions

export default assessmentMarksSlice.reducer
