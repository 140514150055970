import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { createEpicMiddleware } from 'redux-observable'
import { rootReducer, rootEpic } from './root'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

const observableMiddleware = createEpicMiddleware()

let middleware = [observableMiddleware]

if (process.env.NODE_ENV == 'development') {
  middleware.push(logger)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
})

observableMiddleware.run(rootEpic)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
