export const batchOptions = Array.from({ length: 12 }, (_, i) => {
  return `${2012 + i} - ${2012 + i + 1}`
}).reverse()

export const examGroupBatches = Array.from({ length: 10 }, (_, i) => {
  return `${2012 + i} - ${2012 + i + 1}`
})
export const currentYear = new Date().getFullYear()

const startYear = 2012

export const yearOptions = Array.from(
  { length: currentYear - startYear + 1 },
  (_, i) => {
    return `${startYear + i}`
  },
)

export function getPropertyValueFromObject(
  obj: any,
  path: string | string[],
): any {
  if (typeof path === 'string') {
    path = path.split('.')
  }
  if (path.length === 0 || obj === undefined) {
    return obj
  }
  const [firstKey, ...remainingPath] = path
  return getPropertyValueFromObject(obj[firstKey], remainingPath)
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const religionOptions = [
  'Hinduism',
  'Islam',
  'Christianity',
  'Sikhism',
  'Buddhism',
  'Jainism',
]

export const casteOptions = [
  'OC',
  'BC_A',
  'BC_B',
  'BC_C',
  'BC_D',
  'BC_E',
  'SC',
  'ST',
]

export const nationalityOptions = ['Indian', 'Other']

export const examTimings = [
  '09:00 AM',
  '10:00 AM',
  '11:00 AM',
  '01:00 PM',
  '02:00 PM',
  '03:00 PM',
]

export const USER_TYPES = {
  'Staff User': 'staff_user',
  'Faculty User': 'non_staff_user',
  'Student User': 'student',
}

export function getRandomColor(name) {
  const firstAlphabet = name.charAt(0).toLowerCase()

  const asciiCode = firstAlphabet.charCodeAt(0)

  const colorNum =
    asciiCode.toString() + asciiCode.toString() + asciiCode.toString()

  var num = Math.round(0xffffff * parseInt(colorNum))
  var r = (num >> 16) & 145
  var g = (num >> 8) & 145
  var b = num & 145

  return {
    color: 'rgb(' + r + ', ' + g + ', ' + b + ', 0.8)',
    character: firstAlphabet?.toUpperCase(),
  }
}

export function formatTimeTo12Hour(time24Hour) {
  const parsedTime = new Date(`2000-01-01T${time24Hour}`)

  const formattedTime = parsedTime.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })

  const finalFormattedTime = formattedTime.replace(/am/i, 'AM')

  return finalFormattedTime
}
