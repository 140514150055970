import { axios } from '@campxdev/shared'
import { AxiosRequestConfig } from 'axios'
import { SERVICES } from 'services'

export const fetchSemesters = async (params: { courseId: number | string }) => {
  return axios
    .get(`/square/semesters?courseId=${params?.courseId}`)
    .then((res) => res.data)
}

export const fetchClassRoomAndSubjects = async (id) => {
  const classRoom = await axios.get(`/square/classrooms/${id}`)
  const subjects = await axios.get(`/square/classrooms/${id}/subjects`)
  return {
    classRoom: classRoom.data,
    subjects: subjects.data,
  }
}

export const postTimeTable = async (postBody: any) => {
  const config: AxiosRequestConfig = {
    url: `/square/timetable/${postBody?.timeTableId}`,
    method: 'PUT',
    data: postBody,
  }

  return axios(config)
}

interface Params {
  limit?: number
  offset?: number
  batch?: any
  branchCode?: any
  courseId?: any
  programId?: any
}

export const fetchClassrooms = (params: Params) => {
  return axios
    .get('/square/classrooms', {
      params,
    })
    .then((res) => res.data)
}

export const fetchCoursesAndBranches = async () => {
  const branches = await axios.get('/square/branches')
  const courses = await SERVICES.courses.fetchCourses()
  return { branches: branches.data, courses: courses }
}

export const fetchRooms = () => {
  return axios.get(`/exams/rooms`).then((res) => res.data)
}

export const fetchTimetableTemplates = () => {
  return axios.get(`/square/timetable-slot-templates`).then((res) => res.data)
}
export const createClassroom = (data) => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: '/square/classrooms',
    data: {
      ...data,
    },
  }
  return axios(config).then((res) => res.data)
}

export const postMultipleTimeSlots = (postDataMap) => {
  const promises = []
  for (const item in postDataMap) {
    promises.push(axios.post(`/square/timetable`, postDataMap[item]))
  }

  return Promise.all(promises)
}

export const fetchFormOptions = async () => {
  const rooms = await fetchRooms()
  const timetableTemplates = await fetchTimetableTemplates()
  const sections = await axios.get(`/square/sections`).then((res) => res.data)

  return {
    rooms,
    timetableTemplates,
    sections,
  }
}
