export const GreenTickIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g id="Group_95" data-name="Group 95" transform="translate(-635 -400)">
        <circle
          id="Ellipse_7"
          data-name="Ellipse 7"
          cx="12.5"
          cy="12.5"
          r="12.5"
          transform="translate(635 400)"
          fill="#8bc34a"
        />
        <path
          id="check_2_"
          data-name="check (2)"
          d="M4.12,8.6a.549.549,0,0,1-.777,0L.241,5.5a.824.824,0,0,1,0-1.165L.63,3.944a.824.824,0,0,1,1.165,0L3.732,5.881,8.964.648a.824.824,0,0,1,1.165,0l.388.388a.824.824,0,0,1,0,1.165Zm0,0"
          transform="translate(642 407.665)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export const LockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.952"
      height="13.689"
      viewBox="0 0 10.952 13.689"
    >
      <path
        id="_5e0cf8e3305fdc5be08942390fc504e3"
        data-name="5e0cf8e3305fdc5be08942390fc504e3"
        d="M12.9,6.791V5.422a3.422,3.422,0,1,0-6.845,0V6.791A2.053,2.053,0,0,0,4,8.845v4.791a2.053,2.053,0,0,0,2.053,2.053H12.9a2.053,2.053,0,0,0,2.053-2.053V8.845A2.053,2.053,0,0,0,12.9,6.791ZM7.422,5.422a2.053,2.053,0,1,1,4.107,0V6.791H7.422Zm6.16,8.214a.684.684,0,0,1-.684.684H6.053a.684.684,0,0,1-.684-.684V8.845a.684.684,0,0,1,.684-.684H12.9a.684.684,0,0,1,.684.684Z"
        transform="translate(-4 -2)"
        opacity="0.5"
      />
    </svg>
  )
}

export const InformationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="1.414"
      clip-rule="evenodd"
      viewBox="0 0 32 32"
      id="information"
    >
      <rect width="32" height="32" fill="none"></rect>
      <path
        fill-rule="nonzero"
        d="M16.053,2.988c4.736,0.022 9.352,2.803 11.551,7.092c2.144,4.184 1.813,9.513 -0.955,13.429c-2.856,4.04 -8.132,6.235 -13.1,5.282c-5.237,-1.004 -9.643,-5.473 -10.424,-10.926c-0.683,-4.764 1.472,-9.839 5.576,-12.63c2.108,-1.434 4.642,-2.219 7.184,-2.247c0.056,0 0.112,-0.001 0.168,0Zm-0.077,2.002c-2.761,0.014 -5.505,1.105 -7.515,2.998c-1.662,1.566 -2.824,3.657 -3.262,5.899c-0.826,4.235 1.055,8.883 4.766,11.327c3.525,2.32 8.394,2.388 12.009,0.054c2.636,-1.702 4.494,-4.565 4.941,-7.687c0.321,-2.238 -0.062,-4.574 -1.093,-6.587c-1.823,-3.556 -5.694,-5.959 -9.704,-6.003c-0.047,-0.001 -0.095,-0.001 -0.142,-0.001Zm0.091,8.013c0.183,0.016 0.232,0.036 0.327,0.075c0.329,0.136 0.572,0.457 0.612,0.813c0.006,0.05 0.005,0.062 0.007,0.112l0,8.012c-0.022,0.766 -1.034,1.314 -1.669,0.746c-0.182,-0.162 -0.301,-0.393 -0.328,-0.634c-0.006,-0.05 -0.005,-0.063 -0.006,-0.112l0,-8.012c0.005,-0.183 0.022,-0.233 0.056,-0.331c0.123,-0.353 0.457,-0.622 0.833,-0.664c0.108,-0.012 0.134,-0.006 0.168,-0.005Zm-0.056,-2c0.556,0 1.008,-0.451 1.008,-1.006c0,-0.554 -0.452,-1.005 -1.008,-1.005c-0.556,0 -1.008,0.451 -1.008,1.005c0,0.555 0.452,1.006 1.008,1.006Z"
      ></path>
    </svg>
  )
}

export const TableIcon = ({ height = '17.106', width = '17.106' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="blue"
    >
      <path
        id="ecb60dda384d1015e2e8d6f321dc4f51"
        d="M16.933,2H3.067A1.067,1.067,0,0,0,2,3.067V16.933A1.067,1.067,0,0,0,3.067,18H16.933A1.067,1.067,0,0,0,18,16.933V3.067A1.067,1.067,0,0,0,16.933,2Zm0,14.933H3.067V3.067H16.933Zm-11.2-6.4h8.533a.533.533,0,1,0,0-1.067H5.733a.533.533,0,1,0,0,1.067Zm0-3.2h8.533a.533.533,0,1,0,0-1.067H5.733a.533.533,0,0,0,0,1.067Zm0,6.4h8.533a.533.533,0,0,0,0-1.067H5.733a.533.533,0,0,0,0,1.067Z"
        transform="translate(-2 -2)"
        fill="blue"
      />
    </svg>
  )
}

export const GridIcon = ({ height = '17.106', width = '17.106' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17.106 17.106"
      fill="blue"
    >
      <path
        id="df27880bad2416bf3e662de455acbdc4"
        d="M17.586,2H2.82A.82.82,0,0,0,2,2.82V17.586a.82.82,0,0,0,.82.82H17.586a.82.82,0,0,0,.82-.82V2.82A.82.82,0,0,0,17.586,2Zm-8.2,14.766H3.641V11.023H9.383Zm0-7.383H3.641V3.641H9.383Zm7.383,7.383H11.023V11.023h5.742Zm0-7.383H11.023V3.641h5.742Z"
        transform="translate(-1.65 -1.65)"
        stroke="#fff"
        stroke-width="0.7"
        fill="blue"
      />
    </svg>
  )
}
