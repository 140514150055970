import { PageContent, Spinner, axios } from '@campxdev/shared'
//Should be on Top
import FullCalendar from '@fullcalendar/react'
//Should be on Top

import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

import timeGridPlugin from '@fullcalendar/timegrid'
import { Box, Typography, useTheme } from '@mui/material'
import { parse, set } from 'date-fns'
import _ from 'lodash'
import { Store } from 'pullstate'
import { useQuery } from 'react-query'
import {
  findMinMaxTimes,
  findMissingWeekdaysIndices,
  subjectColors,
} from '../ClassRoomTimeTable/constants'

import { View } from 'components/FullCalendar/Actions'
import { FullCalendarWrapper } from 'components/FullCalendar/FullCalendarWrapper'
import { useRef, useState } from 'react'
import { StyledCustomEvent } from '../ClassRoomTimeTable/ClassRoomTimeTable'

const stepSize = 60

export const timeTableStore = new Store({
  subjects: [],
  selectedSubject: null,
  events: [],
  minimumFromTime: null,
  maximumToTime: null,
  weekDays: [],
})

export const RenderCustomEventDisplay = (ctx) => {
  const subjectName = ctx.event?._def?.extendedProps?.subjectName
  const room = ctx.event?._def?.extendedProps
  return (
    <StyledCustomEvent sx={{ position: 'relative' }}>
      <Typography variant="body2" style={{ fontSize: '11px' }}>
        {ctx?.timeText}
      </Typography>
      <Typography variant="body1" style={{ fontSize: '12px' }}>
        {subjectName}
      </Typography>
      {room?.roomId && (
        <Typography variant="body1" style={{ fontSize: '12px' }}>
          {`(Block ${room?.block}, Room No - ${room?.roomNo} )`}
        </Typography>
      )}
      <Box className="overlay"></Box>
    </StyledCustomEvent>
  )
}

export const FacultyTimetable = () => {
  const theme = useTheme()
  const state = timeTableStore.useState((s) => s)

  const eventElRef = useRef(null)
  const calendarRef = useRef<FullCalendar | null>(null)
  const [view, setView] = useState<View>('timeGridWeek')
  const { data, isLoading, isRefetching } = useQuery(
    'faculty-timetable',
    () =>
      axios.get(`/square/timetable/faculty-timetable`).then((res) => res.data),
    {
      onSuccess: (data) => {
        const { weekdays, events } = data?.reduce(
          (result, obj) => {
            if (!result.subjectColorMap[obj.subjectId]) {
              result.subjectColorMap[obj.subjectId] =
                subjectColors[result.index]?.light ?? 'white'
              result.index++
            }
            result.weekdays.push(obj.day)
            const date = parse(obj?.day?.toLowerCase(), 'cccc', new Date())
            result.events.push({
              start: set(date, {
                hours: parseInt(obj?.fromTime?.split(':')[0]),
                minutes: parseInt(obj?.fromTime?.split(':')[1]),
              }),
              end: set(date, {
                hours: parseInt(obj?.toTime?.split(':')[0]),
                minutes: parseInt(obj?.toTime?.split(':')[1]),
              }),
              title: obj?.subjectName,
              backgroundColor: result.subjectColorMap[obj.subjectId],
              textColor: theme.palette.secondary.main,
              extendedProps: { ...obj },
            })
            return result
          },
          {
            weekdays: [],
            events: [],
            subjectColorMap: {},
            index: 0,
          },
        )

        const { minFromTime, maxToTime } = findMinMaxTimes(
          'fromTime',
          'toTime',
          data,
        )

        timeTableStore.update((s) => {
          s.minimumFromTime = minFromTime
          s.maximumToTime = maxToTime
          s.events = events
          s.weekDays = _.uniq(weekdays)
        })
      },
    },
  )

  if (isRefetching || isLoading) return <Spinner />

  return (
    <>
      <PageContent>
        <FullCalendarWrapper>
          <FullCalendar
            allDayMaintainDuration
            allDaySlot={false}
            slotMinTime={state.minimumFromTime}
            slotMaxTime={state.maximumToTime}
            slotDuration={`00:${stepSize}`}
            initialDate={new Date()}
            slotLabelFormat={{ hour: '2-digit', minute: '2-digit' }}
            initialView={view}
            eventDisplay="block"
            dayHeaderFormat={{
              weekday: 'long',
            }}
            eventContent={RenderCustomEventDisplay}
            hiddenDays={findMissingWeekdaysIndices(state.weekDays)}
            eventDurationEditable={false}
            eventStartEditable={false}
            dayMaxEventRows={4}
            eventResizableFromStart
            events={state.events}
            headerToolbar={false}
            height={'calc(100vh - 200px)'}
            ref={calendarRef}
            rerenderDelay={10}
            weekends
            eventMouseEnter={(ctx) => {
              eventElRef.current = ctx.el
            }}
            eventMouseLeave={(ctx) => {
              eventElRef.current = null
            }}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          />
        </FullCalendarWrapper>
      </PageContent>
    </>
  )
}
