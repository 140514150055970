import {
  ActionButton,
  axios,
  SingleCheckbox,
  useConfirm,
  useErrorModal,
} from '@campxdev/shared'
import { Alert, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

function GenerateSession({ close, classRoom }) {
  const [regenerate, setRegenerate] = useState(false)
  const [loading, setLoading] = useState(false)
  const { isConfirmed } = useConfirm()
  const queryClient = useQueryClient()
  const errorModal = useErrorModal()

  const handleGenerateSessions = async () => {
    const confirm = await isConfirmed(
      'Are you sure you want to generate sessions?',
    )
    if (!confirm) return
    setLoading(true)
    const postBody = {
      courseId: classRoom?.courseId,
      branchCode: classRoom?.branchCode,
      batch: classRoom?.batchName,
      section: classRoom?.section,
      semNo: classRoom?.currentSemester,
      editPrevSessions: regenerate,
    }

    try {
      await axios.post(`/square/classroom-timetables`, postBody)
      setLoading(false)
      toast.success('Sessions generated/updated successfully')
      queryClient.invalidateQueries('classroom-timetable')
      close()
    } catch (error) {
      setLoading(false)
      errorModal({ error: error })
    }
  }

  return (
    <>
      <Stack gap={2} sx={{ padding: '10px' }}>
        <SingleCheckbox
          label={'Re-generate previously not completed sessions'}
          name={'editPrevSessions'}
          checked={regenerate}
          onChange={(e) => setRegenerate(e.target.checked)}
        />
        <Alert color={'warning'}>
          <Typography>
            Note: Previously completed sessions will not be re-generated
          </Typography>
        </Alert>
        <Stack direction={'row'} gap={2}>
          <ActionButton onClick={close} variant="outlined">
            Cancel
          </ActionButton>
          <ActionButton loading={loading} onClick={handleGenerateSessions}>
            Generate
          </ActionButton>
        </Stack>
      </Stack>
    </>
  )
}
export default GenerateSession
