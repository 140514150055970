import { axios } from '@campxdev/shared'

type GetMentorDto = {
  isArchived?: boolean
  search?: string
  offset?: number
  limit?: number
}

const MENTORS_END_POINT = '/square/mentors'
const MENTEES_END_POINT = '/square/mentees'
const SESSION_END_POINT = '/square/mentor-sessions'
const MENTORSHIP_COMMENTS_END_POINT = '/square/mentorship-comments'
export const mentors = {
  addMentor(body: any) {
    return axios.post(MENTORS_END_POINT, body)
  },

  async findAll(query: GetMentorDto): Promise<any> {
    const res = await axios.get(MENTORS_END_POINT, {
      params: query,
    })
    return res.data
  },
  async getMentorSessionsOverview(params: {
    mentorUserId: string
    batch?: string
  }): Promise<any> {
    const res = await axios.get(`${MENTORS_END_POINT}/sessions-overview`, {
      params,
    })
    return res.data
  },

  async getMentorSessionsByBatch(params: {
    mentorUserId: string
    batch?: string
    sessionOrder?: number
  }): Promise<any> {
    const res = await axios.get(`${MENTORS_END_POINT}/sessions-by-batch`, {
      params,
    })
    return res.data
  },
  async getMentorSessions(params: {
    mentorUserId: string
    batch?: string
    sessionOrder?: number
  }): Promise<any> {
    const res = await axios.get(`${MENTORS_END_POINT}/sessions`, {
      params,
    })
    return res.data
  },
  async getMentorSessionsBySessionOrder(params: {
    mentorUserId: string
    batch?: string
  }): Promise<any> {
    const res = await axios.get(
      `${MENTORS_END_POINT}/sessions-by-session-order`,
      {
        params,
      },
    )
    return res.data
  },

  async getMentorshipComments(params: {
    mentorUserId: string
    batch?: string
  }): Promise<any> {
    const res = await axios.get(`${MENTORSHIP_COMMENTS_END_POINT}`, {
      params,
    })
    return res.data
  },
  addMentorshipComment(body: any) {
    return axios.post(MENTORSHIP_COMMENTS_END_POINT, body)
  },

  async findOne(id: string): Promise<any> {
    const res = await axios.get(`${MENTORS_END_POINT}/${id}`)
    return res.data
  },

  async myMentees(id: string, params: any): Promise<any> {
    const res = await axios.get(`${MENTEES_END_POINT}/${id}`, {
      params,
    })
    return res.data
  },

  assignMentees({ body, id }: any) {
    return axios.post(`${MENTEES_END_POINT}/${id}`, body)
  },

  removeMentee({ id, studentId }: any) {
    return axios.delete(`${MENTEES_END_POINT}/${id}/${studentId}`)
  },

  createSession(body: any) {
    return axios.post(SESSION_END_POINT, body)
  },

  async fetchAllSessions(query: any): Promise<any> {
    const res = await axios.get(SESSION_END_POINT, {
      params: query,
    })
    return res.data
  },

  async getMenteeSessions(
    mentorUserId: string,
    studentId: string,
  ): Promise<any> {
    const res = await axios.get(
      `${SESSION_END_POINT}/${mentorUserId}/student/${studentId}`,
    )
    return res.data
  },

  async findSessionsWithoutComments(
    mentorUserId: string,
    studentId: string,
  ): Promise<any> {
    const res = await axios.get(
      `${SESSION_END_POINT}/${mentorUserId}/student/${studentId}/without-comments`,
    )
    return res.data
  },
}
