import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { ofType } from 'redux-observable'
import { map, switchMap } from 'rxjs/operators'
import { axios } from '@campxdev/shared'

const initialState = {
  loading: true,
  data: [],
  error: null,
  timetable: [],
}

const slice = createSlice({
  name: 'examTimetable',
  initialState: initialState,
  reducers: {
    fetchExamTimetable(state, action: any) {
      state.loading = true
    },
    fetchedExamTimetable(state, action: any) {
      state.loading = false
      state.data = action.payload.data
      state.timetable = action.payload.data.map((item, index) => ({
        ...item,
        paper: item.paper,
        isEditing: false,
      }))
    },
    updateValue(state, action: any) {
      let index = action.payload.index
      let key = action.payload.key
      let value = action.payload.value
      state.timetable[index][key] = value
    },
    updateTimeSlots(state, action: any) {
      let index = action.payload.index
      state.timetable[index]['fromTimeString'] = action.payload.fromTime
      state.timetable[index]['toTimeString'] = action.payload.toTime
    },
    addPaper(state) {
      state.timetable = state.timetable.filter((f) => f)
      let id = state.timetable.length + 1
      if (state.timetable.length == 0) {
        state.timetable.push({
          paper: id,
          date: moment(),
          fromTimeString: moment().format('hh:mm A'),
          toTimeString: moment().format('hh:mm A'),
          isNew: true,
          isEditing: true,
        })
      } else {
        state.timetable = [
          ...state.timetable,
          {
            paper: id,
            date: moment(state.timetable.reverse()[0].date).add(2, 'days'),
            fromTimeString: state.timetable[0].fromTimeString,
            toTimeString: state.timetable[0].toTimeString,
            isNew: true,
            isEditing: true,
          },
        ]
      }
    },
    removePaper(state, action) {
      delete state.timetable[action.payload.index]
    },
    editPaper(state, action) {
      state.timetable[action.payload.index].isEditing = true
    },
    updatePaper(state, action) {
      state.timetable[action.payload.index].isEditing = false
    },
    savePaper(state, action) {
      state.timetable[action.payload.index] = {
        ...action.payload.data,
        name: 'Paper ' + (action.payload.index + 1),
      }
    },
    onCancel(state, action) {
      state.timetable[action.payload.index].isEditing = false
    },
  },
})

export function fetchExamTimetableEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchExamTimetable.type),
    switchMap(async (action: any) => {
      const res = await axios.get(
        `/square/exam-timetable?examGroupId=${action.payload.examGroupId}`,
      )
      return {
        data: res.data,
      }
    }),
    map(fetchedExamTimetable),
  )
}

const { fetchedExamTimetable } = slice.actions

export const {
  fetchExamTimetable,
  updatePaper,
  updateValue,
  onCancel,
  savePaper,
  addPaper,
  removePaper,
  editPaper,
  updateTimeSlots,
} = slice.actions
export default slice.reducer
