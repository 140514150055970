import { withSuspense } from '@campxdev/shared'
import AUEvent from 'pages/AUEvent'
import { FeedbackFormPreview } from 'pages/Feedback/FacultyFeedbacks/FacultyFeedback'
import PaymentSuccess from 'pages/FormBuilder/FormAccess/FormPayments/PaymentSuccess'
import InitiatePayment from 'pages/InitiatePayment'
import { lazy } from 'react'

const AccessForm = lazy(() => import('pages/FormBuilder/FormAccess'))
const PrintResults = lazy(() => import('pages/StudentResults/PrintResults'))
export const publicRoutes = [
  {
    element: <AccessForm hideLastUpdatedTime={true} />,
    path: 'form/:formId',
  },
  {
    path: 'form/:formId/payment-status/:orderId',
    element: <InitiatePayment />,
  },
  {
    path: 'form/:formId/payment-status/:orderId/success',
    element: <PaymentSuccess />,
  },
  {
    element: <PaymentSuccess />,
    path: 'form/:formId/:orderId',
  },
  {
    element: <FeedbackFormPreview />,
    path: 'feedback-form/:feedbackId/preview',
  },
  {
    element: <AUEvent />,
    path: 'au-event',
  },
  {
    element: <FeedbackFormPreview />,
    path: 'feedback-form/:feedbackId/:studentFeedbackId',
  },
  {
    element: withSuspense(<PrintResults />),
    path: 'results/print',
  },
]
