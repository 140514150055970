import { ReportHeader, Spinner, Table2D, UserStore } from '@campxdev/shared'
import { TableColumn } from '@campxdev/shared/src/components/Tables/2DTable/Table'
import { Stack } from '@mui/material'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { SERVICES } from 'services'

const MentoringReport = () => {
  let { mentorUserId, studentId } = useParams()
  const [state, setState] = useState(null)

  if (!mentorUserId) {
    mentorUserId = UserStore.useState().globalUserId
  }

  const { data, isLoading, isRefetching } = useQuery(
    'student-sessions',
    () => SERVICES.mentors.getMenteeSessions(mentorUserId, studentId),
    {
      onSuccess: (data) => {
        const studentData = data.studentData
        const sessionData = data.sessionData

        const tableData = sessionData.map((session) => {
          return {
            ...session,
            student: studentData,
          }
        })

        setState(tableData)
      },
    },
  )

  const columns: TableColumn[] = [
    {
      dataIndex: 'student.rollNo',
      key: 'id',
      title: 'Rollno',
      showTitle: true,
    },
    {
      dataIndex: 'student.admissionSubValue.fullName',
      key: 'Student Name',
      title: 'Student Name',
      showTitle: true,
    },
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Session Name',
      showTitle: true,
    },
    {
      dataIndex: 'academics',
      key: 'academics',
      title: 'Academics',
      render: (data) => (
        <div
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      ),
      showTitle: true,
    },
    {
      dataIndex: 'extraCurricularActivities',
      key: 'extraCurricularActivities',
      title: ' Extra-Curricular Activities',
      showTitle: true,
      render: (data) => (
        <div
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      ),
    },
    {
      dataIndex: 'coCurricularActivities',
      key: 'coCurricularActivities',
      title: 'Co curricular Activities',
      showTitle: true,
      render: (data) => (
        <div
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      ),
    },
    {
      dataIndex: 'personalityDevelopment',
      key: 'personalityDevelopment',
      title: ' Personality Development',
      showTitle: true,
      render: (data) => (
        <div
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      ),
    },
    {
      dataIndex: 'placements',
      key: 'placements',
      title: '  Placements',
      showTitle: true,
      render: (data) => (
        <div
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      ),
    },
    {
      dataIndex: 'others',
      key: 'others',
      title: 'Others',
      showTitle: true,
      render: (data) => (
        <div
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      ),
    },
  ]

  if (isLoading || isRefetching) return <Spinner />
  return (
    <Stack sx={{ margin: '60px 20px' }} gap={2}>
      <ReportHeader />
      <Table2D
        columns={columns}
        dataSource={state ?? []}
        loading={isLoading}
        sx={{
          Tablecell: {
            borderRightColor: '#1212121A',
            borderRightStyle: 'solid',
          },
        }}
      />
    </Stack>
  )
}

export default MentoringReport
