import { createSlice } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { map, switchMap } from 'rxjs/operators'
import serverErrorDialog from '../../components/serverErrorDialog'
import { axios } from '@campxdev/shared'

export interface BundlesState {
  data: any
  bundles: any[]
  error: any
  loading: boolean
  selectedBundle: any
  selectedBundleIndex: any
  showAddBookletsButton: boolean
  editBundleIndex: any
  exam: any
  selectedBundleSheets: []
  subject: any
  evaluators: []
  params: any
  editMarks: boolean
}

const initialState: BundlesState = {
  data: {},
  bundles: [],
  error: null,
  loading: true,
  selectedBundle: null,
  selectedBundleIndex: null,
  showAddBookletsButton: false,
  editBundleIndex: null,
  exam: null,
  selectedBundleSheets: [],
  subject: null,
  evaluators: [],
  params: null,
  editMarks: false,
}

const bundlesSlice: any = createSlice({
  name: 'manualBundles',
  initialState: initialState,
  reducers: {
    toggleMarksEdit(state, action) {
      state.editMarks = !state.editMarks
    },
    cancelEditing(state, action) {
      if (state.selectedBundleIndex) {
        state.editMarks = false
        for (let s of state.bundles[state.selectedBundleIndex].sheets) {
          s.evaluationMarks = s.marks
        }
      }
    },
    fetchBundles(state, action) {
      state.loading = true
      state.params = action.payload.params
    },
    fetchedBundles(state, action) {
      state.loading = false
      state.data = action.payload.data
      state.subject = action.payload.data['subject']
      state.bundles = action.payload.data['Evaluation']
      state.bundles = state.bundles.map((bundle) => {
        return {
          ...bundle,
          sheets: bundle.sheets.map((v, index) => {
            return {
              ...v,
              evaluationMarks: v.marks,
              index: index,
            }
          }),
        }
      })
    },
    fetchedEvaluators(state, action) {
      state.evaluators = action.payload.data
    },
    addBundle(state, action) {},
    addedBundle(state, action) {
      if (action.payload.data != null) {
        state.bundles = [
          ...state.bundles,
          { ...action.payload.data, sheets: [] },
        ]
      }
    },
    editBundle(state, action) {
      state.editBundleIndex = action.payload.index
    },
    editedBundle(state, action) {
      if (action.payload.data) {
        state.bundles[state.editBundleIndex] = {
          ...state.bundles[state.editBundleIndex],
          ...action.payload.data,
        }
      }
    },
    cancelEvaluation(state, action) {},
    canceledEvaluation(state, action) {
      if (action.payload.data) {
        state.bundles[state.selectedBundleIndex]['status'] = 'saved'
      }
    },
    onSearch(state, action) {
      let value = action.payload.value
      if (!value) {
        state.bundles = state.data['Evaluation']
        return
      }
      state.bundles = state.data['Evaluation'].filter((bundle) => {
        if (
          bundle.bundleSerialNo &&
          bundle.bundleSerialNo?.toUpperCase().includes(value?.toUpperCase())
        ) {
          return true
        }
      })
    },
    addSheet(state, action) {},
    addedSheet(state, action) {
      if (action.payload.data) {
        state.bundles[state.selectedBundleIndex].sheets = [
          ...state.bundles[state.selectedBundleIndex].sheets,
          action.payload.data,
        ]
      }
    },
    deleteSheet(state, action) {
      delete state.bundles[state.selectedBundleIndex].sheets[
        action.payload.index
      ]
      state.bundles[state.selectedBundleIndex].sheets = state.bundles[
        state.selectedBundleIndex
      ].sheets.filter((f) => f)
    },
    selectBundle(state, action) {
      state.selectedBundle = action.payload.selectedBundle
      state.selectedBundleIndex = action.payload.selectedBundleIndex
      state.selectedBundleSheets =
        state.bundles[action.payload.selectedBundleIndex].sheets
    },
    updateStatus(state, action) {
      state.bundles[state.selectedBundleIndex]['status'] = action.payload.status
      state.selectedBundle.status = action.payload.status
    },
    saveBundle(state, action) {},
    savedBundle(state, action) {
      state.bundles[state.selectedBundleIndex]['status'] = action.payload.status
      state.selectedBundle.status = action.payload.status
    },
    updateSheetMarks(state, action) {
      state.bundles[state.selectedBundleIndex].sheets[action.payload.index][
        action.payload.key
      ] = action.payload.value
    },
    updateEvaluator(state, action) {},
    clearState(state, actions) {
      state.data = {}
      state.bundles = []
      state.error = null
      state.loading = true
      state.selectedBundle = null
      state.selectedBundleIndex = null
      state.showAddBookletsButton = false
      state.editBundleIndex = null
      state.exam = null
      state.selectedBundleSheets = []
      state.subject = null
      state.evaluators = []
      state.params = null
      state.editMarks = false
    },
  },
})

export function fetchBundlesEpic(action$) {
  return action$.pipe(
    ofType(fetchBundles.type),
    switchMap(async (action: any) => {
      const res = await axios.get(`/square/manual-bundles`, {
        params: action.payload.params,
      })
      return {
        data: res.data,
      }
    }),
    map(fetchedBundles),
  )
}

export function addBundleEpic(action$) {
  return action$.pipe(
    ofType(addBundle.type),
    switchMap(async (action: any) => {
      try {
        const res = await axios.post(`/square/manual-bundles`, {
          bundleId: action.payload.bundleId,
          bundleType: 'Evaluation',
          examGroupId: action.payload.examGroupId,
          subjectCode: action.payload.subjectCode,
        })
        return {
          data: res.data,
        }
      } catch (e) {
        serverErrorDialog({ error: e })
        return {
          data: null,
        }
      }
    }),
    map(addedBundle),
  )
}

export function addSheetEpic(action$, state$) {
  return action$.pipe(
    ofType(addSheet.type),
    switchMap(async (action: any) => {
      try {
        let bundleId = state$.value.manualBundles.selectedBundle.id
        const res = await axios.post(
          `/square/manual-bundles/${bundleId}/add-sheet`,
          {
            barcode: action.payload.barcode,
            examGroupId: action.payload.examGroupId,
            subjectCode: action.payload.subjectCode,
          },
        )

        action.payload.form.resetFields()

        return {
          data: res.data,
        }
      } catch (e) {
        serverErrorDialog({ error: e })
        return {
          data: null,
        }
      }
    }),
    map(addedSheet),
  )
}

export function saveBundleEpic(action$, state$) {
  return action$.pipe(
    ofType(saveBundle.type),
    switchMap(async (action: any) => {
      try {
        let bundleId = state$.value.manualBundles.selectedBundle.id
        const res = await axios.get(`/square/manual-bundles/${bundleId}/save`)
        return {
          status: 'saved',
        }
      } catch (e) {
        serverErrorDialog({ error: e })
        return {
          status: 'created',
        }
      }
    }),
    map(savedBundle),
  )
}

export function fetchEvaluatorsEpic(action$, state$) {
  return action$.pipe(
    ofType(
      selectBundle.type,
      updateStatus.type,
      canceledEvaluation.type,
      updateEvaluator.type,
    ),
    switchMap(async (action: any) => {
      let bundleId = state$.value.manualBundles.selectedBundle.id
      const res = await axios.get(
        `/square/manual-bundles/${bundleId}/evaluators`,
      )
      return {
        data: res.data,
      }
    }),
    map(fetchedEvaluators),
  )
}

export function editBundleEpic(action$, state$) {
  return action$.pipe(
    ofType(editBundle.type),
    switchMap(async (action: any) => {
      try {
        const res = await axios.put(
          `/square/manual-bundles/${action.payload.bundleId}`,
          {
            bundleSerialNo: action.payload.bundleSerialNo,
          },
        )
        return {
          data: res.data,
        }
      } catch (e) {
        serverErrorDialog({ error: e })
        return {
          data: null,
        }
      }
    }),
    map(editedBundle),
  )
}

export function cancelEvaluationEpic(action$, state$) {
  return action$.pipe(
    ofType(cancelEvaluation.type),
    switchMap(async (action: any) => {
      try {
        let bundleId = state$.value.manualBundles.selectedBundle.id
        const res = await axios.get(`/square/manual-bundles/${bundleId}/cancel`)
        return {
          data: res.data,
        }
      } catch (e) {
        serverErrorDialog({ error: e })
        return {
          data: null,
        }
      }
    }),
    map(canceledEvaluation),
  )
}

export const epics = {
  fetchBundlesEpic,
  addBundleEpic,
  addSheetEpic,
  saveBundleEpic,
  fetchEvaluatorsEpic,
  editBundleEpic,
  cancelEvaluationEpic,
}

const {
  fetchedBundles,
  addedBundle,
  savedBundle,
  addedSheet,
  fetchedEvaluators,
  editedBundle,
  canceledEvaluation,
} = bundlesSlice.actions
export const {
  fetchBundles,
  addBundle,
  selectBundle,
  addSheet,
  saveBundle,
  updateStatus,
  deleteSheet,
  clearState,
  editBundle,
  onSearch,
  cancelEvaluation,
  updateSheetMarks,
  updateEvaluator,
  toggleMarksEdit,
  cancelEditing,
} = bundlesSlice.actions

export default bundlesSlice.reducer
