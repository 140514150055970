import { Permission } from '@campxdev/shared'
import {
  AcademicsIcon,
  AdmissionsIcon,
  CalenderIcon,
  ManageUsersIcon,
  OrgIcon,
} from './Icons'

const basePath = ''
export const menu = [
  {
    title: 'Org. Settings',
    path: basePath + '/org',
    icon: OrgIcon,
  },

  {
    path: '/admissions',
    title: 'Admissions',
    icon: AdmissionsIcon,
    children: [
      {
        path: '/lead-statuses',
        title: 'Lead Statuses',
        permissionKey: Permission.CAN_LEAD_STATUS_VIEW,
      },
    ],
  },
  {
    path: '/academics',
    title: 'Academics',
    icon: AcademicsIcon,

    children: [
      {
        path: '/schools',
        title: 'Schools',
        permissionKey: Permission.SCHOOLS_VIEW,
      },
      {
        path: '/academic-calendar',
        title: 'Academic Calendar',
        icon: CalenderIcon,
        permissionKey: Permission.USERS_VIEW,
      },
      {
        path: '/subject-types',
        title: 'Subject Types',
        permissionKey: Permission.SUBJECT_TYPE_VIEW,
      },
      {
        path: '/assesment-types',
        title: 'Assessment Types',
        permissionKey: Permission.ASSESSMENT_TYPE_VIEW,
      },
      {
        path: '/courses',
        title: 'Degrees',
        permissionKey: Permission.CAN_DEGREES_VIEW,
      },
      {
        path: '/semesters',
        title: 'Semesters',
        permissionKey: Permission.CAN_SEMESTERS_VIEW,
      },
      {
        path: '/programs',
        title: 'Programs',
        permissionKey: Permission.CAN_PROGRAMS_VIEW,
      },
      {
        path: '/curriculums',
        title: 'Curriculums',
        permissionKey: Permission.CAN_CURRICULUMS_VIEW,
      },

      {
        path: '/assessment-rules',
        title: 'Assessment Rules',
        permissionKey: Permission.CAN_ASSESSMENT_RULES_VIEW,
      },
      {
        path: '/all-courses',
        title: 'Master Courses',
        permissionKey: Permission.SUBJECTS_VIEW,
      },
    ],
  },

  {
    path: '/users',
    title: 'Manage Users',
    icon: ManageUsersIcon,
    children: [
      {
        path: '/roles',
        title: 'Roles',
        permissionKey: Permission.MANAGE_ROLES_AND_PROFILES,
      },
      {
        path: '/profiles',
        title: 'Profiles',
        permissionKey: Permission.MANAGE_ROLES_AND_PROFILES,
      },
      {
        title: 'Users',
        path: '/users',
        // icon: UsergroupAddOutlined,
        permissionKey: Permission.USERS_VIEW,
      },
    ],
  },
]
