import { axios } from '@campxdev/shared'
import { AxiosRequestConfig } from 'axios'

const ENDPOINT = '/hrms/designations'

export const designations = {
  async fetchAll(params?) {
    const res = await axios.get(ENDPOINT, {
      params: {},
    })
    return res.data
  },

  async create(body: { name: string; headIds: string[]; _id: string }) {
    const postBody = {
      name: body.name,
    }

    const updateBody = {
      headIds: body.headIds,
      name: body.name,
    }

    const config: AxiosRequestConfig = {
      url: body?._id ? `${ENDPOINT}/${body._id}` : ENDPOINT,
      method: body?._id ? 'put' : 'post',
      data: body?._id ? updateBody : postBody,
    }

    const res = await axios(config)
    return res.data
  },

  async deleteOne(id: string) {
    const res = await axios.delete(`${ENDPOINT}/${id}`)
    return res.data
  },
}
