import {
  Breadcrumbs,
  CardsGrid,
  PageContent,
  PageHeader,
  PermissionsStore,
  Spinner,
} from '@campxdev/shared'
import { Box, IconButton, Stack, Typography, styled } from '@mui/material'
import { useQuery } from 'react-query'

import { useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import AssessmentCard from './AssessmentCard'
import { fetchDetails } from './service'
import {
  StyledAssessmentContainer,
  StyledAssessmentType,
  StyledCard,
  StyledCardContainer,
  StyledSpanAssessmentTypeText,
  StyledStudentProfileContainer,
} from './styles'
const defaultObject = {
  subjectData: null,
  studentData: null,
}
function StudentView() {
  const params = useParams()
  const [state, setState] = useImmer(defaultObject)
  const { data: studentDetails, isLoading } = useQuery(
    'student-assessment-details',
    () => fetchDetails(params.classRoomId, params.subjectId, params.studentId),

    {
      onSuccess: (res) => {
        setState((s) => {
          s.subjectData = res.subjectData
          s.studentData = res.studentData
        })
      },
    },
  )

  const canViewClassrooms = PermissionsStore.useState(
    (s) => s.permissions.can_classroom_view,
  )

  if (isLoading) {
    return <Spinner />
  }
  return (
    <>
      <PageHeader
        title={
          canViewClassrooms ? (
            <Breadcrumbs
              links={[
                { name: 'Classrooms', to: '/classrooms/classrooms' },
                {
                  name: `${state?.subjectData?.classroom?.name}`,
                  to: `/classrooms/${params.classRoomId}`,
                },
                {
                  name: `${state?.subjectData?.name}`,
                  to: `/classrooms/${params.classRoomId}/subject/${params.subjectId}/students`,
                },
                { name: 'Student Performance', to: null },
              ]}
            />
          ) : (
            <Breadcrumbs
              links={[
                { name: 'My Courses', to: '/my-courses' },
                {
                  name: `${state?.subjectData?.name}(${state?.subjectData?.classroom?.name})`,
                  to: `/classrooms/${params.classRoomId}/subject/${params.subjectId}/students`,
                },
                // {
                //   name: `${state?.subjectData?.classroom?.name}`,
                //   to: `/classrooms/${params.classRoomId}`,
                // },
                // {
                //   name: `${state?.subjectData?.name}`,
                //   to: `/classrooms/${params.classRoomId}/subject/${params.subjectId}/students`,
                // },
                { name: 'Student Performance', to: null },
              ]}
            />
          )
        }
      />
      <PageContent>
        <Stack gap="0.5rem">
          <StyledStudentProfileContainer>
            <img
              style={{ width: '50px', height: '50px' }}
              src="/images/profileImage.jpg"
            />
            <Box mx={2}>
              <Typography variant="h3">
                {state?.studentData?.student?.fullName}
              </Typography>
              <Typography sx={{ color: 'grey' }}>
                {state?.studentData?.student?.rollNo}
              </Typography>
            </Box>
          </StyledStudentProfileContainer>
          <Box>
            <Typography variant="h3">Student Performance</Typography>
            <StyledCardContainer>
              <StyledList
                averages={state?.studentData?.averages}
                attendancePercentage={state?.studentData?.attendancePercentage}
              />
            </StyledCardContainer>
          </Box>
          <StyledAssessmentContainer>
            <Box>
              <Typography variant="h3">All Assessments</Typography>
            </Box>
          </StyledAssessmentContainer>
          <Box>
            {state?.studentData?.assessments?.map((assessment) => {
              if (assessment.records.length) {
                return (
                  <>
                    <StyledAssessmentType variant="h5">
                      <StyledSpanAssessmentTypeText>
                        {assessment?.assessmentTypeName}
                      </StyledSpanAssessmentTypeText>
                    </StyledAssessmentType>
                    <Box sx={{ margin: '40px 0px' }}>
                      <CardsGrid cardWidth={312} gap={20}>
                        {assessment?.records?.map((item, index) => {
                          return (
                            <AssessmentCard
                              key={index}
                              status={item?.isAbsent}
                              title={item?.assessmentName}
                              subTitle={item.assessmentTypeName}
                              marks={item?.marks}
                            />
                          )
                        })}
                      </CardsGrid>
                    </Box>
                  </>
                )
              }
            })}
          </Box>
        </Stack>
      </PageContent>
    </>
  )
}
export default StudentView

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: theme.borders.grayLight,
  borderRadius: '10px',
}))

function StyledDropDownList({ label, color }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: ' 20px 20px 20px 0px',
      }}
    >
      <Box
        sx={{
          width: '12px',
          height: '12px',
          borderRadius: '50%',
          backgroundColor: `${color}`,
          marginRight: '10px',
        }}
      ></Box>
      <Typography variant="h6">{label}</Typography>
    </Box>
  )
}

const StyledList = ({ attendancePercentage, averages }) => {
  let averageArray = averages ? Object.keys(averages) : []
  let averageObject = {
    Assignment: 'Assignment',
    MID: 'Mid',
    Quiz: 'Quiz',
  }

  return (
    <>
      <StyledCard>
        <Typography mx={2} my={1} sx={{ opacity: '0.7' }}>
          Attendance
        </Typography>
        <Typography mx={2} variant="h1" sx={{ fontSize: '22px' }}>
          {attendancePercentage}%
        </Typography>
      </StyledCard>
      {averageArray.map((item, index) => {
        return (
          <StyledCard key={index}>
            <Typography mx={2} my={1} sx={{ opacity: '0.7' }}>
              Avg. {averageObject[averages[item]?.assessmentType]} marks
            </Typography>
            <Typography mx={2} variant="h1" sx={{ fontSize: '22px' }}>
              {averages[item]?.average} / 10
            </Typography>
          </StyledCard>
        )
      })}
    </>
  )
}
