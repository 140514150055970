import { lazy } from 'react'
import { AcademicCalendarTimeline } from './AcademicCalendar/AcademicCalendarTimeline'

const AssessmentTypes = lazy(() => import('./AssessmentTypes'))
const AssessmentTemplates = lazy(() => import('./AssessmentTemplates'))

const Semesters = lazy(() => import('./Semesters'))
const SubjectTypes = lazy(() => import('./SubjectTypes'))

const Curriculums = lazy(() => import('./Curriculums'))
const AssessmentRules = lazy(
  () => import('./AssessmentTemplates/AssessmentRules'),
)

const AcademicCalendar = lazy(
  () => import('./AcademicCalendar/AcademicCalendar'),
)

const AcademicCalendarEventList = lazy(
  () => import('./AcademicCalendar/AcademicCalendarEventList'),
)

const AcademicCalendarPrint = lazy(
  () => import('./AcademicCalendar/AcademicCalendarPrint'),
)
const HolidaysPrint = lazy(() => import('../Academics/Holidays/HolidayPrint'))
const TimeSlotTemplate = lazy(() => import('./TimeSlotTemplate'))

const TimeTableSlots = lazy(() => import('./TimeSlotTemplate/TimeTableSlots'))

const Holidays = lazy(() => import('../Academics/Holidays'))

const Sections = lazy(() => import('./Sections'))
export {
  AcademicCalendar,
  AcademicCalendarEventList,
  AcademicCalendarPrint,
  AcademicCalendarTimeline,
  AssessmentRules,
  AssessmentTemplates,
  AssessmentTypes,
  Curriculums,
  Holidays,
  HolidaysPrint,
  Sections,
  Semesters,
  SubjectTypes,
  TimeSlotTemplate,
  TimeTableSlots,
}
