import { axios } from '@campxdev/shared'

export const academicCalender = {
  async fetchAcademicCalendar(params?) {
    const res = await axios.get(
      '/square/academic-calendar/academics-events-by-academic-year',
      {
        params: {
          ...params,
        },
      },
    )
    return res.data
  },
}
