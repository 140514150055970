import { Box, styled } from '@mui/material'

export const FullCalendarWrapper = styled(Box)(({ theme }) => ({
  '& colgroup': {
    '& col': {
      minWidth: '120px',
    },
  },
  '.fc .fc-timegrid-axis-cushion': {
    maxWidth: 'unset',
  },
  '.fc .fc-timegrid-axis-frame': {
    justifyContent: 'flex-start',
  },
  '.fc-direction-ltr .fc-timegrid-slot-label-frame': {
    textAlign: 'left',
  },
  '.fc .fc-timegrid-divider': {
    padding: 0,
  },
  '.fc .fc-timegrid-slot-minor': {
    borderTop: 'none',
  },
  '& thead': {
    '& th': {
      verticalAlign: 'bottom',
    },
    height: '40px',
    '& *': {
      color: theme.palette.secondary.main,
    },
  },
  '& .fc .fc-timegrid-slot': {
    height: '84px',
    border: 'none',
  },
  '& .fc-v-event, & .fc-h-event': {
    border: 'none',
    padding: '2px 5px',
  },
  '& .fc .fc-timegrid-col.fc-day-today': {
    backgroundColor: 'white',
  },
  '& .fc-daygrid-day-top': {
    '& a': {
      color: theme.palette.text.primary,
    },
  },
  '& .fc-day-disabled': {
    background: 'rgba(0, 0, 0, 0.02)',
  },
  '& .fc-daygrid-event .fc-event-time': {
    display: 'none',
  },
  '& .fc-event-main': {
    overflow: 'hidden',
  },
}))

export const FullCalendarWrapperPrint = styled(Box)(({ theme }) => ({
  '& colgroup': {
    '& col': {
      minWidth: '120px',
    },
  },
  '.fc .fc-timegrid-axis-cushion': {
    maxWidth: 'unset',
  },
  '.fc .fc-timegrid-axis-frame': {
    justifyContent: 'flex-start',
  },
  '.fc-direction-ltr .fc-timegrid-slot-label-frame': {
    textAlign: 'left',
  },
  '.fc .fc-timegrid-divider': {
    padding: 0,
  },
  '.fc .fc-timegrid-slot-minor': {
    borderTop: 'none',
  },
  '& thead': {
    '& th': {
      verticalAlign: 'bottom',
    },
    height: '40px',
    '& *': {
      color: theme.palette.secondary.main,
    },
  },
  '& .fc .fc-timegrid-slot': {
    height: '84px',
    border: 'none',
  },
  '& .fc-v-event, & .fc-h-event': {
    border: 'none',
  },
  '& .fc .fc-timegrid-col.fc-day-today': {
    backgroundColor: 'white',
  },
  '& .fc-daygrid-day-top': {
    '& a': {
      color: theme.palette.text.primary,
    },
  },
  '& .fc-day-disabled': {
    background: 'rgba(0, 0, 0, 0.02)',
  },
  '& .fc-daygrid-event .fc-event-time': {
    display: 'none',
  },
  '& .fc-event-main': {
    overflow: 'hidden',
  },
}))
