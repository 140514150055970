import { alpha, Box, Button, Stack, styled, Typography } from '@mui/material'

export const StyledContainer = styled(Box)(({ theme }) => ({
  border: theme.borders.grayLight,
  borderRadius: '20px',
  width: '360px',
}))

export const StyledHeader = styled(Box)(({ theme }) => ({
  borderTopRightRadius: '20px',
  borderTopLeftRadius: '20px',
  background: theme.palette.secondary.lighter,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
}))

export const StyledBody = styled(Box)(({ theme }) => ({
  margin: '0 auto',
}))

export const MainContainer = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
}))

export const StyledClubDetailsBox = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  display: 'grid',
  gridTemplateColumns: '170px 1fr 200px',
  padding: '20px',
  border: theme?.borders?.grayLight,
  gap: '10px',
}))

export const StyledTypography = styled(Typography)<{ bg?: string }>(
  ({ theme, bg }) => ({
    padding: '6px 20px',
    borderRadius: '10px',
    background:
      bg === 'green' ? alpha(theme.palette.common.green, 0.2) : '#F8F8F8',
    border: theme.borders.grayLight,
  }),
)
export const StyledStack = styled(Stack)(({ theme }) => ({
  padding: '10px 20px',
  borderRadius: '10px',
  background: '#F7F7F7',
  gap: '20px',
  justifyContent: 'flex-end',
}))

export const StyledPage = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: '20px',
}))

export const StyledStatusLabel = styled(Typography)(({ theme }) => ({
  '&:before': {
    content: '""',
    display: 'inline-block',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    background: theme.palette.common.green,
    marginRight: '10px',
  },
}))

export const StyledActionButton = styled(Button)<{
  actionType: 'accept' | 'reject'
}>(({ theme, actionType }) => ({
  // padding: '2px 20px',
  height: '30px',
  background:
    actionType === 'accept'
      ? alpha(theme.palette.common.green, 0.2)
      : alpha(theme.palette.error.main, 0.2),
  color:
    actionType === 'accept'
      ? theme.palette.common.green
      : theme.palette.error.main,
  borderColor:
    actionType === 'accept'
      ? theme.palette.common.green
      : theme.palette.error.main,

  '&:hover': {
    ...(actionType === 'accept'
      ? {
          background: alpha(theme.palette.common.green, 0.25),
          color: theme.palette.common.green,
          borderColor: theme.palette.common.green,
        }
      : {
          background: alpha(theme.palette.error.main, 0.25),
          color: theme.palette.error.main,
          borderColor: theme.palette.error.main,
        }),
  },
}))
