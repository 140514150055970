import { Card } from '@campxdev/shared'
import { alpha, Box, Stack, styled, Typography } from '@mui/material'
import { GreenTickIcon } from 'assets/icons'

function AssessmentCard({ title, subTitle, status, marks }) {
  return (
    <Box sx={{ cursor: 'pointer' }}>
      <Card title={title}>
        <Stack gap={2}>
          <StyledStack direction={'row'}>
            <Typography variant="subtitle2">Assessment type:</Typography>
            <Typography>{subTitle}</Typography>
          </StyledStack>
          <StyledStack direction={'row'}>
            {status == 1 ? (
              <Box
                sx={{
                  padding: '6px 10px',
                  borderRadius: '10px',
                  border: '1px solid #FFAF20',
                }}
              >
                <Typography sx={{ fontSize: '12px' }}>{'Absent'}</Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  padding: '6px 10px',
                  borderRadius: '10px',
                  backgroundColor: alpha('#FFAF20', 0.6),
                }}
              >
                <Typography sx={{ fontSize: '12px' }}>
                  {' '}
                  Marks: {marks}
                </Typography>
              </Box>
            )}
          </StyledStack>
        </Stack>
      </Card>
    </Box>
  )
}
export default AssessmentCard

export const StyledStack = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
}))
