import styled from 'styled-components'
import { styled as muiStyled } from '@mui/material'

export const headerHeight = '64px'
export const sideNavWidth = '220px'

export const StyledLayoutContainer = styled.div`
  & > div {
  }
`

export const StyledHeaderContainer = styled.header`
  z-index: 300;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  min-height: ${headerHeight};
  background: #fff;
  box-shadow: 0px 0px 3px #0000001a;
  & > div {
    height: ${headerHeight};
  }
`

export const StyledLeftNavContainer = muiStyled('aside')(({ theme }) => ({
  width: sideNavWidth,
  background: theme.palette.secondary.main,
  color: 'white',
  position: 'fixed',
  top: headerHeight,
  left: 0,
  height: `calc(100vh - ${headerHeight})`,
  overflowY: 'auto',

  '&::-webkit-scrollbar': {
    width: '0.5em',
    height: '0.5em',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '3px',

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.4)',
    },
  },

  zIndex: 100,
}))

export const StyledMainContentContainer = muiStyled('main')(() => ({
  width: `calc(100% - ${sideNavWidth})`,
  height: `calc(100vh - ${headerHeight})`,
  position: 'fixed',
  top: headerHeight,
  left: sideNavWidth,
  overflowY: 'auto',
}))
