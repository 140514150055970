import { branches } from './branches'
import { courses } from './courses'
import { departments } from './departments'
import { designations } from './designations'
import { employees } from './employees'
import { institutions } from './instituitions'
import { regBatches } from './regBatches'
import { timetableSlots } from './timetable-slots'

export const services = {
  timetableSlots: timetableSlots,
  institutions: institutions,
  courses: courses,
  regBatches: regBatches,
  departments: departments,
  designations: designations,
  employees: employees,
  branches: branches,
}
