import { Spinner, axios } from '@campxdev/shared'

import { queryClient } from '@campxdev/shared/src/contexts/QueryClientProvider'
import { Box } from '@mui/material'
import _ from 'lodash'
import { FeedbackTypes } from 'pages/Feedback/utils'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { TargetAudienceDistribution } from '../../../components/components/TargetAudienceDistribution'
import { ManageTargetAudienceForm } from './ManageTargetAudienceForm'

export const ManageTargetAudience = ({ close }) => {
  const { data, isLoading } = useQuery('selectors-data', async () => {
    const programs = await axios
      .get('/paymentx/domain/programs')
      .then((res) => res.data)
    const courses = await await axios
      .get('/paymentx/domain/courses')
      .then((res) => res.data)
    const classrooms = await axios
      .get('/square/classrooms/filters')
      .then((res) => res.data)

    return { programs, courses, classrooms }
  })

  useEffect(() => {
    queryClient.invalidateQueries('feedback')
  }, [])

  if (isLoading) return <Spinner />

  const classroomsMap = _.keyBy(data.classrooms, (c) => c.id)
  const programsMap = _.keyBy(data.programs, (p) => p.id)
  const coursesMap = _.keyBy(data.courses, (c) => c.id)

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap="10px">
      <ManageTargetAudienceForm data={data} close={close} />
      <TargetAudienceDistribution
        classroomsMap={classroomsMap}
        programsMap={programsMap}
        coursesMap={coursesMap}
        type={FeedbackTypes.FACILITY}
      />
    </Box>
  )
}
