import { Image, LabelValue, Spinner, axios } from '@campxdev/shared'
import { urlTenantKey } from '@campxdev/shared/src/contexts/Providers'
import { Box, Button, Divider, Typography } from '@mui/material'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { StyledContainer } from '../styles'
const StyledBox = styled.div`
  /* Your other styles go here */

  @media print {
    .print_btn {
      display: none;
    }
  }
`

const PaymentSuccess = () => {
  const { data: assetsData, isLoading: assetsLoading } = useQuery('logos', () =>
    axios.get('/square/assets').then((res) => res.data),
  )

  const params: any = useParams()

  const { data, isLoading } = useQuery('form-details', () =>
    axios
      .get(`/square/forms/${params.formId}/form-details/${params.orderId}`)
      .then((res) => res.data),
  )

  if (assetsLoading || isLoading) {
    return <Spinner />
  }
  if (data && data[0] && data[0].razorPayDetails?.paymentId) {
    data[0].data.push({
      label: 'Payment ID',
      value: data[0].razorPayDetails.paymentId,
    })
  }

  if (data && data[0] && data[0].razorPayDetails?.razorpayOrderId) {
    data[0].data.push({
      label: 'Receipt ID',
      value: data[0].razorPayDetails.razorpayOrderId,
    })
  }

  return (
    <>
      {/* vpulse   */}
      {urlTenantKey == 'vpulse' && (
        <StyledBox>
          <div
            className="print_btn"
            style={{ margin: '20px 5px', textAlign: 'right' }}
          >
            <Button onClick={() => window.print()} size="small">
              Download
            </Button>
          </div>
          <StyledContainer>
            <Box p={2}>
              <Box pb={2} display={'flex'} alignItems={'center'} gap={2}>
                <Image
                  alt="logo-square"
                  src={assetsData?.logo}
                  height={'30px'}
                  width={'100px'}
                />
                <Divider flexItem orientation="vertical" />
                <Typography
                  sx={{
                    fontWeight: '500',
                    letterSpacing: '2px',
                  }}
                  variant="h3"
                >
                  UDHBHAV-2K24
                </Typography>
              </Box>
              <Divider flexItem />
              <Typography pt={2} pb={2} variant="h3" color="grey">
                ENTRY PASS
              </Typography>

              <Typography pt={1} pb={1} variant="h3" color="green">
                PAYMENT COMPLETED
              </Typography>
              {data &&
                data[0]?.data?.map((item, index) => (
                  <LabelValue
                    label={`${item?.label} :`}
                    value={
                      item?.label === 'Mobile Number'
                        ? item?.value?.number
                        : item?.value
                    }
                    key={index}
                    sx={{ flexDirection: 'row' }}
                    labelsx={{
                      fontSize: '16px',
                      color: 'black',
                      fontWeight: '600',
                    }}
                    valuesx={{ fontWeight: '500' }}
                  />
                ))}
            </Box>
          </StyledContainer>
        </StyledBox>
      )}
      <>
        <StyledBox>
          <div
            className="print_btn"
            style={{ margin: '20px 5px', textAlign: 'right' }}
          >
            <Button onClick={() => window.print()} size="small">
              Download
            </Button>
          </div>
          <StyledContainer>
            <Box p={2}>
              <Box
                pb={2}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Image
                  alt="logo-square"
                  src={assetsData?.logo}
                  height={'40px'}
                  width={'100px'}
                />
              </Box>
              <Divider flexItem />
              <Typography pt={1} pb={1} variant="h3" color="green">
                PAYMENT COMPLETED
              </Typography>
              {data &&
                data[0]?.data?.map((item, index) => {
                  let value = item?.value

                  if (item?.label === 'Name' && typeof value === 'object') {
                    value = Object.values(value)[0]
                  } else if (typeof value === 'object') {
                    value = JSON.stringify(value)
                  }

                  return (
                    <>
                      <LabelValue
                        label={`${item?.label} :`}
                        value={
                          item?.label === 'Mobile Number'
                            ? item?.value?.number
                            : value
                        }
                        key={index}
                        sx={{ flexDirection: 'row' }}
                        labelsx={{
                          fontSize: '16px',
                          color: 'black',
                          fontWeight: '600',
                          marginBottom: '10px',
                        }}
                        valuesx={{ fontWeight: '500' }}
                      />
                    </>
                  )
                })}
            </Box>
          </StyledContainer>
        </StyledBox>
      </>
    </>
  )
}

export default PaymentSuccess
