import {
  PageContent,
  PageHeader,
  Spinner,
  UserStore,
  ValidateAccess,
} from '@campxdev/shared'
import { SquarePermissions } from '@campxdev/shared/src/shared-state/PermissionsStore'
import { ArrowBackIosOutlined } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { SERVICES } from 'services'

const HodComments = () => {
  let { mentorUserId, studentId } = useParams()
  const [comments, setComments] = useState(null)
  const navigate = useNavigate()
  const isMentorUserId = !!mentorUserId

  if (!mentorUserId) {
    mentorUserId = UserStore.useState().globalUserId
  }

  const { data, isLoading, isRefetching } = useQuery(
    'student-sessions',
    () => SERVICES.mentors.getMenteeSessions(mentorUserId, studentId),
    {
      onSuccess: (res) => {
        const sessionsWithCommentId = res.sessionData.filter(
          (session) => session.commentId,
        )
        const groupedByCommentId = _.groupBy(sessionsWithCommentId, 'commentId')
        const commentsArray = _.map(
          groupedByCommentId,
          (sessionsGroup, commentId) => {
            const comment = sessionsGroup[0].comment
            const createdBy = sessionsGroup[0].createdBy
            const sessionNames = sessionsGroup.map((session) => session.name)

            return {
              comment,
              createdBy,
              sessionNames,
            }
          },
        )

        setComments(commentsArray)
      },
    },
  )
  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <PageHeader
        title={
          isMentorUserId ? (
            <Button
              sx={{ color: 'black', fontSize: '16px', p: 0 }}
              variant="text"
              startIcon={<ArrowBackIosOutlined />}
              onClick={() =>
                navigate(
                  `/mentors/all-mentors/${mentorUserId}/student/${studentId}`,
                )
              }
            >
              HOD Comments
            </Button>
          ) : (
            <Button
              sx={{ color: 'black', fontSize: '16px', p: 0 }}
              variant="text"
              startIcon={<ArrowBackIosOutlined />}
              onClick={() => navigate(`/mentors/my-mentees/${studentId}`)}
            >
              HOD Comments
            </Button>
          )
        }
        actions={[
          <ValidateAccess
            key="0"
            accessKey={SquarePermissions.CAN_VIEW_MENTORS}
          >
            <Button onClick={() => navigate('new')}>+ Add Comment</Button>
          </ValidateAccess>,
        ]}
      />

      <PageContent>
        <Box sx={{ width: '60%' }}>
          {comments &&
            comments?.map((item: any) => (
              <CommentBox data={item} key={item?._id} />
            ))}
        </Box>
      </PageContent>
    </>
  )
}

export default HodComments

const CommentBox = ({ data }) => {
  return (
    <Stack
      sx={{
        border: '1px solid #1212121A',
        padding: '10px',
        borderRadius: '5px',
        my: '10px',
      }}
    >
      <Typography>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.comment?.comment,
          }}
        />
      </Typography>
      <Typography variant="caption" color={'gray'}>
        Posted on{' '}
        {moment(data?.comment?.createdAt).format('YYYY-MM-DD, HH:mm:ss')}
      </Typography>
      <Stack direction={'row'} alignItems={'center'}>
        <Typography variant="caption" color={'gray'} mr={1}>
          Sessions:
        </Typography>
        <Stack sx={{ color: 'black', fontWeight: '500' }} direction={'row'}>
          {data?.sessionNames.map((item, index) => (
            <Stack key={index} direction={'row'}>
              <Box>{item}</Box>
              {index < data.sessionNames.length - 1 && <Box>, </Box>}
            </Stack>
          ))}
        </Stack>
      </Stack>

      <Typography>By: {data?.createdBy?.fullName}</Typography>
    </Stack>
  )
}
