import {
  ActionButton,
  CardsGrid,
  NoDataIllustration,
  PageHeader,
  Permission,
  Spinner,
  TableFooter,
  ValidateAccess,
  DrawerButton,
  ReactJoyRide,
} from '@campxdev/shared'
import { Box, Typography } from '@mui/material'
import { Store } from 'pullstate'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { fetchClassrooms, fetchCoursesAndBranches } from '../services'
import ClassroomCard from './ClassRoomCard'
import ClassroomsFilter from './ClassroomsFilters'
import ClassroomsForm from './ClassroomsForm'
import { StyledMainContainer } from './styles'

export const classRoomStore = new Store({
  sems: [],
  classRoom: null,
  currentSemSubjects: [],
  list: {
    filters: {
      limit: 10,
      offset: 0,
      courseId: '',
      programId: '',
      batch: '',
    },
    data: [],
  },
  masterData: {},
})

export default function ClassRooms() {
  const classroomState = classRoomStore.useState((s) => s)
  const { isLoading: loadingPrograms, data: masterData } = useQuery(
    'courses-programs',
    () => fetchCoursesAndBranches(),
    {
      onSuccess: (res) => {
        classRoomStore.update((s) => {
          s.masterData = res
        })
      },
    },
  )

  useEffect(() => {
    if (localStorage.getItem('classroom-selection')) {
      const selection = JSON.parse(
        localStorage.getItem('classroom-selection') || '',
      )

      if (
        masterData?.courses?.filter((item) => item.id == selection.courseId)
          .length
      ) {
        classRoomStore.update((s) => {
          s.list.filters = {
            ...s.list.filters,
            ...selection,
          }
        })
      }
    }
  }, [classroomState.masterData])

  const tourSteps = [
    {
      target: '.createClassrooms',
      title: 'Create Classrooms',
      content: (
        <Typography variant="subtitle1">
          Click here to create a new classroom. Set up in moments and get
          started
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.filterOptions',
      title: 'Filter Options',
      content: (
        <Typography variant="subtitle1">
          Use the filters to quickly sort and find specific classrooms based on
          your preferences.
        </Typography>
      ),
    },
  ]

  if (loadingPrograms) return <Spinner />
  return (
    <>
      {/* <ReactJoyRide steps={tourSteps} tourName="ClassRooms-Page" /> */}
      <PageHeader
        title={'Class Rooms'}
        actions={[
          <ValidateAccess key={1} accessKey={Permission.CLASSROOM_ADD}>
            <DrawerButton
              anchor={({ open }) => (
                <ActionButton onClick={open} className="createClassrooms">
                  Create Classroom
                </ActionButton>
              )}
              content={({ close }) => (
                <ClassroomsForm close={close} masterData={masterData} />
              )}
              title="Create Classroom"
            />
          </ValidateAccess>,
        ]}
      />
      <StyledMainContainer>
        <ClassroomsFilter masterData={masterData} />
        <ClassRoomList />
      </StyledMainContainer>
    </>
  )
}

const ClassRoomList = () => {
  const filters = classRoomStore.useState((s) => s.list.filters)

  const { data, isLoading: loading } = useQuery(
    ['classrooms', ...Object.values(filters)],
    () => fetchClassrooms({ ...filters }),
    {
      enabled: !!filters?.courseId || !!filters?.programId || !!filters?.batch,
    },
  )

  const handlePagination = (newPage) => {
    classRoomStore.update((s) => {
      s.list.filters = {
        ...s.list.filters,
        offset: (newPage - 1) * filters.limit,
      }
    })
  }
  const handlePageLimit = (value) => {
    classRoomStore.update((s) => {
      s.list.filters = {
        ...s.list.filters,
        limit: value,
        offset: 0,
      }
    })
  }

  const applyFilters = data === undefined
  const noData = !data?.result?.length
  return (
    <Box width={'100%'}>
      <Box sx={{ height: 'calc(100vh - 220px)', overflowY: 'auto' }}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {applyFilters || noData ? (
              <NoDataIllustration
                imageSrc="/illustrations/whiteboard.svg"
                message={
                  applyFilters
                    ? 'Apply filters to view classrooms'
                    : 'No Classrooms Found'
                }
              />
            ) : (
              <>
                <CardsGrid cardWidth={320} gap={20}>
                  {data?.result?.map((item, index) => (
                    <ClassroomCard data={item} key={index} />
                  ))}
                </CardsGrid>

                <TableFooter
                  page={Math.ceil(filters.offset / filters.limit) + 1}
                  totalCount={data?.count}
                  limit={filters.limit}
                  handlePagination={handlePagination}
                  handlePageLimit={handlePageLimit}
                />
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}
