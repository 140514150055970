import { ActionButton, FormMultiSelect, batchOptions } from '@campxdev/shared'
import { queryClient } from '@campxdev/shared/src/contexts/QueryClientProvider'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Typography } from '@mui/material'
import { wait } from 'assets/images'
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { FeedbackStore } from 'pages/Feedback/store'
import * as pluralize from 'pluralize'
import { toast } from 'react-toastify'
import { SERVICES } from 'services'
import * as yup from 'yup'

export const ManageTargetAudienceForm = ({
  data: { programs, courses, classrooms },
  close,
}) => {
  const facilityFeedback = FeedbackStore.useState((s) => s.feedback)
  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      batches: [],
      courseIds: [],
      programIds: [],
      classroomIds: [],
    },
    resolver: yupResolver(
      yup.object().shape({
        batches: yup.array().min(1).required('Please select a batch'),
      }),
    ),
  })
  const { mutate: addMutate, isLoading: adding } = useMutation(
    SERVICES.facilityFeedbacks.addFacilityFeedbackTargetAudience,
    {
      onSuccess: (data) => {
        toast.success(data.data.message)
        queryClient.invalidateQueries('feedback')
      },
    },
  )
  const { mutate: deleteMutate, isLoading: deleting } = useMutation(
    SERVICES.facultyFeedbacks.deleteFacultyFeedbackTargetAudience,
    {
      onSuccess: (data) => {
        toast.success(data.data.message)
        queryClient.invalidateQueries('target-audience-distribution')
        queryClient.invalidateQueries('facility-feedbacks')
      },
    },
  )
  const params = useParams()

  const onAdd = (formData) => {
    addMutate({
      id: params.id,
      body: {
        batches: formData.batches.map((batch) => batch.value),
        courseIds: formData.courseIds.map((courseId) => courseId.value),
        programIds: formData.programIds.map((programId) => programId.value),
        classroomIds: formData.classroomIds.map(
          (classroomId) => classroomId.value,
        ),
      },
    })
  }

  const onDelete = (formData) => {
    deleteMutate({
      id: params.id,
      body: {
        batches: formData.batches.map((batch) => batch.value),
        courseIds: formData.courseIds.map((courseId) => courseId.value),
        programIds: formData.programIds.map((programId) => programId.value),
        classroomIds: formData.classroomIds.map(
          (classroomId) => classroomId.value,
        ),
      },
    })
  }

  //TODO: Multiple Maps with id and uniqueId are required here this code needs to be optimized when classrooms are moved to mongodb
  const coursesMapUnq = _.keyBy(courses, (c) => c.uniqueId)
  const programsMapUnq = _.keyBy(programs, (p) => p.uniqueId)
  const coursesMap = _.keyBy(courses, (c) => c.id)

  //TODO: Need to find a way to optimize this

  const getToolTip = () => {
    if (watch().classroomIds.length) {
      return `${watch()
        .classroomIds.map((id) => id.label)
        .join(' , ')} ${pluralize(
        'Classroom',
        watch().classroomIds.length,
      )} ${pluralize('is', watch().classroomIds.length)} Selected`
    } else if (watch().programIds.length) {
      return `All Classrooms in ${pluralize(
        'Batch',
        watch().batches.length,
      )} ${watch()
        .batches.map((id) => id.label)
        .join(' , ')} and ${watch()
        .programIds.map((id) => id.label)
        .join(' , ')} ${pluralize(
        'Program',
        watch().programIds.length,
      )} are Selected`
    } else if (watch().courseIds.length) {
      return `All Classrooms in ${pluralize(
        'Batch',
        watch().batches.length,
      )} ${watch()
        .batches.map((id) => id.label)
        .join(' , ')} and ${watch()
        .courseIds.map((id) => id.label)
        .join(' , ')} ${pluralize(
        'Degree',
        watch().courseIds.length,
      )} are Selected`
    } else if (watch().batches.length) {
      return `All Classrooms in ${pluralize(
        'Batch',
        watch().batches.length,
      )} ${watch()
        .batches.map((id) => id.label)
        .join(' , ')} are Selected`
    }
  }
  return (
    <>
      {facilityFeedback?.addingTargetAudience ? (
        <Stack
          gap={10}
          width="100%"
          minHeight="400px"
          justifyContent="center"
          alignItems="center"
        >
          <img src={wait} height="200px" width="200px" />
          <Typography>Adding Target Audience This may take a while</Typography>
        </Stack>
      ) : (
        <Stack gap={2} width="100%">
          <FormMultiSelect
            required
            name="batches"
            control={control}
            label="Select Batches"
            options={batchOptions?.map((item) => ({
              label: item,
              value: item,
            }))}
          />
          <FormMultiSelect
            name="courseIds"
            control={control}
            label="Select Courses"
            options={courses?.map((item) => ({
              label: item.courseName,
              value: item.id,
            }))}
          />
          <FormMultiSelect
            noOptionsText="select courses to select programs"
            name="programIds"
            control={control}
            label="Select Programs"
            options={programs
              ?.filter((item) =>
                watch()
                  .courseIds?.map((x) => x.value)
                  ?.includes(item.courseId),
              )
              ?.map((item) => ({
                label: `${item.branchName}(${
                  coursesMap[item.courseId].courseName
                })`,
                value: item.id,
              }))}
          />
          <FormMultiSelect
            noOptionsText="select courses, batches, programs to select classrooms"
            name="classroomIds"
            label="Select Classrooms"
            control={control}
            options={classrooms
              ?.filter(
                (item) =>
                  watch()
                    .courseIds?.map((x) => x.value)
                    ?.includes(coursesMapUnq[item.courseId]?.id) &&
                  watch()
                    .batches?.map((x) => x.value)
                    ?.includes(item?.batchName) &&
                  watch()
                    .programIds?.map((x) => x.value)
                    ?.includes(programsMapUnq[item.programId]?.id),
              )
              ?.map((item) => ({
                label: `${item?.name} (${item?.batchName}) (${item?.courseName})`,
                value: item.id,
              }))}
          />

          <Typography
            variant="subtitle2"
            justifyContent="center"
            display="flex"
          >
            {getToolTip()}
          </Typography>

          <Stack direction="row" gap="10px">
            <ActionButton variant="outlined" fullWidth onClick={close}>
              Cancel
            </ActionButton>
            <ActionButton
              fullWidth
              onClick={handleSubmit(onAdd)}
              loading={adding}
            >
              Add
            </ActionButton>
            <ActionButton
              variant="outlined"
              color="error"
              fullWidth
              onClick={handleSubmit(onDelete)}
              loading={deleting}
            >
              Delete
            </ActionButton>
          </Stack>
        </Stack>
      )}
    </>
  )
}
