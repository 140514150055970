import {
  ActionButton,
  CardsGrid,
  DeleteButton,
  RestoreButton,
} from '@campxdev/shared'
import { Alert, Box, Button, Stack, Typography, styled } from '@mui/material'
import React from 'react'

function ReportColumns({ onClose, columns, setColumns }) {
  const onToggle = (index) => {
    setColumns((s) => {
      s[index].status = !s[index].status
    })
  }
  return (
    <>
      <Stack gap={2} sx={{ padding: '10px' }}>
        <StyledInfoContrainer>
          <Typography>
            Delete columns and customize your report to suit your specific needs
          </Typography>
        </StyledInfoContrainer>

        <Typography variant="h3">Columns List</Typography>
        <CardsGrid
          cardWidth={250}
          gap={15}
          sx={{
            marginBottom: '20px',
            maxHeight: '40vh',
            overflowY: 'auto',
            border: '1px solid gray',
            borderRadius: '10px',
            '&::-webkit-scrollbar': {
              width: '0.5em',
              height: '0.5em',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.15)',
              borderRadius: '3px',
              '&:hover': {
                background: 'rgba(0, 0, 0, 0.2)',
              },
            },
          }}
        >
          {columns?.map((column, index) => (
            <ColumnComponent
              key={index}
              label={column?.label}
              status={column?.status}
              onToggle={() => onToggle(index)}
            />
          ))}
        </CardsGrid>

        <Stack direction={'row'} gap={2} sx={{ margin: 'auto' }}>
          <ActionButton variant="outlined" onClick={onClose}>
            Cancel
          </ActionButton>
          <ActionButton
            onClick={() => {
              onClose()
            }}
          >
            Generate
          </ActionButton>
        </Stack>
      </Stack>
    </>
  )
}

export default ReportColumns

export const ColumnComponent = ({ label, status, onToggle }) => {
  return (
    <>
      <Box>
        <StyledColumnHeader>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            {label}
          </Typography>
        </StyledColumnHeader>
        <StyledColumn>
          <IconComponent
            type={status ? 'delete' : 'restore'}
            onToggle={onToggle}
          />
        </StyledColumn>
      </Box>
    </>
  )
}

export const IconComponent = ({
  type,
  onToggle,
}: {
  onToggle: any
  type: 'delete' | 'restore'
}) => {
  return (
    <>
      <StyledButton
        variant="text"
        btnType={type}
        startIcon={type == 'delete' ? <DeleteButton /> : <RestoreButton />}
        onClick={onToggle}
      >
        {type == 'delete' ? 'Delete' : 'Restore'}
      </StyledButton>
    </>
  )
}

export const StyledInfoContrainer = styled(Box)(({ theme }) => ({
  width: '600px',
  backgroundColor: '#FFAF200D',
  border: '1px solid #FFAF2080',
  borderRadius: '10px',
  padding: '15px',
}))

export const StyledColumnHeader = styled(Box)(({ theme }) => ({
  backgroundColor: '#F2F2F2',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.palette.secondary.dark}`,
}))

export const StyledColumn = styled(Box)(({ theme }) => ({
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.palette.secondary.dark}`,
}))

export const StyledButton = styled(Button)<{ btnType: 'delete' | 'restore' }>(
  ({ theme, btnType }) => ({
    color: btnType == 'delete' ? 'red' : 'black',
  }),
)
