import { AcademicsIcon, CalenderIcon } from '@campx/settings/src/layout/Icons'
import { Permission } from '@campxdev/shared'
import { SquarePermissions } from '@campxdev/shared/src/shared-state/PermissionsStore'
import {
  ActivitiesIcon,
  ClassroomsIcon,
  CollegeFeedIcon,
  CourseRegistrationIcon,
  EyeIcon,
  FormBuilderIcon,
  InfrastructureIcon,
  LogsIcon,
  MentorsIcon,
  SettingsIcon,
  StudentsIcon,
} from './icons'
export interface IMenuItem {
  title: string
  path: string
  icon?: any
  index?: boolean
  children?: Array<IMenuItem>
  permissionKey?: string
}

export const dashboardMenu: Array<IMenuItem> = [
  {
    title: 'Notice Board',
    path: '/feed/digitalNoticeBoard',
    icon: CollegeFeedIcon,
    permissionKey: Permission.CAN_NOTICE_BOARD_VIEW,
  },
  {
    title: 'Birds Eye View',
    path: '/',

    icon: EyeIcon,
    permissionKey: Permission.CAN_BIRDS_EYE_VIEW,
    children: [
      {
        title: 'Analytics Dashboard',
        path: 'analytics-dashboard',
        permissionKey: Permission.CAN_BIRDS_EYE_VIEW,
      },
      {
        title: 'Payments Dashboard ',
        path: 'payment-dashboard',
        permissionKey: Permission.CAN_BIRDS_EYE_VIEW,
      },
      {
        title: 'Principal Dashboard ',
        path: 'principal-dashboard',
        permissionKey: Permission.CAN_BIRDS_EYE_VIEW,
      },

      {
        title: 'Department Dashboard ',
        path: 'department-dashboard',
        permissionKey: Permission.CAN_BIRDS_EYE_VIEW,
      },
    ],
  },

  // {
  //   title: 'Tasks',
  //   path: '/tasks',
  //   icon: TaskListIcon,
  //   permissionKey: Permission.CAN_TASKS_VIEW,
  //   children: [
  //     {
  //       title: 'Dashboard',
  //       path: '/dashboard',
  //     },
  //     {
  //       title: 'Taskboard',
  //       path: '/taskboard/pending-task',
  //       permissionKey: Permission.CAN_TASKS_VIEW,
  //     },
  //     {
  //       title: 'Reports',
  //       path: '/reports',
  //     },
  //   ],
  // },
  {
    title: 'Students',
    path: '/students',
    icon: StudentsIcon,
    permissionKey: Permission.STUDENTS_VIEW,
    children: [
      {
        title: 'All Students',
        path: '/all-students',
        permissionKey: Permission.STUDENTS_VIEW,
      },
      // {
      //   title: 'Student Promotion',
      //   path: '/student-promotion',
      //   permissionKey: isDevelopment && Permission.STUDENTS_VIEW,
      // },
    ],
  },
  {
    title: 'Attendance',
    path: 'attendance',
    icon: CalenderIcon,
    permissionKey: Permission.CAN_ADMIN_VIEW,
  },
  {
    title: 'Classrooms',
    path: '/classrooms',
    icon: ClassroomsIcon,
    children: [
      {
        title: 'Classrooms',
        path: '/classrooms',
        permissionKey: Permission.CLASSROOM_VIEW,
      },
      {
        title: 'Faculty Timetable',
        path: '/faculty-timetable',
        permissionKey: Permission.CLASSROOM_VIEW,
      },

      {
        title: 'Reports',
        path: '/reports',
        permissionKey: Permission.CLASSROOM_VIEW,
      },
    ],
  },
  {
    title: 'My Courses',
    path: '/my-courses',
    permissionKey: SquarePermissions.CAN_VIEW_MY_SUBJECTS,
    icon: AcademicsIcon,
  },
  {
    title: 'Mentors',
    path: '/mentors',
    icon: MentorsIcon,
    permissionKey: SquarePermissions.CAN_VIEW_MENTORS,
  },
  {
    title: 'My Mentees',
    path: '/my-mentees',
    icon: MentorsIcon,
    permissionKey: SquarePermissions.CAN_VIEW_MENTEES,
  },
  {
    title: 'Activities',
    path: '/activities',
    icon: ActivitiesIcon,
    children: [
      {
        title: 'Clubs',
        path: '/clubs',
        permissionKey: Permission.EXTRA_CURRICULAR_ACTIVITIES_CLUBS,
      },
      {
        title: 'Events',
        path: '/events',
        permissionKey: Permission.EXTRA_CURRICULAR_ACTIVITIES_EVENTS,
      },
      {
        title: 'Reports',
        path: '/reports',
        permissionKey: Permission.EXTRA_CURRICULAR_ACTIVITIES_EVENTS,
      },
    ],
  },
  {
    title: 'Course Registrations',
    path: '/course-registrations',
    icon: CourseRegistrationIcon,
    permissionKey: Permission.COURSE_REGISTRATION_VIEW,
  },

  {
    title: 'Form Builder',
    path: '/forms',
    icon: FormBuilderIcon,
    children: [
      {
        title: 'Forms',
        path: '/forms',
        permissionKey: Permission.CAN_FORM_BUILDER_VIEW,
      },
      {
        title: 'Form Validation',
        path: '/form-validations',
        permissionKey: Permission.CAN_FORM_BUILDER_VIEW,
      },
    ],
  },
  {
    title: 'Infrastructure',
    path: '/infrastructure',
    icon: InfrastructureIcon,
    permissionKey: Permission.CAN_INFRASTRUCTURE_VIEW,
    children: [
      {
        title: 'Buildings',
        path: '/buildings',
        permissionKey: Permission.CAN_INFRASTRUCTURE_BUILDINGS_VIEW,
      },
      {
        title: 'Labs',
        path: '/labs',
        permissionKey: Permission.CAN_INFRASTRUCTURE_LABS_VIEW,
      },
      {
        title: 'Classrooms',
        path: '/classrooms',
        permissionKey: Permission.CAN_INFRASTRUCTURE_CLASSROOMS_VIEW,
      },
      {
        title: 'Equipments',
        path: '/equipments',
        permissionKey: Permission.CAN_INFRASTRUCTURE_EQUIPMENTS_VIEW,
      },
    ],
  },
  {
    title: 'Research Projects',
    path: '/research-projects',
    icon: EyeIcon,
    permissionKey: Permission.CAN_RESEARCH_PROJECT_VIEW,
  },
  {
    title: 'Surveys',
    path: '/surveys',
    icon: FormBuilderIcon,
    permissionKey: Permission.CAN_SURVEYS_VIEW,
  },
  {
    title: 'Feedback',
    path: '/feedback',
    icon: FormBuilderIcon,
    permissionKey: Permission.CAN_VIEW_FEEDBACKS,
    children: [
      {
        title: 'Faculty Feedbacks',
        path: '/faculty-feedbacks',
        permissionKey: Permission.CAN_VIEW_FEEDBACKS,
      },
      {
        title: 'Facility Feedbacks',
        path: '/facility-feedbacks',
        permissionKey: Permission.VIEW_FACILITY_FEEDBACKS,
      },
      {
        title: 'Feedback Questions',
        path: '/feedback-questions',
        permissionKey: Permission.CAN_VIEW_FEEDBACK_QUESTIONS,
      },
      // {
      //   title: 'Analytics',
      //   path: '/analytics',
      //   permissionKey: Permission.CAN_ADMIN_VIEW,
      // },
    ],
  },

  {
    title: 'Activity Logs',
    path: '/audit-logs',
    icon: LogsIcon,
    permissionKey: Permission.VIEW_AUDIT_LOGS,
  },
  {
    title: 'Configuration',
    path: '/settings',
    icon: SettingsIcon,
    permissionKey: Permission.CAN_CONFIGURATION_VIEW,
  },
]
