import {
  CardsGrid,
  DrawerButton,
  NoDataIllustration,
  PageContent,
  PageHeader,
  Permission,
  Spinner,
  ValidateAccess,
} from '@campxdev/shared'
import { Button } from '@mui/material'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { SERVICES } from 'services'
import { FeedbackFormStore } from '../store'
import UpsertFacilityFeedbackForm from './Forms/UpsertFacilityFeedbackForms'
import { FacilityFeedbackCard } from './components/FacilityFeedbackCard'

const FacilityFeedbacks = () => {
  const navigate = useNavigate()
  const { data, isLoading } = useQuery('facility-feedbacks', () =>
    SERVICES.facilityFeedbacks.getFacilityFeedbacks({}),
  )
  const freshState = FeedbackFormStore.useLocalCopyInitial()
  FeedbackFormStore.replace(freshState['initialState'])

  if (isLoading) return <Spinner />
  return (
    <div>
      <PageHeader
        title="Facility Feedback"
        actions={[
          <ValidateAccess
            key={0}
            accessKey={Permission.CREATE_FACILITY_FEEDBACK}
          >
            <DrawerButton
              key="1"
              title="New Facility Feedback"
              anchor={({ open }) => (
                <Button onClick={open}>Create Facility Feedback</Button>
              )}
              content={({ close }) => (
                <UpsertFacilityFeedbackForm data={null} close={close} />
              )}
            />
          </ValidateAccess>,
        ]}
      />
      <PageContent>
        <>
          {data.length ? (
            <CardsGrid cardWidth={350} gap={20}>
              {data?.map((item, index) => (
                <FacilityFeedbackCard key={index} data={item} />
              ))}
            </CardsGrid>
          ) : (
            <NoDataIllustration
              imageSrc="/illustrations/whiteboard.svg"
              message="No Faculty Feedbacks Found"
            />
          )}
        </>
      </PageContent>
    </div>
  )
}

export default FacilityFeedbacks
