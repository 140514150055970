import styled from '@emotion/styled'
import { Typography } from '@mui/material'

export const FeedbackStatuses = {
  CONFIGURATION_PENDING: 'configuration_pending',
  PUBLISH_PENDING: 'publish_pending',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
  PARTIALLY_PUBLISHED: 'partially_published',
}

export interface StyledStatus {
  status: string
  hasDot: boolean
}

export const getStatusColor = (status, theme) => {
  switch (status) {
    case FeedbackStatuses.CONFIGURATION_PENDING: {
      return theme.palette.common.yellow
    }
    case FeedbackStatuses.PUBLISH_PENDING: {
      return theme.palette.common.yellow
    }

    case FeedbackStatuses.PUBLISHED: {
      return theme.palette.common.green
    }

    case FeedbackStatuses.UNPUBLISHED: {
      return theme.palette.error.main
    }

    case FeedbackStatuses.PARTIALLY_PUBLISHED: {
      return theme.palette.common.green
    }
  }
}

export const StyledStatus = styled(Typography)<StyledStatus>(
  ({ theme, status, hasDot }) => ({
    ...(hasDot
      ? {
          marginBottom: '40px',
          '&:before': {
            content: '""',
            display: 'inline-block',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            background: getStatusColor(status, theme),
            marginRight: '10px',
          },
        }
      : {
          color: getStatusColor(status, theme),
        }),
  }),
)

export const FeedbackStatus = ({ status, hasDot = true }) => {
  switch (status) {
    case FeedbackStatuses.CONFIGURATION_PENDING: {
      return (
        <StyledStatus status={status} hasDot={hasDot}>
          CONFIGURATION PENDING
        </StyledStatus>
      )
    }
    case FeedbackStatuses.PUBLISH_PENDING: {
      return (
        <StyledStatus status={status} hasDot={hasDot}>
          PUBLISH PENDING
        </StyledStatus>
      )
    }

    case FeedbackStatuses.PUBLISHED: {
      return (
        <StyledStatus status={status} hasDot={hasDot}>
          PUBLISHED
        </StyledStatus>
      )
    }

    case FeedbackStatuses.UNPUBLISHED: {
      return (
        <StyledStatus status={status} hasDot={hasDot}>
          UNPUBLISHED
        </StyledStatus>
      )
    }

    case FeedbackStatuses.PARTIALLY_PUBLISHED: {
      return (
        <StyledStatus status={status} hasDot={hasDot}>
          PARTIALLY PUBLISHED
        </StyledStatus>
      )
    }
  }
}
