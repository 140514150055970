import { getPropertyValueFromObject } from 'constants/constants'
import _ from 'lodash'

export const subjectColors: { light?: string; dark: string }[] = [
  {
    dark: '#88B053',
    light: '#E6EEDC',
  },
  {
    dark: '#4BAABE',
    light: '#DAEDF1',
  },
  {
    dark: '#573DAB',
    light: '#DCD7ED',
  },
  {
    dark: '#ED9035',
    light: '#FBE8D5',
  },
  {
    dark: '#E35C33',
    light: '#F9DDD5',
  },
  {
    dark: '#EA4A6B',
    light: '#FADAE0',
  },
  {
    dark: '#F8D759',
    light: '#FDF6DD',
  },
  {
    dark: '#D4483E',
    light: '#F6D9D7',
  },
  {
    dark: '#8234A4',
    light: '#E5D5EC',
  },
  {
    dark: '#3A4CA5',
    light: '#D6DAEC',
  },
  {
    dark: '#FFA000',
    light: '#FFECB3',
  },
  {
    dark: '#795548',
    light: '#D7CCC8',
  },
  {
    dark: '#FF4081',
    light: '#F48FB1',
  },
  {
    dark: '#33691E',
    light: '#C5E1A5',
  },
]

type Id = {
  id: number | string
}

export const colorMap = (ids: Id[]) => {
  return _.uniq(ids)
    ?.map((item, index) => ({
      subject: item,
      color: subjectColors[index]?.light,
    }))
    .reduce(
      (acc, curr: any) => ({
        ...acc,
        [curr.subject]: curr.color,
      }),
      {},
    )
}

export const findMinMaxTimes = (
  fromTimePath: string,
  toTimePath: string,
  timetableSlots: any[] = [],
): { minFromTime: string; maxToTime: string } => {
  if (!timetableSlots.length)
    return { minFromTime: '07:00:00', maxToTime: '20:00:00' }

  let minFromTime = getPropertyValueFromObject(timetableSlots[0], fromTimePath)
  let maxToTime = getPropertyValueFromObject(timetableSlots[0], toTimePath)

  timetableSlots.forEach((slot) => {
    const fromTime = getPropertyValueFromObject(slot, fromTimePath)
    const toTime = getPropertyValueFromObject(slot, toTimePath)

    if (fromTime <= minFromTime) {
      minFromTime = fromTime
    }

    if (toTime >= maxToTime) {
      maxToTime = toTime
    }
  })
  return {
    minFromTime: minFromTime,
    maxToTime: maxToTime,
  }
}

export const findMissingWeekdaysIndices = (weekdays: string[]): number[] => {
  const allWeekdays = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
  ]
  const presentWeekdays = new Set(weekdays)
  const missingWeekdayIndices = []

  allWeekdays.forEach((day, index) => {
    if (!presentWeekdays.has(day)) {
      missingWeekdayIndices.push(index)
    }
  })
  return missingWeekdayIndices.length == 7 ? [] : missingWeekdayIndices
}
