import { axios } from '@campxdev/shared'

export const deleteTimeTable = (id: number) => {
  return axios.delete(`/square/timetable/${id}`).then((res) => res.data)
}

export const fetchTimeTableSlots = (timetableSlotTemplateId: number) => {
  return axios
    .get('/square/timetable-slots', {
      params: {
        templateId: timetableSlotTemplateId,
      },
    })
    .then((res) => res.data)
}

export const fetchRooms = () => {
  return axios.get('exams/rooms').then((res) => res.data)
}

export const fetchTimeTableFormOptions = async (
  timeSlotsTemplateId: number,
) => {
  const timeSlots = await fetchTimeTableSlots(timeSlotsTemplateId)
  const rooms = await fetchRooms()

  return {
    timeSlots,
    rooms,
  }
}

export const fetchSubjectGroups = ({
  classroomId,
  subjectId,
}: {
  classroomId: number
  subjectId: number
}) => {
  return axios
    .get(`/square/classrooms/subject-groups`, {
      params: {
        classroomId: classroomId,
        subjectId: subjectId,
      },
    })
    .then((res) => res.data)
}
