import { JsonPreview, PageContent, PageHeader, Spinner } from '@campxdev/shared'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { SERVICES } from 'services'

export default function FormResponses() {
  const params = useParams()
  const { data, isLoading } = useQuery('survey-responses', () =>
    SERVICES.surveys.getResponses({
      surveyId: params.surveyId,
    }),
  )
  if (isLoading) return <Spinner />
  return (
    <div>
      <PageHeader title="Form Responses" />
      <PageContent>
        <JsonPreview data={data} />
      </PageContent>
    </div>
  )
}
