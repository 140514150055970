import { Permission } from '@campxdev/shared'
import {
  ClassroomsIcon,
  PeopleIcon,
  SettingsIcon,
} from 'layouts/DashboardLayout/icons'
import { AcademicsIcon } from './icons'

const basePath = '/settings'
export const settingsMenu = [
  {
    title: 'People',
    path: 'application-profiles',
    icon: PeopleIcon,
    permissionKey: Permission.CAN_MANAGE_SQUARE_PROFILE_PERMISSIONS_VIEW,
  },
  {
    path: basePath + '/academics',
    title: 'Academics',
    icon: AcademicsIcon,

    children: [
      {
        path: '/curriculums',
        title: 'Curriculums',
        permissionKey: Permission.CAN_CURRICULUMS_VIEW,
      },

      {
        path: '/academic-calendar',
        title: 'Academic Calendar',
        permissionKey: Permission.CAN_ACADEMIC_CALENDAR_TIMELINE_VIEW,
      },
      {
        path: '/holidays',
        title: 'Holidays',
        permissionKey: Permission.CAN_ACADEMIC_CALENDAR_TIMELINE_VIEW,
      },
    ],
  },

  {
    path: basePath + '/assessments',
    title: 'Assessments',
    icon: SettingsIcon,
    children: [
      {
        path: '/assessment-types',
        title: 'Assessment Types',
        permissionKey: Permission.ASSESSMENT_TYPE_VIEW,
      },
      {
        path: '/assessment-templates',
        title: 'Grading Templates',
        permissionKey: Permission.ASSESSMENT_TYPE_VIEW,
      },
    ],
  },

  {
    title: 'Classrooms',
    path: 'classrooms',
    icon: ClassroomsIcon,
    children: [
      {
        path: '/time-slot-templates',
        title: 'Time Slot Templates',
        permissionKey: Permission.CAN_TIME_SLOTS_VIEW,
      },
      {
        path: '/sections',
        title: 'Sections',
        permissionKey: Permission.CAN_DEPARTMENTS_VIEW,
      },
    ],
  },
]
