import { Card, useHistory } from '@campxdev/shared'
import { Box, Divider, Stack, Typography } from '@mui/material'

export default function ClassroomCard({ data }) {
  const history = useHistory()
  return (
    <Box
      sx={{
        '& .MuiCardHeader-root': {
          paddingBottom: '10px',
        },
      }}
    >
      <Card
        title={data?.name}
        footer={{
          onClick: () => {
            history.push(`/classrooms/${data?.id}`)
          },
        }}
      >
        <Stack
          direction={'row'}
          gap={1}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Typography variant="subtitle1">{`${data?.noOfStudents} Students`}</Typography>
          {/* <Typography variant="subtitle1">{`Class Avg. Attendance : ${data?.averageAttendance}`}</Typography> */}
        </Stack>
      </Card>
    </Box>
  )
}
