import { axios } from '@campxdev/shared'

export const branches = {
  async fetchBranches(params?: any) {
    const res = await axios.get('/paymentx/domain/programs', {
      params: {
        ...params,
      },
    })
    return res.data
  },
}
