import { createSlice } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { map, switchMap } from 'rxjs/operators'
import { axios } from '@campxdev/shared'

export interface EvaluationRulesState {
  loading: boolean
  data: any
  error: any
  masterSubjectTypes: Array<any>
  disabledAddSubjectTypeButton: boolean
  subjectTypes: SubjectTypes[]
}

export interface SubjectTypes {
  id?: number
  type: string
  masterSubjectTypeId: number
  curriculumId: number
  evaluationRules: evaluationRules[]
}

export interface evaluationRules {
  id?: number
  curriculumId: number
  subjectTypeId: number
  evaluationTypeId: number
  maxMarks: number
  passPercentage: number
  gradedBy: string
  isNew: boolean
  isEditing: boolean
}

const initialState: EvaluationRulesState = {
  loading: true,
  data: [],
  error: null,
  disabledAddSubjectTypeButton: false,
  subjectTypes: [],
  masterSubjectTypes: [],
}

const evaluationRulesSlice = createSlice({
  name: 'evaluationRules',
  initialState: initialState,
  reducers: {
    fetchEvaluationRules(state, action: any) {
      state.loading = true
    },
    fetchedEvaluationRules(state, action: any) {
      state.loading = false
      state.data = action.payload.data
      state.masterSubjectTypes = action.payload.masterSubjectTypes
      state.subjectTypes = action.payload.data.map((item) => ({
        ...item,
        evaluationRules: item.evaluationRules.map((item) => ({
          ...item,
          isEditing: false,
        })),
      }))
    },
    updateValue(state, action: any) {
      let index = action.payload.index
      let key = action.payload.key
      let value = action.payload.value
      state.subjectTypes[action.payload.subjectTypeIndex].evaluationRules[
        index
      ][key] = value
    },
    addEvaluationRule(state, action) {
      state.subjectTypes[action.payload.subjectTypeIndex].evaluationRules =
        state.subjectTypes[
          action.payload.subjectTypeIndex
        ].evaluationRules.filter((f) => f)
      let id =
        state.subjectTypes[action.payload.subjectTypeIndex].evaluationRules
          .length + 1
      state.subjectTypes[action.payload.subjectTypeIndex].evaluationRules = [
        ...state.subjectTypes[action.payload.subjectTypeIndex].evaluationRules,
        {
          curriculumId:
            state.subjectTypes[action.payload.subjectTypeIndex].curriculumId,
          subjectTypeId: state.subjectTypes[action.payload.subjectTypeIndex].id,
          evaluationTypeId: 1,
          maxMarks: 5,
          passPercentage: 30,
          isNew: true,
          isEditing: true,
          gradedBy: 'Faculty',
        },
      ]
    },
    removeEvaluationRule(state, action) {
      delete state.subjectTypes[action.payload.subjectTypeIndex]
        .evaluationRules[action.payload.index]
    },
    editEvaluationRule(state, action) {
      state.subjectTypes[action.payload.subjectTypeIndex].evaluationRules[
        action.payload.index
      ].isEditing = true
    },
    updateEvaluationRule(state, action) {
      state.subjectTypes[action.payload.subjectTypeIndex].evaluationRules[
        action.payload.index
      ].isEditing = false
    },
    saveAssessmentEvaluationRule(state, action) {
      state.subjectTypes[action.payload.subjectTypeIndex].evaluationRules[
        action.payload.index
      ] = action.payload.data
    },
    onCancel(state, action) {
      state.subjectTypes[action.payload.subjectTypeIndex].evaluationRules[
        action.payload.index
      ].isEditing = false
    },
    updateSubjectType(state, action) {
      state.subjectTypes[action.payload.subjectTypeIndex][action.payload.key] =
        action.payload.value
    },
    removeSubjectType(state, action) {
      delete state.subjectTypes[action.payload.subjectTypeIndex]
    },
    addSubjectType(state, action) {
      state.disabledAddSubjectTypeButton = true
      state.subjectTypes = state.subjectTypes.filter(
        (subjectType) => subjectType,
      )
      state.subjectTypes.push({
        masterSubjectTypeId: 1,
        type: ``,
        curriculumId: action.payload.curriculumId,
        evaluationRules: [],
      })
    },
    addedSubjectType(state, action) {
      state.subjectTypes[state.subjectTypes.length - 1] = {
        ...action.payload,
        assessments: [],
      }
    },
    updateSubjectTypeData(state, action) {
      state.subjectTypes[action.payload.subjectTypeIndex] = action.payload.data
    },
    enableAddSubjectTypeButton(state) {
      state.disabledAddSubjectTypeButton = false
    },
  },
})

export function fetchEvaluationRulesEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchEvaluationRules.type),
    switchMap(async (action: any) => {
      const res = await axios.get(
        `/square/evaluation-rules?curriculumId=${action.payload.curriculumId}`,
      )
      const masterrRes = await axios.get('/square/subject-types/master')
      return {
        data: res.data,
        masterSubjectTypes: masterrRes.data,
      }
    }),
    map(fetchedEvaluationRules),
  )
}

export const {
  fetchedEvaluationRules,
  fetchEvaluationRules,
  updateValue,
  updateEvaluationRule,
  addEvaluationRule,
  onCancel,
  editEvaluationRule,
  removeEvaluationRule,
  saveAssessmentEvaluationRule,
  updateSubjectType,
  removeSubjectType,
  addSubjectType,
  updateSubjectTypeData,
  enableAddSubjectTypeButton,
} = evaluationRulesSlice.actions
export default evaluationRulesSlice.reducer
