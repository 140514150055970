import { axios } from '@campxdev/shared'

const ENDPOINT = '/square/facility-feedbacks'

export const facilityFeedbacks = {
  async upsertFacilityFeedback({ formData }) {
    const res = !formData._id
      ? await axios.post(ENDPOINT, {
          ...formData,
        })
      : await axios.put(`${ENDPOINT}/${formData._id}`, {
          ...formData,
        })
    return res
  },

  async deleteFacilityFeedback(id) {
    const res = await axios.delete(`${ENDPOINT}/${id}`)
    return res.data
  },

  async getFacilityFeedbacks(params) {
    const res = await axios.get(ENDPOINT, {
      params: params,
    })
    return res.data.data
  },

  async getFacilityFeedbackById(id) {
    const res = await axios.get(`${ENDPOINT}/${id}`)
    return res.data
  },

  async updateFacilityFeedbackStatus(data) {
    const res = await axios.put(`${ENDPOINT}/${data._id}/change-status`, data)
    return res
  },

  async updateFacilityFeedbackFormConfiguration(data) {
    const res = await axios.put(`${ENDPOINT}/${data._id}/configure-form`, data)
    return data
  },
  async getFacilityFeedbackDistributionById(id) {
    const res = await axios.get(
      `${ENDPOINT}/${id}/target-audience-distribution`,
    )
    return res.data
  },
  async addFacilityFeedbackTargetAudience({ id, body }) {
    return axios.post(`${ENDPOINT}/${id}/add-target-audience`, body)
  },
  async deleteFacilityFeedbackTargetAudience({ id, body }) {
    return axios.post(`${ENDPOINT}/${id}/delete-target-audience`, body)
  },

  async getFacilityFeedbackDistributionByClassroom(query, id) {
    const res = await axios.get(
      `${ENDPOINT}/${id}/classroom-wise-distribution`,
      {
        params: query,
      },
    )
    return res.data
  },
}
