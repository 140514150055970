import { Image, Spinner, axios } from '@campxdev/shared'
import { Box, Typography } from '@mui/material'
import { CalendareIcon, LocationIcon } from 'layouts/DashboardLayout/icons'
import moment from 'moment'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Members } from './EventDetails'
import {
  StyledCard,
  StyledContainer,
  StyledDescriptionBox,
  StyledTypographyBox,
} from './styles'

function PrintEventReport() {
  const { id } = useParams()

  const { data, isLoading } = useQuery('event-details', () =>
    axios.get(`/square/events/details/${id}`).then((res) => res.data),
  )
  if (isLoading) {
    return <Spinner />
  }

  return (
    <Box sx={{ margin: '20px' }}>
      <EventDetailsHeader data={data} />
      <States data={data} />
      <Members data={data?.participants} showExport={false} />
    </Box>
  )
}

export const EventDetailsHeader = ({ data }) => {
  return (
    <>
      <StyledContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{ minWidth: '160px', minHeight: '160px', alignSelf: 'center' }}
          >
            <Image
              alt=""
              radius={'10px'}
              src={data?.imageUrl}
              width={'160px'}
              height={'160px'}
              fit={'cover'}
            />
          </Box>
          <Box sx={{ marginTop: '20px' }}>
            <StyledTypographyBox>
              <LocationIcon />
              {data?.venue}
            </StyledTypographyBox>
            <StyledTypographyBox>
              <CalendareIcon />
              {moment(data?.time).format('DD MMMM, YYYY hh:mm A')}
            </StyledTypographyBox>
          </Box>
        </Box>
        <StyledDescriptionBox>
          <Box>
            <Typography variant="h6" sx={{ fontSize: '1rem', maxWidth: '50%' }}>
              {data?.name}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: '1rem' }}
            >{`Posted by ${data?.organizer} | ${moment(
              moment(data?.createdAt).format('YYYY-MM-DD, HH:mm:ss'),
            ).fromNow()}`}</Typography>

            <Box my={1}>
              <Typography variant="subtitle2" sx={{ fontSize: '0.8rem' }}>
                Description
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '0.9rem' }}>
                {data?.description}
              </Typography>
            </Box>
            <Box my={1}>
              <Typography variant="subtitle2" sx={{ fontSize: '0.8rem' }}>
                Event Report
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '0.9rem' }}>
                {data?.eventReport}
              </Typography>
            </Box>
          </Box>
        </StyledDescriptionBox>
      </StyledContainer>
    </>
  )
}

const States = ({ data }) => {
  return (
    <>
      <StyledCard gap={'20px'}>
        <Typography variant="h3">Total Participation </Typography>
        <Typography>{data?.participants?.length}</Typography>
      </StyledCard>
    </>
  )
}

export default PrintEventReport
