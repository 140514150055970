import {
  Card,
  DropDownButton,
  LabelValue,
  Permission,
  useConfirm,
  useErrorModal,
} from '@campxdev/shared'
import { queryClient } from '@campxdev/shared/src/contexts/QueryClientProvider'
import { MoreVert } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { LockIcon } from 'assets/icons'
import {
  FeedbackStatus,
  FeedbackStatuses,
} from 'pages/Feedback/FacultyFeedbacks/components/FeedbackStatus'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SERVICES } from 'services'

export const ClassroomFeedbackStatus = ({
  classroomPublished,
  feedbackStatus,
}) => {
  switch (feedbackStatus) {
    case FeedbackStatuses.PUBLISH_PENDING:
    case FeedbackStatuses.CONFIGURATION_PENDING:
      return <FeedbackStatus status={feedbackStatus} hasDot={false} />

    default: {
      const classroomFeedbackStatus = classroomPublished
        ? FeedbackStatuses.PUBLISHED
        : FeedbackStatuses.UNPUBLISHED

      return <FeedbackStatus status={classroomFeedbackStatus} hasDot={false} />
    }
  }
}

export const ClassroomCard = ({ classroomCardData, index, feedbackStatus }) => {
  const { isConfirmed } = useConfirm()
  const navigate = useNavigate()
  const params = useParams()
  const errorModal = useErrorModal()

  const { mutate, isLoading: changingStatus } = useMutation(
    SERVICES.facilityFeedbacks.updateFacilityFeedbackStatus,
    {
      onSuccess: (data) => {
        toast.success(data.data.message)
        queryClient.invalidateQueries(
          'classroom-wise-feedback-analysis-facility',
        )
        queryClient.invalidateQueries('facility-feedback')
      },
      onError: (err) => {
        errorModal({ error: err })
      },
    },
  )
  const handlePublish = async (classroomId) => {
    const confirmed = await isConfirmed(`Are you sure you want to Publish?`)
    if (!confirmed) return
    mutate({
      _id: params.id,
      classroomId,
      status: FeedbackStatuses.PUBLISHED,
    })
  }

  const handleUnpublish = async (classroomId) => {
    const confirmed = await isConfirmed('Are you sure you want to Unpublish?')
    if (!confirmed) return
    mutate({
      _id: params.id,
      classroomId,
      status: FeedbackStatuses.UNPUBLISHED,
    })
  }

  return (
    <Card
      title={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4" fontWeight="600">
            {classroomCardData?.classroom?.name}
          </Typography>
          <Stack direction="row" alignItems="center">
            <ClassroomFeedbackStatus
              classroomPublished={classroomCardData?.published}
              feedbackStatus={feedbackStatus}
            />
            <DropDownButton
              key={3}
              menu={[
                feedbackStatus != FeedbackStatuses.CONFIGURATION_PENDING
                  ? classroomCardData?.published !== true
                    ? {
                        label: 'Publish',
                        onClick: () =>
                          handlePublish(classroomCardData?.classroom?.id),
                        permissionKey: Permission.CAN_CHANGE_FEEDBACK_STATUS,
                      }
                    : {
                        label: 'Unpublish',
                        onClick: () =>
                          handleUnpublish(classroomCardData?.classroom?.id),
                        permissionKey: Permission.CAN_CHANGE_FEEDBACK_STATUS,
                      }
                  : {
                      label: 'Configure Form to Publish',
                      icon: <LockIcon />,
                      onClick: () => navigate('configure-form/facility'),
                    },
              ]}
              icon={{
                icon: <MoreVert />,
                outlined: false,
                iconProps: {
                  sx: {
                    color: 'black',
                  },
                },
              }}
            />
          </Stack>
        </Stack>
      }
      key={index}
      subheader={
        <Box color="black">
          <Box fontWeight="500">
            {`${classroomCardData?.classroom?.batchName} (${classroomCardData?.classroom?.course?.courseName})`}
          </Box>
        </Box>
      }
    >
      <Stack direction={'column'} gap={2}>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <LabelValue
            label="Responses"
            value={`${classroomCardData?.submittedCount}/${classroomCardData?.totalCount}`}
          />
          <LabelValue
            label="Total Students"
            value={classroomCardData?.studentCount ?? 0}
          />
        </Box>
      </Stack>
    </Card>
  )
}
