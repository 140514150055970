import { axios } from '@campxdev/shared'

export const fetchSubjectDetails = (classroomId, subjectId) => {
  return axios
    .get(`/square/classrooms/${classroomId}/subjects/${subjectId}`)
    .then((res) => res.data)
}

export const fetchStudentDetails = (params) => {
  return axios
    .get(`/square/student-assessments/student`, {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
}

export const fetchDetails = async (classroomId, subjectId, studentId) => {
  const subjectData = await fetchSubjectDetails(classroomId, subjectId)
  const studentData = await fetchStudentDetails({
    subjectId: subjectId,
    classroomId: classroomId,
    studentId: studentId,
  })
  return {
    subjectData: subjectData,
    studentData: studentData,
  }
}
