import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { Providers } from '@campxdev/shared'
import { enableMapSet } from 'immer'
enableMapSet()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Providers>
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </Providers>,
)
reportWebVitals()
