import { urlTenantKey } from '@campxdev/shared/src/contexts/Providers'
import { lazy } from 'react'

const BirdsEyeViewDashboard = lazy(() => import('./Dashboard'))

const PaymentsDashboard = lazy(() => import('./Payments/Payments'))
const PrincipalDashboard = lazy(() => import('./PrincipalDashboard'))
const DepartmentDashboard = lazy(() => import('./DepartmentDashboard'))
export {
  BirdsEyeViewDashboard,
  DepartmentDashboard,
  PaymentsDashboard,
  PrincipalDashboard,
}
