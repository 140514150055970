import React from 'react'

export const AcademicsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14.682"
      viewBox="0 0 16 14.682"
    >
      <path
        id="graduation-cap_1_"
        data-name="graduation-cap (1)"
        d="M14.693,23.689,9.777,21.347a3.484,3.484,0,0,0-3.522-.03L1.284,23.689c-.019.009-.038.019-.056.03a2.461,2.461,0,0,0,.056,4.3l1.371.653v3.267A3.338,3.338,0,0,0,5,35.128a10.371,10.371,0,0,0,2.987.408,10.372,10.372,0,0,0,2.987-.4,3.338,3.338,0,0,0,2.346-3.185V28.674l1.333-.637v5.5a.667.667,0,1,0,1.333,0v-8A2.232,2.232,0,0,0,14.693,23.689Zm-2.7,8.256a2,2,0,0,1-1.4,1.909,9.061,9.061,0,0,1-2.6.348,9.061,9.061,0,0,1-2.6-.348,2,2,0,0,1-1.4-1.909V29.311L6.2,30.364a3.515,3.515,0,0,0,1.8.493,3.333,3.333,0,0,0,1.718-.463l2.267-1.083Zm2.133-5.127-5.028,2.4a2.2,2.2,0,0,1-2.26-.03l-4.919-2.34a1.128,1.128,0,0,1-.025-1.972l5-2.387a2.2,2.2,0,0,1,2.26.03l4.916,2.342a1.152,1.152,0,0,1,.593.993,1.133,1.133,0,0,1-.533.963Z"
        transform="translate(0.011 -20.854)"
        fill="#fff"
      />
    </svg>
  )
}

export const AdmissionsIcon = () => {
  return (
    <svg
      id="user-add"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Path_1381"
        data-name="Path 1381"
        d="M20.667,10H19.333V8.667a.667.667,0,1,0-1.333,0V10H16.667a.667.667,0,0,0,0,1.333H18v1.333a.667.667,0,1,0,1.333,0V11.333h1.333a.667.667,0,0,0,0-1.333Z"
        transform="translate(-5.333 -2.667)"
        fill="#fff"
      />
      <path
        id="Path_1382"
        data-name="Path 1382"
        d="M7,8A4,4,0,1,0,3,4,4,4,0,0,0,7,8ZM7,1.333A2.667,2.667,0,1,1,4.333,4,2.667,2.667,0,0,1,7,1.333Z"
        transform="translate(-1 0)"
        fill="#fff"
      />
      <path
        id="Path_1383"
        data-name="Path 1383"
        d="M6,14a6.007,6.007,0,0,0-6,6,.667.667,0,1,0,1.333,0,4.667,4.667,0,1,1,9.333,0A.667.667,0,0,0,12,20,6.007,6.007,0,0,0,6,14Z"
        transform="translate(0 -4.667)"
        fill="#fff"
      />
    </svg>
  )
}

export const HrmsIcon = () => {
  return (
    <svg
      id="user-add"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="users-alt"
        d="M8,10.667A2.667,2.667,0,1,1,10.667,8,2.667,2.667,0,0,1,8,10.667Zm0-4A1.333,1.333,0,1,0,9.333,8,1.333,1.333,0,0,0,8,6.667Zm4,8.667a4,4,0,1,0-8,0,.667.667,0,1,0,1.333,0,2.667,2.667,0,1,1,5.333,0,.667.667,0,0,0,1.333,0Zm0-10a2.667,2.667,0,1,1,2.667-2.667A2.667,2.667,0,0,1,12,5.333Zm0-4a1.333,1.333,0,1,0,1.333,1.333A1.333,1.333,0,0,0,12,1.333ZM16,10a4,4,0,0,0-4-4,.667.667,0,1,0,0,1.333A2.667,2.667,0,0,1,14.667,10,.667.667,0,0,0,16,10ZM4,5.333A2.667,2.667,0,1,1,6.667,2.667,2.667,2.667,0,0,1,4,5.333Zm0-4A1.333,1.333,0,1,0,5.333,2.667,1.333,1.333,0,0,0,4,1.333ZM1.333,10A2.667,2.667,0,0,1,4,7.333.667.667,0,0,0,4,6a4,4,0,0,0-4,4,.667.667,0,0,0,1.333,0Z"
        fill="#fff"
      />
    </svg>
  )
}

export const ManageUsersIcon = () => {
  return (
    <svg
      id="mode-portrait"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Path_1413"
        data-name="Path 1413"
        d="M20.667,16a.667.667,0,0,0-.667.667V18a2,2,0,0,1-2,2H16.667a.667.667,0,1,0,0,1.333H18A3.337,3.337,0,0,0,21.333,18V16.667A.667.667,0,0,0,20.667,16Z"
        transform="translate(-5.333 -5.333)"
        fill="#fff"
      />
      <path
        id="Path_1414"
        data-name="Path 1414"
        d="M.667,5.333a.667.667,0,0,0,.667-.667V3.333a2,2,0,0,1,2-2H4.667A.667.667,0,0,0,4.667,0H3.333A3.337,3.337,0,0,0,0,3.333V4.667a.667.667,0,0,0,.667.667Z"
        fill="#fff"
      />
      <path
        id="Path_1415"
        data-name="Path 1415"
        d="M4.667,20H3.333a2,2,0,0,1-2-2V16.667a.667.667,0,1,0-1.333,0V18a3.337,3.337,0,0,0,3.333,3.333H4.667a.667.667,0,0,0,0-1.333Z"
        transform="translate(0 -5.333)"
        fill="#fff"
      />
      <path
        id="Path_1416"
        data-name="Path 1416"
        d="M18,0H16.667a.667.667,0,1,0,0,1.333H18a2,2,0,0,1,2,2V4.667a.667.667,0,0,0,1.333,0V3.333A3.337,3.337,0,0,0,18,0Z"
        transform="translate(-5.333)"
        fill="#fff"
      />
      <path
        id="Path_1417"
        data-name="Path 1417"
        d="M10.667,8.333A2.667,2.667,0,1,0,8,5.667a2.667,2.667,0,0,0,2.667,2.667Zm0-4A1.333,1.333,0,1,1,9.333,5.667,1.333,1.333,0,0,1,10.667,4.333Z"
        transform="translate(-2.667 -1)"
        fill="#fff"
      />
      <path
        id="Path_1418"
        data-name="Path 1418"
        d="M13.667,17.667A.667.667,0,0,0,14.333,17a4,4,0,0,0-4-4H9a4,4,0,0,0-4,4,.667.667,0,1,0,1.333,0A2.667,2.667,0,0,1,9,14.333h1.333A2.667,2.667,0,0,1,13,17,.667.667,0,0,0,13.667,17.667Z"
        transform="translate(-1.667 -4.333)"
        fill="#fff"
      />
    </svg>
  )
}

export const ExamsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      viewBox="0 0 12 16"
    >
      <path
        id="test_5_"
        data-name="test (5)"
        d="M11.667,11.333a.667.667,0,0,1,0,1.333H11a.667.667,0,0,1,0-1.333ZM10.333,10a.667.667,0,0,1-.667-.667V8.667H8.333v.667A.667.667,0,0,1,7,9.333V6.667a2,2,0,1,1,4,0V9.333A.667.667,0,0,1,10.333,10ZM9.667,7.333V6.667a.667.667,0,1,0-1.333,0v.667ZM8.526,10.867,7.451,11.953a.167.167,0,0,1-.229.007l-.411-.427a.668.668,0,0,0-.956.933l.417.429a1.5,1.5,0,0,0,2.124,0l1.077-1.1a.667.667,0,0,0-.948-.933ZM15,4.438v8.229A3.337,3.337,0,0,1,11.667,16H6.333A3.337,3.337,0,0,1,3,12.667V3.333A3.337,3.337,0,0,1,6.333,0h4.229a3.313,3.313,0,0,1,2.357.977l1.1,1.1A3.313,3.313,0,0,1,15,4.438ZM11.977,1.919a2.028,2.028,0,0,0-.31-.253V3.333h1.667a2.028,2.028,0,0,0-.253-.31Zm1.69,2.747h-2a1.333,1.333,0,0,1-1.333-1.333v-2h-4a2,2,0,0,0-2,2v9.333a2,2,0,0,0,2,2h5.333a2,2,0,0,0,2-2Z"
        transform="translate(-3 0)"
        fill="#fff"
      />
    </svg>
  )
}

export const OrgIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.2"
      height="16.214"
      viewBox="0 0 16.2 16.214"
    >
      <path
        id="school_1_"
        data-name="school (1)"
        d="M12.667,4H9.547a2.633,2.633,0,0,0-.881-.408V3.331l2.353-1.1a.667.667,0,0,0,0-1.133L8.887.1a1.091,1.091,0,0,0-1.553.992v2.5A2.625,2.625,0,0,0,6.453,4H3.333A3.337,3.337,0,0,0,0,7.331V14a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V7.331A3.337,3.337,0,0,0,12.667,4Zm1.877,2.667H13.1a2.011,2.011,0,0,1-1.414-.586l-.748-.747h1.724A2,2,0,0,1,14.544,6.665ZM3.333,5.331H5.057l-.748.747A2.011,2.011,0,0,1,2.9,6.665H1.456A2,2,0,0,1,3.333,5.331Zm5.333,9.333H7.333v-2a.667.667,0,1,1,1.333,0Zm5.333,0H10v-2a2,2,0,1,0-4,0v2H2A.667.667,0,0,1,1.333,14V8H2.9a3.312,3.312,0,0,0,2.357-.977l1.805-1.8a1.333,1.333,0,0,1,1.885,0l1.805,1.8A3.312,3.312,0,0,0,13.1,8h1.562v6A.667.667,0,0,1,14,14.665ZM9.333,8A1.333,1.333,0,1,1,8,6.665,1.333,1.333,0,0,1,9.333,8Z"
        transform="translate(0.1 0.116)"
        fill="#fff"
        stroke="#121212"
        strokeWidth="0.2"
      />
    </svg>
  )
}

export const LibraryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.207"
      height="16.294"
      viewBox="0 0 16.207 16.294"
    >
      <path
        id="books"
        d="M15.851,12.979,11.137,1.213A2,2,0,0,0,8.531.117L7.915.369a2.015,2.015,0,0,0-.453.268A1.986,1.986,0,0,0,6-.011H2a2,2,0,0,0-2,2V13.984a2,2,0,0,0,2,2H6a2,2,0,0,0,2-2V5.917l3.534,8.824a2.005,2.005,0,0,0,2.6,1.094l.617-.252a2.008,2.008,0,0,0,1.1-2.6ZM9.045,4.952,10.9,4.2l2.779,6.938-1.851.754ZM8.418,1.6l.616-.252a.667.667,0,0,1,.868.362l.5,1.247-1.851.754-.5-1.243A.667.667,0,0,1,8.419,1.6ZM1.333,4.654h2v6.664h-2Zm3.332,0h2v6.664h-2Zm2-2.666V3.322h-2v-2H6A.667.667,0,0,1,6.664,1.989ZM2,1.323H3.332v2h-2V1.989A.667.667,0,0,1,2,1.323ZM1.333,13.984V12.651h2v2H2A.667.667,0,0,1,1.333,13.984ZM6,14.65H4.665v-2h2v1.333A.667.667,0,0,1,6,14.65Zm8.616-.66a.663.663,0,0,1-.363.359h0l-.617.252a.667.667,0,0,1-.866-.36l-.446-1.114,1.851-.754.443,1.107a.658.658,0,0,1,0,.51Z"
        transform="translate(0.1 0.204)"
        fill="#fff"
        stroke="#121212"
        strokeWidth="0.2"
      />
    </svg>
  )
}

export const CalenderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g id="calendar" transform="translate(0 0)">
        <path
          id="Path_14982"
          data-name="Path 14982"
          d="M12.667,1.333H12V.667a.667.667,0,0,0-1.333,0v.667H5.333V.667A.667.667,0,1,0,4,.667v.667H3.333A3.337,3.337,0,0,0,0,4.667v8A3.337,3.337,0,0,0,3.333,16h9.333A3.337,3.337,0,0,0,16,12.667v-8A3.337,3.337,0,0,0,12.667,1.333ZM1.333,4.667a2,2,0,0,1,2-2h9.333a2,2,0,0,1,2,2v.667H1.333Zm11.333,10H3.333a2,2,0,0,1-2-2v-6H14.667v6A2,2,0,0,1,12.667,14.667Z"
          transform="translate(0 0)"
          fill="#fff"
        />
        <circle
          id="Ellipse_564"
          data-name="Ellipse 564"
          cx="1"
          cy="1"
          r="1"
          transform="translate(7 9)"
          fill="#fff"
        />
        <circle
          id="Ellipse_565"
          data-name="Ellipse 565"
          cx="1"
          cy="1"
          r="1"
          transform="translate(3.667 9)"
          fill="#fff"
        />
        <circle
          id="Ellipse_566"
          data-name="Ellipse 566"
          cx="1"
          cy="1"
          r="1"
          transform="translate(10.333 9)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export const ImportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15.325"
      viewBox="0 0 16 15.325"
    >
      <g id="Group_828" data-name="Group 828" transform="translate(1176 6885)">
        <path
          id="cloud-download"
          d="M12.232,5.241a.725.725,0,0,1-.481-.49,5.332,5.332,0,0,0-10.323.561,5.1,5.1,0,0,0,.533,3.452A3.631,3.631,0,0,0,.037,12.531a3.894,3.894,0,0,0,3.749,3.132H5.33a.666.666,0,1,0,0-1.333H3.786a2.539,2.539,0,0,1-2.43-1.987,2.312,2.312,0,0,1,1.222-2.4,1.328,1.328,0,0,0,.529-1.789,4,4,0,1,1,7.371-3.008,2.066,2.066,0,0,0,1.363,1.368,3.979,3.979,0,0,1,2.807,4.139,3.584,3.584,0,0,1-.74,1.939.667.667,0,0,0,1.057.812,4.976,4.976,0,0,0,1.013-2.647,5.3,5.3,0,0,0-3.745-5.516Z"
          transform="translate(-1175.996 -6886.005)"
          fill="#fff"
        />
        <path
          id="cloud-download-2"
          data-name="cloud-download"
          d="M16.162,15.443a.666.666,0,0,0-.054-.942l-1.057-.942A1.933,1.933,0,0,0,13.676,13a2.008,2.008,0,0,0-1.424.588l-1.029.916a.667.667,0,1,0,.886,1l.89-.8v4.961a.666.666,0,0,0,1.333,0V14.7l.89.794A.666.666,0,0,0,16.162,15.443Z"
          transform="translate(-1179.666 -6890.006)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export const HostelsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="building"
        d="M4.667,9.333A.667.667,0,0,1,4,10H3.333a.667.667,0,1,1,0-1.333H4A.667.667,0,0,1,4.667,9.333Zm2.667-.667H6.667a.667.667,0,1,0,0,1.333h.667a.667.667,0,1,0,0-1.333ZM4,11.333H3.333a.667.667,0,1,0,0,1.333H4a.667.667,0,1,0,0-1.333Zm3.333,0H6.667a.667.667,0,1,0,0,1.333h.667a.667.667,0,1,0,0-1.333ZM4,3.333H3.333a.667.667,0,1,0,0,1.333H4A.667.667,0,1,0,4,3.333Zm3.333,0H6.667a.667.667,0,1,0,0,1.333h.667a.667.667,0,0,0,0-1.333ZM4,6H3.333a.667.667,0,1,0,0,1.333H4A.667.667,0,1,0,4,6ZM7.333,6H6.667a.667.667,0,1,0,0,1.333h.667A.667.667,0,0,0,7.333,6ZM16,6.667v6A3.337,3.337,0,0,1,12.667,16H3.333A3.337,3.337,0,0,1,0,12.667V3.333A3.337,3.337,0,0,1,3.333,0h4a3.337,3.337,0,0,1,3.333,3.333h2A3.337,3.337,0,0,1,16,6.667Zm-12.667,8h6V3.333a2,2,0,0,0-2-2h-4a2,2,0,0,0-2,2v9.333a2,2,0,0,0,2,2Zm11.333-8a2,2,0,0,0-2-2h-2v10h2a2,2,0,0,0,2-2Zm-2,2a.667.667,0,1,0,.667.667A.667.667,0,0,0,12.667,8.667Zm0,2.667a.667.667,0,1,0,.667.667A.667.667,0,0,0,12.667,11.333Zm0-5.333a.667.667,0,1,0,.667.667A.667.667,0,0,0,12.667,6Z"
        fill="#fff"
      />
    </svg>
  )
}
