import { axios } from '@campxdev/shared'

export const courses = {
  async fetchCourses(params?: any) {
    const res = await axios.get('/square/courses', {
      params: {
        sortByName: true,
      },
    })
    return res.data.courses
  },

  async fetchMCourses(params?: any) {
    const res = await axios.get('/paymentx/domain/courses', {
      params: {
        ...params,
      },
    })
    return res.data
  },
}
