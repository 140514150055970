import { axios } from '@campxdev/shared'
import { activityLogTypes } from 'constants/activityLogTypes'
const getStatusCondition = (thisMonth: number, lastMonth: number) => {
  const var1 = thisMonth ?? 0
  const var2 = lastMonth ?? 0
  return var1 > var2 ? 'increase' : var1 === var2 ? 'equal' : 'decrease'
}

const ACTIVITY_LOGS = '/square/activity-logs'
const logTypes = [
  activityLogTypes.ATTENDANCE.add,
  activityLogTypes.POST.announcement,
  activityLogTypes.POST.blog,
  activityLogTypes.POST.event,
  activityLogTypes.TASK.cancelled,
  activityLogTypes.TASK.completed,
  activityLogTypes.TASK.created,
  activityLogTypes.TASK['on-hold'],
  activityLogTypes.TASK.ongoing,
]

export const activityLogs = {
  async fetchAll() {
    const res = await axios.get(ACTIVITY_LOGS, {
      params: {
        logType: [...logTypes],
      },
    })
    return res.data
  },

  async fetchMonthlyActiveUsers() {
    const totalActiveUsers = await axios.get(
      `${ACTIVITY_LOGS}/monthly-active-users`,
      { params: { type: null } },
    )

    const activeStaffUsers = await axios.get(
      `${ACTIVITY_LOGS}/monthly-active-users`,
      { params: { type: 'staff_user' } },
    )

    const activeStudentUsers = await axios.get(
      `${ACTIVITY_LOGS}/monthly-active-users`,
      { params: { type: 'student' } },
    )

    return {
      totalActive: totalActiveUsers.data,
      activeStaffUsers: activeStaffUsers.data,
      activeStudentUsers: activeStudentUsers.data,
    }
  },

  async fetchTotalUsersData() {
    const res = await axios.get(`${ACTIVITY_LOGS}/total-users-statistics`)
    return res.data
  },

  async fetchPaymentsStatistics() {
    const res = await axios.get(`${ACTIVITY_LOGS}/payments-statistics`)
    return res.data
  },

  async attendanceMarkedByStaff() {
    const res = await axios.get(`${ACTIVITY_LOGS}/attendance-statistics`)
    const mobile = res.data?.platforms
      ?.filter((item) => item.platformType === 'mobile')
      ?.reduce((acc, curr) => {
        return {
          totalCount: acc.totalCount ?? 0 + curr.totalCount,
          percentage: acc.percentage ?? 0 + curr.percentage,
          thisMonth: acc.thisMonth ?? 0 + curr.thisMonth,
          lastMonth: acc.lastMonth ?? 0 + curr.lastMonth,
          status: getStatusCondition(
            acc.thisMonth ?? 0 + curr.thisMonth,
            acc.lastMonth ?? 0 + curr.lastMonth,
          ),
        }
      }, {})

    const web = res.data.platforms?.find((item) => item.platformType === 'web')

    return {
      totalCount: res.data.totalCount,
      percentage: res.data.percentage,
      status: res.data.status,
      mobile: mobile,
      web: web,
    }
  },
}
