import { withSuspense } from '@campxdev/shared'
import { lazy } from 'react'

const StudentResults = lazy(() => import('pages/StudentResults'))
const PrintResults = lazy(() => import('pages/StudentResults/PrintResults'))
const StudentTranscript = lazy(() => import('pages/Transcripts'))
export const resultsRoutes = [
  {
    element: withSuspense(<StudentResults />),
    path: '/results',
  },
  {
    element: withSuspense(<StudentTranscript />),
    path: '/transcript/:id',
  },
]
