import { ErrorBoundary, LayoutWrapper, SideMenuHeader } from '@campxdev/shared'
import { Outlet } from 'react-router-dom'
import { settingsMenu } from './settingsMenu'

export default function SettingsLayout() {
  return (
    <LayoutWrapper
      menu={settingsMenu}
      sideMenuHeader={
        <SideMenuHeader path="/feed/digitalNoticeBoard" title={'Settings'} />
      }
    >
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </LayoutWrapper>
  )
}
