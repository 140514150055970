import {
  ActionButton,
  FormSingleSelect,
  ReportHeader,
  Spinner,
  axios,
  getUrlParams,
} from '@campxdev/shared'
import {
  instituitionKey,
  urlTenantKey,
} from '@campxdev/shared/src/contexts/Providers'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, CircularProgress, Stack, Typography, styled } from '@mui/material'
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import * as yup from 'yup'
import { NoDataComponent } from './SemesterWiseAssessmentReport'

interface GetGpaReport {
  courseId: number
  regulation: string
  monthYear: string
  semNo: number
  branchCode?: string
  section?: string
}

export const fetchGpaReport = (params: GetGpaReport) => {
  return axios
    .get('/square/classroom-timetable-reports/gpa-report', {
      params,
    })
    .then((res) => res.data)
}

export const fetchMonthYearFromGrades = async () => {
  return await axios
    .get(`/square/classroom-timetable-reports/student-grade-filters`)
    .then((res) => res.data)
}

export const getSections = (params) => {
  return axios
    .get('/square/classrooms', {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
}

const schema = yup.object().shape({
  courseId: yup.number().required('Degree is required').nullable(),
  semNo: yup.number().required('Semester is required').nullable(),
  monthYear: yup.string().required('Month Year is required').nullable(),
  regulation: yup.string().required('Regulation is required').nullable(),
})

const GPAReportFilter = () => {
  const { data: monthYear, isLoading } = useQuery('monthYear', () =>
    fetchMonthYearFromGrades(),
  )

  const { control, handleSubmit, watch, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      courseId: null,
      semNo: null,
      monthYear: null,
      regulation: null,
      branchCode: null,
      batch: null,
      section: null,
    },
  })

  const onSubmit = async (formData) => {
    const searchParams = new URLSearchParams()
    for (const key in formData) {
      if (formData[key] !== null && formData[key] !== 'all') {
        searchParams.append(key, encodeURIComponent(formData[key]))
      }
    }
    window.open(
      `/${urlTenantKey}/${instituitionKey}/print/gpa-report/?${searchParams}`,
    )
  }

  const onError = (err) => {
    // eslint-disable-next-line no-console
    console.log(err)
  }

  const batches =
    monthYear
      ?.find((item) => item.courseId == watch('courseId'))
      ?.semesters?.find((item) => item.semNo == watch('semNo'))
      ?.monthYears?.find((item) => item.monthYear == watch('monthYear'))
      ?.regulations?.find((item) => item.regulationName == watch('regulation'))
      ?.examTypes?.flatMap((item) => item?.batches?.map((batch) => batch)) ?? []

  const {
    data: classroomSections,
    mutate: fetchSections,
    isLoading: sectionsLoading,
  } = useMutation(getSections)

  if (isLoading) return <Spinner />

  return (
    <>
      <Box sx={{ width: '450px' }}>
        <StyledFiltersContainer>
          <StyledFiltersHeader>
            <Typography variant="h6">Apply Filters</Typography>
          </StyledFiltersHeader>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Stack gap={2.5} sx={{ padding: '20px' }}>
              <FormSingleSelect
                label={'Degree'}
                name={'courseId'}
                control={control}
                options={
                  monthYear?.map((course) => ({
                    label: course.courseName,
                    value: course.courseId,
                  })) ?? []
                }
                onChange={(e) => {
                  reset({ courseId: +e.target.value })
                }}
                required
              />
              <FormSingleSelect
                label={'Program'}
                name={'branchCode'}
                control={control}
                options={
                  monthYear
                    ?.find((item) => item.courseId == watch('courseId'))
                    ?.branches?.map((item) => ({
                      label: item?.branchCode,
                      value: item?.branchCode,
                    })) ?? []
                }
                required
              />

              <FormSingleSelect
                label={'Semester'}
                name={'semNo'}
                control={control}
                options={
                  monthYear
                    ?.find((item) => item.courseId == watch('courseId'))
                    ?.semesters?.map((item) => ({
                      label: item?.name,
                      value: item?.semNo,
                    })) ?? []
                }
                required
                onChange={(e) => {
                  reset({
                    ...watch(),
                    semNo: e.target.value,
                    monthYear: null,
                    regulation: null,
                  })
                }}
              />
              <FormSingleSelect
                label={'Month Year'}
                name={'monthYear'}
                control={control}
                options={
                  monthYear
                    ?.find((item) => item.courseId == watch('courseId'))
                    ?.semesters?.find((item) => item.semNo == watch('semNo'))
                    ?.monthYears?.map((item, index) => ({
                      label: item?.monthYear,
                      value: item?.monthYear,
                    })) ?? []
                }
                onChange={(e) => {
                  reset({
                    ...watch(),
                    monthYear: e.target.value,
                    regulation: null,
                  })
                }}
                required
              />

              <FormSingleSelect
                label={'Regulation'}
                name={'regulation'}
                control={control}
                options={
                  monthYear
                    ?.find((item) => item.courseId == watch('courseId'))
                    ?.semesters?.find((item) => item.semNo == watch('semNo'))
                    ?.monthYears?.find(
                      (item) => item.monthYear == watch('monthYear'),
                    )
                    ?.regulations?.map((reg) => ({
                      label: reg?.regulationName,
                      value: reg?.regulationName,
                    })) ?? []
                }
                onChange={(e) => {
                  reset({
                    ...watch(),
                    regulation: e.target.value,
                  })
                }}
                required
              />

              <FormSingleSelect
                label={'Batch'}
                name={'batch'}
                control={control}
                options={
                  batches.map((item) => ({
                    label: item,
                    value: item,
                  })) ?? []
                }
                onChange={(e) => {
                  reset({
                    ...watch(),
                    batch: e.target.value,
                  })
                  fetchSections({
                    courseId: watch('courseId'),
                    branchCode: watch('branchCode'),
                    batch: watch('batch'),
                  })
                }}
              />

              {sectionsLoading ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  <FormSingleSelect
                    label={'Section'}
                    name={'section'}
                    control={control}
                    options={
                      classroomSections?.result?.map((section) => ({
                        label: section?.name,
                        value: section?.section,
                      })) ?? []
                    }
                  />
                </>
              )}

              <Stack direction="row" mt={2}>
                <ActionButton type="submit" fullWidth>
                  View Report
                </ActionButton>
              </Stack>
            </Stack>
          </form>
        </StyledFiltersContainer>
      </Box>
    </>
  )
}

export default GPAReportFilter

export function GPAReport() {
  const query = getUrlParams()
  const { data, isLoading } = useQuery(['gpa-report'], () =>
    fetchGpaReport({
      courseId: +query.courseId,
      regulation: query.regulation,
      monthYear: query.monthYear,
      semNo: +query.semNo,
      branchCode: query.branchCode,
      section: query.section,
    }),
  )

  if (isLoading) {
    return <Spinner />
  }

  if (data?.memos.length == 0) {
    return <NoDataComponent />
  }

  const chunks = _.chunk(data?.memos, 15)

  return (
    <StyledReportContainer>
      {chunks.map((chunk: any, index) => (
        <StyledContainer key={index}>
          <ReportHeader
            phone={{
              hide: true,
            }}
            recognitionDetails={{
              hide: true,
            }}
            showDivider={true}
            typographyList={[
              {
                text: 'GPA Report',
                variant: 'h1',
              },
              {
                text: data.examination,
                variant: 'h6',
              },
            ]}
          />
          <TableComponent chunk={chunk} />
        </StyledContainer>
      ))}
    </StyledReportContainer>
  )
}

export const TableComponent = ({ chunk }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Roll No</th>
          <th style={{ textAlign: 'left' }}>Student</th>
          <th>SGPA</th>
          <th>CGPA</th>
        </tr>
      </thead>
      <tbody>
        {chunk.map((item, idx) => (
          <tr key={idx}>
            <td>{item?.student?.rollNo}</td>
            <td style={{ textAlign: 'left' }}>{item?.student?.fullName}</td>
            <th>{item.sgpa ?? '--'}</th>
            <th>{item.cgpa ?? '--'}</th>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export const StyledContainer = styled(Box)({
  padding: '1cm 0.5cm 0 0.5cm',
  table: {
    width: '100%',
    thead: {
      tr: {
        backgroundColor: '#efefef',
      },
    },
    td: {
      border: '1px solid black',
      padding: '8px 4px',
      textAlign: 'center',
    },
    th: {
      border: '1px solid black',
      padding: '8px 4px',
      textAlign: 'center',
    },
  },
  '@media print': {
    marginLeft: '0.5cm',
    pageBreakAfter: 'always',
    marginRight: '0.5cm',
  },
})

export const StyledFiltersContainer = styled(Box)(({ theme }) => ({
  border: theme?.borders?.grayLight,
  borderRadius: '10px',
  height: 'fit-content',
}))

export const StyledFiltersHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme?.palette?.secondary?.light,
  padding: '20px',
  borderRadius: '10px 10px 0 0',
}))

export const StyledReportContainer = styled(Box)({
  width: '25cm',
  height: 'auto',
  margin: 'auto',
  padding: '20px',
  background: 'white',
})
