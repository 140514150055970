import { createSlice } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { map, switchMap } from 'rxjs/operators'
import { axios } from '@campxdev/shared'

const initialState = {
  loading: true,
  data: [],
  error: null,
  examLateFees: [],
  examFees: [],
}

const examFee = createSlice({
  name: 'exam-fee',
  initialState: initialState,
  reducers: {
    fetchExamFee(state, action: any) {
      state.loading = false
    },
    fetchedExamFee(state, action: any) {
      state.loading = false
      state.data = action.payload.data
      state.examFees = action.payload.data.examFees
        ? action.payload.data.examFees.map((item) => ({
            ...item,
            isEditing: false,
          }))
        : []
      state.examLateFees = action.payload.data.examLateFees
        ? action.payload.data.examLateFees.map((item) => ({
            ...item,
            isEditing: false,
          }))
        : []
    },
    updateExamFeeValue(state, action: any) {
      let index = action.payload.index
      let key = action.payload.key
      let value = action.payload.value
      state.examFees[index][key] = value
    },

    addExamFee(state) {
      state.examFees = state.examFees.filter((f) => f)
      state.examFees = [
        ...state.examFees,
        {
          examType: 'regular',
          minSubjects: 1,
          amount: 100,
          isNew: true,
          isEditing: true,
        },
      ]
    },
    removeExamFee(state, action) {
      delete state.examFees[action.payload.index]
    },
    editExamFee(state, action) {
      state.examFees[action.payload.index].isEditing = true
    },
    updateExamFee(state, action) {
      state.examFees[action.payload.index].isEditing = false
    },
    saveExamFee(state, action) {
      state.examFees[action.payload.index] = action.payload.data
    },
    onExamFeeCancel(state, action) {
      state.examFees[action.payload.index].isEditing = false
    },

    updateDueDateValue(state, action: any) {
      let index = action.payload.index
      let key = action.payload.key
      let value = action.payload.value
      state.examLateFees[index][key] = value
    },
    addDueDate(state) {
      state.examLateFees = state.examLateFees.filter((f) => f)
      state.examLateFees = [
        ...state.examLateFees,
        {
          dueDate: Date.now(),
          amount: 100,
          isNew: true,
          isEditing: true,
        },
      ]
    },
    removeDueDate(state, action) {
      delete state.examLateFees[action.payload.index]
    },
    editDueDate(state, action) {
      state.examLateFees[action.payload.index].isEditing = true
    },
    updateDueDate(state, action) {
      state.examLateFees[action.payload.index].isEditing = false
    },
    saveDueDate(state, action) {
      state.examLateFees[action.payload.index] = action.payload.data
    },
    onDueDateCancel(state, action) {
      state.examLateFees[action.payload.index].isEditing = false
    },
  },
})

export function fetchExamFeeEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchExamFee.type),
    switchMap(async (action: any) => {
      const res = await axios.get(
        `/square/exam-fee?examGroupId=${action.payload.examGroupId}`,
      )
      return {
        data: res.data,
      }
    }),
    map(fetchedExamFee),
  )
}

const { fetchedExamFee } = examFee.actions

export const {
  fetchExamFee,
  updateExamFee,
  updateExamFeeValue,
  onExamFeeCancel,
  saveExamFee,
  addExamFee,
  removeExamFee,
  editExamFee,
  updateDueDate,
  onDueDateCancel,
  saveDueDate,
  addDueDate,
  removeDueDate,
  editDueDate,
  updateDueDateValue,
} = examFee.actions
export default examFee.reducer
