import { axios } from '@campxdev/shared'

const ENDPOINT = '/square'
export const quicksight = {
  async getTemplate(analysisName: string) {
    const res = await axios.get(`${ENDPOINT}/dashboard-manager/get-template`, {
      params: {
        analysisName: analysisName,
      },
    })
    return res.data
  },

  // async getListAnalysis() {
  //   const res = await axios.get(`${ENDPOINT}/dashboard-manager/analysis`)
  //   return res.data
  // },

  // async createTemplate(analysisId: string) {
  //   const res = await axios.post(`${ENDPOINT}/dashboard-manager/template`, {
  //     presetAnalysisId: analysisId,
  //   })
  //   return res.data
  // },

  async createDashboard({ dashboardName }: { dashboardName: string }) {
    const res = await axios.post(`${ENDPOINT}/dashboard-manager/dashboard`, {
      dashboardName,
    })
    return res.data
  },
}
