import { createSlice } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { map, switchMap } from 'rxjs/operators'
import { axios } from '@campxdev/shared'

const initialState = {
  loading: true,
  data: null,
  error: null,
  items: [],
  links: {},
  meta: {},
}

const auditLogsSlice = createSlice({
  name: 'audit-logs',
  initialState: initialState,
  reducers: {
    fetchAuditLogs(state, action) {},
    fetchedAuditLogs(state, action) {
      state.loading = false
      state.data = action.payload.data
      state.items = action.payload.data.items
      state.links = action.payload.data.links
      state.meta = action.payload.data.meta
    },
  },
})

export function fetchAuditLogsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchAuditLogs.type),
    switchMap(async (action: any) => {
      const res = await axios.get('/square/audit-log', {
        params: {
          page: action.payload.page,
          limit: action.payload.limit,
        },
      })
      return {
        data: res.data,
      }
    }),
    map(fetchedAuditLogs),
  )
}

export const { fetchAuditLogs, fetchedAuditLogs } = auditLogsSlice.actions

export default auditLogsSlice.reducer
