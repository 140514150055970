import {
  ErrorBoundary,
  Helmet,
  ReportHeader,
  Spinner,
  useAuth,
} from '@campxdev/shared'
import { Outlet } from 'react-router-dom'
const FeedbackLayoutWrapper = styled(Box)({
  '@media print': {
    zoom: '75%',
  },
})

const FeedbackLayout = () => {
  const { data, loading } = useAuth({
    permissionsEndpoint: '/auth-server/auth/my-permissions',
  })
  const { current } = InstitutionsStore.useState((s) => s)

  if (loading) {
    return <Spinner />
  }
  return (
    <FeedbackLayoutWrapper>
      <Helmet
        user={data?.user}
        appTitle="Digital Campus | Campx"
        favicon={data?.assets?.logo_square}
        description={'University management system'}
      />
      <ReportHeader phone={{ hide: true }} />

      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </FeedbackLayoutWrapper>
  )
}

export default FeedbackLayout

import { InstitutionsStore } from '@campxdev/shared/src/shared-state/InstitutionsStore'
import { Box, Stack, Typography, styled } from '@mui/material'

export const FeedbackReportHeader = ({ institution }) => {
  return (
    <Box display="flex" width="100%">
      <Stack
        style={{ alignItems: 'center', justifyContent: 'center' }}
        gap={2}
        direction={'row'}
        width="100%"
      >
        <img
          style={{ width: '100px', height: '100px', objectFit: 'contain' }}
          src={institution.imageSquare.url}
          alt=""
        />
        <Stack gap={1}>
          <Typography fontSize="20px" variant="h1">
            {institution.name}
          </Typography>
          <Typography variant="h5">{institution.address}</Typography>
          <Typography variant="h5">{`Email: ${institution.email}, mobile: ${institution.phone}`}</Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
