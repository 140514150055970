import { Spinner } from '@campxdev/shared'
import { queryClient } from '@campxdev/shared/src/contexts/QueryClientProvider'

import { Cached } from '@mui/icons-material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, IconButton, Typography } from '@mui/material'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import { FeedbackTypes } from 'pages/Feedback/utils'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { SERVICES } from 'services'

export const TargetAudienceDistribution = ({
  classroomsMap,
  programsMap,
  coursesMap,
  type,
}: {
  classroomsMap: any
  programsMap: any
  coursesMap: any
  type: FeedbackTypes
}) => {
  const params = useParams()
  const { isLoading, data } = useQuery('targetAudience-distribution', () => {
    switch (type) {
      case FeedbackTypes.FACULTY:
        return SERVICES.facultyFeedbacks.getFacultyFeedbackDistributionById(
          params.id,
        )
      case FeedbackTypes.FACILITY:
        return SERVICES.facilityFeedbacks.getFacilityFeedbackDistributionById(
          params.id,
        )
    }
  })

  const TreeNode = ({ id, data }) => {
    const nodeName =
      coursesMap[id]?.courseName || programsMap[id]?.branchName || id
    if (typeof data !== 'object' || data === null) {
      return (
        <TreeItem
          sx={{
            div: {
              backgroundColor: 'unset !important',
            },
          }}
          nodeId={id}
          label={
            <Typography padding="2px 10px" fontSize="15px">
              {classroomsMap[id]?.name + ' , ' + data + ' students'}
            </Typography>
          }
        />
      )
    }
    return (
      <TreeItem
        sx={{
          div: {
            backgroundColor: 'unset !important',
          },
        }}
        nodeId={id}
        label={
          <Typography padding="0px 0px 0px 5px" fontSize="15px">
            {nodeName}
          </Typography>
        }
      >
        {Object.entries(data).map(([key, value]) => (
          <TreeNode key={key} id={key} data={value} />
        ))}
      </TreeItem>
    )
  }

  if (isLoading) return <Spinner />
  return (
    <Box
      sx={{
        background: 'rgb(239, 248, 251)',
        borderRadius: '5px',
        padding: '10px',
      }}
    >
      <IconButton
        sx={{ float: 'right' }}
        onClick={() => {
          queryClient.invalidateQueries('targetAudience-distribution')
          queryClient.invalidateQueries('feedback')
        }}
      >
        <Cached sx={{ '&:hover': { color: 'green' } }} />
      </IconButton>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon sx={{ scale: '1.3' }} />}
        defaultExpandIcon={<ChevronRightIcon sx={{ scale: '1.3' }} />}
        defaultExpanded={['Target Audience Distribution']}
      >
        <TreeNode id="Target Audience Distribution" data={data} />
      </TreeView>
    </Box>
  )
}
