import { services } from '@campx/settings'
import { facilityFeedbacks } from './Feedback/facility-feedbacks'
import { facultyFeedbacks } from './Feedback/faculty-feedbacks'
import { feedbackQuestions } from './Feedback/feedback-questions'
import { activityLogs } from './activity-logs'
import { branches } from './branches'
import { comments } from './comments'
import { courses } from './courses'
import { forms } from './forms'
import { mentors } from './mentors'
import { payments } from './payments'
import { quicksight } from './quicksight'
import { regBatches } from './regBatches'
import { surveys } from './surveys'
import { academicCalender } from './academic-calender'

export const SERVICES = {
  activityLogs: activityLogs,
  courses: courses,
  academicCalender: academicCalender,
  regBatches: regBatches,
  departments: services.departments,
  surveys: surveys,
  forms: forms,
  mentors: mentors,
  feedbackQuestions: feedbackQuestions,
  facultyFeedbacks: facultyFeedbacks,
  facilityFeedbacks: facilityFeedbacks,
  payments: payments,
  branches: branches,
  comment: comments,
  quicksight: quicksight,
}
