import { ErrorBoundary } from '@campxdev/shared'
import { Outlet } from 'react-router-dom'

export default function PublicFormsLayout() {
  return (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  )
}
