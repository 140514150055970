import { AcademicCalendarPrint, HolidaysPrint } from '@campx/settings'
import { Permission } from '@campxdev/shared'
import { urlTenantKey } from '@campxdev/shared/src/contexts/Providers'
import { SquarePermissions } from '@campxdev/shared/src/shared-state/PermissionsStore'
import PrintEventReport from 'pages/Activities/Events/PrintEventReport'
import { GPAReport } from 'pages/ClassRooms/ClassRoomReports/ReportPages/GPAReport'
import AnuragSectionWiseAttendanceReport from 'pages/ClassRooms/ClassRoomView/IndividualClassroomReports/AnuragSectionWiseAttendanceReport'
import MentoringReport from 'pages/Mentors/components/MenteeDetails/MentoringReport'
import { MentorReports } from 'pages/Mentors/components/MentorReports'

import { lazy } from 'react'

const AssessmentReport = lazy(
  () => import('pages/ClassRooms/ClassRoomView/IndividualClassroomReports'),
)

const AdmissionFeeReceipt = lazy(
  () => import('pages/Students/AllStudents/ViewStudent/AdmissionFeeReceipt'),
)

const AssessmentWiseReport = lazy(
  () =>
    import(
      'pages/ClassRooms/ClassRoomView/IndividualClassroomReports/AssessmentWiseReport'
    ),
)

const ResetPassword = lazy(() => import('pages/ResetPassword'))

const ReceiptPage = lazy(() => import('pages/ReceiptPage'))

const PrintBranchWiseTimeTable = lazy(
  () =>
    import(
      'pages/ClassRooms/ClassRoomReports/ReportPages/FacultyTimetableReport/PrintBranchWiseTimeTable'
    ),
)
const FacultyAttendanceStatusReport = lazy(
  () =>
    import(
      'pages/ClassRooms/ClassRoomReports/ReportPages/FacultyAttendanceStatusReport/PrintReport'
    ),
)
const AccessForm = lazy(() => import('pages/FormBuilder/FormAccess'))
const SectionWiseAttendanceReport = lazy(
  () =>
    import(
      'pages/ClassRooms/ClassRoomView/IndividualClassroomReports/SectionWiseAttendanceReport'
    ),
)

const ClassroomTimeTablePrint = lazy(
  () => import('pages/ClassRooms/ClassRoomTimeTable/ClassRoomTimeTablePrint'),
)

const SemesterWiseAssessmentReport = lazy(
  () =>
    import(
      'pages/ClassRooms/ClassRoomReports/ReportPages/SemesterWiseAssessmentReport'
    ),
)

const MidReport = lazy(
  () =>
    import(
      'pages/ClassRooms/ClassRoomReports/ReportPages/KGreddyReports/MidReport'
    ),
)

const TotalReport = lazy(
  () =>
    import(
      'pages/ClassRooms/ClassRoomReports/ReportPages/KGreddyReports/TotalReport'
    ),
)

const FinalMidWiseMarksReport = lazy(
  () =>
    import(
      'pages/ClassRooms/ClassRoomReports/ReportPages/KGreddyReports/FinalMidWiseMarksReport/FinalMidWiseMarksReport'
    ),
)

const StudentsPrint = lazy(
  () => import('pages/Students/AllStudents/PrintPages/StudentsPrint'),
)

const DayWiseAttendanceReport = lazy(
  () =>
    import(
      'pages/ClassRooms/ClassRoomSubject/SubjectReports/DayWiseAttendanceReport'
    ),
)

const AssessmentTypeReport = lazy(
  () =>
    import(
      'pages/ClassRooms/ClassRoomSubject/SubjectReports/AssessmnetTypeReport'
    ),
)

const InternalQuestionWiseMarksReport = lazy(
  () =>
    import(
      'pages/ClassRooms/ClassRoomReports/ReportPages/InternalQuestionWiseMarksReport'
    ),
)

export const individualRoutes = [
  {
    path: 'assessment-report/*',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
    element: <AssessmentReport />,
  },
  {
    path: 'assessment-type-report/*',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
    element: <AssessmentTypeReport />,
  },
  {
    path: 'semester-wise-assessment-report',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
    element: <SemesterWiseAssessmentReport />,
  },
  {
    path: 'mid1-report/*',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
    element: <MidReport />,
  },
  {
    path: 'mid2-report/*',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
    element: <MidReport />,
  },
  {
    path: 'total-report/*',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
    element: <TotalReport />,
  },
  {
    path: 'final-midwise-marks-report/*',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
    element: <FinalMidWiseMarksReport />,
  },
  {
    path: 'internal-question-wise-marks-report',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
    element: <InternalQuestionWiseMarksReport />,
  },
  {
    path: 'assessment-wise-report',
    element: <AssessmentWiseReport />,
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    element:
      urlTenantKey == 'aupulse' ? (
        <AnuragSectionWiseAttendanceReport />
      ) : (
        <SectionWiseAttendanceReport />
      ),
    path: 'classroom-report',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    element: <FacultyAttendanceStatusReport />,
    path: 'faculty-attendance-status-report/*',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    element: <GPAReport />,
    path: 'gpa-report',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    element: <AdmissionFeeReceipt />,
    exact: true,
    name: 'FeeReceipt',
    path: 'students/:studentId/fee-receipt/:id',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },

  {
    element: <ResetPassword />,
    exact: true,
    name: 'Reset Password',
    path: 'reset-password',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },

  {
    element: <ReceiptPage />,
    exact: true,
    name: 'Receipt',
    path: 'fee-receipt/:id',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },

  {
    element: <AcademicCalendarPrint />,
    path: 'academic-calendar/semester/print',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },

  {
    element: <HolidaysPrint />,
    path: 'academic-calendar/holidays/print',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    element: <AccessForm />,
    path: 'forms/access/:formId/',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    element: <PrintBranchWiseTimeTable />,
    path: 'facultytimetable-print',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    element: <ClassroomTimeTablePrint />,
    path: 'classroom-timetable',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    element: <StudentsPrint />,
    path: 'students',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    element: <DayWiseAttendanceReport />,
    path: 'day-wise-attendance-report',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    element: <PrintEventReport />,
    path: 'print-event-report/:id',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },

  {
    element: <MentoringReport />,
    path: 'all-mentors/:mentorUserId/student/:studentId',
    permissionKey: SquarePermissions.CAN_VIEW_MENTEE_REPORT,
  },
  {
    element: <MentorReports />,
    path: 'all-mentors/:mentorUserId',
    permissionKey: SquarePermissions.CAN_VIEW_MENTOR_REPORT,
  },
  {
    element: <MentorReports />,
    path: 'my-mentees',
    permissionKey: SquarePermissions.CAN_VIEW_MENTOR_REPORT,
  },
  {
    element: <MentoringReport />,
    path: 'my-mentees/:studentId',
    permissionKey: SquarePermissions.CAN_VIEW_MENTEE_REPORT,
  },
]
