import { Permission } from '@campxdev/shared'
import { lazy } from 'react'

const TaskBoard = lazy(() => import('pages/Tasks/TaskBoard'))
const TasksDashboard = lazy(() => import('pages/Tasks/TasksDashboard'))
const TasksReports = lazy(() => import('pages/Tasks/TasksReports'))
const TaskView = lazy(() => import('pages/Tasks/TaskBoard/TaskView'))

export const taskRoutes = [
  {
    element: <TasksDashboard />,
    path: 'dashboard',
  },
  {
    element: <TaskBoard />,
    path: 'taskboard/*',
    permissionKey: Permission.CAN_TASKS_VIEW,
  },
  {
    element: <TasksReports />,
    path: 'reports',
  },
  {
    element: <TaskView />,
    path: 'view/:id',
    permissionKey: Permission.CAN_TASKS_VIEW,
  },
]
