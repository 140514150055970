import {
  axios,
  Breadcrumbs,
  CustomDrawer,
  DialogButton,
  DropDownButton,
  PageHeader,
  Permission,
  Spinner,
  UploadFileDialog,
  ValidateAccess,
} from '@campxdev/shared'
//Should be on Top
import FullCalendar from '@fullcalendar/react'
//Should be on Top

import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

import timeGridPlugin from '@fullcalendar/timegrid'
import { Box, Button, styled, Typography, useTheme } from '@mui/material'
import { View } from 'components/FullCalendar/Actions'
import { FullCalendarWrapper } from 'components/FullCalendar/FullCalendarWrapper'
import { parse, set } from 'date-fns'
import _ from 'lodash'
import { Store } from 'pullstate'
import { useRef, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { classRoomStore } from '../ClassRooms/ClassRooms'
import { fetchClassRoomAndSubjects } from '../services'
import {
  findMinMaxTimes,
  findMissingWeekdaysIndices,
  subjectColors,
} from './constants'
import GenerateSession from './GenerateSession'
import TimeTableForm from './TimeTableForm'

const stepSize = 60

export const timeTableStore = new Store({
  subjects: [],
  selectedSubject: null,
  events: [],
  minimumFromTime: null,
  maximumToTime: null,
  weekDays: [],
})

const RenderCustomEventDisplay = (ctx) => {
  const subjectName = ctx.event?._def?.extendedProps?.subject.name
  const room = ctx.event?._def?.extendedProps?.room
  return (
    <StyledCustomEvent sx={{ position: 'relative' }}>
      <Typography variant="body2" style={{ fontSize: '11px' }}>
        {ctx?.timeText}
      </Typography>
      <Typography variant="body1" style={{ fontSize: '12px' }}>
        {subjectName}
      </Typography>
      {room && (
        <Typography variant="body1" style={{ fontSize: '12px' }}>
          {`(Block ${room?.block}, Room No - ${room?.roomNo} )`}
        </Typography>
      )}
      <Box className="overlay"></Box>
    </StyledCustomEvent>
  )
}

export default function ClassRoomTimeTable() {
  const navigate = useNavigate()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [slotData, setSlotData] = useState(null)
  const queryClient = useQueryClient()

  const theme = useTheme()
  const eventElRef = useRef(null)
  const calendarRef = useRef<FullCalendar | null>(null)
  const [view, setView] = useState<View>('timeGridWeek')
  const state = timeTableStore.useState((s) => s)
  const params: any = useParams()
  const { classRoom } = classRoomStore.useState((s) => s)

  const { isLoading: loadingClassroom, isRefetching } = useQuery(
    ['classroom', params?.classRoomId],
    () => fetchClassRoomAndSubjects(params?.classRoomId),
    {
      onSuccess: (res) => {
        classRoomStore.update((s) => {
          s.classRoom = res.classRoom
          s.currentSemSubjects = res.subjects?.filter(
            (item) => item?.semNo == res.classRoom?.currentSemester,
          )
        })
        timeTableStore.update((s) => {
          s.subjects = res.subjects?.filter(
            (item) => item?.semNo == res?.classRoom?.currentSemester,
          )
        })
      },
    },
  )
  const { isRefetching: refetchingTimeTable, isLoading: loadingTimeTable } =
    useQuery(
      ['classroom-timetable', classRoom?.id, params?.classRoomId],
      () =>
        axios
          .get(`/square/classrooms/${params?.classRoomId}/timetable`, {
            params: {
              semNo: classRoom?.currentSemester,
            },
          })
          .then((res) => res.data),
      {
        refetchOnMount: 'always',
        enabled: !!classRoom,
        onSuccess: (data) => {
          const { weekdays, events } = data?.reduce(
            (result, obj) => {
              if (!result.subjectColorMap[obj.subjectId]) {
                result.subjectColorMap[obj.subjectId] =
                  subjectColors[result.index]?.light ?? 'white'
                result.index++
              }
              result.weekdays.push(obj.day)
              const date = parse(obj?.day?.toLowerCase(), 'cccc', new Date())
              result.events.push({
                start: set(date, {
                  hours: parseInt(obj?.timetableSlot?.fromTime?.split(':')[0]),
                  minutes: parseInt(
                    obj?.timetableSlot?.fromTime?.split(':')[1],
                  ),
                }),
                end: set(date, {
                  hours: parseInt(obj?.timetableSlot?.toTime?.split(':')[0]),
                  minutes: parseInt(obj?.timetableSlot?.toTime?.split(':')[1]),
                }),
                title: obj.subject.name,
                backgroundColor: result.subjectColorMap[obj.subjectId],
                textColor: theme.palette.secondary.main,
                extendedProps: { ...obj },
              })
              return result
            },
            {
              weekdays: [],
              events: [],
              subjectColorMap: {},
              index: 0,
            },
          )
          const { minFromTime, maxToTime } = findMinMaxTimes(
            'timetableSlot.fromTime',
            'timetableSlot.toTime',
            data,
          )
          timeTableStore.update((s) => {
            s.minimumFromTime = minFromTime
            s.maximumToTime = maxToTime
            s.events = events
            s.weekDays = _.uniq(weekdays)
          })
        },
      },
    )

  const handleEventClick = (ctx) => {
    setOpenDrawer(true)
    setSlotData(ctx.event._def.extendedProps)
  }

  if (
    loadingClassroom ||
    isRefetching ||
    refetchingTimeTable ||
    loadingTimeTable
  )
    return <Spinner />

  const tourSteps = [
    {
      target: '.generateSessions',
      title: 'Generate Sessions',
      content: (
        <Typography variant="subtitle1">
          After finalizing the timetable, click 'Generate Sessions' to split the
          weekly timetable into separate sessions for the entire semester.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.viewSessions',
      title: 'View Sessions',
      content: (
        <Typography variant="subtitle1">
          To view the weekly schedule divided by dates and individual sessions,
          click 'View Sessions and you can also find "ADD NEW" Button inside
          this.
        </Typography>
      ),
    },
  ]
  return (
    <>
      {/* <ReactJoyRide steps={tourSteps} tourName="ClassRooms-Timetable" /> */}
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Classrooms', to: '/classrooms/classrooms' },
              {
                name: classRoom?.name,
                to: `/classrooms/${params.classRoomId}`,
              },
              { name: 'Time Table', to: null },
            ]}
          />
        }
        actions={[
          <Button
            key="1"
            variant="outlined"
            className="viewSessions"
            onClick={() => {
              navigate(`/classrooms/${params?.classRoomId}/timetable/sessions`)
            }}
          >
            View Sessions
          </Button>,
          <UploadFileDialog
            key="2"
            buttonText="Import Timetable"
            dialogTitle="Import Timetable and Faculty"
            refetchFn={() => {
              queryClient.invalidateQueries('classroom-timetable')
            }}
            successMessage="Time Table imported successfully"
            postBody={{
              classroomId: params?.classRoomId,
              semNo: classRoom?.currentSemester,
            }}
            uploadUrl="/square/timetable/import"
            sampleFileUrl="/static_files/time_table_import.xlsx"
          />,
          <DropDownButton
            key={2}
            menu={[
              {
                actionType: 'link',
                label: 'Print TimeTable',
                link: {
                  to: `/print/classroom-timetable/?classroomId=${params?.classRoomId}&semNo=${classRoom?.currentSemester}`,
                },
              },
              {
                actionType: 'dialog',
                label: 'Add New Class',
                contentTitle: 'Add New Class',
                content: ({ close }) => (
                  <ValidateAccess
                    key="3"
                    accessKey={Permission.CAN_CLASSROOM_SESSION_ADD}
                  >
                    <TimeTableForm close={close} prevData={null} />
                  </ValidateAccess>
                ),
              },
            ]}
          />,
        ]}
      />

      <Box sx={{ padding: '0 25px' }}>
        <FullCalendarWrapper>
          <FullCalendar
            allDayMaintainDuration
            allDaySlot={false}
            slotMinTime={state.minimumFromTime}
            slotMaxTime={state.maximumToTime}
            slotDuration={`00:${stepSize}`}
            initialDate={new Date()}
            slotLabelFormat={{ hour: '2-digit', minute: '2-digit' }}
            initialView={view}
            eventDisplay="block"
            dayHeaderFormat={{
              weekday: 'long',
            }}
            eventContent={RenderCustomEventDisplay}
            hiddenDays={findMissingWeekdaysIndices(state.weekDays)}
            eventDurationEditable={false}
            eventStartEditable={false}
            eventClick={handleEventClick}
            dayMaxEventRows={4}
            eventResizableFromStart
            events={state.events}
            headerToolbar={false}
            height={'calc(100vh - 200px)'}
            ref={calendarRef}
            rerenderDelay={10}
            weekends
            eventMouseEnter={(ctx) => {
              eventElRef.current = ctx.el
            }}
            eventMouseLeave={(ctx) => {
              eventElRef.current = null
            }}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          />
        </FullCalendarWrapper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <DialogButton
            title={'Confirm generation'}
            anchor={({ open }) => (
              <Button onClick={open} className="generateSessions">
                Generate Sessions
              </Button>
            )}
            content={({ close }) => (
              <GenerateSession close={close} classRoom={classRoom} />
            )}
          />
        </Box>
        <CustomDrawer
          onClose={() => {
            setOpenDrawer(false)
          }}
          open={openDrawer}
          title={slotData ? 'Edit Class' : 'Add Class'}
          content={({ close }) => (
            <TimeTableForm close={close} prevData={slotData} />
          )}
        />
      </Box>
    </>
  )
}

export const StyledCustomEvent = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  '& .overlay': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'none',
  },
  '&:hover .overlay': {
    display: 'block',
  },
}))
