import { Permission, withSuspense } from '@campxdev/shared'
import { AcademicCalendarPrint } from '../pages/Academics/AcademicCalendar'

export const individualRoutes = [
  {
    element: withSuspense(<AcademicCalendarPrint />),
    path: 'academic-calendar/semester/print',
    permissionKey: Permission.ACADEMIC_CALENDAR_VIEW,
  },
]
