import { axios } from '@campxdev/shared'

const ENDPOINT = '/square/forms'
export const forms = {
  async getAll() {
    const res = await axios.get(ENDPOINT)
    return res.data
  },

  async searchForms(params: {
    search?: string
    limit?: number
    offset?: number
  }) {
    const res = await axios.get(ENDPOINT + '/search', {
      params: params,
    })
    return res.data
  },
}
