import { axios } from '@campxdev/shared'

const ENDPOINT = '/square/feedback-questions'
export const feedbackQuestions = {
  async upsertQuestion({ formData }) {
    const res = !formData._id
      ? await axios.post(ENDPOINT, {
          ...formData,
        })
      : await axios.put(`${ENDPOINT}/${formData._id}`, {
          ...formData,
        })
    return formData
  },
  async getFeedbackQuestions(params) {
    const res = await axios.get(ENDPOINT, {
      params: params,
    })
    return res.data.data
  },

  async getFeedbackDetails(params) {
    const res = await axios.get(
      `/square/student-feedbacks/${params.studentFeedbackId}`,
    )
    return res.data
  },

  async getFeedbackQuestion(id) {
    const res = await axios.get(`${ENDPOINT}/${id}`)
    return res.data
  },
}
