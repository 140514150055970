import { ActionButton, FormSingleSelect } from '@campxdev/shared'
import { Stack, Typography } from '@mui/material'
import { batchOptions } from 'constants/constants'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { classRoomStore } from './ClassRooms'
import { StyledFiltersContainer, StyledFiltersHeader } from './styles'

export default function ClassroomsFilter({ masterData }) {
  const filters = classRoomStore.useState((s) => s.list.filters)

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      courseId: filters?.courseId ?? '',
      programId: filters?.programId ?? '',
      batch: filters?.batch ?? '',
    },
  })

  useEffect(() => {
    setValue('courseId', filters?.courseId)
    setValue('programId', filters?.programId)
    setValue('batch', filters?.batch)
  }, [...Object.keys(filters).map((key) => filters[key])])

  const onSubmit = async (formData) => {
    localStorage.setItem('classroom-selection', JSON.stringify(formData))
    classRoomStore.update((s) => {
      s.list.filters = { ...s.list.filters, ...formData }
    })
  }

  const onError = (err) => {
    // eslint-disable-next-line no-console
    console.log(err)
  }
  const clearFilters = () => {
    localStorage.removeItem('classroom-selection')
    setValue('courseId', '')
    setValue('programId', '')
    setValue('batch', '')
    classRoomStore.update((s) => {
      s.list.filters = {
        ...s.list.filters,
        batch: '',
        courseId: '',
        programId: '',
      }
    })
  }
  return (
    <StyledFiltersContainer>
      <StyledFiltersHeader className="filterOptions">
        <Typography variant="h6">Apply Filters</Typography>
      </StyledFiltersHeader>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Stack gap={2.5} sx={{ padding: '20px' }}>
          <FormSingleSelect
            name="courseId"
            label={'Filter by Degree'}
            control={control}
            options={
              masterData?.courses?.map((item) => ({
                label: item?.courseName,
                value: item?.id,
              })) || []
            }
          />
          <FormSingleSelect
            name="programId"
            label={'Filter by Program'}
            control={control}
            options={
              masterData?.branches
                ?.filter((item) => item?.courseId == watch('courseId'))
                ?.map((item, index) => ({
                  label: item?.branchCode,
                  value: item?.id,
                })) || []
            }
          />
          <FormSingleSelect
            name="batch"
            label={'Filter by Batch'}
            control={control}
            options={
              batchOptions?.map((item, index) => ({
                label: item,
                value: item,
              })) || []
            }
          />
          <ActionButton type="submit" fullWidth>
            Get Classrooms
          </ActionButton>
          <ActionButton variant="outlined" fullWidth onClick={clearFilters}>
            Clear filters
          </ActionButton>
        </Stack>
      </form>
    </StyledFiltersContainer>
  )
}
