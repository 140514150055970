import {
  ActionButton,
  axios,
  Breadcrumbs,
  DeleteButton,
  DrawerButton,
  DropDownButton,
  Image,
  PageContent,
  PageHeader,
  Permission,
  Spinner,
  Table,
  useConfirm,
  ValidateAccess,
} from '@campxdev/shared'
import { Box, Typography } from '@mui/material'
import IconLabel from 'components/IconLabel'
import moment from 'moment'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { CalendareIcon, LocationIcon } from 'layouts/DashboardLayout/icons'
import { StyledStack, StyledStatusLabel } from '../Clubs/styles'
import EventsForm from './EventsForm'

import { queryClient } from '@campxdev/shared/src/contexts/QueryClientProvider'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { excelExport, removeEventParticipant } from './service'
import { StyledEventDetailsBox } from './styles'

function EventDetails() {
  const params: any = useParams()
  const navigate = useNavigate()

  const { data: event, isLoading } = useQuery('event-details', () =>
    axios
      .get(`/square/events/details/${params.eventId}`)
      .then((res) => res.data),
  )

  if (isLoading) return <Spinner />
  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Events', to: '/activities/events' },
              { name: event?.name, to: null },
            ]}
          />
        }
        actions={[
          <DrawerButton
            key="1"
            anchor={({ open }) => (
              <>
                <ActionButton onClick={open} variant="outlined">
                  Edit Event
                </ActionButton>
                <ActionButton
                  variant="outlined"
                  onClick={() => navigate('gallery')}
                >
                  Event Gallery
                </ActionButton>
                <ValidateAccess
                  accessKey={Permission.EXTRA_CURRICULAR_ACTIVITIES_EVENTS}
                  key="2"
                >
                  <DropDownButton
                    menu={[
                      {
                        actionType: 'normal',
                        label: 'Download Event Report',
                        onClick: () =>
                          navigate(
                            `/print/print-event-report/${params.eventId}`,
                          ),
                      },
                    ]}
                  />
                </ValidateAccess>
              </>
            )}
            content={({ close }) => (
              <EventsForm data={event} hideDialog={close} />
            )}
            title="Edit Event"
          />,
        ]}
      />
      <PageContent>
        <EventDetailsHeader data={event} />
        <Members data={event?.participants} />
      </PageContent>
    </div>
  )
}

export default EventDetails

export const EventDetailsHeader = ({ data }) => {
  return (
    <StyledEventDetailsBox>
      <Image
        alt=""
        radius={'10px'}
        src={data?.imageUrl}
        width={'160px'}
        height={'160px'}
        fit={'cover'}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          height: '100%',
          gap: '10px',
        }}
      >
        <Box>
          <Typography variant="h6">{data?.name}</Typography>
          <Typography variant="subtitle1">{`Posted by ${
            data?.organizer
          } | ${moment(
            moment(data?.createdAt).format('YYYY-MM-DD, HH:mm:ss'),
          ).fromNow()}`}</Typography>

          <Box my={1}>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body1">{data?.description}</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'end',
          gap: '20px',
        }}
      >
        <StyledStatusLabel variant="h6">Yet To be Held</StyledStatusLabel>
        <StyledStack gap={2}>
          <IconLabel icon={<LocationIcon />} label={data?.venue} />
          <IconLabel
            icon={<CalendareIcon />}
            label={moment(data?.time).format('DD MMMM, YYYY hh:mm A')}
          />
        </StyledStack>
      </Box>
    </StyledEventDetailsBox>
  )
}

export const Members = ({ data, showExport = true }) => {
  const params: any = useParams()

  const { mutate: deleteParticipant } = useMutation(removeEventParticipant, {
    onSuccess: (data) => {
      toast.success('Participant Removed Successfully')
      queryClient.invalidateQueries('event-details')
    },
  })

  const { isConfirmed } = useConfirm()

  const handleDelete = async (participantId: number) => {
    const confirmed = await isConfirmed(
      'Are you sure? You want to Delete a participant?',
    )
    if (!confirmed) return
    deleteParticipant({
      participantId,
    })
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'user.fullName',
      key: 'user.fullName',
    },
    {
      title: 'Email',
      dataIndex: 'user.email',
      key: 'email',
      render: (_, row) => {
        return <>{row?.user?.email ? row?.user?.email : '-'}</>
      },
    },
    {
      title: 'Batch',
      dataIndex: 'student.batch',
      key: 'batch',
    },
    {
      title: 'Program',
      dataIndex: 'student.branchCode',
      key: 'branch',
    },
    {
      title: 'ID Number',
      key: 'rollNo',
      dataIndex: 'rollNo',
      render: (_, row) => {
        return <>{row?.user?.id ? row?.user.id : row?.id}</>
      },
    },
    {
      title: 'User Type',
      key: 'userType',
      dataIndex: 'userType',
      render: (_, row) => {
        return (
          <>
            {row?.userType == 'admin'
              ? 'Admin'
              : row?.userType == 'participant'
              ? 'Student'
              : 'Faculty'}
          </>
        )
      },
    },
    {
      title: 'Attendance',
      key: 'isAttended',
      dataIndex: 'isAttended',
      render: (_, row) => {
        return (
          <Typography style={{ color: row?.isAttended ? 'green' : 'red' }}>
            {row?.isAttended ? 'Present' : 'Absent'}
          </Typography>
        )
      },
    },
    {
      title: 'CheckIn',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: (_, row) => {
        return (
          <Typography>
            {row?.isAttended
              ? format(new Date(row.updatedAt), 'dd MMM yyyy hh:mm:ss a')
              : '--'}
          </Typography>
        )
      },
    },
    {
      title: 'CheckInBy',
      key: 'checkInUserName',
      dataIndex: 'checkInUser.fullName',
      render: (col, row) => row?.checkInUser?.fullName || '--',
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: '',
      render: (_, row) => {
        return (
          <DeleteButton
            onClick={() => {
              handleDelete(row.id)
            }}
          />
        )
      },
    },
  ]

  return (
    <Box sx={{ mt: '30px' }}>
      {showExport && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3" sx={{ mb: '20px' }}>
            Interested members/Participants
          </Typography>

          <ActionButton
            variant="outlined"
            onClick={() => excelExport({ eventId: params.eventId })}
            sx={{ marginBottom: '16px' }}
          >
            Export
          </ActionButton>
        </Box>
      )}
      <Table
        key={'interestedMembers'}
        dataSource={data}
        rowKey={'id'}
        columns={columns}
        loading={false}
        pagination={null}
      />
    </Box>
  )
}
