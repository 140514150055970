import {
  ActionButton,
  Breadcrumbs,
  DialogButton,
  DropDownButton,
  PageContent,
  PageHeader,
  Permission,
  Spinner,
  ValidateAccess,
  useConfirm,
  useErrorModal,
} from '@campxdev/shared'
import { queryClient } from '@campxdev/shared/src/contexts/QueryClientProvider'
import LockIcon from '@mui/icons-material/Lock'
import { FeedbackStatuses } from 'pages/Feedback/FacultyFeedbacks/components/FeedbackStatus'
import { FeedbackStore } from 'pages/Feedback/store'
import { FeedbackTypes } from 'pages/Feedback/utils'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SERVICES } from 'services'
import UpsertFacilityFeedbackForm from '../Forms/UpsertFacilityFeedbackForms'
import { ClassroomWiseAnalysis } from './ClassroomWiseAnalysis/ClassroomWiseAnalysis'
import { ManageTargetAudience } from './ManageTargetAudience/ManageTargetAudience'
import { FacilityFeedbackDetailsCard } from './components/FacilityFeedbackDetailCard'

const FacilityFeedback = () => {
  const params = useParams()
  const navigate = useNavigate()
  const facilityFeedbacksStore = FeedbackStore.useState((s) => s)
  const errorModal = useErrorModal()
  const feedback = facilityFeedbacksStore.feedback

  const { isConfirmed } = useConfirm()

  const { isLoading, isRefetching } = useQuery(
    'feedback',
    () => SERVICES.facilityFeedbacks.getFacilityFeedbackById(params.id),
    {
      onSuccess(data) {
        FeedbackStore.update((s) => ({
          ...s,
          feedback: data,
          type: FeedbackTypes.FACILITY,
        }))
      },
      enabled: facilityFeedbacksStore.enableAPI,
    },
  )

  const { mutate, isLoading: changingStatus } = useMutation(
    SERVICES.facilityFeedbacks.updateFacilityFeedbackStatus,
    {
      onSuccess: (data) => {
        toast.success(data.data.message)
        queryClient.invalidateQueries('feedback')
        queryClient.invalidateQueries(
          'classroom-wise-feedback-analysis-facility',
        )
      },
      onError: (err) => {
        errorModal({ error: err })
      },
    },
  )

  const handlePublish = async () => {
    const confirmed = await isConfirmed(
      `Are you sure you want to Publish?
        This Action disables Form Configuration Indefinitely`,
    )
    if (!confirmed) return
    mutate({
      _id: params.id,
      status: FeedbackStatuses.PUBLISHED,
    })
  }

  const handleUnpublish = async () => {
    const confirmed = await isConfirmed('Are you sure you want to Unpublish?')
    if (!confirmed) return
    mutate({
      _id: params.id,
      status: FeedbackStatuses.UNPUBLISHED,
    })
  }

  if (isLoading) return <Spinner />
  const disableFormConfiguration =
    feedback?.status !== FeedbackStatuses.CONFIGURATION_PENDING &&
    feedback?.status !== FeedbackStatuses.PUBLISH_PENDING

  return (
    <>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              {
                name: 'Facility Feedbacks',
                to: '/feedback/facility-feedbacks',
              },
              { name: `${feedback?.title}`, to: null },
            ]}
          />
        }
        actions={[
          <ValidateAccess
            key={1}
            accessKey={Permission.CAN_CONFIGURE_FEEDBACK_FORM}
          >
            <ActionButton
              key={1}
              variant="outlined"
              onClick={() =>
                navigate(`configure-form/${FeedbackTypes.FACILITY}`)
              }
              disabled={disableFormConfiguration}
              endIcon={disableFormConfiguration ? <LockIcon /> : <></>}
            >
              Configure Form
            </ActionButton>
          </ValidateAccess>,
          <ValidateAccess
            key={2}
            accessKey={Permission.MANAGE_TARGET_AUDIENCE_FACILITY}
          >
            <DialogButton
              key={2}
              dialogProps={{
                maxWidth: 'md',
              }}
              anchor={({ open }) => (
                <ActionButton onClick={open}>
                  Manage Target Audience
                </ActionButton>
              )}
              onDialogClose={() => {
                queryClient.invalidateQueries('feedback')
                queryClient.invalidateQueries(
                  'classroom-wise-feedback-analysis-facility',
                )
              }}
              content={({ close }) => <ManageTargetAudience close={close} />}
              title={'Manage Target Audience'}
            />
          </ValidateAccess>,
          <DropDownButton
            key={3}
            menu={[
              feedback?.status != FeedbackStatuses.CONFIGURATION_PENDING
                ? feedback?.status != FeedbackStatuses.PUBLISHED
                  ? {
                      label: 'Publish All',
                      onClick: handlePublish,
                      permissionKey: Permission.CAN_CHANGE_FEEDBACK_STATUS,
                    }
                  : {
                      label: 'Unpublish All',
                      onClick: handleUnpublish,
                      permissionKey: Permission.CAN_CHANGE_FEEDBACK_STATUS,
                    }
                : {
                    label: 'Configure Form to Publish',
                    icon: <LockIcon />,
                    onClick: () => navigate('configure-form/facility'),
                  },
              {
                actionType: 'link',
                link: {
                  to: `/public/feedback-form/${params.id}/preview?type=${FeedbackTypes.FACILITY}`,
                },
                label: 'Preview Form',
              },
              {
                label: 'Edit',
                actionType: 'drawer',
                contentTitle: 'Edit Faculty Feedback',
                content: ({ close }) => (
                  <UpsertFacilityFeedbackForm
                    data={facilityFeedbacksStore.feedback}
                    close={close}
                  />
                ),
              },
            ]}
          />,
        ]}
      />
      <PageContent>
        <FacilityFeedbackDetailsCard data={feedback} />
        <ClassroomWiseAnalysis />
      </PageContent>
    </>
  )
}

export default FacilityFeedback
