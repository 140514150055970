import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { ofType } from 'redux-observable'
import { map, switchMap } from 'rxjs/operators'
import { axios } from '@campxdev/shared'
import * as XLSX from 'xlsx'

export const userTypes = ['Staff_User', 'Faculty_User', 'Student_User']
interface UsersState {
  data: Array<any>
  error: any
  loading: boolean
  users: Array<any>
  filteredData: Array<any>
}

const initialState: UsersState = {
  data: [],
  error: null,
  loading: true,
  users: [],
  filteredData: [],
}

function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0)
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsers(state) {},
    fetchingUsers(state) {
      state.loading = true
    },
    fetchedUsers(state, action) {
      state.data = action.payload.data
      state.users = action.payload.data
      state.loading = false
      state.error = null
    },
    onSearch(state, action) {
      let value = action.payload.value?.toUpperCase()
      if (!value) {
        state.users = state.data
        return
      }
      state.users = state.data.filter((user) => {
        if (
          user.fullName &&
          `${user.fullName?.toUpperCase() || ''}`.includes(value?.toUpperCase())
        ) {
          return true
        } else if (
          user.username &&
          `${user.username?.toUpperCase() || ''}`.includes(value)
        ) {
          return true
        } else if (
          user.email &&
          `${user.email?.toUpperCase() || ''}`.includes(value)
        ) {
          return true
        } else if (user.id && `${user.id}`.includes(value)) {
          return true
        }
      })
    },
    exportToExcel(state) {
      if (!state.users) {
        alert('Please fetch the report first')
        return
      }

      const excelData = state.users.map((item, index) => ({
        id: item.id,
        'S.No': index + 1,
        Name: item.fullName,
        'User name': item.username,
        Email: item.email,
        Designation: item.designation,
        Department: item?.department?.name,
        // Roles: item.roles.join(','),
      }))

      const fileName = `Users.xlsx`
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
      const wb: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `${moment().format('DD-MM-YYYY')} - Users`,
      )
      XLSX.writeFile(wb, fileName)
    },
  },
})

export const { fetchUsers, fetchedUsers, onSearch, exportToExcel } =
  usersSlice.actions

export function fetchUserssEpic(action$) {
  return action$.pipe(
    ofType(fetchUsers.type),
    switchMap(async (action: any) => {
      try {
        let res = await axios.get(`/square/users`)

        return {
          data: res.data,
        }
      } catch (err) {
        console.log(err)
        alert(`Error occurred: [${err.message}]`)
        window.location.href = '/'
      }
    }),
    map((data) => fetchedUsers(data)),
  )
}

export default usersSlice.reducer
