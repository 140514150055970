import { icons } from 'assets'
import * as yup from 'yup'

export enum QuestionTypes {
  question = 'question',
  RADIO = 'radio',
  SINGLE_SELECT = 'singleSelect',
  MULTI_SELECT = 'multiSelect',
  TEXT_FIELD = 'textField',
  MULTI_CHECKBOX = 'multiCheckBox',
  RATING = 'rating',
}

export enum FeedbackTypes {
  FACULTY = 'faculty',
  FACILITY = 'facility',
}

export const questionTypes = [
  {
    icon: icons.multiLine,
    label: 'Text Field',
    value: 'textField',
    fieldType: QuestionTypes.TEXT_FIELD,
  },

  {
    icon: icons.dropDown,
    label: 'Single Select',
    value: 'singleSelect',
    fieldType: QuestionTypes.SINGLE_SELECT,
  },
  {
    icon: icons.dropDown,
    label: 'Multi Select',
    value: 'multiSelect',
    fieldType: QuestionTypes.MULTI_SELECT,
  },
  {
    icon: icons.checkBox,
    label: 'Radio Group',
    value: 'radio',
    fieldType: QuestionTypes.RADIO,
  },
  {
    icon: icons.checkBox,
    label: 'Multi Check Box',
    value: 'multiCheckBox',
    fieldType: QuestionTypes.MULTI_CHECKBOX,
  },

  {
    icon: icons.termsAndConditions,
    label: 'Rating',
    value: 'rating',
    fieldType: QuestionTypes.RATING,
  },
]

// export const getDefaultValues = (item: any) => {
//   switch (item) {
//     case QuestionTypes.TEXT_FIELD:
//       return {
//         required: false,
//         placeholderText: null,
//         fieldSize: 'small',
//         showCharacterCount: false,
//         minimumLength: 1,
//         maximumLength: 1,
//         lengthMetric: 'characters',
//       }

//     case QuestionTypes.SINGLE_SELECT:
//       return {
//         required: false,
//         fieldSize: 'small',
//         options: [{ value: '' }, { value: '' }],
//       }

//     case QuestionTypes.MULTI_SELECT:
//       return {
//         required: false,
//         fieldSize: 'small',
//         options: [{ value: '' }, { value: '' }],
//         minimumSelectedOptions: 1,
//         maximumSelectedOptions: 1,
//       }

//     case QuestionTypes.MULTI_CHECKBOX:
//       return {
//         required: false,
//         options: [{ value: '' }, { value: '' }],
//         minimumSelectedOptions: 1,
//         maximumSelectedOptions: 1,
//         columns: 1,
//       }

//     case QuestionTypes.RADIO:
//       return {
//         required: false,
//         options: [{ value: '' }, { value: '' }],
//         columns: 1,
//       }

//     case QuestionTypes.RATING:
//       return {
//         required: false,
//         type: 'stars',
//         allowPartialRating: false,
//       }

//     default:
//       break
//   }
// }

export const getValidationSchema = (
  questionType: QuestionTypes,
  hasScore: boolean,
) => {
  let baseSchema = yup.object().shape({
    question: yup.string().nullable().trim().required('Question is required'),
  })

  switch (questionType) {
    case QuestionTypes.TEXT_FIELD:
      return baseSchema

    case QuestionTypes.SINGLE_SELECT:
      return baseSchema.shape({
        singleSelect: yup.object().shape({
          options: yup.array().of(
            yup.object().shape({
              option: yup.string().required('Option is Required'),
              ...(hasScore
                ? { score: yup.number().required('Score is Required') }
                : {}),
            }),
          ),
        }),
      })

    case QuestionTypes.MULTI_SELECT:
      return baseSchema.shape({
        multiSelect: yup.object().shape({
          options: yup.array().of(
            yup.object().shape({
              option: yup.string().required('Option is Required'),
              ...(hasScore
                ? { score: yup.number().required('Score is Required') }
                : {}),
            }),
          ),
        }),
      })

    case QuestionTypes.RADIO:
      return baseSchema.shape({
        radio: yup.object().shape({
          options: yup.array().of(
            yup.object().shape({
              option: yup.string().required('Option is Required'),
              ...(hasScore
                ? { score: yup.number().required('Score is Required') }
                : {}),
            }),
          ),
        }),
      })

    case QuestionTypes.MULTI_CHECKBOX:
      return baseSchema.shape({
        multiCheckBox: yup.object().shape({
          options: yup.array().of(
            yup.object().shape({
              option: yup.string().required('Option is Required'),
              ...(hasScore
                ? { score: yup.number().required('Score is Required') }
                : {}),
            }),
          ),
        }),
      })

    case QuestionTypes.RATING:
      return baseSchema.shape({
        maximumScore: yup
          .number()
          .typeError('maximumScore must be a number')
          .required('maximumScore is required')
          .min(1, 'Maximum Score must be at least 1'),
      })

    default:
      return baseSchema
  }
}

export const getFieldSize = (fieldSize: 'small' | 'medium' | 'large') => {
  if (window.innerWidth < 680) {
    switch (fieldSize) {
      case 'small':
        const caluclate = ((window.innerWidth - 30) * 50) / 100
        return `${caluclate}px`
      case 'medium':
        const caluclate2 = ((window.innerWidth - 30) * 75) / 100
        return `${caluclate2}px`
      case 'large':
        return `${window.innerWidth - 30}px`
      default:
        return `${window.innerWidth - 30}px`
    }
  } else {
    switch (fieldSize) {
      case 'small':
        return '50%'
      case 'medium':
        return '75%'
      case 'large':
        return '100%'
      default:
        return '100%'
    }
  }
}
