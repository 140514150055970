import { createSlice } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { map, switchMap } from 'rxjs/operators'
import { axios } from '@campxdev/shared'
export interface CurriculumState {
  data: Curriculums[]
  selectedProgramId: boolean
  error: any
  loading: boolean
  curriculums: Curriculums[]
}

export interface Curriculums {
  finalGraceMarks: number
  finalGraceSubjects: number
  gracePercentage: string
  id: number
  maxCreditsExcluded: number
  maxSubjectsExcluded: number
  name: string
  program: Program
  programId: number
  totalCredits: number
  year: string
}

export interface Program {
  branchCode: string
  branchDisplay: string
  branchId: any
  branchName: string
  courseId: number
  courseName: string
  id: number
  seats: number
}

const initialState: CurriculumState = {
  data: [],
  selectedProgramId: null,
  error: null,
  loading: false,
  curriculums: [],
}

const curriculumsSlice = createSlice({
  name: 'curriculums',
  initialState: initialState,
  reducers: {
    fetchCurriculums(state, action: any) {
      state.loading = true
    },
    fetchedCurriculums(state, action: any) {
      state.loading = false
      state.data = action.payload.data
      state.curriculums = action.payload.data
    },
    filterCurriculums(state, action: any) {
      state.selectedProgramId = action.payload.programId
      state.curriculums = state.data.filter(
        (item) => item.program.id == action.payload.programId,
      )
    },
  },
})

export function fetchCurriculumsEpic(action$) {
  return action$.pipe(
    ofType(fetchCurriculums.type),
    switchMap(async (action: any) => {
      const res = await axios.get(`/square/curriculums`)
      return {
        data: res.data,
      }
    }),
    map(fetchedCurriculums),
  )
}

export const { fetchCurriculums, filterCurriculums } = curriculumsSlice.actions

const { fetchedCurriculums } = curriculumsSlice.actions

export default curriculumsSlice.reducer
