import { SquarePermissions } from '@campxdev/shared/src/shared-state/PermissionsStore'

import { MyMentees } from 'pages/Mentors/MyMentees'
import { AddSession } from 'pages/Mentors/components/AddSession'
import HodComments from 'pages/Mentors/components/HodComments'
import { MenteeDetails } from 'pages/Mentors/components/MenteeDetails'

export const myMenteesRoutes = [
  {
    index: true,
    element: <MyMentees />,
    permissionKey: SquarePermissions.CAN_VIEW_MENTEES,
  },
  {
    element: <MenteeDetails />,
    path: ':studentId',
    permissionKey: SquarePermissions.CAN_VIEW_MENTEES,
  },
  {
    element: <AddSession />,
    path: ':studentId/add-session',
    permissionKey: SquarePermissions.CAN_CREATE_SESSION,
  },
  {
    element: <HodComments />,
    path: ':studentId/hod-comments',
    permissionKey: SquarePermissions.CAN_CREATE_SESSION,
  },
]
