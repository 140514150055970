import {
  AppHeader,
  ErrorBoundary,
  Helmet,
  Spinner,
  useAuth,
} from '@campxdev/shared'
import { Outlet, useNavigate } from 'react-router-dom'
import {
  StyledHeaderContainer,
  StyledMainContentContainer,
} from './styles/mainLayout'
import { IconButton } from '@mui/material'
import { CareerIcon, ExamResultIcon } from 'Icons'

export default function AppLayout() {
  const navigate = useNavigate()
  const { data, loading } = useAuth({
    permissionsEndpoint: '/auth-server/auth/my-permissions',
  })

  if (loading) {
    return <Spinner height={'100vh'} />
  }

  const actions = [
    <IconButton
      onClick={() => {
        navigate('/careers')
      }}
    >
      <CareerIcon />
    </IconButton>,
    <IconButton
      onClick={() => {
        navigate('/results')
      }}
    >
      <ExamResultIcon />
    </IconButton>,
  ]

  return (
    <>
      <Helmet
        user={data?.user}
        appTitle="Digital Campus | Campx"
        favicon={data?.assets?.logo_square}
        description={'UMS Aupulse'}
      />

      <StyledHeaderContainer>
        <AppHeader
          userBoxActions={[]}
          clientLogo={data?.assets?.logo}
          fullName={data?.user?.fullName}
          profileUrl={data?.user?.picture?.url}
          actions={actions}
        />
      </StyledHeaderContainer>
      <StyledMainContentContainer>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </StyledMainContentContainer>
    </>
  )
}
