import { Permission } from '@campxdev/shared'
import { lazy } from 'react'

const FormValidations = lazy(() => import('pages/FormBuilder/FormValidation'))
const Forms = lazy(() => import('pages/FormBuilder/Forms'))
const FormBuilder = lazy(() => import('pages/FormBuilder/FormConfig'))
const FormDetails = lazy(() => import('pages/FormBuilder/FormDetails'))
const FormConfiguration = lazy(
  () => import('pages/FormBuilder/FormConfiguration'),
)

export const formsRoutes = [
  {
    element: <Forms />,
    path: 'forms',
    permissionKey: Permission.COURSE_REGISTRATION_VIEW,
  },
  {
    element: <FormBuilder />,
    path: 'builder/:formId',
    permissionKey: Permission.COURSE_REGISTRATION_VIEW,
  },
  {
    element: <FormValidations />,
    path: 'form-validations',
    permissionKey: Permission.COURSE_REGISTRATION_VIEW,
  },
  {
    element: <FormDetails />,
    path: ':formId/view',
    permissionKey: Permission.COURSE_REGISTRATION_VIEW,
  },
  {
    element: <FormConfiguration />,
    path: 'configure-event/:formId/*',
    permissionKey: Permission.COURSE_REGISTRATION_VIEW,
  },
]
