import { Box, styled } from '@mui/material'

function StudentResultsHeader({ logo }) {
  return (
    <StyledHeader>
      <img
        src={logo}
        style={{
          width: 'auto',
          height: '24px',
        }}
      />
    </StyledHeader>
  )
}

export default StudentResultsHeader

export const StyledHeader = styled(Box)(({ theme }) => ({
  boxShadow: '0px 3px 15px #0000001a',
  background: 'white',
  padding: '20px',
  width: '100%',
  justifyContent: 'center',
  display: 'flex',
}))
