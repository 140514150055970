import { Permission } from '@campxdev/shared'
import { SquarePermissions } from '@campxdev/shared/src/shared-state/PermissionsStore'
import StudentView from 'pages/ClassRooms/ClassRoomSubject/Students/Student View'
import FacultyTimetable from 'pages/ClassRooms/FacultyTimetable'
import { lazy } from 'react'
const ClassRooms = lazy(() => import('pages/ClassRooms/ClassRooms'))
const ClassRoomSubject = lazy(() => import('pages/ClassRooms/ClassRoomSubject'))
const ClassRoomView = lazy(() => import('pages/ClassRooms/ClassRoomView'))
const ClassroomReportLayout = lazy(
  () => import('pages/ClassRooms/ClassRoomReports/ReportsViewLayout'),
)
const ClassroomReports = lazy(() => import('pages/ClassRooms/ClassRoomReports'))
const ClassRoomSessions = lazy(
  () =>
    import('pages/ClassRooms/ClassRoomTimeTable/Sessions/ClassRoomSessions'),
)
const ClassRoomTimeTable = lazy(
  () => import('pages/ClassRooms/ClassRoomTimeTable'),
)

const MyClassroom = lazy(() => import('pages/ClassRooms/MyClassroom'))
const StudentDetails = lazy(
  () => import('pages/Students/AllStudents/StudentDetails'),
)

export const classroomRoutes = [
  {
    element: <FacultyTimetable />,
    name: 'Faculty Time Table Print Filters',
    path: 'faculty-timetable',
    permissionKey: Permission.CLASSROOM_VIEW,
  },
  {
    element: <StudentView />,

    path: ':classRoomId/subject/:subjectId/students/:studentId',
    permissionKey: SquarePermissions.CAN_VIEW_MY_SUBJECTS,
  },
  {
    element: <StudentDetails />,

    path: ':classRoomId/student/:studentId/student-details',
    permissionKey: Permission.STUDENT_INFO_VIEW,
  },

  {
    element: <ClassRoomSubject />,

    path: ':classRoomId/subject/:subjectId/*',
    permissionKey: SquarePermissions.CAN_VIEW_MY_SUBJECTS,
  },
  {
    element: <ClassRoomSessions />,

    path: ':classRoomId/timetable/sessions',
    permissionKey: Permission.CLASSROOM_VIEW,
  },
  {
    element: <ClassRoomTimeTable />,

    path: ':classRoomId/timetable',
    permissionKey: Permission.CLASSROOM_VIEW,
  },
  {
    element: <ClassRoomView />,
    path: ':classRoomId',
    permissionKey: Permission.SUBJECTS_VIEW,
  },
  {
    element: <ClassRooms />,

    path: 'classrooms',
    permissionKey: Permission.SUBJECTS_VIEW,
  },

  {
    element: <ClassroomReportLayout />,
    path: 'reports/report-view/*',

    permissionKey: Permission.CLASSROOM_VIEW,
  },
  {
    element: <ClassroomReports />,
    path: 'reports',
    name: 'Reports',
    exact: true,
    permissionKey: Permission.CLASSROOM_VIEW,
  },
]
