// icons
import address from './icons/address.svg'
import checkBox from './icons/checkBox.svg'
import currency from './icons/currency.svg'
import fieldDate from './icons/date.svg'
import decisionBox from './icons/decisionBox.svg'
import dropDown from './icons/dropDown.svg'
import email from './icons/email.svg'
import pdf from './icons/fileTypes/pdf.png'
import fileUpload from './icons/fileUpload.svg'
import mobileNumber from './icons/mobileNumber.svg'
import multiLine from './icons/multiLine.svg'
import name from './icons/name.svg'
import numbers from './icons/number.svg'
import singleLine from './icons/singleLine.svg'
import termsAndConditions from './icons/termsAndConditions.svg'

import mentorAssigned from './icons/mentor_assigned.svg'
// svgs
import lock from './icons/lock.svg'
import studentFemale from './images/svg/student-female.svg'
import studentMale from './images/svg/student-male.svg'
const empty = require('./images/svg/empty.svg')
const logo = require('./images/svg/logo.svg')

const assessment_Marks = require('./static_files/assessment_Marks.xlsx')
// png
import clientFormCard from './images/png/clientFormCard.png'

export {
  assessment_Marks,
  clientFormCard,
  empty,
  logo,
  studentFemale,
  studentMale,
}

export const icons = {
  pdf,
  singleLine,
  email,
  address,
  multiLine,
  name,
  checkBox,
  fileUpload,
  termsAndConditions,
  currency,
  dropDown,
  decisionBox,
  fieldDate,
  mobileNumber,
  numbers,
  lock,
  mentorAssigned,
}
