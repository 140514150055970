import {
  Card,
  DropDownButton,
  LabelValue,
  Permission,
  useConfirm,
} from '@campxdev/shared'
import { queryClient } from '@campxdev/shared/src/contexts/QueryClientProvider'
import { MoreVert } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { FeedbackStore } from 'pages/Feedback/store'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { SERVICES } from 'services'

export const FacilityFeedbackCard = ({ data }) => {
  const navigate = useNavigate()
  const { isConfirmed } = useConfirm()

  const { mutate, isLoading } = useMutation(
    SERVICES.facilityFeedbacks.deleteFacilityFeedback,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('facility-feedbacks')
      },
    },
  )

  const handleDelete = async (id) => {
    const confirmed = await isConfirmed(
      'Are you sure you want to delete the feedback?',
    )
    if (!confirmed) return
    mutate(id)
  }
  return (
    <Card
      title={
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography variant="h6">{data?.title}</Typography>
          <DropDownButton
            menu={[
              {
                label: 'Delete',
                onClick: () => handleDelete(data?._id),
                permissionKey: Permission.CREATE_FACILITY_FEEDBACK,
              },
            ]}
            icon={{
              icon: <MoreVert />,
              outlined: false,
              iconProps: {
                sx: {
                  color: 'black',
                },
              },
            }}
          />
        </Stack>
      }
      subheader={
        <Typography variant="subtitle1" marginTop={'10px'}>
          {format(new Date(data?.startDate ?? null), 'dd MMM, yyyy')} -{' '}
          {format(new Date(data?.endDate ?? null), 'dd MMM, yyyy')}
        </Typography>
      }
      footer={{
        onClick() {
          navigate(data?._id)
          FeedbackStore.update((s) => ({
            feedback: data,
          }))
        },
        buttonText: 'View',
      }}
    >
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <LabelValue
          label="Responses"
          value={`${data?.noOfStudentResponses}/${data?.totalNoOfStudentResponses}`}
        />
        <LabelValue label="Total Students" value={data?.noOfStudents ?? 0} />
      </Box>
    </Card>
  )
}
