import { axios } from '@campxdev/shared'
import { CounterFeeDetails } from 'interfaces/payments.interface'

const ENDPOINT = '/paymentx'
export const payments = {
  async getCounterFeeDetails(query: CounterFeeDetails) {
    const res = await axios.get(`${ENDPOINT}/ledger/counter-fee-details`, {
      params: query,
    })
    return res.data
  },

  async fetchFeeDueList(query) {
    const res = await axios.get(`${ENDPOINT}/ledger-reports/due-list-report`, {
      params: query,
    })
    return res.data
  },
}
