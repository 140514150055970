import FullCalendar from '@fullcalendar/react'
//Should be on Top

import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'

import {
  ActionButton,
  FormMultiSelect,
  FormSingleSelect,
  NoDataIllustration,
  PageContent,
  Spinner,
  axios,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, useTheme } from '@mui/material'
import { View } from 'components/FullCalendar/Actions'
import { FullCalendarWrapper } from 'components/FullCalendar/FullCalendarWrapper'
import { parse, set } from 'date-fns'
import _ from 'lodash'
import {
  findMinMaxTimes,
  findMissingWeekdaysIndices,
  subjectColors,
} from 'pages/ClassRooms/ClassRoomTimeTable/constants'
import { RenderCustomEventDisplay } from 'pages/ClassRooms/MyClassroom/FacultyTimetable'
import { Store } from 'pullstate'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import * as yup from 'yup'
import {
  getFaculties,
  getFacultyTimeTable,
} from './ClassRoomReports/ReportPages/service'

const stepSize = 60

const schema = yup.object().shape({
  departmentId: yup.string().required('Departmemt is required').nullable(),
  facultyId: yup.object().required('Faculty is required').nullable(),
})

export const TimeTableStore = new Store({
  subjects: [],
  selectedSubject: null,
  events: [],
  minimumFromTime: null,
  maximumToTime: null,
  weekDays: [],
  userSelected: null,
})

const FacultyTimetable = () => {
  const theme = useTheme()
  const state = TimeTableStore.useState((s) => s)
  const eventElRef = useRef(null)
  const calendarRef = useRef<FullCalendar | null>(null)
  const [view, setView] = useState<View>('timeGridWeek')
  const { control, watch, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      departmentId: null,
      facultyId: null,
    },
  })
  const { data: departments, isLoading: departmentLoading } = useQuery(
    'departments',
    () => axios.get('/hrms/departments').then((res) => res.data?.data),
  )

  const { data: facultyData, isLoading: facultyLoading } = useQuery(
    ['facultyData', watch().departmentId],
    () => getFaculties({ departmentId: watch().departmentId }),
    {
      enabled: !!watch().departmentId,
    },
  )

  const { mutate, isLoading } = useMutation(getFacultyTimeTable, {
    onSuccess(data) {
      const { weekdays, events } = data?.reduce(
        (result, obj) => {
          if (!result.subjectColorMap[obj.subjectId]) {
            result.subjectColorMap[obj.subjectId] =
              subjectColors[result.index]?.light ?? 'white'
            result.index++
          }
          result.weekdays.push(obj.day)
          const date = parse(obj?.day?.toLowerCase(), 'cccc', new Date())
          result.events.push({
            start: set(date, {
              hours: parseInt(obj?.fromTime?.split(':')[0]),
              minutes: parseInt(obj?.fromTime?.split(':')[1]),
            }),
            end: set(date, {
              hours: parseInt(obj?.toTime?.split(':')[0]),
              minutes: parseInt(obj?.toTime?.split(':')[1]),
            }),
            title: obj?.subjectName,
            backgroundColor: result.subjectColorMap[obj.subjectId],
            textColor: theme.palette.secondary.main,
            extendedProps: { ...obj },
          })
          return result
        },
        {
          weekdays: [],
          events: [],
          subjectColorMap: {},
          index: 0,
        },
      )

      const { minFromTime, maxToTime } = findMinMaxTimes(
        'fromTime',
        'toTime',
        data,
      )

      TimeTableStore.update((s) => {
        s.minimumFromTime = minFromTime
        s.maximumToTime = maxToTime
        s.events = events
        s.weekDays = _.uniq(weekdays)
      })
    },
  })

  const onSubmit = (formData) => {
    mutate({
      facultyId: formData.facultyId.value,
    })
    TimeTableStore.update((s) => {
      s.userSelected = true
    })
  }

  return (
    <PageContent>
      <Box
        sx={{
          display: 'flex',
          gap: 5,
          alignItems: 'flex-end',
          maxWidth: '1000px',
        }}
      >
        <FormSingleSelect
          label={'Select Department'}
          name={'departmentId'}
          control={control}
          options={departments?.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
        />
        <FormMultiSelect
          control={control}
          label={'Select Faculty'}
          name={'facultyId'}
          multiple={false}
          loading={facultyLoading}
          noOptionsText="Select Department"
          options={facultyData?.map((item) => ({
            label: item?.fullName,
            value: item?.userId,
          }))}
        />
        <Box>
          <ActionButton
            sx={{ minWidth: '200px', padding: '30px 0px' }}
            onClick={handleSubmit(onSubmit)}
          >
            Get Timetable
          </ActionButton>
        </Box>
      </Box>

      <Box sx={{ padding: '20px 0px' }}>
        {state.userSelected ? (
          isLoading ? (
            <Spinner />
          ) : (
            <FullCalendarWrapper>
              <FullCalendar
                allDayMaintainDuration
                allDaySlot={false}
                slotMinTime={state.minimumFromTime}
                slotMaxTime={state.maximumToTime}
                slotDuration={`00:${stepSize}`}
                initialDate={new Date()}
                slotLabelFormat={{ hour: '2-digit', minute: '2-digit' }}
                initialView={view}
                eventDisplay="block"
                dayHeaderFormat={{
                  weekday: 'long',
                }}
                eventContent={RenderCustomEventDisplay}
                hiddenDays={findMissingWeekdaysIndices(state.weekDays)}
                eventDurationEditable={false}
                eventStartEditable={false}
                dayMaxEventRows={4}
                eventResizableFromStart
                events={state.events}
                headerToolbar={false}
                height={'calc(100vh - 200px)'}
                ref={calendarRef}
                rerenderDelay={10}
                weekends
                eventMouseEnter={(ctx) => {
                  eventElRef.current = ctx.el
                }}
                eventMouseLeave={(ctx) => {
                  eventElRef.current = null
                }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              />
            </FullCalendarWrapper>
          )
        ) : (
          <NoDataIllustration imageSrc="/illustrations/whiteboard.svg" />
        )}
      </Box>
    </PageContent>
  )
}

export default FacultyTimetable
