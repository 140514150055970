import { createSlice } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { map, switchMap } from 'rxjs/operators'
import { axios } from '@campxdev/shared'

const initialState = {
  loading: true,
  data: [],
  error: null,
  evaluationTypes: [],
}

const evaluationTypes = createSlice({
  name: 'evaluation-types',
  initialState: initialState,
  reducers: {
    fetchEvaluationTypes(state, action: any) {
      state.loading = true
    },
    fetchedEvaluationTypes(state, action: any) {
      state.loading = false
      state.data = action.payload.data
      state.evaluationTypes = action.payload.data.map((item) => ({
        ...item,
        isEditing: false,
      }))
    },
    updateValue(state, action: any) {
      let index = action.payload.index
      let key = action.payload.key
      let value = action.payload.value

      if (key == 'isWrittenExam' && value == true) {
        state.evaluationTypes[index]['name'] = 'Internal'
      }

      state.evaluationTypes[index][key] = value
    },
    addEvaluationType(state) {
      state.evaluationTypes = state.evaluationTypes.filter((f) => f)
      let id = state.evaluationTypes.length + 1
      state.evaluationTypes = [
        ...state.evaluationTypes,
        {
          name: 'New EvaluationType ' + id,
          isNew: true,
          isEditing: true,
        },
      ]
    },
    removeEvaluationType(state, action) {
      delete state.evaluationTypes[action.payload.index]
    },
    editEvaluationType(state, action) {
      state.evaluationTypes[action.payload.index].isEditing = true
    },
    updateEvaluationType(state, action) {
      state.evaluationTypes[action.payload.index].isEditing = false
    },
    saveEvaluationType(state, action) {
      state.evaluationTypes[action.payload.index] = action.payload.data
    },
    onCancel(state, action) {
      state.evaluationTypes[action.payload.index].isEditing = false
    },
  },
})

export function fetchEvaluationTypesEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchEvaluationTypes.type),
    switchMap(async (action: any) => {
      const res = await axios.get(
        `/square/evaluation-types?curriculumId=${action.payload.curriculumId}`,
      )
      return {
        data: res.data,
      }
    }),
    map(fetchedEvaluationTypes),
  )
}

const { fetchedEvaluationTypes } = evaluationTypes.actions

export const {
  fetchEvaluationTypes,
  updateEvaluationType,
  updateValue,
  onCancel,
  saveEvaluationType,
  addEvaluationType,
  removeEvaluationType,
  editEvaluationType,
} = evaluationTypes.actions
export default evaluationTypes.reducer
