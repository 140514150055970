import { Box, Stack, Typography, styled } from '@mui/material'

export const StyledEventDetailsBox = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  display: 'grid',
  gridTemplateColumns: '160px 1fr 320px',
  padding: '20px',
  border: theme?.borders?.grayLight,
  gap: '20px',
}))

export const StyledPreviewBox = styled(Box)({
  zIndex: 500,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  opacity: 0,
  cursor: 'pointer',
})

export const StyledTypographyBox = styled(Typography)({
  fontSize: '12px',
  display: 'flex',
  gap: '7px',
  margin: '3px',
  lineHeight: '13px',
  padding: '5px',
  whiteSpace: 'nowrap',
})
export const StyledBoxBorder = styled(Box)({
  border: '1px solid white',
  borderRadius: '4px',
  padding: '8px',
  height: '40px',
})
export const StyledCard = styled(Stack)({
  padding: '20px',
  border: '1px solid #1212121a',
  maxWidth: '30%',
  marginTop: '20px',
  borderRadius: '10px',
})

export const StyledContainer = styled(Box)({
  display: 'flex',

  padding: '20px',
  border: '1px solid #1212121a',
  marginBottom: '20px',
  gap: '20px',
})

export const StyledDescriptionBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '100%',
})

export const StyledBox = styled(Box)({
  maxWidth: '300px',
  border: '1px solid #1212121A',
  borderRadius: '10px',
  position: 'relative',

  '&:hover': {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      zIndex: 1,
      borderRadius: '10px',
    },
    '& > div': {
      opacity: 1,
    },
  },
})

export const StyledImageContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 10,
  top: 10,
  cursor: 'pointer',
  zIndex: 1000,
  opacity: 0,
  transition: 'opacity 0.3s ease',
}))
