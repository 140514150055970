import { axios } from '@campxdev/shared'
import { AxiosRequestConfig } from 'axios'

export const INSTITUTIONS = '/square/institutions'

export const institutions = {
  async fetchAll(params: {}) {
    const res = await axios.get(INSTITUTIONS, {
      params,
    })
    return res.data
  },
  async create(body: any) {
    const config: AxiosRequestConfig = {
      method: body?.id ? 'PUT' : 'POST',
      url: body?.id
        ? `/square/institutions/${body.id}`
        : '/square/institutions',
      data: {
        ...body,
      },
    }

    return axios(config)
  },
}
