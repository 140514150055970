import {
  ActionButton,
  FormDatePicker,
  FormTextField,
  useErrorModal,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { SERVICES } from 'services'
import * as yup from 'yup'

const schema = yup.object().shape({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
  endDate: yup
    .date()
    .min(
      yup.ref('startDate'),
      'End date must be greater than or equal to the start date',
    )
    .required('End date is required'),
  startDate: yup.date().required('Start date is required'),
})

export default function UpsertFacilityFeedbackForm({ data, close }) {
  const queryClient = useQueryClient()
  const errorModal = useErrorModal()
  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: data,
  })

  const { mutate, isLoading: posting } = useMutation(
    SERVICES.facilityFeedbacks.upsertFacilityFeedback,
    {
      onSuccess() {
        close()
        toast.success(
          data
            ? 'Facility Feedback Updated Successfully'
            : 'Facility Feedback Created Successfully',
        )
        data
          ? [
              queryClient.invalidateQueries('facility-feedback'),
              //   queryClient.invalidateQueries('classroom-wise-feedback-analysis'),
            ]
          : queryClient.invalidateQueries('facility-feedbacks')
      },

      onError(error) {
        errorModal({ error: error })
      },
    },
  )

  const onSubmit = (formData) => {
    mutate({ formData })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2.5}>
        <FormTextField
          control={control}
          name="title"
          label={'Title'}
          required
        />
        <FormTextField
          control={control}
          name="description"
          label={'Description'}
          required
        />
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <FormDatePicker
            control={control}
            name="startDate"
            required
            label="Start Date"
          />
          <FormDatePicker
            minDate={watch('startDate')}
            control={control}
            name="endDate"
            required
            label="End Date"
          />
        </Box>
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <ActionButton fullWidth type="submit" loading={posting}>
            {data ? 'Update FeedBack' : 'Create Feedback'}
          </ActionButton>
          <ActionButton variant="outlined" fullWidth onClick={close}>
            Cancel
          </ActionButton>
        </Box>
      </Stack>
    </form>
  )
}
