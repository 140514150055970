import {
  ActionButton,
  NoDataIllustration,
  ReportHeader,
  Spinner,
} from '@campxdev/shared'
import { Box, Stack, Typography } from '@mui/material'
import { formImage } from 'assets/images'
import _ from 'lodash'
import { useState } from 'react'
import { useQuery } from 'react-query'

import CustomReportGenerator from 'pages/ClassRooms/ClassRoomView/IndividualClassroomReports/components/CustomReportGenerator'
import { exportAssessmentReport, fetchAssessmentReport } from './service'
import {
  StyledLogoContainer,
  StyledMediaPrint,
  StyledSignatureContainer,
  StyledTableContainer,
} from './styles'

function useUrlParams() {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  for (const i in params) {
    params[i] = decodeURIComponent(params[i])
  }
  return params
}

export default function AssessmentReport() {
  const params = useUrlParams()
  const [headers, setHeaders] = useState([])
  const { data, isLoading } = useQuery(
    'assessment-report',
    () => fetchAssessmentReport(params),
    {
      onSuccess: (res) => {
        setHeaders(res?.header?.map((item) => ({ label: item, status: true })))
      },
    },
  )

  if (isLoading) return <Spinner />

  if (data?.response.length == 0) {
    return <NoDataComponent />
  }

  const groupedBySubject = _.groupBy(data?.response, (v) => v['Subject Code'])
  const updatedHeaderList = headers.map((labelObj) => {
    switch (labelObj.label) {
      case 'Branch':
        return { ...labelObj, label: 'Program' }
      case 'Subject Name':
        return { ...labelObj, label: 'Course Name' }
      case 'subject Order':
        return { ...labelObj, label: 'Course Order' }
      case 'Subject Code':
        return { ...labelObj, label: 'Course Code' }
      default:
        return labelObj
    }
  })

  return (
    <div style={{ padding: '24px', width: '100%' }}>
      <StyledMediaPrint>
        <CustomReportGenerator
          columns={updatedHeaderList}
          dialogProps={{
            maxWidth: 'xl',
          }}
          onSubmit={(columns) => {
            setHeaders(columns)
          }}
        />
        <ActionButton
          size={'small'}
          onClick={() =>
            exportAssessmentReport(
              params?.branchCode,
              params?.batch,
              data,
              updatedHeaderList,
            )
          }
        >
          Download XLSX
        </ActionButton>
      </StyledMediaPrint>

      {Object.keys(groupedBySubject)?.map((subject) => {
        const groupBySection = _.groupBy(
          groupedBySubject[subject],
          (v) => v.Section,
        )
        return Object.keys(groupBySection)?.map((section) => {
          let chunkLength = 20
          let chunks = _.chunk(groupBySection[section], chunkLength)
          return chunks?.map((chunk, chunkIndex) => {
            return (
              <>
                <Box
                  sx={{ pageBreakAfter: 'always', marginTop: '20px' }}
                  key={chunkIndex}
                >
                  <Stack gap={2}>
                    <ReportHeader
                      showDivider
                      typographyList={[
                        {
                          text: params?.pageTitle,
                          variant: 'h3',
                        },
                      ]}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div>
                        <LabelValue
                          label={'Course Name'}
                          value={chunk[0]?.['Subject Name']}
                        />
                        <LabelValue
                          label={'Course Code'}
                          value={chunk[0]?.['Subject Code']}
                        />
                      </div>
                      <div>
                        <LabelValue
                          label={'Regulation'}
                          value={chunk[0]?.['Regulation']}
                        />
                        <LabelValue
                          label={'Program'}
                          value={chunk[0]?.['Branch']}
                        />
                        <LabelValue
                          label={'Section'}
                          value={chunk[0]?.['Class Room']}
                        />
                      </div>
                    </div>
                    <AssessmentTable headers={headers} records={chunk} />
                    <StyledSignatureContainer>
                      <Typography variant="h2">Faculty</Typography>
                      <Typography variant="h2">
                        Head of the department
                      </Typography>
                    </StyledSignatureContainer>
                  </Stack>
                </Box>
              </>
            )
          })
        })
      })}

      <SubjectWiseSummaryReport data={data} pageTitle={params?.pageTitle} />
    </div>
  )
}

export const Header = ({ logo, address, title }) => {
  return (
    <>
      <Stack style={{ alignItems: 'center', width: '100%' }} gap={2}>
        <Stack
          gap={1}
          sx={{
            alignItems: 'center',
            borderBottom: '1px solid black',
            padding: '10px',
            width: '100%',
          }}
        >
          <img
            style={{ width: '200px', height: '80px', objectFit: 'contain' }}
            src={logo}
            alt="Institution Logo"
          />
          <Typography variant="body2">{address}</Typography>
        </Stack>
        <Typography variant="h3">{title}</Typography>
      </Stack>
    </>
  )
}

export const AssessmentTable = ({ headers, records }) => {
  const updatedHeaderList = headers.map((labelObj) => {
    switch (labelObj.label) {
      case 'Branch':
        return { ...labelObj, label: 'Program' }
      case 'Subject Name':
        return { ...labelObj, label: 'Course Name' }
      case 'subject Order':
        return { ...labelObj, label: 'Course Order' }
      case 'Subject Code':
        return { ...labelObj, label: 'Course Code' }
      default:
        return labelObj
    }
  })

  return (
    <>
      <StyledTableContainer>
        <table>
          <thead>
            <tr>
              {updatedHeaderList
                ?.filter((column) => column.status == true)
                ?.map((header, index) => (
                  <th key={index}>{header?.label}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {records?.map((student, index) => (
              <tr key={index}>
                {headers
                  ?.filter((column) => column.status == true)
                  ?.map((header, index) => (
                    <td key={index}>{student[header?.label] ?? '--'}</td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </StyledTableContainer>
    </>
  )
}

export const SubjectWiseSummaryReport = ({ data, pageTitle }) => {
  return (
    <>
      <Box sx={{ pageBreakAfter: 'always', marginTop: '20px' }}>
        <Header
          logo={data?.institution?.images?.url}
          address={data?.institution?.address}
          title={pageTitle}
        />
        <SubjectTable subjects={data} />
        <StyledSignatureContainer>
          <Typography variant="h2">Faculty</Typography>
          <Typography variant="h2">Head of the department</Typography>
        </StyledSignatureContainer>
      </Box>
    </>
  )
}

export const SubjectTable = ({ subjects }) => {
  return (
    <>
      <Box sx={{ width: '60%', margin: 'auto' }}>
        <StyledTableContainer>
          <table>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th colSpan={4}>Number of Students Securing</th>
              </tr>
              <tr>
                {[
                  ...new Set(
                    Object.keys(subjects?.percentageWiseCount).flatMap((key) =>
                      Object.keys(subjects?.percentageWiseCount[key]),
                    ),
                  ),
                ]?.map((item, index) => (
                  <th key={index}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(subjects?.percentageWiseCount)?.map((key, index) => (
                <tr key={index}>
                  {Object.keys(subjects?.percentageWiseCount[key])?.map(
                    (item, index) => (
                      <td key={index}>
                        {subjects?.percentageWiseCount[key][item]}
                      </td>
                    ),
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </StyledTableContainer>
      </Box>
    </>
  )
}

export const NoDataComponent = () => {
  return (
    <>
      <StyledLogoContainer>
        <NoDataIllustration
          imageSrc={formImage}
          message={
            <Typography
              variant="h6"
              style={{ textAlign: 'center', marginTop: '20px' }}
            >
              No Data Found
            </Typography>
          }
        />
      </StyledLogoContainer>
    </>
  )
}

export const LabelValue = ({ label, value }) => {
  return (
    <>
      <div style={{ display: 'flex', gap: '10px' }}>
        <Typography>{label ?? '--'}:</Typography>
        <Typography sx={{ fontWeight: 900 }}>{value ?? '--'}</Typography>
      </div>
    </>
  )
}
