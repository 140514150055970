import { Permission } from '@campxdev/shared'

import { Sections, TimeSlotTemplate, TimeTableSlots } from '../pages/Academics'

export const classroomRoutes = [
  {
    element: <TimeSlotTemplate />,
    path: 'time-slot-templates',
    permissionKey: Permission.CAN_TIME_SLOTS_VIEW,
  },
  {
    path: 'time-slot-templates/:timeTableTemplateId/time-slots',
    element: <TimeTableSlots />,
    permissionKey: Permission.CAN_TIME_SLOTS_VIEW,
  },

  {
    element: <Sections />,
    path: 'sections',
    permissionKey: Permission.CAN_INSTITUTION_VIEW,
  },
]
