import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import usersReducer, { fetchUserssEpic } from './slices/usersSlice'
import assessmentMarksReducer, {
  fetchAssessmentMarksEpic,
} from './slices/assessmentMarksSlice'
import studentsReducer, { fetchStudentsEpic } from './slices/studentsSlice'
import postsReducer, {
  fetchPostsEpic,
  fetchMorePostsEpic,
} from './slices/postsSlice'
import auditLogsReducer, { fetchAuditLogsEpic } from './slices/auditLogsSlice'
import curriculumSubjectsReducer, {
  fetchCurriculumSubjectsEpic,
} from './slices/curriculumSubjectsSlice'
import assessmentRulesReducer, {
  fetchAssessmentRulesEpic,
  updateAssessmentEpic,
} from './slices/assessmentRulesSlice'

import assessmentsReducer, {
  fetchAssessmentsEpic,
} from './slices/assessmentsSlice'
import subjectAssessmentsReducer, {
  fetchSubjectAssessmentsEpic,
} from './slices/subjectAssessmentsSlice'
import batchSubjectsReducer, {
  fetchSubjectsEpic,
  fetchExamsEpic,
} from './slices/batchSubjectsSlice'
import seatingArrangementSlice from './slices/seatingArrangementSlice'
import evaluationRulesReducer, {
  fetchEvaluationRulesEpic,
} from './slices/evaluationRulesSlice'
import curriculumsReducer, {
  fetchCurriculumsEpic,
} from './slices/curriculumsSlice'
import bundlesReducer, {
  fetchBundlesEpic,
  editBundleEpic,
  addBundleEpic,
  addSheetEpic,
  saveBundleEpic,
  fetchEvaluatorsEpic,
  cancelEvaluationEpic,
} from './slices/bundlesSlice'
import manualBundlesReducer, {
  BundlesState,
  epics as manualBundleEpics,
} from './slices/manualBundlesSlice'
import evaluationTypesReducer, {
  fetchEvaluationTypesEpic,
} from './slices/evaluationTypesSlice'
import createExamsReducer, {
  fetchDataEpic as fetchExamFormData,
} from './slices/createExamsSlice'
import createExamTimeTableReducer, {
  fetchDataEpic as fetchExamsData,
} from './slices/createExamSlice'
import examTimetableReducer, {
  fetchExamTimetableEpic,
} from './slices/examTimetableSlice'
import examFeeReducer, { fetchExamFeeEpic } from './slices/examFeeSlice'
import subjectAttendanceReducer, {
  fetchSubjectAttendanceEpic,
} from './slices/subjectAttendanceSlice'
import careersReducer, {
  fetchCareersEpic,
  fetchRangeCareersEpic,
} from './slices/careersSlice'
import phdReducer, { fetchPhdApplicationsEpic } from './slices/phdSlice'
import invigilationsSlice, {
  fetchInvigilationsEpic,
} from './slices/invigilationsSlice'

import studentExternalReducer, {
  fetchStudentExternalEpic,
  fetchNonTheoryExternalUpdateReasonsEpic,
} from './slices/studentExternalSlice'
import revaluationReducer, {
  fetchRevaluationStudentsEpic,
} from './slices/revaluationSlice'
import satisfactorySubjectReducer, {
  fetchTheoryExternalEpic as fetchSatisfactorySubjectsEpic,
  fetchTheoryExternalUpdateReasonsEpic as fetchSatisfactorySubjectsUpdateReasonsEpic,
} from './slices/satisfactorySubjectsSlice'
import theoryExternalMarksReducer, {
  fetchTheoryExternalEpic,
  fetchTheoryExternalUpdateReasonsEpic,
} from './slices/theoryExternalMarksSlice'
import internalMarksReducer, {
  fetchInternalMarksReasonsEpic,
  fetchStudentInternalMarksEpic,
} from './slices/internalMarksSlice'
import createInternalExamSlice, {
  fetchInternalExamDataEpic,
} from './slices/createInternalExamSlice'
import formsReducer from 'redux/slices/formsSlice'
import GlobalReducer from 'redux/slices/globalSlice'
export const rootEpic = combineEpics(
  fetchUserssEpic,
  fetchStudentsEpic,
  fetchPostsEpic,
  fetchMorePostsEpic,
  fetchAuditLogsEpic,
  fetchCurriculumSubjectsEpic,
  fetchAssessmentRulesEpic,
  updateAssessmentEpic,
  fetchAssessmentMarksEpic,
  fetchAssessmentsEpic,
  fetchSubjectAssessmentsEpic,
  fetchSubjectsEpic,
  fetchEvaluationRulesEpic,
  fetchCurriculumsEpic,
  fetchBundlesEpic,
  cancelEvaluationEpic,
  addBundleEpic,
  editBundleEpic,
  addSheetEpic,
  saveBundleEpic,
  manualBundleEpics.fetchBundlesEpic,
  manualBundleEpics.cancelEvaluationEpic,
  manualBundleEpics.addBundleEpic,
  manualBundleEpics.editBundleEpic,
  manualBundleEpics.addSheetEpic,
  manualBundleEpics.saveBundleEpic,
  manualBundleEpics.fetchEvaluatorsEpic,
  fetchEvaluatorsEpic,
  fetchEvaluationTypesEpic,
  fetchExamFormData,
  fetchExamsData,
  fetchExamTimetableEpic,
  fetchExamFeeEpic,
  fetchSubjectAttendanceEpic,
  fetchCareersEpic,
  fetchRangeCareersEpic,
  fetchPhdApplicationsEpic,
  fetchInvigilationsEpic,
  fetchExamsEpic,
  fetchStudentExternalEpic,
  fetchRevaluationStudentsEpic,
  fetchTheoryExternalEpic,
  fetchTheoryExternalUpdateReasonsEpic,
  fetchStudentInternalMarksEpic,
  fetchNonTheoryExternalUpdateReasonsEpic,
  fetchInternalMarksReasonsEpic,
  fetchInternalExamDataEpic,
  fetchSatisfactorySubjectsEpic,
  fetchSatisfactorySubjectsUpdateReasonsEpic,
)

export const rootReducer = combineReducers({
  users: usersReducer,
  global: GlobalReducer,
  forms: formsReducer,
  students: studentsReducer,
  posts: postsReducer,
  auditLogs: auditLogsReducer,
  curriculumSubjects: curriculumSubjectsReducer,
  assessmentRules: assessmentRulesReducer,
  assessmentMarks: assessmentMarksReducer,
  assessments: assessmentsReducer,
  subjectAssessments: subjectAssessmentsReducer,
  batchSubjects: batchSubjectsReducer,
  seatingArrangement: seatingArrangementSlice,
  evaluationRules: evaluationRulesReducer,
  curriculums: curriculumsReducer,
  bundles: bundlesReducer,
  manualBundles: manualBundlesReducer,
  evaluationTypes: evaluationTypesReducer,
  createExams: createExamsReducer,
  createExamTimeTable: createExamTimeTableReducer,
  examTimetable: examTimetableReducer,
  examFee: examFeeReducer,
  subjectAttendance: subjectAttendanceReducer,
  careers: careersReducer,
  phd: phdReducer,
  invigilations: invigilationsSlice,
  studentExternal: studentExternalReducer,
  revaluation: revaluationReducer,
  theoryExternalMarks: theoryExternalMarksReducer,
  internalMarks: internalMarksReducer,
  createInternalExam: createInternalExamSlice,
  satisfactorySubjects: satisfactorySubjectReducer,
})

export interface AppState {
  bundles: BundlesState
}
