import { ActionButton } from '@campxdev/shared'
import {
  Box,
  Dialog,
  DialogProps,
  DialogTitle,
  Grow,
  IconButton,
  styled,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React, { ReactNode, forwardRef, useState } from 'react'
// import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
// import { SCROLLBAR_DARK } from '../../theme/muiTheme'
import { Close } from '@mui/icons-material'
import ReportColumns from './ReportColumns'
import { useImmer } from 'use-immer'

const StyledDialogHeader = styled(Box)(() => ({
  height: '64px',
  backgroundColor: '#f7f7f7',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
  padding: '0.6rem 1rem',
  paddingBottom: '10px',
}))

const StyledDialogContent = styled(Box)(() => ({
  width: '100%',
  padding: '1rem',
  maxHeight: 'calc(100vh - 64px)',
  overflow: 'auto',
  //   ...SCROLLBAR_DARK,
}))

export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Grow timeout={1000} ref={ref} {...props} />
})

interface ButtonProps {
  dialogProps?: Omit<DialogProps, 'open'>
  onDialogClose?: () => void
  columns: Array<{ label: string; status: boolean }>
  onSubmit: (columns) => void
}

function CustomReportGenerator({
  dialogProps,
  onDialogClose,
  columns,
  onSubmit,
}: ButtonProps) {
  const [headers, setHeaders] = useImmer([])
  const [open, setOpen] = useState(false)

  const onClose = () => {
    onDialogClose && onDialogClose()
    onSubmit && onSubmit(headers)
    setOpen(false)
  }

  const onOpen = () => {
    setHeaders(columns)
    setOpen(true)
  }

  return (
    <>
      <ActionButton onClick={onOpen} sx={{ marginRight: '10px' }}>
        {'Customize Report'}
      </ActionButton>
      <CustomDialog
        open={open}
        dialogProps={dialogProps}
        onClose={onClose}
        headers={headers}
        setHeaders={setHeaders}
        title={'Customize Report'}
      />
    </>
  )
}

export default CustomReportGenerator

interface CustomDialogProps {
  title?: string
  onClose: () => void
  open: boolean
  setHeaders?: any
  headers: Array<{ label: string; status: boolean }>
  dialogProps?: Omit<DialogProps, 'open'>
}

export const CustomDialog = ({
  onClose,
  dialogProps,
  title,
  open,
  headers,
  setHeaders,
}: CustomDialogProps) => {
  const props = {
    PaperProps: {
      ...dialogProps?.PaperProps,

      elevation: 2,
      sx: { borderRadius: '10px' },
    },

    fullWidth: true,
    onClose: onClose,
    open: open,
    transitionDuration: 140,
    TransitionComponent: Transition,
    sx: {
      ...dialogProps?.sx,
      '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.4)' },
    },
    ...dialogProps,
  }

  return (
    <Dialog {...props}>
      {title && (
        <StyledDialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <IconButton onClick={onClose} sx={{ color: 'black' }}>
            <Close />
          </IconButton>
        </StyledDialogHeader>
      )}
      <StyledDialogContent>
        {/* <ErrorBoundary> */}
        <ReportColumns
          columns={headers}
          onClose={onClose}
          setColumns={setHeaders}
        />
        {/* </ErrorBoundary> */}
      </StyledDialogContent>
    </Dialog>
  )
}
