import { ErrorBoundary, Spinner } from '@campxdev/shared'
import axios from 'axios'
import { StudentResultResponse } from 'pages/StudentResults/service'
import { Store } from 'pullstate'
import { useQuery } from 'react-query'
import { Outlet } from 'react-router-dom'
import Header from './Header'

const tenantKey = window.location.pathname.split('/')[1]

export const resultsAxios = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    'x-tenant-id': tenantKey,
  },
})

export const ResultStore = new Store({
  clientLogo: null,
  step: 1,
  params: {
    rollNo: '',
    semNo: null,
    examType: null,
    honorsMinorsType: null,
  },
  cgpa: null,
  details: null,
  results: null as StudentResultResponse | null,
  showMarks: false,
  loadingDetails: false,
  loadingResults: false,
  examGroupId: null,
  allSem: false,
  allSemResults: [],
})

export default function ResultsLayout() {
  const { isLoading, data } = useQuery('assets', () => {
    return resultsAxios.get('/square/assets').then((res) => {
      ResultStore.update((s) => {
        s.clientLogo = res.data.logo
      })
      return res.data
    })
  })

  if (isLoading) return <Spinner />

  return (
    <div>
      <Header logo={data?.logo} />

      <div
        style={{
          minHeight: '100vh',
          backgroundSize: 'cover',
          height: 'inherit',
        }}
      >
        <div style={{ height: '4rem' }}></div>

        <div style={{ padding: '0rem' }}>
          {
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          }
        </div>
      </div>
    </div>
  )
}
