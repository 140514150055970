import { LabelValue } from '@campxdev/shared'
import { Box, Card, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { FeedbackStatus } from 'pages/Feedback/FacultyFeedbacks/components/FeedbackStatus'

export const FacilityFeedbackDetailsCard = ({ data }) => {
  console.log(data)
  return (
    <Card sx={{ padding: '15px', marginBottom: '30px' }}>
      <Box
        sx={{
          gridTemplateColumns: '1fr 260px',
          display: 'grid',
          gap: '20px',
        }}
      >
        <Stack gap={2}>
          <Typography variant="h6">{data?.title}</Typography>
          <Typography variant="subtitle1">
            {format(new Date(data?.startDate ?? null), 'dd MMM, yyyy')} -{' '}
            {format(new Date(data?.endDate ?? null), 'dd MMM, yyyy')}
          </Typography>
          <LabelValue label="Description" value={data?.description} />
        </Stack>
        <Box>
          <FeedbackStatus status={data?.status} />
          <Box
            sx={{
              padding: '10px',
              borderRadius: '10px',
              background: (theme) => theme.palette.secondary.light,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <LabelValue
              label="Responses"
              value={`${data?.noOfStudentResponses} /${data?.totalNoOfStudentResponses}`}
            />
            <LabelValue label="Total Students" value={data?.noOfStudents} />
          </Box>
        </Box>
      </Box>
    </Card>
  )
}
