import { Permission } from '@campxdev/shared'
import { Navigate } from 'react-router-dom'
import { People } from '../pages/People'
import { academicRoutes } from './academics'
import { assessmentRoutes } from './assessments'
import { classroomRoutes } from './classrooms'
import { courseRoutes } from './course'
import { individualRoutes } from './individual'

export const baseRoutes = [
  {
    index: true,
    element: <Navigate to={'application-profiles'} />,
    permissionKey: Permission.CAN_MANAGE_SETTINGS,
  },
  {
    path: 'application-profiles',
    element: <People />,
    permissionKey: Permission.CAN_MANAGE_SQUARE_PROFILE_PERMISSIONS_VIEW,
  },

  {
    path: 'academics/*',
    children: academicRoutes,
  },

  {
    path: 'courses/*',
    children: courseRoutes,
  },

  {
    path: 'assessments/*',
    children: assessmentRoutes,
  },

  {
    path: 'classrooms/*',
    children: classroomRoutes,
  },
  {
    path: 'print',
    children: individualRoutes,
  },

  {
    path: '*',
    element: <h1>Page Not found</h1>,
  },
]
