import {
  ActionButton,
  AsyncSearchSelect,
  DeleteButton,
  FormDatePicker,
  FormSingleSelect,
  FormTextField,
  Media,
  MediaRow,
  Spinner,
  axios,
  useErrorModal,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Add } from '@mui/icons-material'
import UploadIcon from '@mui/icons-material/Upload'
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  alpha,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import {
  FundingSources,
  PatentStatus,
  createProject,
  fetchPrograms,
  uploadFiles,
} from '../service'
import {
  StyledDeleteButton,
  StyledStack,
  StyledUploadContainer,
  StyledUploadedFileContainer,
} from '../styles'
import ImageUpload from './UploadImage'

export const schema = yup.object().shape({
  title: yup.string().required('Project title is required'),
  programName: yup.string().required('Research Area is required'),
  description: yup.string().required('Description is required'),
  projectLeadIds: yup
    .array()
    .min(1, 'Need at least 1 project lead')
    .required('Lead Researcher is required'),
  projectMemberIds: yup
    .array()
    .min(1, 'Need at least 1 project member')
    .required('Project Member is required'),
  fundingSource: yup.string().required('Funding source is required'),
  patentStatus: yup.string().required('Patent Status should not be empty'),
  startDate: yup.string().required('Start date is required').nullable(),
  fundingAmount: yup.string().required('Funding Amount is required'),
  endDate: yup.string().required('End date is required').nullable(),
  coverImage: yup.array().min(1).required('Cover Image is required').nullable(),
  keywords: yup
    .array()
    .of(
      yup.object().shape({
        keyword: yup.string().required('Keyword is required'),
      }),
    )
    .min(1, 'Need at least 1 keyword')
    .required('Keyword minimum of 1 is required'),
})

export default function CreateProjectForm({
  data,
  close,
}: {
  data?: any
  close: () => void
}) {
  const [coverMedia, setCoverMedia] = useState<Media[]>([])
  const [attachmentMedia, setAttachmentMedia] = useState<Media[]>([])
  const queryClient = useQueryClient()
  const errorModal = useErrorModal()
  const { isLoading: loadingUsers, data: options } = useQuery('programs', () =>
    fetchPrograms(),
  )

  const { control, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      attachment: [],
      coverImage: [],
      startDate: null,
      endDate: null,
      projectLeadIds: [],
      projectMemberIds: [],
      keywords: [],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'keywords',
  })

  const fetchFacultyUsers = async ({ ...rest }) => {
    const res = await axios.get(`/hrms/employees`, {
      params: { ...rest, isActive: true },
    })
    return res.data?.data?.map((item) => ({
      label: item?.fullName,
      value: item?._id,
    }))
  }

  const { mutate, isLoading: creatingProject } = useMutation(createProject, {
    onSuccess: (res) => {
      close()
      toast.success(`Project created successfully`)
      queryClient.invalidateQueries('projects')
    },
    onError: (err) => {
      errorModal({ error: err })
    },
  })

  const onSubmit = async (formData) => {
    const postBody = {
      ...formData,
      title: formData?.title,
      programName: formData?.programName,
      description: formData?.description,
      startDate: formData?.startDate,
      endDate: formData?.endDate,
      fundingSource: formData.fundingSource,
      fundingAmount: formData?.fundingAmount,
      patentStatus: formData?.patentStatus,
      imageKey: coverMedia[0]?.key,
      attachmentKeys: attachmentMedia?.map((item) => item?.key) ?? [],
      projectLeadIds:
        formData?.projectLeadIds?.map((item) => item?.value.toString()) ?? [],
      projectMemberIds:
        formData?.projectMemberIds?.map((item) => item?.value.toString()) ?? [],
      keywords: formData?.keywords?.map((item) => item?.keyword),
    }

    mutate(postBody)
  }

  const onError = (err) => {
    // eslint-disable-next-line no-console
    console.log(err)
  }

  const { mutate: uploadFile, isLoading: uploadingFile } = useMutation(
    uploadFiles,
    {
      onSuccess: (res) => {
        setValue('attachment', [
          ...watch('attachment'),
          { fileName: res?.originalFileName, key: res?.key, url: res?.url },
        ])
        setAttachmentMedia((prev) => [
          ...prev,
          {
            key: res?.key,
            type: 'file',
            url: res.url,
            fileName: res.originalFileName,
          },
        ])
      },
      onError: (err) => {
        // eslint-disable-next-line no-console
        console.log(err)
        errorModal({ error: err })
      },
    },
  )

  const handleFileInput = (e) => {
    let filesData = e.target.files
    let fileArray = Object.keys(filesData)
    fileArray.forEach(async (file) => {
      const fileData = new FormData()
      fileData.append('file', filesData[file])
      fileData.append('subType', 'attachment')
      uploadFile(fileData)
    })
  }

  const onFileUploaded = (res: any) => {
    setValue('coverImage', [{ key: res.key, type: 'image', url: res.url }])
    setCoverMedia([{ type: 'image', url: res.url, key: res.key }])
  }

  const handleDeleteCoverImage = (key: string) => {
    setValue('coverImage', [])
    setCoverMedia((prev) => prev.filter((m) => m.key !== key))
  }

  useEffect(() => {
    if (watch('keywords').length == 0) {
      append({ keyword: '' })
    }
  }, [])

  if (loadingUsers) {
    return <Spinner />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Stack gap={2}>
        <Stack direction={'row'} gap={0.4}>
          <Typography sx={{ opacity: '0.58' }}>Cover Image</Typography>
          <Typography sx={{ color: '#f5222d' }}>*</Typography>
        </Stack>
        {coverMedia?.length ? (
          <MediaRow list={coverMedia} onDelete={handleDeleteCoverImage} />
        ) : (
          <Controller
            name={'coverImage'}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <ImageUpload
                  onFileUploaded={(res) => {
                    onFileUploaded(res)
                  }}
                  postUrl="/square/research-projects/upload"
                  postBody={{ subType: 'image' }}
                />
                {error && (
                  <Typography
                    variant="caption"
                    sx={{ pl: '2px', pt: '6px' }}
                    color="rgb(211, 47, 47)"
                  >
                    {error?.message}
                  </Typography>
                )}
              </>
            )}
          />
        )}
      </Stack>
      <Stack gap={3.5}>
        <FormTextField
          name="title"
          label={'Research Project Title'}
          control={control}
          required
        />

        <FormSingleSelect
          label="Research Areas/Field"
          control={control}
          name="programName"
          options={options?.map((item) => ({
            value: item.branchName,
            label: item.branchName,
          }))}
          required
        />

        <FormTextField
          label="Description/Abstract"
          name="description"
          control={control}
          multiline
          minRows={4}
          required
        />

        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FormDatePicker
            maxDate={watch('endDate')}
            name="startDate"
            label="Start date "
            control={control}
            required
          />

          <FormDatePicker
            name="endDate"
            label="End date "
            control={control}
            required
            minDate={watch('startDate')}
          />
        </Box>
        <StyledStack direction={'row'}>
          <Typography variant="h3">Keywords</Typography>
          <Button
            variant="text"
            startIcon={<Add />}
            size="small"
            onClick={() => append({ keyword: '' })}
          >
            Add
          </Button>
        </StyledStack>
        {fields?.map((field, index) => (
          <Box key={field?.id}>
            <StyledStack
              direction={'row'}
              gap={2}
              sx={{ alignItems: 'flex-end' }}
            >
              <FormTextField
                name={`keywords.${index}.keyword`}
                label={`Keyword ${index + 1}`}
                control={control}
                required
              />

              <StyledDeleteButton onClick={() => remove(index)} />
            </StyledStack>
          </Box>
        ))}
        <Controller
          name={'projectLeadIds'}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <AsyncSearchSelect
                label="Lead Researcher"
                fetchFn={fetchFacultyUsers}
                onChange={(value) => {
                  setValue('projectLeadIds', value)
                }}
                initialOptions={watch('projectLeadIds')}
                required
              />
              {error && (
                <Typography
                  variant="caption"
                  sx={{ pl: '2px', pt: '6px' }}
                  color="rgb(211, 47, 47)"
                >
                  {error?.message}
                </Typography>
              )}
            </>
          )}
        />

        <Controller
          name={'projectMemberIds'}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <AsyncSearchSelect
                label="Project Members"
                fetchFn={fetchFacultyUsers}
                onChange={(value) => {
                  setValue('projectMemberIds', value)
                }}
                initialOptions={watch('projectMemberIds')}
                required
              />
              {error && (
                <Typography
                  variant="caption"
                  sx={{ pl: '2px', pt: '6px' }}
                  color="rgb(211, 47, 47)"
                >
                  {error?.message}
                </Typography>
              )}
            </>
          )}
        />

        <FormSingleSelect
          label={'Funding Source'}
          name="fundingSource"
          control={control}
          options={
            Object.keys(FundingSources)?.map((key) => ({
              label: FundingSources[key],
              value: key,
            })) ?? []
          }
          required
        />

        <FormTextField
          label={'Funding Amount'}
          name="fundingAmount"
          control={control}
          type="number"
          required
        />

        <FormSingleSelect
          label={'Patent Status'}
          name="patentStatus"
          control={control}
          options={
            Object.keys(PatentStatus)?.map((key) => ({
              label: PatentStatus[key],
              value: key,
            })) ?? []
          }
          required
        />

        <Box>
          <Typography
            sx={{
              color: alpha('#121212', 0.5),
              lineHeight: 2,
            }}
          >
            Add Files
          </Typography>
          {attachmentMedia.length !== 0 && (
            <FileRender
              state={attachmentMedia}
              setState={setAttachmentMedia}
              setValue={setValue}
            />
          )}
          <Controller
            name={'attachment'}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <StyledUploadContainer htmlFor="file">
                  <UploadIcon />
                  <Typography variant="subtitle2" sx={{ margin: '0px 10px' }}>
                    Upload Files
                  </Typography>
                  {uploadingFile && <CircularProgress size={20} />}
                </StyledUploadContainer>

                {error && (
                  <Typography
                    variant="caption"
                    sx={{ pl: '2px', pt: '6px' }}
                    color="rgb(211, 47, 47)"
                  >
                    {error?.message}
                  </Typography>
                )}
              </>
            )}
          />
          <input
            id="file"
            type="file"
            hidden
            multiple
            onChange={handleFileInput}
          />
        </Box>
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <ActionButton variant="outlined" fullWidth onClick={close}>
          Cancel
        </ActionButton>
        <ActionButton type="submit" fullWidth loading={creatingProject}>
          {data ? 'Update Project' : 'Add Project'}
        </ActionButton>
      </Stack>
    </form>
  )
}

export const FileRender = ({ state, setState, setValue }) => {
  const onDelete = (fileKey) => {
    setValue(
      'attachment',
      state.filter((item) => item.key !== fileKey),
    )
    setState((prev) => prev?.filter((item) => item.key !== fileKey))
  }

  return (
    <>
      {state?.map((file, index) => {
        let extension = file.fileName.split('.')[1]
        return (
          <StyledUploadedFileContainer key={index}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {extension === 'pdf' ? (
                <span>
                  <img src={`/images/pdf.png`} />
                </span>
              ) : extension === 'pptx' ? (
                <span>
                  <img src={'/images/ppt.png'} />
                </span>
              ) : extension === 'txt' ? (
                <span>
                  <img src={'/images/doc.png'} />
                </span>
              ) : (
                <span>
                  <img src={'/images/jpg.png'} />
                </span>
              )}

              <Typography sx={{ margin: '0px 10px' }}>
                {file.fileName}
              </Typography>
            </Box>
            <DeleteButton
              onClick={() => {
                onDelete(file.key)
              }}
            />
          </StyledUploadedFileContainer>
        )
      })}
    </>
  )
}
