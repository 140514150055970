import { createSlice } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { map, switchMap } from 'rxjs/operators'
import { axios } from '@campxdev/shared'
import serverErrorDialog from '../../components/serverErrorDialog'

interface TheoryExternalState {
  data: Array<any>
  reasons: Array<any>
  error: any
  loading: boolean
  subjectId: number
  monthYear: string
  semNo: number
  courseId: number
  branchCode: string
  examType: string
}

const initialState: TheoryExternalState = {
  data: [],
  reasons: [],
  error: null,
  loading: false,
  subjectId: null,
  monthYear: null,
  semNo: null,
  courseId: null,
  branchCode: null,
  examType: null,
}

const theoryExternalMarksSlice = createSlice({
  name: 'theoryExternalMarks',
  initialState,
  reducers: {
    fetchTheoryExternalMarks(state, action) {
      state.loading = true
      state.subjectId = action.payload.subjectId
      state.monthYear = action.payload.monthYear
      state.semNo = action.payload.semNo
      state.courseId = action.payload.courseId
      state.branchCode = action.payload.branchCode
      state.examType = action.payload.examType
    },
    // fetchingAdmissions(state) {
    //   state.loading = true;
    // },
    fetchedTheoryExternalMarks(state, action) {
      state.data = action.payload.data
      // state.careers = action.payload.data;
      state.loading = false
      state.error = null
    },

    fetchedUpdateReasons(state, action) {
      state.reasons = action.payload.data
      // state.careers = action.payload.data;
      state.loading = false
      state.error = null
    },

    updateMarks(state, action) {
      state.data[action.payload.index][action.payload.key] =
        action.payload.value
      // state.careers = action.payload.data;
    },
    updateAbsent(state, action) {
      if (action.payload.value == 1) {
        state.data[action.payload.index]['marks'] = 0
      }
      state.data[action.payload.index][action.payload.key] =
        action.payload.value
      // state.careers = action.payload.data;
    },
    clearState(state, action) {
      state.data = []
    },
  },
})

export const {
  fetchTheoryExternalMarks,
  fetchedTheoryExternalMarks,
  updateMarks,
  updateAbsent,
  clearState,
  fetchedUpdateReasons,
} = theoryExternalMarksSlice.actions

export function fetchTheoryExternalEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchTheoryExternalMarks.type),
    switchMap(async (action: any) => {
      try {
        let res = await axios.get(`/square/external-marks`, {
          params: {
            subjectId: action.payload.subjectId,
            monthYear: action.payload.monthYear,
            batch: action.payload.batch,
            branchCode: action.payload.branchCode,
            examType: action.payload.examType,
          },
        })
        return {
          data: res.data,
        }
      } catch (err) {
        serverErrorDialog({ error: err })
        return {
          data: [],
        }
      }
    }),
    map((data) => fetchedTheoryExternalMarks(data)),
  )
}

export function fetchTheoryExternalUpdateReasonsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchTheoryExternalMarks.type),
    switchMap(async (action: any) => {
      try {
        let res = await axios.get(`/square/update-reason`, {
          params: {
            type: 'external',
            subjectId: action.payload.subjectId,
            monthYear: action.payload.monthYear,
            batch: action.payload.batch,
            branchCode: action.payload.branchCode,
          },
        })
        return {
          data: res.data,
        }
      } catch (err) {
        serverErrorDialog({ error: err })
        return {
          data: [],
        }
      }
    }),
    map((data) => fetchedUpdateReasons(data)),
  )
}

export default theoryExternalMarksSlice.reducer
