import { Box, styled } from '@mui/material'

export const StyledMainContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: '20px',
  padding: '0 25px',
}))

export const StyledFiltersContainer = styled(Box)(({ theme }) => ({
  border: theme?.borders?.grayLight,
  borderRadius: '10px',
  minWidth: '350px',
  flexShrink: 0,
}))

export const StyledFiltersHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme?.palette?.secondary?.light,
  padding: '20px',
  borderRadius: '10px 10px 0 0',
}))
