import { Permission } from '@campxdev/shared'

import { lazy } from 'react'

const Events = lazy(() => import('pages/Activities/Events'))
const EventDetails = lazy(() => import('pages/Activities/Events/EventDetails'))
const EventGallery = lazy(() => import('pages/Activities/Events/EventGallery'))
const EventReportsViewLayout = lazy(
  () => import('pages/Activities/ReportsViewLayout'),
)

const ClubDetails = lazy(() => import('pages/Activities/Clubs/ClubDetails'))
const Clubs = lazy(() => import('pages/Activities/Clubs'))
const EventsReport = lazy(() => import('pages/Activities/EventsReport'))
export const activitesRoutes = [
  {
    element: <EventReportsViewLayout />,
    path: 'reports/report-view/*',
    permissionKey: Permission.EXTRA_CURRICULAR_ACTIVITIES_EVENTS,
  },
  {
    element: <EventsReport />,
    path: 'reports',
    exact: true,
    permissionKey: Permission.EXTRA_CURRICULAR_ACTIVITIES_CLUBS,
  },
  {
    element: <ClubDetails />,
    path: 'clubs/:clubId/details',
    permissionKey: Permission.EXTRA_CURRICULAR_ACTIVITIES_CLUBS,
  },
  {
    element: <Clubs />,
    path: 'clubs',
    permissionKey: Permission.EXTRA_CURRICULAR_ACTIVITIES_CLUBS,
  },

  {
    element: <EventDetails />,
    path: 'events/:eventId/details',

    permissionKey: Permission.EXTRA_CURRICULAR_ACTIVITIES_EVENTS,
  },
  {
    element: <EventGallery />,
    path: 'events/:eventId/details/gallery',

    permissionKey: Permission.EXTRA_CURRICULAR_ACTIVITIES_EVENTS,
  },
  {
    element: <Events />,
    path: 'events',

    permissionKey: Permission.EXTRA_CURRICULAR_ACTIVITIES_EVENTS,
  },
]
