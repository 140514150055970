import { axios } from '@campxdev/shared'
import { createSlice } from '@reduxjs/toolkit'
import { examGroupBatches } from 'constants/constants'
import { ofType } from 'redux-observable'
import { map, switchMap } from 'rxjs/operators'
import { SERVICES } from 'services'

const initialState = {
  loading: true,
  data: {},
  courses: [],
  semesters: [],
  regulations: [],
  sessionsByTime: {},
  batches: examGroupBatches,
  newBatches: examGroupBatches,
  selected: {
    displayName: '',
    courseId: null,
    semNo: null,
    regulation: '',
    examYear: null,
    examMonth: null,
    batch: null,
  },
}

const slice = createSlice({
  name: 'createInternalExam',
  initialState: initialState,
  reducers: {
    fetchData(state) {
      state.loading = true
    },
    fetchedData(state, action) {
      state.loading = false
      state.courses = action.payload.courses
      state.semesters = action.payload.semesters
      state.regulations = action.payload.regulations
    },

    select(state, action) {
      const key = action.payload.key
      state.selected[key] = action.payload.value
      if (key == 'courseId') {
        state.selected['semesterId'] = null
      }
    },
    selectRegulation(state, action) {
      const key = action.payload.key
      state.selected[key] = action.payload.value
      const r = action.payload.value.split('R')[1]
      const index = state.batches.findIndex((item) => {
        return item.split('-')[0].trim() == `20${r}`
      })
      state.newBatches = state.batches.slice(index, state.batches.length)
      if (key == 'courseId') {
        state.selected['semesterId'] = null
      }
    },

    clearState(state, action) {
      state.newBatches = state.batches
      state.selected = {
        displayName: '',
        courseId: null,
        semNo: null,
        regulation: '',
        examMonth: null,
        examYear: null,
        batch: null,
      }
    },
  },
})
export function fetchInternalExamDataEpic(action$) {
  return action$.pipe(
    ofType(fetchData),
    switchMap(async (action: any) => {
      const courses = await SERVICES.courses.fetchCourses()
      const semesters = await axios.get('/square/semesters')
      const regulations = await axios.get('/square/exams/regulations')
      return {
        courses: courses,
        semesters: semesters.data,
        regulations: regulations.data,
      }
    }),
    map(fetchedData),
  )
}

const { fetchedData } = slice.actions

export const { fetchData, select, selectRegulation, clearState } = slice.actions

export default slice.reducer
