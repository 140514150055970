import { axios } from '@campxdev/shared'

type CreateSurveyDto = {
  title: string
  description: string
  programIds?: string[]
  targetAudience: string
  startDate: string
  endDate: string
}

type GetSurveyDto = {
  isArchived?: boolean
  search?: string
  offset?: number
  limit?: number
}

const ENDPOINT = '/square/surveys'
export const surveys = {
  async fetchAll(query: GetSurveyDto) {
    const res = await axios.get(ENDPOINT, {
      params: query,
    })
    return res.data
  },
  async fetchOne(id: string) {
    const res = await axios.get(`${ENDPOINT}/${id}`)
    return res.data
  },

  async create(body: CreateSurveyDto) {
    return axios.post(ENDPOINT, body)
  },

  async createSurveyForm(data: { surveyId: string }) {
    return axios.post(`${ENDPOINT}/${data.surveyId}/new-form`)
  },

  async copyExistingForm(data: { surveyId: string; formId: string }) {
    return axios.post(`${ENDPOINT}/${data.surveyId}/copy-existing-form`, {
      formId: data.formId,
    })
  },

  async archiveSurvey(data: { surveyId: string }) {
    return axios.post(`${ENDPOINT}/${data.surveyId}/archive`)
  },

  async changeStatus(body: {
    surveyId: string
    status: 'draft' | 'published' | 'closed'
  }) {
    return axios.post(`${ENDPOINT}/${body.surveyId}/change-status`, {
      status: body.status === 'closed' ? 'archived' : body.status,
    })
  },

  async getResponses(data: { surveyId: string }) {
    const res = await axios.get(`${ENDPOINT}/${data.surveyId}/all-responses`)
    return res.data
  },

  async getQuestionResponses(data: { surveyId: string }) {
    const res = await axios.get(
      `${ENDPOINT}/${data.surveyId}/question-wise-responses`,
    )
    return res.data
  },
}
