import { axios } from '@campxdev/shared'

const ENDPOINT = '/square/faculty-feedbacks'
export const facultyFeedbacks = {
  async upsertFacultyFeedback({ formData }) {
    const res = !formData._id
      ? await axios.post(ENDPOINT, {
          ...formData,
        })
      : await axios.put(`${ENDPOINT}/${formData._id}`, {
          ...formData,
        })
    return formData
  },
  async updateFacultyFeedbackFormConfiguration(data) {
    const res = await axios.put(`${ENDPOINT}/${data._id}/configure-form`, data)
    return data
  },
  async updateFeedbackStatus(data) {
    const res = await axios.put(`${ENDPOINT}/${data._id}/change-status`, data)
    return res
  },
  async getFacultyFeedbacks(params) {
    const res = await axios.get(ENDPOINT, {
      params: params,
    })
    return res.data.data
  },

  async deleteFacultyFeedback(id) {
    const res = await axios.delete(`${ENDPOINT}/${id}`)
    return res.data
  },

  async getDetailedQuestionWiseDistribution(params) {
    const res = await axios.get(
      `${ENDPOINT}/${params.id}/detailed-question-wise-distribution`,
      {
        params,
      },
    )
    return res.data
  },

  async getQuestionWiseDistribution(params) {
    const res = await axios.get(
      `${ENDPOINT}/${params.id}/question-wise-distribution`,
      {
        params,
      },
    )
    return res.data
  },

  async getFacultyFeedbackById(id) {
    const res = await axios.get(`${ENDPOINT}/${id}`)
    return res.data
  },
  async getFacultyFeedbackDistributionById(id) {
    const res = await axios.get(
      `${ENDPOINT}/${id}/target-audience-distribution`,
    )
    return res.data
  },
  async addFacultyFeedbackTargetAudience({ id, body }) {
    return axios.post(`${ENDPOINT}/${id}/add-target-audience`, body)
  },
  async deleteFacultyFeedbackTargetAudience({ id, body }) {
    return axios.post(`${ENDPOINT}/${id}/delete-target-audience`, body)
  },

  async getFeedbackDistributionByClassroom(query, id) {
    const res = await axios.get(
      `${ENDPOINT}/${id}/classroom-wise-distribution`,
      {
        params: query,
      },
    )
    return res.data
  },
  async getFeedbackDistributionForClassroom(classroomId, id) {
    const res = await axios.get(
      `${ENDPOINT}/${id}/classroom-distribution/${classroomId}`,
    )
    return res.data
  },
  async getFeedbackAnalysisForClassroom(params) {
    const res = await axios.get(`${ENDPOINT}/classroom-analysis`, {
      params: params,
    })
    return res.data
  },
}
