import {
  ErrorBoundary,
  LayoutWrapper,
  withRouteWrapper,
  withSuspense,
} from '@campxdev/shared'
import PageNotFound from '@campxdev/shared/src/components/PageNotFound'
import AppLayout from 'layouts/AppLayout'
import { lazy } from 'react'
const ForgotPassword = lazy(() => import('pages/ForgotPassword'))

import PrintLayout from '../layouts/PrintLayout'
import { baseRoutes } from './baseRoutes'
import { individualRoutes } from './individualRoutes'
import { resultsRoutes } from './results'

import { settingsRoutes } from '@campx/settings'
import { dashboardMenu } from 'layouts/DashboardLayout/dashboardMenu'
import FeedbackLayout from 'layouts/FeedbackLayout'
import PublicFormsLayout from 'layouts/PublicFormsLayout'
import ResultsLayout from 'layouts/ResultsLayout'
import SettingsLayout from 'layouts/SettingsLayout/SettingsLayout'
import { Outlet } from 'react-router-dom'
import { feedbackRoutes } from './feedbackRoutes'
import { publicRoutes } from './public'
import { publicFormsRoutes } from './publicFormsRoutes'

export const mainRoutes = [
  {
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: (
          <LayoutWrapper menu={dashboardMenu}>
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </LayoutWrapper>
        ),
        children: withRouteWrapper(baseRoutes),
      },
      {
        element: <SettingsLayout />,
        path: '/settings',
        children: withRouteWrapper(settingsRoutes),
      },
    ],
  },
  {
    path: '/print',
    element: <PrintLayout />,
    children: withRouteWrapper(individualRoutes),
  },
  {
    path: '/faculty-feedback-reports',
    element: <FeedbackLayout />,
    children: feedbackRoutes,
  },
  {
    path: '/forgot-password',
    element: withSuspense(<ForgotPassword />),
  },
  {
    element: <ResultsLayout />,
    children: resultsRoutes,
  },
  {
    path: '/public-forms',
    element: <PublicFormsLayout />,
    children: publicFormsRoutes,
  },
  {
    path: '/public',
    element: <PublicFormsLayout />,
    children: publicRoutes,
  },

  {
    path: '*',
    element: <PageNotFound />,
  },
]
