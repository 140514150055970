import { Permission } from '@campxdev/shared'
import { DigitalNoticeBoard } from 'pages/DigitalNoticeBoard'
import { CreateAnnouncementForm } from 'pages/DigitalNoticeBoard/Forms/CreateAnnouncementForm'
import { CreatePollForm } from 'pages/DigitalNoticeBoard/Forms/CreatePollForm'
import { CreatePostForm } from 'pages/DigitalNoticeBoard/Forms/CreatePostForm'

export const digitalNoticeBoardRoutes = [
  {
    element: <DigitalNoticeBoard />,
    path: 'digitalNoticeBoard',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    element: <CreatePollForm />,
    path: 'digitalNoticeBoard/createPollForm',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    element: <CreatePostForm />,
    path: 'digitalNoticeBoard/createPostForm',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    element: <CreateAnnouncementForm />,
    path: 'digitalNoticeBoard/createAnnouncementsForm',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
]
