import { createSlice } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { map, switchMap } from 'rxjs/operators'
import { axios } from '@campxdev/shared'

const initialState = {
  loading: true,
  data: null,
  error: null,
  selectedSubject: null,
  subjects: [],
  selectedSubjectIndex: null,
}

const curriculumSubjects = createSlice({
  name: 'curriculum-subjects',
  initialState: initialState,
  reducers: {
    fetchCurriculumSubjects(state, action: any) {
      state.loading = true
    },
    fetchedCurriculumSubjects(state, action: any) {
      state.loading = false
      state.data = action.payload.data
      state.subjects = action.payload.data.subjects
      state.selectedSubjectIndex = state.selectedSubjectIndex || 0
    },
    selectCurriculumSubject(state, action: any) {
      state.selectedSubjectIndex = action.payload.subjectIndex
    },
    clearCurriculumsSubjectsState(state, action: any) {
      state.loading = true
      state.data = null
      state.error = null
      state.selectedSubjectIndex = null
      state.subjects = null
    },
    updateOutcome(state, action) {
      state.subjects[state.selectedSubjectIndex][action.payload.key] =
        action.payload.value
    },
    updateObjectives(state, action) {
      state.subjects[state.selectedSubjectIndex]['courseObjectives'] =
        action.payload.courseObjectives
    },
    onSearch(state, action: any) {
      let value = action.payload.value
      if (!value) {
        state.subjects = state.data.subjects
        return
      }
      state.subjects = state.data.subjects.filter((subject) => {
        return subject.name.toLowerCase().includes(value.toLowerCase())
      })
    },
    refreshCurriculumSubjects(state, action) {
      state.selectedSubjectIndex = action.payload.subjectIndex
    },
  },
})

export function fetchCurriculumSubjectsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchCurriculumSubjects.type),
    switchMap(async (action: any) => {
      const res = await axios.get(
        `/square/curriculums/${action.payload.curriculumId}/subjects`,
      )
      return {
        data: res.data,
      }
    }),
    map(fetchedCurriculumSubjects),
  )
}

export const {
  fetchCurriculumSubjects,
  fetchedCurriculumSubjects,
  selectCurriculumSubject,
  clearCurriculumsSubjectsState,
  onSearch,
  updateObjectives,
  updateOutcome,
} = curriculumSubjects.actions

export default curriculumSubjects.reducer
